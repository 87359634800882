import React from "react";

const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.08442 9.75664C2.08442 5.92555 5.09363 2.81983 8.80567 2.81983C12.5177 2.81983 15.5269 5.92555 15.5269 9.75664C15.5269 13.5877 12.5177 16.6934 8.80567 16.6934C5.09363 16.6934 2.08442 13.5877 2.08442 9.75664ZM8.80567 0.837891C4.03304 0.837891 0.164062 4.83095 0.164062 9.75664C0.164062 14.6824 4.03304 18.6754 8.80567 18.6754C10.846 18.6754 12.7213 17.9455 14.1997 16.7251L17.7285 20.3671C18.1035 20.754 18.7114 20.754 19.0864 20.3671C19.4613 19.9801 19.4613 19.3526 19.0864 18.9656L15.5575 15.3236C16.7401 13.7978 17.4473 11.8625 17.4473 9.75664C17.4473 4.83095 13.5783 0.837891 8.80567 0.837891Z"
        fill="#36CDB4"
      />
    </svg>
  );
};

export default Search;
