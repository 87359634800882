import { Typography } from "@mui/material";
import React, { useState } from "react";
import EditModal from "./EditModal";
import { useSelector } from "react-redux";

const ControllerCard = ({ heading, selection }) => {
  const product = useSelector((state) => state.activeStep.product);

  const [edit, setEdit] = useState("");
  const [show, setShow] = useState(false);

  // console.log(selection);
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          <>Edit</>
        </Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          // padding: "10px",
        }}
      >
        {product?.accessories?.dimmer?.name ? (
          <div
            style={{
              border: "2px solid #AFE9E0",
              width: "100%",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img src={selection?.image} alt="" />
          </div>
        ) : (
          <div
            style={{
              height: "100px",
              width: "100%",
              border: "2px solid #AFE9E0",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                margin: "20px",
                fontSize: "32px",
                fontWeight: "400",
                fontFamily: "Beatrice",
                color: "#000000",
              }}
            >
              N/A
            </Typography>
          </div>
        )}
      </div>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default ControllerCard;
