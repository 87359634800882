import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrice,
  fetchColors,
  fetchExamples,
  fetchHelp,
  setLockedHeight,
  setLockedWidth,
  setMaterialLengthFactor,
  setProductBackingHeight,
  setProductBackingWidth,
  setProductColorDescription,
  setProductFont,
  setProductSizeHeight,
  setProductSizeWidth,
  updatePositions,
} from "../Redux/Slice";
import { Typography } from "@mui/material";
import ColorsList from "../Components/Colors/ColorsList";
import BottomControls from "../Components/BottomControls";
import {
  calculateFontArea,
  calculateLetterLength,
} from "../utils/client/calculations";
import axios from "axios";
import RainbowColor from "../Components/Colors/RainbowColor";
import Draggable from "react-draggable";
import SuperColor from "./SuperCustom/SuperColor";
import ExampleModal from "../Components/ExampleModal";
import "./../App.css";
import "./styles/Colors.css";
import CustomColorPopup from "../Components/Colors/CustomColorPopup";
import DescriptionButton from "../Components/DescriptionButton";
import ClearButton from "../Components/ClearButton";
import { ThreeDots } from "react-loader-spinner";
import PriceEstimate from "../Components/PriceEstimate";
import SplitColors from "../Components/SuperCustom/SuperColor/SplitColors";

const Colors = () => {
  const width = window.innerWidth;
  const product = useSelector((state) => state.activeStep.product);
  const colorDesc = useSelector(
    (state) => state.activeStep.product.colorDescription
  );
  const showConsoles = useSelector((state) => state.activeStep.showConsoles);
  const fonts = useSelector((state) => state.activeStep.fonts);
  const colors = useSelector((state) => state.activeStep.colors);
  const [showCustom, setShowCustom] = useState(false);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showRGB, setShowRGB] = useState(false);
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [textAreas, setTextAreas] = useState(product?.fonts);
  const [selectedTextArea, setSelectedTextArea] = useState(textAreas[0]?.id);
  const [showSplit, setShowSplit] = useState(false);

  const desc = colorDesc ? colorDesc : "";
  const dispatch = useDispatch();
  const positions = useSelector((state) => state.activeStep.positions);
  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});
  const [warningThree, setWarningThree] = useState({});

  const theme = useSelector((state) => state.activeStep.theme);

  const handleColorSelection = (color) => {
    if (selectedTextArea !== null) {
      // console.log("color");
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          // console.log(selectedTextArea);
          if (textArea.id === selectedTextArea) {
            // console.log(" matched");
            document.documentElement.style.setProperty("--stroke-color", color);
            let isCustom = !colors.find((c) => c.code === color);
            // console.log(color);
            // console.log(isCustom);
            if (isCustom) {
              return { ...textArea, color: color, customColor: true };
            } else {
              return { ...textArea, color: color, customColor: false };
            }
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleTextAreaClick = (id) => {
    // console.log(id);
    setSelectedTextArea(id);
  };

  const handleLetterColorSelection = (color) => {
    if (selectedLetter !== null) {
      setTextAreas((prevState) => {
        const updatedTextAreas = prevState.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // console.log("TextArea Matched");
            const updatedLetters = textArea?.letters?.map((letter) => {
              if (letter.id === selectedLetter) {
                let isCustom = !colors.find((c) => c.code === color);
                if (isCustom) {
                  return { ...letter, color: color, customColor: true };
                } else {
                  return { ...letter, color: color, customColor: false };
                }
              }
              return letter;
            });
            const updatedTextArea = { ...textArea, letters: updatedLetters };
            return updatedTextArea;
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
    }
  };

  const handleSplitButtonClick = () => {
    // console.log(selectedTextArea);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            const letters = textArea.value.split("");
            const coloredLetters = letters.map((letter, index) => ({
              id: index,
              letter,
              color: textArea.color, // default color
            }));
            return {
              ...textArea,
              // color: "black",
              isSplit: true,
              letters: coloredLetters,
            };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
      setShowSplit(true);
    }
  };

  const handleMergeButtonClick = () => {
    // console.log(textAreas);
    if (selectedTextArea !== null) {
      setTextAreas((prevTextAreas) => {
        const updatedTextAreas = prevTextAreas.map((textArea) => {
          if (textArea.id === selectedTextArea) {
            // Remove the letters array and set isSplit to false
            return {
              ...textArea,
              color: textArea?.color,
              isSplit: false,
              letters: undefined, // Remove the letters array
            };
          }
          return textArea;
        });
        dispatch(setProductFont(updatedTextAreas));
        return updatedTextAreas;
      });
      setShowSplit(false);
    }
  };

  const handleDragDrop = (e, data, id) => {
    const { x, y } = positions[id] || { x: 0, y: 0 };
    const newX = x + data.deltaX;
    const newY = y + data.deltaY;

    const updatedPositions = {
      ...positions,
      [id]: { x: newX, y: newY },
    };
    // console.log(updatedPositions);

    dispatch(updatePositions(updatedPositions));
  };

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Colors/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;
        case 3:
          setWarningThree(response.data);
          break;
        default:
          break;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    dispatch(fetchColors(product.source));
    fetchWarnings(product.source, 1);
    fetchWarnings(product.source, 2);
    fetchWarnings(product.source, 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (product.format === "super-custom") {
      dispatch(fetchHelp(`Super Colors/${product.source}`));
      dispatch(fetchExamples("Super Colors", product.source));
    } else {
      dispatch(fetchHelp(`Colors/${product.source}`));
      dispatch(fetchExamples("Colors", product.source));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );
  const factor = useSelector((state) => state.activeStep.materialLengthFactor);
  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );
  const getColorPriceForLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return colorCost; // Use the default color cost if color is not found
    }
  };

  const calculateColorPrice = async () => {
    // console.log("Calculating Price");
    // console.log(selectedFont);
    if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
      // console.log(selectedFont);

      let colorPricePerFt = 0;
      let fontPricePerFt = 0;
      let materialLength = 0;
      let neonWidth = 0;
      let neonHeight = 0;
      let signWidth = 0;
      let signHeight = 0;
      let glassCost = 0;
      let glassCost1 = 0;
      let glassCost2 = 0;
      let glassCost3 = 0;
      let unitNumber = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Bending Cost :", bendingCost);
        console.log("EGL White Cost :", colorCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1) {
        // console.log("Single Font");
        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + 2 * 2;
          signHeight = result?.maxHeight + 2 * 2;

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(fontPricePerFt);
              fontPricePerFt = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );

            glassCost +=
              Number(fontPricePerFt + bendingCost + colorPricePerFt) *
              (letterLength / 12);
          }
          // console.log (glassCost, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + 2 * 2;
          signHeight = result?.maxHeight + 2 * 2;

          materialLength *= factor;

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt = color.price;
            }
          });

          colorPricePerFt = colorPricePerFt ? colorPricePerFt : colorCost;
          if (showConsoles) {
            console.log("Color Price : ", colorPricePerFt);
            console.log("Font Price : ", fontPricePerFt);
          }
          glassCost =
            Number(fontPricePerFt + bendingCost + colorPricePerFt) *
            (materialLength / 12);
        }
        if (showConsoles) {
          console.log("Material Length : ", materialLength);

          console.log("Neon Width : ", neonWidth);
          console.log("Neon Height : ", neonHeight);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }
        unitNumber = Number(
          Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
        );
        if (showConsoles) {
          console.log("Unit Numbers : ", unitNumber);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments : ", segments);
        }
      } else if (textAreas.length === 2) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2);
        // console.log(glassCost);
        signHeight = Number(signHeight1 + signHeight2) + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2) + 2 * 2;
        materialLength = materialLength1 + materialLength2;

        materialLength *= factor;
        // console.log("Sign Height :", signHeight);
        // console.log("Sign Width :", signWidth);
        // console.log("Total Material Length :", materialLength);

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
            Math.ceil((neonHeight1 + neonHeight2) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      } else if (textAreas.length === 3) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let fontPricePerFt3 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;
        let colorPricePerFt3 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let neonWidth3 = 0;
        let neonHeight3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }

          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          for (const letterObj of textAreas[2].letters) {
            colorPricePerFt3 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            glassCost3 +=
              Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[2].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPricePerFt3 = color.price;
            }
          });

          colorPricePerFt3 = colorPricePerFt3 ? colorPricePerFt3 : colorCost;

          // console.log(fontPricePerFt);
          glassCost3 =
            Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
            (materialLength3 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2 + glassCost3);
        // console.log(glassCost);
        signHeight = signHeight1 + signHeight2 + signHeight3 + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + 2 * 2;
        materialLength = materialLength1 + materialLength2 + materialLength3;

        materialLength *= factor;

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
            Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      }
      dispatch(setProductBackingWidth(4));
      dispatch(setProductBackingHeight(4));

      if (showConsoles) {
        console.log("Glass Cost : ", glassCost);
      }

      let techs = Number(
        Math.ceil((materialLength / 12 + unitNumber) / materialLengthPerTech) +
          segments -
          1
      );
      if (showConsoles) {
        console.log("Techs:", techs);
      }

      let transformerCost = Number(techs * tech22Cost);
      if (showConsoles) {
        console.log("Transformer Cost:", transformerCost);
      }

      let backingCost = Number(signWidth * signHeight * clearPlexiCost);
      if (showConsoles) {
        console.log("Backing Cost:", backingCost);
      }

      let accessoriesCost = Number(unitNumber * blackAccessoryCost);
      if (showConsoles) {
        console.log("Accessories Cost:", accessoriesCost);
      }

      let totalCost = Number(
        glassCost + transformerCost + backingCost + accessoriesCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);

        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price:", price);
      }

      dispatch(addPrice(Math.ceil(price)));
    } else if (!textAreas[0]?.value) {
      dispatch(addPrice(0));
      dispatch(setProductSizeWidth(0));
      dispatch(setProductSizeHeight(0));
      dispatch(setProductBackingWidth(0));
      dispatch(setProductBackingHeight(0));
    }
  };

  const RGBColor = useSelector((state) => state.activeStep.product.RGBColor);
  const SpecialColorFee = useSelector(
    (state) => state.activeStep.SpecialColorFee
  );
  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );
  const standardRGBCost = useSelector(
    (state) => state.activeStep.standardRGBCost
  );
  const magicRGBCost = useSelector((state) => state.activeStep.magicRGBCost);

  const getColorPriceForLEDLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return LEDCostPerMaterial; // Use the default color cost if color is not found
    }
  };

  const calculateLEDFontPrice = async () => {
    // console.log("Calculating Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let colorPrice = LEDCostPerMaterial;

      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;
      // let minPowerSupply = 0;
      let LEDCost = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Special Color Cost :", SpecialColorFee);
        console.log("Magic RGB Cost :", magicRGBCost);
        console.log("Standard RGB Cost :", standardRGBCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1 && textAreas[0].value) {
        if (showConsoles) {
          console.log("------------------Single Font");
        }
        if (textAreas[0]?.isSplit) {
          if (showConsoles) {
            console.log("------------------Is Split");
          }
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + 2 * 2;
          signHeight = result?.maxHeight + 2 * 2;

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }

          for (const letterObj of textAreas[0].letters) {
            colorPrice = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost += Number((SpecialColorFee + colorPrice) * letterLength);
            } else {
              LEDCost += Number(colorPrice * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + 2 * 2;
          signHeight = result?.maxHeight + 2 * 2;

          materialLength *= factor;
          if (showConsoles) {
            console.log(`Material length :`, materialLength);
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost :`, colorPrice);
          }

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }
          if (textAreas[0]?.customColor) {
            // console.log(materialLength, SpecialColorFee, colorPrice);
            LEDCost = Number((colorPrice + SpecialColorFee) * materialLength);
          } else {
            LEDCost = Number(colorPrice) * materialLength;
          }
          if (showConsoles) {
            console.log("LED Cost :", LEDCost);
          }
        }
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }
        dispatch(setProductSizeWidth(signWidth - 4));
        dispatch(setProductSizeHeight(signHeight - 4));

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 2) {
        if (showConsoles) {
          console.log("------------------Double Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[1].value}:`, colorPrice2);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[1].value} :`, LEDCost2);
          }
        }

        LEDCost = LEDCost1 + LEDCost2;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2;

        materialLength *= factor;

        signHeight = Number(signHeight1 + signHeight2) + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2) + 2 * 2;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }
        dispatch(setProductSizeWidth(signWidth - 4));
        dispatch(setProductSizeHeight(signHeight - 4));

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 3) {
        if (showConsoles) {
          console.log("------------------Triple Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let colorPrice3 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;
        let LEDCost3 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          for (const letterObj of textAreas[2].letters) {
            colorPrice3 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost3 += Number(
                (SpecialColorFee + colorPrice3) * letterLength
              );
            } else {
              LEDCost3 += Number(colorPrice3 * letterLength);
            }
          }
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[2].value}:`,
              materialLength3
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPrice3 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[2].value}:`, colorPrice3);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[2]?.customColor) {
            LEDCost3 = Number(
              (colorPrice3 + SpecialColorFee) * materialLength3
            );
          } else {
            LEDCost3 = Number(colorPrice3) * materialLength3;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[2].value} :`, LEDCost3);
          }
        }

        LEDCost = LEDCost1 + LEDCost2 + LEDCost3;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2 + materialLength3;

        materialLength *= factor;

        signHeight = Number(signHeight1 + signHeight2 + signHeight3) + 2 * 2;
        signWidth = Math.max(signWidth1, signWidth2, signWidth3) + 2 * 2;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }
        dispatch(setProductSizeWidth(signWidth - 4));
        dispatch(setProductSizeHeight(signHeight - 4));

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }
        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      }
      dispatch(setProductBackingWidth(4));
      dispatch(setProductBackingHeight(4));
      if (showConsoles) {
        console.log("Total LED Cost :", LEDCost);
      }

      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      if (showConsoles) {
        console.log("Number of Power Supply :", powerSupplies);
      }

      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      if (showConsoles) {
        console.log("Power Supply Cost :", powerSupplyCost);
      }

      let backingCost = Number(backingArea * clearPlexiCost);
      if (showConsoles) {
        console.log("Backing Cost :", backingCost);
      }

      let accessoriesCost = Number(segments * blackAccessoryCost);
      if (showConsoles) {
        console.log("Accessories Cost :", accessoriesCost);

        console.log("RGB Style Selected :", RGBColor);
      }
      if (RGBColor) {
        if (RGBColor === "standardRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + standardRGBCost)
          );
          if (showConsoles) {
            console.log(
              "Accessories Cost with Standard RGB :",
              accessoriesCost
            );
          }
        }
        if (RGBColor === "magicRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + magicRGBCost)
          );
          if (showConsoles) {
            console.log("Accessories Cost with Magic RGB :", accessoriesCost);
          }
        }
      }
      let shippingCost = Number(
        Number(backingArea * InterShippingCost) / segments
      );
      if (showConsoles) {
        console.log("Shipping Cost :", shippingCost);
      }
      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);

        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price :", price);
      }

      dispatch(addPrice(Math.ceil(price)));
    } else if (!textAreas[0]?.value) {
      dispatch(addPrice(0));
      dispatch(setProductSizeWidth(0));
      dispatch(setProductSizeHeight(0));
      dispatch(setProductBackingWidth(0));
      dispatch(setProductBackingHeight(0));
    }
  };

  useEffect(() => {
    dispatch(setMaterialLengthFactor(1));
    if (product.source === "Glass") {
      calculateColorPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreas, colors]);

  useEffect(() => {
    if (textAreas.length > 0) {
      document.documentElement.style.setProperty(
        "--stroke-color",
        textAreas[0]?.color
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLockedWidth(0));
    dispatch(setLockedHeight(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(textAreas);
  return (
    <>
      <div className=" margin-adjust parent-container">
        {product.format === "super-custom" ? (
          <>
            <SuperColor />
          </>
        ) : (
          <>
            <Row>
              <Col md={12} lg={2} xs={12} sm={12}>
                <div
                  style={{
                    display: "flex",
                    height: "60vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    // margin: "auto",
                  }}
                >
                  {colors.length > 0 ? (
                    <>
                      <ColorsList
                        setShowCustomPopup={setShowCustomPopup}
                        setShowCustom={setShowCustom}
                        showCustom={showCustom}
                        setShowRGB={setShowRGB}
                        showRGB={showRGB}
                        selectedTextArea={selectedTextArea}
                        handleLetterColorSelection={handleLetterColorSelection}
                        handleColorSelection={handleColorSelection}
                        textAreas={textAreas}
                      />
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </div>
                <Typography
                  className={` border-2 border-[#AFE9E0] py-4 px-3  cursor-pointer rounded-3xl font-black text-center items-center ${
                    textAreas[0]?.isSplit ||
                    textAreas[1]?.isSplit ||
                    textAreas[2]?.isSplit
                      ? "selected"
                      : " hover-effect"
                  }`}
                  style={{
                    margin: "20px",
                    fontSize:
                      window.innerWidth > 1440
                        ? "1.3rem"
                        : window.innerWidth > 1024
                        ? "0.75rem"
                        : window.innerWidth > 992
                        ? "0.6rem"
                        : "2rem",
                    fontFamily: "Beatrice-Display",
                    fontStyle: "italic",
                  }}
                  onClick={() => {
                    handleSplitButtonClick();
                  }}
                >
                  Split into letters
                </Typography>
              </Col>
              <Col xs={12} md={12} lg={10} sm={12}>
                <div
                  draggable="false"
                  className="border-2 border-[#AFE9E0] rounded-3xl p-4"
                  style={{
                    backgroundColor: `${theme}`,
                    boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
                  }}
                >
                  <div className="price-container">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Beatrice-Display",
                          color: "#25CCB0",
                          fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                          fontWeight: "700",
                          fontStyle: "italic",
                          letterSpacing: "0.05em",
                        }}
                      >
                        {textAreas.some((textArea) => textArea.isSplit) ? (
                          <>Choose Colors</>
                        ) : (
                          <>Pick a Color</>
                        )}
                      </Typography>

                      <div
                        style={{
                          width: "40%",
                          height: "10vh",
                          display: warningOne?.warningStatus ? "" : "none",
                          zIndex: 9,
                        }}
                        className="warning"
                      >
                        <Typography className="warning-title">
                          {warningOne?.title}
                        </Typography>
                        <div
                          className="enable-listing-style warning-desc"
                          dangerouslySetInnerHTML={{
                            __html: warningOne?.warningContent,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: warningTwo?.warningStatus ? "none" : "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Align items to the right for both mobile and desktop
                        justifyContent: "flex-start", // Align content to the bottom of the container
                        marginTop: "10px", // Add space between text and price
                      }}
                      className="text-end"
                    >
                      <PriceEstimate />
                    </div>
                    <div
                      style={{
                        width: "30%",
                        height: "100px",
                        display: warningTwo?.warningStatus ? "" : "none",
                        zIndex: 9,
                      }}
                      className="warning"
                    >
                      <Typography className="warning-title">
                        {warningTwo?.title}
                      </Typography>
                      <div
                        className="enable-listing-style warning-desc"
                        dangerouslySetInnerHTML={{
                          __html: warningTwo?.warningContent,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      display: warningThree?.warningStatus ? "" : "none",
                      zIndex: 9,
                      position: "relative",
                    }}
                    className="warning"
                  >
                    <Typography className="warning-title">
                      {warningThree?.title}
                    </Typography>
                    <div
                      className="enable-listing-style warning-desc"
                      dangerouslySetInnerHTML={{
                        __html: warningThree?.warningContent,
                      }}
                    ></div>
                  </div>
                  <div>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          lg={11}
                          md={12}
                          style={{
                            backgroundColor: `${theme}`,
                            color: !theme,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: `${theme}`,
                              display: "flex",
                              flexDirection: `${product.fontsAlignment}`,
                              alignItems: "center",
                              justifyContent: "center",
                              height: "50vh",
                              width: "100%",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            {textAreas?.map((textArea, index) => (
                              <Draggable
                                disabled={width > 990 ? false : true}
                                bounds="parent"
                                key={index}
                                defaultPosition={
                                  positions[textArea?.id] || {
                                    x: 0,

                                    y: 0,
                                  }
                                }
                                onDrag={(e, data) =>
                                  handleDragDrop(e, data, textArea?.id)
                                }
                                onStop={(e, data) =>
                                  handleDragDrop(e, data, textArea?.id)
                                }
                              >
                                <div
                                  style={{
                                    textShadow:
                                      textArea.fontFamily === "Dylan" ||
                                      textArea.fontFamily === "Spencer"
                                        ? ""
                                        : !textArea.isSplit
                                        ? `1px 2px 10px ${textArea.color}, 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`
                                        : "",
                                  }}
                                  onClick={() =>
                                    setSelectedTextArea(textArea.id)
                                  }
                                >
                                  {textArea?.isSplit ? (
                                    <>
                                      {/* <button
                                        style={{
                                          color: "#4EBFFF",

                                          height: "10px",

                                          fontSize: "18px",
                                        }}
                                        onClick={() => {
                                          setSelectedTextArea(textArea.id);
                                          handleMergeButtonClick();
                                        }}
                                      >
                                        Cancel Split
                                      </button> */}

                                      <div
                                        style={{
                                          border:
                                            selectedTextArea === textArea.id
                                              ? "1px solid #4EBFFF"
                                              : "none",

                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {textArea?.letters?.map(
                                          (letterObj, letterIndex) => (
                                            <>
                                              <div
                                                className={
                                                  textArea.fontFamily ===
                                                    "Dylan" ||
                                                  textArea.fontFamily ===
                                                    "Spencer"
                                                    ? "Dylan-Spencer"
                                                    : ""
                                                }
                                                key={letterIndex}
                                                style={{
                                                  background: "none",

                                                  fontSize: `${textArea.fontSize}px`,

                                                  // padding: "5px",

                                                  margin: "2px",

                                                  fontFamily:
                                                    textArea.fontFamily,

                                                  color:
                                                    textArea.fontFamily ===
                                                      "Dylan" ||
                                                    textArea.fontFamily ===
                                                      "Spencer"
                                                      ? "transparent"
                                                      : letterObj.color,
                                                  WebkitTextStrokeColor:
                                                    letterObj.color,
                                                  // border: `1px solid ${
                                                  //   selectedLetter ===
                                                  //   letterIndex
                                                  //     ? "#4EBFFF"
                                                  //     : "#AFE9E0"
                                                  // }`,

                                                  borderRadius: "0px",

                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setSelectedLetter(
                                                    letterIndex
                                                  );
                                                }}
                                              >
                                                {letterObj.letter}
                                              </div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="led color"
                                        style={{
                                          display: "inline-block",

                                          position: "relative",

                                          color: `${textArea.color}`,
                                        }}
                                      >
                                        <div
                                          className={
                                            textArea.fontFamily === "Dylan" ||
                                            textArea.fontFamily === "Spencer"
                                              ? "Dylan-Spencer"
                                              : ""
                                          }
                                          contentEditable={false}
                                          maxLength={100}
                                          key={textArea.inputWidth}
                                          placeholder={"Type Something"}
                                          value={textArea.value}
                                          style={{
                                            fontFamily: `${textArea.fontFamily}`,

                                            outline: "none",

                                            fontSize: `${textArea.fontSize}px`,

                                            wordWrap: "break-word",

                                            whiteSpace: "pre-line",

                                            wordBreak: "break-word",

                                            resize: "none",

                                            position: "relative",

                                            cursor: "pointer",

                                            verticalAlign: `${textArea.verticalAlign}`,

                                            textAlign: `${textArea.horizontalAlign}`,

                                            border:
                                              selectedTextArea === textArea.id
                                                ? "1px solid #4EBFFF"
                                                : "none",

                                            zIndex: 2,
                                            textStrokeWidth:
                                              textArea.fontFamily === "Dylan" ||
                                              textArea.fontFamily === "Spencer"
                                                ? "1px"
                                                : "none",
                                            textStrokeColor:
                                              textArea.fontFamily === "Dylan" ||
                                              textArea.fontFamily === "Spencer"
                                                ? `${textArea.color}`
                                                : "none",
                                          }}
                                          onClick={(textArea) => {
                                            handleTextAreaClick(textArea.id);
                                          }}
                                        >
                                          {textArea.value}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Draggable>
                            ))}
                          </div>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                          }}
                          lg={1}
                          xs={0}
                          sm={0}
                          className="desktop-div"
                        >
                          <span className="mobile-display">
                            {showCustom ? (
                              <>
                                <RainbowColor
                                  textAreas={textAreas}
                                  handleLetterColorSelection={
                                    handleLetterColorSelection
                                  }
                                  handleColorSelection={handleColorSelection}
                                  selectedColor={textAreas[0]?.color}
                                  setSelectedColor={setTextAreas}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <DescriptionButton
                              value={desc}
                              setValue={setProductColorDescription}
                            />
                            <ClearButton />
                          </span>
                        </Col>
                        <div xs={1} className="mobile-tablet-div">
                          {showCustom ? (
                            <>
                              <RainbowColor
                                textAreas={textAreas}
                                handleLetterColorSelection={
                                  handleLetterColorSelection
                                }
                                handleColorSelection={handleColorSelection}
                                selectedColor={textAreas[0]?.color}
                                setSelectedColor={setTextAreas}
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          <DescriptionButton
                            value={desc}
                            setValue={setProductColorDescription}
                          />
                          <ClearButton />
                        </div>
                      </Row>
                      <Row>
                        <BottomControls />
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
            {showExamples && <ExampleModal />}
            <CustomColorPopup
              close={() => setShowCustomPopup(false)}
              show={showCustomPopup}
              setShowCustom={setShowCustom}
            />
          </>
        )}
      </div>
      <SplitColors
        textAreas={textAreas}
        setTextAreas={setTextAreas}
        selectedLetter={selectedLetter}
        handleMergeButtonClick={handleMergeButtonClick}
        setSelectedLetter={setSelectedLetter}
        selectedTextArea={selectedTextArea}
        show={showSplit}
        close={() => setShowSplit(false)}
      />
    </>
  );
};

export default Colors;
