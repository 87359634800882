import React from "react";

const Center = () => {
  return (
    <svg
      width="45"
      height="42"
      viewBox="0 0 45 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43.1654" height="41" rx="16.5" fill="none" />
      <rect x="12" y="17.3958" width="20.5794" height="2" fill="#25CCB0" />
      <rect x="14.7903" y="22.6042" width="14.9988" height="2" fill="#25CCB0" />
      <rect
        x="21.7897"
        y="30"
        width="18"
        height="1"
        transform="rotate(-90 21.7897 30)"
        fill="#25CCB0"
      />
      <rect
        x="0.5"
        y="0.5"
        width="43.1654"
        height="41"
        rx="16.5"
        stroke="#25CCB0"
      />
    </svg>
  );
};

export default Center;
