import React from "react";

const ZoomOut = () => {
  return (
    <svg
      fill="#36CDB4"
      height="65"
      width="65"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 299.998 299.998"
    >
      <g>
        <g>
          <g>
            <path
              d="M139.415,96.195c-22.673,0-41.056,18.389-41.056,41.062c0,22.676,18.383,41.059,41.056,41.059
               c7.446,0,14.41-2.01,20.43-5.478c2.625-1.511,5.06-3.308,7.275-5.342c0.08-0.073,0.163-0.145,0.241-0.218
               c0.705-0.659,1.393-1.343,2.052-2.049c0.036-0.039,0.07-0.078,0.106-0.117c2.754-2.977,5.073-6.367,6.86-10.068
               c2.596-5.387,4.095-11.404,4.095-17.787C180.474,114.584,162.093,96.195,139.415,96.195z M159.256,146.973h-39.684
               c-4.298,0-7.781-3.483-7.781-7.781c0-4.298,3.483-7.781,7.781-7.781h39.684c4.298,0,7.781,3.483,7.781,7.781
               C167.037,143.49,163.554,146.973,159.256,146.973z"
            />
            <path
              d="M149.995,0C67.156,0,0,67.158,0,149.995s67.156,150,149.995,150s150-67.163,150-150S232.834,0,149.995,0z
                M225.437,221.254c-2.371,2.376-5.48,3.561-8.59,3.561c-3.11,0-6.217-1.185-8.593-3.561l-34.145-34.147
               c-9.837,6.863-21.791,10.896-34.697,10.896c-33.548,0-60.742-27.196-60.742-60.744c0-33.548,27.194-60.742,60.742-60.742
               c33.548,0,60.744,27.194,60.744,60.742c0,11.855-3.408,22.909-9.28,32.259l34.56,34.56
               C230.183,208.817,230.183,216.512,225.437,221.254z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ZoomOut;
