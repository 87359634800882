// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
@media (max-width: 1350px) {
  .main-buttons {
    margin-top: 0px;
    margin-bottom: 5px;
    height: 100px;
  }
}
@media (max-width: 1230px) {
  .main-buttons {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1180px) {
  .main-buttons {
    margin-top: 0px;
    margin-bottom: 40px;
    
  }
}
@media (max-width: 790px) {
  .main-buttons {
    margin-top: 0px;
    margin-bottom: 78px;
  }
}
@media (max-width: 769px) {
  .main-buttons {
    margin-bottom: 0px;
    height: 50px;
  }
}
@media (max-width: 440px) {
  .main-buttons {
    margin-bottom: 0px;
    height: 80px;
  }
}
  @media (max-width: 769px) {
    .header-tabs {
      margin-bottom: 0px;
      height: 50px;
    }
  }
  @media (max-width: 440px) {
    .sketch-design-button {
      margin-bottom: 0px;
      height: 40px;
    }
  .mobile-margin{
    margin-bottom: 270px;
  }
    .preview-design-text{
    margin-top: "40px";
  }
  }
/* Media Query for Tablets */
@media (max-width: 1024px) {
  /* Adjust the column padding for tablets */
  .tablet-padding {
    padding: 10px;
  }
 
  /* Adjust the font size for tablets */
  .tablet-font-size {
    font-size: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/SuperCustom/styles/SuperColor.css"],"names":[],"mappings":";;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;IAClB,aAAa;EACf;AACF;AACA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF;AACA;EACE;IACE,eAAe;IACf,mBAAmB;;EAErB;AACF;AACA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;AACF;AACA;EACE;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;AACA;EACE;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;EACE;IACE;MACE,kBAAkB;MAClB,YAAY;IACd;EACF;EACA;IACE;MACE,kBAAkB;MAClB,YAAY;IACd;EACF;IACE,oBAAoB;EACtB;IACE;IACA,kBAAkB;EACpB;EACA;AACF,4BAA4B;AAC5B;EACE,0CAA0C;EAC1C;IACE,aAAa;EACf;;EAEA,qCAAqC;EACrC;IACE,eAAe;EACjB;AACF","sourcesContent":["\n  \n@media (max-width: 1350px) {\n  .main-buttons {\n    margin-top: 0px;\n    margin-bottom: 5px;\n    height: 100px;\n  }\n}\n@media (max-width: 1230px) {\n  .main-buttons {\n    margin-top: 0px;\n    margin-bottom: 5px;\n  }\n}\n@media (max-width: 1180px) {\n  .main-buttons {\n    margin-top: 0px;\n    margin-bottom: 40px;\n    \n  }\n}\n@media (max-width: 790px) {\n  .main-buttons {\n    margin-top: 0px;\n    margin-bottom: 78px;\n  }\n}\n@media (max-width: 769px) {\n  .main-buttons {\n    margin-bottom: 0px;\n    height: 50px;\n  }\n}\n@media (max-width: 440px) {\n  .main-buttons {\n    margin-bottom: 0px;\n    height: 80px;\n  }\n}\n  @media (max-width: 769px) {\n    .header-tabs {\n      margin-bottom: 0px;\n      height: 50px;\n    }\n  }\n  @media (max-width: 440px) {\n    .sketch-design-button {\n      margin-bottom: 0px;\n      height: 40px;\n    }\n  .mobile-margin{\n    margin-bottom: 270px;\n  }\n    .preview-design-text{\n    margin-top: \"40px\";\n  }\n  }\n/* Media Query for Tablets */\n@media (max-width: 1024px) {\n  /* Adjust the column padding for tablets */\n  .tablet-padding {\n    padding: 10px;\n  }\n \n  /* Adjust the font size for tablets */\n  .tablet-font-size {\n    font-size: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
