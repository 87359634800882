import React from "react";
import Upload from "../../Assets/Upload";
import { Popover, Typography } from "@mui/material";
import Close from "../../Assets/Close";
import Refresh from "../../Assets/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { setProductRefImageLink } from "../../Redux/Slice";
import axios from "axios";

const UploadBacking = () => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.activeStep.product);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFileSelect = async (e) => {
    let image = e.target.files[0];
    // console.log(image);
    const form = new FormData();
    form.append("image", image);
    // console.log(form.get("image"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}led-backing`,
        form
      );
      dispatch(setProductRefImageLink(response.data));
    } catch (error) {
      console.log(error);
    }
    // console.log(e.target.files[0]);

    // console.log(e);
  };
  const handleRemove = (e) => {
    dispatch(setProductRefImageLink(""));
  };
  return (
    <>
      <button
        style={{
          padding: "10px",
        }}
        onClick={handleDescriptionClick}
      >
        <Upload />
      </button>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            backgroundColor: "#F4F4F4",
            width: "500px",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleDescriptionClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F4",
            margin: "10px",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#595959",
                fontFamily: "Beatrice",
                fontWeight: "600",
                fontSize: "15px",
                wordBreak: "break-word",
              }}
            >
              Upload the file you want us to print.{" "}
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                Please note that your file will be saved in our system but you
                will not be able to preview it in the following pages.
              </p>
            </Typography>
            <button onClick={handleDescriptionClose}>
              <Close />
            </button>
          </div>
          <label
            style={{
              background: "white",
              borderRadius: "16px",
              paddingBottom: "15px",
              border: "2px dashed #595959 ",
              padding: "20px",
              cursor: "pointer",
              margin: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={(e) => handleFileSelect(e)}
            />
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                paddingBottom: "15px",
              }}
            >
              <Upload />
              <Typography
                style={{
                  fontFamily: "Beatrice",
                  fontWeight: "600",
                  color: "#595959",
                  fontSize: "1.2rem",
                  marginLeft: "5px",
                }}
              >
                Upload image{" "}
                <span
                  style={{
                    fontSize: "0.7rem",
                    fontFamily: "Beatrice",
                    fontWeight: "400",
                    color: "#595959",
                  }}
                >
                  (png,jpg)
                </span>
              </Typography>
            </div>
          </label>
          <div
            style={{
              margin: "5px",
              background: "white",
              borderRadius: "16px",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {product?.refImage?.link ? (
              <img
                style={{
                  width: "200px",
                  height: "100px",
                }}
                src={product.refImage.link}
                alt="Upload"
              />
            ) : (
              <></>
            )}
            <button
              style={{
                display: "flex",
                padding: "5px",
                alignContent: "end",
                alignItems: "flex-start",
              }}
              onClick={handleRemove}
            >
              <Refresh />
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default UploadBacking;
