import {
  Box,
  Button,
  Modal,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../../Assets/Close";
import { Card, Col, ModalFooter, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Info from "../../../Assets/SuperCustom/Info";
import UploadFont from "../../../Assets/UploadFont.png";

import {
  addFont,
  addOtherColors,
  addOtherFonts,
  removeOtherColors,
  removeOtherFonts,
  setProductFormat,
} from "../../../Redux/Slice";
import FontUpload from "../../Content/FontUpload";
import { getOSInfo } from "../../../utils/client/calculations";
import { debounce } from "../../../utils/client/helpers";

const ChooseStrokeColors = ({ strokeColor, setStrokeColor, show, close }) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const colors = useSelector((state) => state.activeStep.colors);
  const product = useSelector((state) => state.activeStep.product);
  const otherColors = useSelector((state) => state.activeStep.otherColors);
  const [name, setName] = useState("");
  const [fontFile, setFontFile] = useState("");
  // const [strokeColor, setStrokeColor] = useState("");

  const storedDrawingData = JSON.parse(localStorage.getItem("savedDrawing"));

  const handleColorClick = (color) => {
    // console.log(color.code);
    if (otherColors?.includes(color)) {
      dispatch(removeOtherColors(color));
    } else {
      dispatch(addOtherColors(color));
    }
    setStrokeColor(color.code);
    // setStrokeColor(color.code);
    // const updatedDrawingData = storedDrawingData.map((drawing) => ({
    //   ...drawing,
    //   strokeColor: color.code,
    // }));
    // // console.log(updatedDrawingData);
    // setSavedDrawingData(updatedDrawingData);
    // localStorage.setItem("savedDrawing", JSON.stringify(updatedDrawingData));
  };

  const handleSubmit = () => {
    close();
  };

  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });
  // useEffect(() => {
  //   if (storedDrawingData) {
  //     storedDrawingData.map((drawing) => {
  //       setStrokeColor(drawing.strokeColor);
  //     });
  //   }
  // }, [storedDrawingData]);

  // Preload images
  const preloadImages = () => {
    colors.forEach((color) => {
      const imgOn = new Image();
      const imgOff = new Image();
      imgOn.src = color.imageOn;
      imgOff.src = color.imageOff;
    });
  };

  // Call preloadImages function when your component mounts
  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // height: "85vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  // console.log(storedDrawingData);
  // console.log(strokeColor);
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Choose a stroke color
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>

          <div
            style={{
              height: "300px",
              paddingRight: "30px",
              overflowY: "auto",
            }}
          >
            <Row>
              {colors.map((color, index) => (
                <>
                  <Col key={color.name} xs={6} sm={4} md={3}>
                    <img
                      className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-3xl ${
                        color.code === strokeColor ? "selected" : ""
                      } ${
                        otherColors?.some(
                          (addedColor) => addedColor.name === color.name
                        )
                          ? "selected"
                          : ""
                      }`}
                      style={{
                        fontFamily: color?.name,
                        // height: "100px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      src={
                        hoveredStates[index] ? color?.imageOff : color?.imageOn
                      }
                      onClick={() => handleColorClick(color)}
                      onTouchStart={() => {
                        handleColorClick(color);
                        handleHoverChange(index, true);
                      }}
                      onTouchEnd={() => {
                        handleHoverChange(index, false);
                      }}
                      onMouseEnter={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, true);
                        }
                      }}
                      onMouseLeave={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, false);
                        }
                      }}
                      alt=""
                    ></img>
                  </Col>
                </>
              ))}
            </Row>
          </div>

          <div
            className="mobile-flow-switch"
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                color: "#ffffff",
                fontSize: "16px",
                width: window.innerWidth > 768 ? "30%" : "50%",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#36CDB4",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  color: "#36CDB4",
                  border: "1px solid #36CDB4",
                },
              }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ChooseStrokeColors;
