// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Existing styling for larger screens */
.parent-container {
  /* Other styling properties for the parent container */
  position: relative;
}

.colors-list-container {
  position: relative;
  height: 70vh;
}

/* Add media query for mobile and tablet views */
@media (max-width: 768px), (max-width: 1080px) {
  .colors-list-container {
    display: flex;
    overflow: scroll;
    height: auto;
    padding-right:10px;
    justify-content: center;
    align-items: center;
    margin: 20px 0; /* Add margin for spacing */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/styles/Colors.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,sDAAsD;EACtD,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA,gDAAgD;AAChD;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc,EAAE,2BAA2B;EAC7C;AACF","sourcesContent":["/* Existing styling for larger screens */\n.parent-container {\n  /* Other styling properties for the parent container */\n  position: relative;\n}\n\n.colors-list-container {\n  position: relative;\n  height: 70vh;\n}\n\n/* Add media query for mobile and tablet views */\n@media (max-width: 768px), (max-width: 1080px) {\n  .colors-list-container {\n    display: flex;\n    overflow: scroll;\n    height: auto;\n    padding-right:10px;\n    justify-content: center;\n    align-items: center;\n    margin: 20px 0; /* Add margin for spacing */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
