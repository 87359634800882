import React from "react";

const Close = ({ color }) => {
  return (
    <svg
      width="24"
      height="50"
      viewBox="10 10 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4258 14.6777C23.2399 6.78007 35.9534 6.78007 43.7676 14.6777C51.5818 22.5753 51.5807 35.4258 43.7676 43.3223C35.9545 51.2188 23.2399 51.2199 15.4258 43.3223C7.61159 35.4246 7.61159 22.5754 15.4258 14.6777ZM17.2967 16.5686C10.514 23.4237 10.5152 34.5775 17.2967 41.4314C24.0793 48.2865 35.1141 48.2865 41.8967 41.4314C48.6793 34.5763 48.6782 23.4225 41.8967 16.5686C35.1152 9.71465 24.0793 9.71349 17.2967 16.5686Z"
        fill={color ? `${color}` : "#36CDB4"}
        stroke="white"
        stroke-width="0.5"
      />
      <path
        d="M31.748 28.7199L31.5721 28.8977L31.748 29.0754L36.1931 33.568C36.7104 34.0908 36.7095 34.937 36.1931 35.4589C35.6767 35.9809 34.8395 35.9818 34.3221 35.4589L29.877 30.9663L29.7012 30.7886L29.5253 30.9663L25.0802 35.4589C24.5638 35.9809 23.7266 35.9818 23.2092 35.4589C22.6919 34.9361 22.6928 34.0899 23.2092 33.568L27.6544 29.0754L27.8302 28.8977L27.6544 28.7199L23.2092 24.2273C22.6919 23.7045 22.6928 22.8583 23.2092 22.3364C23.7257 21.8144 24.5629 21.8135 25.0802 22.3364L29.5253 26.829L29.7012 27.0067L29.877 26.829L34.3221 22.3364C34.8386 21.8144 35.6758 21.8135 36.1931 22.3364C36.7104 22.8592 36.7095 23.7054 36.1931 24.2273L31.748 28.7199Z"
        fill={color ? `${color}` : "#36CDB4"}
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};

export default Close;
