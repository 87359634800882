import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchInstallations, setProductInstall } from "../../../Redux/Slice";

const InstallList = ({ select, handleSelect }) => {
  const installations = useSelector((state) => state.activeStep.installations);
  const product = useSelector((state) => state.activeStep.product);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstallations(product.source));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(product.source);

  return (
    <>
      <Row
        style={{
          margin: "0px",
        }}
      >
        {installations?.map((install) => (
          <Col xl={3} md={6} xs={12}>
            <Typography
              style={{
                border: "2px solid #AFE9E0",
                backgroundColor: "white",
                color: "black",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Beatrice",
                padding: "30px",
                borderRadius: "20px",
                textAlign: "center",
                margin: "20px",
                cursor: "pointer",
              }}
              className={`${select.name === install?.name ? "selected" : ""}`}
              onClick={() => {
                handleSelect(install);
                dispatch(setProductInstall(install));
              }}
            >
              {install?.name}
            </Typography>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default InstallList;
