import { Box, Checkbox, Modal, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Close from "../../../../Assets/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  setProductDeliveryDetails,
  setProductDeliveryType,
} from "../../../../Redux/Slice";

const zipCodeArray = [
  "10001",
  "10002",
  "10003",
  "10004",
  "10005",
  "10006",
  "10007",
  "10009",
  "10010",
  "10011",
  "10012",
  "10013",
  "10014",
  "10015",
  "10016",
  "10017",
  "10018",
  "10019",
  "10020",
  "10021",
  "10022",
  "10023",
  "10024",
  "10025",
  "10026",
  "10027",
  "10028",
  "10029",
  "10030",
  "10031",
  "10032",
  "10033",
  "10034",
  "10035",
  "10036",
  "10037",
  "10038",
  "10039",
  "10040",
  "10041",
  "10044",
  "10045",
  "10048",
  "10055",
  "10060",
  "10069",
  "10090",
  "10095",
  "10098",
  "10099",
  "10103",
  "10104",
  "10105",
  "10106",
  "10107",
  "10110",
  "10111",
  "10112",
  "10115",
  "10118",
  "10119",
  "10120",
  "10121",
  "10122",
  "10123",
  "10128",
  "10151",
  "10152",
  "10153",
  "10154",
  "10155",
  "10158",
  "10161",
  "10162",
  "10165",
  "10166",
  "10167",
  "10168",
  "10169",
  "10170",
  "10171",
  "10172",
  "10173",
  "10174",
  "10175",
  "10176",
  "10177",
  "10178",
  "10199",
  "10270",
  "10271",
  "10278",
  "10279",
  "10280",
  "10281",
  "10282",
  "10301",
  "10302",
  "10303",
  "10304",
  "10305",
  "10306",
  "10307",
  "10308",
  "10309",
  "10310",
  "10311",
  "10312",
  "10314",
  "10451",
  "10452",
  "10453",
  "10454",
  "10455",
  "10456",
  "10457",
  "10458",
  "10459",
  "10460",
  "10461",
  "10462",
  "10463",
  "10464",
  "10465",
  "10466",
  "10467",
  "10468",
  "10469",
  "10470",
  "10471",
  "10472",
  "10473",
  "10474",
  "10475",
  "11004",
  "11101",
  "11102",
  "11103",
  "11104",
  "11105",
  "11106",
  "11109",
  "11201",
  "11203",
  "11204",
  "11205",
  "11206",
  "11207",
  "11208",
  "11209",
  "11210",
  "11211",
  "11212",
  "11213",
  "11214",
  "11215",
  "11216",
  "11217",
  "11218",
  "11219",
  "11220",
  "11221",
  "11222",
  "11223",
  "11224",
  "11225",
  "11226",
  "11228",
  "11229",
  "11230",
  "11231",
  "11232",
  "11233",
  "11234",
  "11235",
  "11236",
  "11237",
  "11238",
  "11239",
  "11241",
  "11242",
  "11243",
  "11249",
  "11252",
  "11256",
  "11351",
  "11354",
  "11355",
  "11356",
  "11357",
  "11358",
  "11359",
  "11360",
  "11361",
  "11362",
  "11363",
  "11364",
  "11365",
  "11366",
  "11367",
  "11368",
  "11369",
  "11370",
  "11371",
  "11372",
  "11373",
  "11374",
  "11375",
  "11377",
  "11378",
  "11379",
  "11385",
  "11411",
  "11412",
  "11413",
  "11414",
  "11415",
  "11416",
  "11417",
  "11418",
  "11419",
  "11420",
  "11421",
  "11422",
  "11423",
  "11426",
  "11427",
  "11428",
  "11429",
  "11430",
  "11432",
  "11433",
  "11434",
  "11435",
  "11436",
  "11691",
  "11692",
  "11693",
  "11694",
  "11697",
  "10451",
  "10452",
  "10453",
  "10454",
  "10455",
  "10456",
  "10457",
  "10458",
  "10459",
  "10460",
  "10461",
  "10462",
  "10463",
  "10464",
  "10465",
  "10466",
  "10467",
  "10468",
  "10469",
  "10470",
  "10471",
  "10472",
  "10473",
  "10474",
  "10475",
  "11201",
  "11203",
  "11204",
  "11205",
  "11206",
  "11207",
  "11208",
  "11209",
  "11210",
  "11211",
  "11212",
  "11213",
  "11214",
  "11215",
  "11216",
  "11217",
  "11218",
  "11219",
  "11220",
  "11221",
  "11222",
  "11223",
  "11224",
  "11225",
  "11226",
  "11228",
  "11229",
  "11230",
  "11231",
  "11232",
  "11233",
  "11234",
  "11235",
  "11236",
  "11237",
  "11238",
  "11239",
  "11241",
  "11242",
  "11243",
  "11249",
  "11252",
  "11256",
  "10001",
  "10002",
  "10003",
  "10004",
  "10005",
  "10006",
  "10007",
  "10009",
  "10010",
  "10011",
  "10012",
  "10013",
  "10014",
  "10015",
  "10016",
  "10017",
  "10018",
  "10019",
  "10020",
  "10021",
  "10022",
  "10023",
  "10024",
  "10025",
  "10026",
  "10027",
  "10028",
  "10029",
  "10030",
  "10031",
  "10032",
  "10033",
  "10034",
  "10035",
  "10036",
  "10037",
  "10038",
  "10039",
  "10040",
  "10041",
  "10044",
  "10045",
  "10048",
  "10055",
  "10060",
  "10069",
  "10090",
  "10095",
  "10098",
  "10099",
  "10103",
  "10104",
  "10105",
  "10106",
  "10107",
  "10110",
  "10111",
  "10112",
  "10115",
  "10118",
  "10119",
  "10120",
  "10121",
  "10122",
  "10123",
  "10128",
  "10151",
  "10152",
  "10153",
  "10154",
  "10155",
  "10158",
  "10161",
  "10162",
  "10165",
  "10166",
  "10167",
  "10168",
  "10169",
  "10170",
  "10171",
  "10172",
  "10173",
  "10174",
  "10175",
  "10176",
  "10177",
  "10178",
  "10199",
  "10270",
  "10271",
  "10278",
  "10279",
  "10280",
  "10281",
  "10282",
  "11101",
  "11102",
  "11103",
  "11004",
  "11104",
  "11105",
  "11106",
  "11109",
  "11351",
  "11354",
  "11355",
  "11356",
  "11357",
  "11358",
  "11359",
  "11360",
  "11361",
  "11362",
  "11363",
  "11364",
  "11365",
  "11366",
  "11367",
  "11368",
  "11369",
  "11370",
  "11371",
  "11372",
  "11373",
  "11374",
  "11375",
  "11377",
  "11378",
  "11379",
  "11385",
  "11411",
  "11412",
  "11413",
  "11414",
  "11415",
  "11416",
  "11417",
  "11418",
  "11419",
  "11420",
  "11421",
  "11422",
  "11423",
  "11426",
  "11427",
  "11428",
  "11429",
  "11430",
  "11432",
  "11433",
  "11434",
  "11435",
  "11436",
  "11691",
  "11692",
  "11693",
  "11694",
  "11697",
  "10301",
  "10302",
  "10303",
  "10304",
  "10305",
  "10306",
  "10307",
  "10308",
  "10309",
  "10310",
  "10311",
  "10312",
  "10314",
  "6001",
  "6002",
  "6006",
  "6010",
  "6011",
  "6013",
  "6016",
  "6018",
  "6019",
  "6020",
  "6021",
  "6022",
  "6023",
  "6024",
  "6025",
  "6026",
  "6027",
  "6028",
  "6029",
  "6030",
  "6031",
  "6032",
  "6033",
  "6034",
  "6035",
  "6037",
  "6039",
  "6040",
  "6041",
  "6042",
  "6043",
  "6045",
  "6050",
  "6051",
  "6052",
  "6053",
  "6057",
  "6058",
  "6059",
  "6060",
  "6061",
  "6062",
  "6063",
  "6064",
  "6065",
  "6066",
  "6067",
  "6068",
  "6069",
  "6070",
  "6071",
  "6072",
  "6073",
  "6074",
  "6075",
  "6076",
  "6077",
  "6078",
  "6079",
  "6080",
  "6081",
  "6082",
  "6083",
  "6084",
  "6085",
  "6087",
  "6088",
  "6089",
  "6090",
  "6091",
  "6092",
  "6093",
  "6094",
  "6095",
  "6096",
  "6098",
  "6101",
  "6102",
  "6103",
  "6104",
  "6105",
  "6106",
  "6107",
  "6108",
  "6109",
  "6110",
  "6111",
  "6112",
  "6114",
  "6115",
  "6117",
  "6118",
  "6119",
  "6120",
  "6123",
  "6126",
  "6127",
  "6128",
  "6129",
  "6131",
  "6132",
  "6133",
  "6134",
  "6137",
  "6138",
  "6140",
  "6141",
  "6142",
  "6143",
  "6144",
  "6145",
  "6146",
  "6147",
  "6150",
  "6151",
  "6152",
  "6153",
  "6154",
  "6155",
  "6156",
  "6160",
  "6161",
  "6167",
  "6176",
  "6180",
  "6183",
  "6199",
  "6226",
  "6230",
  "6231",
  "6232",
  "6233",
  "6234",
  "6235",
  "6237",
  "6238",
  "6239",
  "6241",
  "6242",
  "6243",
  "6244",
  "6245",
  "6246",
  "6247",
  "6248",
  "6249",
  "6250",
  "6251",
  "6254",
  "6255",
  "6256",
  "6258",
  "6259",
  "6260",
  "6262",
  "6263",
  "6264",
  "6265",
  "6266",
  "6267",
  "6268",
  "6269",
  "6277",
  "6278",
  "6279",
  "6280",
  "6281",
  "6282",
  "6320",
  "6330",
  "6331",
  "6332",
  "6333",
  "6334",
  "6335",
  "6336",
  "6338",
  "6339",
  "6340",
  "6349",
  "6350",
  "6351",
  "6352",
  "6353",
  "6354",
  "6355",
  "6357",
  "6359",
  "6360",
  "6365",
  "6370",
  "6371",
  "6372",
  "6373",
  "6374",
  "6375",
  "6376",
  "6377",
  "6378",
  "6379",
  "6380",
  "6382",
  "6383",
  "6384",
  "6385",
  "6386",
  "6387",
  "6388",
  "6389",
  "6401",
  "6403",
  "6404",
  "6405",
  "6408",
  "6409",
  "6410",
  "6411",
  "6412",
  "6413",
  "6414",
  "6415",
  "6416",
  "6417",
  "6418",
  "6419",
  "6420",
  "6422",
  "6423",
  "6424",
  "6426",
  "6437",
  "6438",
  "6439",
  "6440",
  "6441",
  "6442",
  "6443",
  "6444",
  "6447",
  "6450",
  "6451",
  "6455",
  "6456",
  "6457",
  "6459",
  "6460",
  "6461",
  "6467",
  "6468",
  "6469",
  "6470",
  "6471",
  "6472",
  "6473",
  "6474",
  "6475",
  "6477",
  "6478",
  "6479",
  "6480",
  "6481",
  "6482",
  "6483",
  "6484",
  "6487",
  "6488",
  "6489",
  "6491",
  "6492",
  "6493",
  "6495",
  "6496",
  "6497",
  "6498",
  "6501",
  "6503",
  "6504",
  "6505",
  "6530",
  "6531",
  "6532",
  "6533",
  "6534",
  "6535",
  "6536",
  "6537",
  "6538",
  "6539",
  "6540",
  "6601",
  "6602",
  "6604",
  "6605",
  "6606",
  "6607",
  "6608",
  "6609",
  "6610",
  "6611",
  "6612",
  "6614",
  "6615",
  "6650",
  "6651",
  "6658",
  "6659",
  "6660",
  "6663",
  "6667",
  "6670",
  "6671",
  "6672",
  "6673",
  "6675",
  "6676",
  "6677",
  "6678",
  "6679",
  "6680",
  "6681",
  "6682",
  "6683",
  "6684",
  "6685",
  "6686",
  "6687",
  "6688",
  "6689",
  "6690",
  "6692",
  "6693",
  "6694",
  "6695",
  "6696",
  "6697",
  "6698",
  "6699",
  "6701",
  "6702",
  "6703",
  "6704",
  "6705",
  "6706",
  "6707",
  "6708",
  "6710",
  "6712",
  "6716",
  "6744",
  "6750",
  "6751",
  "6752",
  "6754",
  "6755",
  "6756",
  "6757",
  "6758",
  "6759",
  "6762",
  "6763",
  "6770",
  "6776",
  "6777",
  "6778",
  "6779",
  "6783",
  "6784",
  "6785",
  "6786",
  "6787",
  "6790",
  "6791",
  "6792",
  "6793",
  "6794",
  "6795",
  "6796",
  "6798",
  "6799",
  "6801",
  "6804",
  "6807",
  "6810",
  "6811",
  "6812",
  "6813",
  "6814",
  "6816",
  "6817",
  "6818",
  "6819",
  "6820",
  "6821",
  "6822",
  "6824",
  "6825",
  "6826",
  "6827",
  "6828",
  "6829",
  "6830",
  "6831",
  "6832",
  "6836",
  "6838",
  "6839",
  "6840",
  "6842",
  "6843",
  "6844",
  "6846",
  "6847",
  "6850",
  "6851",
  "6853",
  "6854",
  "6855",
  "6856",
  "6857",
  "6858",
  "6859",
  "6860",
  "6861",
  "6862",
  "6863",
  "6864",
  "6866",
  "6867",
  "6870",
  "6875",
  "6876",
  "6877",
  "6878",
  "6879",
  "6880",
  "6883",
  "6888",
  "6889",
  "6890",
  "6896",
  "6897",
  "6901",
  "6902",
  "6903",
  "6904",
  "6905",
  "6906",
  "6907",
  "6910",
  "6911",
  "6912",
  "6913",
  "6914",
  "6915",
  "6916",
  "6917",
  "6920",
  "6921",
  "6922",
  "6925",
  "6926",
  "6927",
  "6928",
  "6929",
  "6930",
  "6931",
  "6932",
  "6934",
  "6935",
  "6936",
  "6937",
  "6938",
  "6939",
  "6940",
  "6941",
  "6942",
  "6943",
  "6944",
  "6945",
  "6946",
  "6947",
  "7001",
  "7002",
  "7003",
  "7004",
  "7005",
  "7006",
  "7008",
  "7009",
  "7010",
  "7011",
  "7012",
  "7013",
  "7014",
  "7015",
  "7016",
  "7017",
  "7018",
  "7019",
  "7020",
  "7021",
  "7022",
  "7023",
  "7024",
  "7026",
  "7027",
  "7028",
  "7029",
  "7030",
  "7031",
  "7032",
  "7033",
  "7034",
  "7035",
  "7036",
  "7039",
  "7040",
  "7041",
  "7042",
  "7043",
  "7044",
  "7045",
  "7046",
  "7047",
  "7050",
  "7051",
  "7052",
  "7054",
  "7055",
  "7057",
  "7058",
  "7059",
  "7060",
  "7061",
  "7062",
  "7063",
  "7064",
  "7065",
  "7066",
  "7067",
  "7068",
  "7069",
  "7070",
  "7071",
  "7072",
  "7073",
  "7074",
  "7075",
  "7076",
  "7077",
  "7078",
  "7079",
  "7080",
  "7081",
  "7082",
  "7083",
  "7086",
  "7087",
  "7088",
  "7090",
  "7091",
  "7092",
  "7093",
  "7094",
  "7095",
  "7096",
  "7097",
  "7099",
  "7101",
  "7102",
  "7103",
  "7104",
  "7105",
  "7106",
  "7107",
  "7108",
  "7109",
  "7110",
  "7111",
  "7112",
  "7114",
  "7175",
  "7177",
  "7184",
  "7188",
  "7189",
  "7191",
  "7192",
  "7193",
  "7194",
  "7195",
  "7197",
  "7198",
  "7199",
  "7201",
  "7202",
  "7203",
  "7204",
  "7205",
  "7206",
  "7207",
  "7208",
  "7731",
  "7732",
  "7733",
  "7734",
  "7735",
  "7737",
  "7738",
  "7739",
  "7740",
  "7746",
  "7747",
  "7748",
  "7750",
  "7751",
  "7752",
  "7753",
  "7754",
  "7755",
  "7756",
  "7757",
  "7758",
  "7760",
  "7762",
  "7764",
  "7801",
  "7803",
  "7820",
  "7821",
  "7822",
  "7823",
  "7825",
  "7826",
  "7827",
  "7828",
  "7829",
  "7830",
  "7831",
  "7832",
  "7833",
  "7834",
  "7836",
  "7837",
  "7838",
  "7839",
  "7840",
  "7842",
  "7843",
  "7844",
  "7846",
  "7847",
  "7848",
  "7849",
  "7850",
  "7851",
  "7852",
  "7853",
  "7855",
  "7856",
  "7857",
  "7860",
  "7863",
  "7865",
  "7866",
  "7869",
  "7870",
  "7871",
  "7874",
  "7876",
  "7877",
  "7878",
  "7879",
  "7880",
  "7881",
  "7882",
  "7885",
  "7901",
  "7920",
  "7921",
  "7922",
  "7924",
  "7926",
  "7927",
  "7928",
  "7930",
  "7931",
  "7932",
  "7933",
  "7934",
  "7935",
  "7936",
  "7938",
  "7939",
  "7940",
  "7945",
  "7946",
  "7947",
  "7948",
  "7950",
  "7960",
  "7961",
  "7970",
  "7974",
  "7976",
  "7977",
  "7978",
  "7979",
  "7980",
  "7981",
  "7999",
  "8001",
  "8002",
  "8003",
  "8004",
  "8005",
  "8006",
  "8007",
  "8008",
  "8009",
  "8010",
  "8011",
  "8012",
  "8014",
  "8015",
  "8016",
  "8018",
  "8019",
  "8020",
  "8021",
  "8022",
  "8023",
  "8025",
  "8026",
  "8027",
  "8028",
  "8029",
  "8030",
  "8031",
  "8032",
  "8033",
  "8034",
  "8035",
  "8036",
  "8037",
  "8038",
  "8039",
  "8041",
  "8042",
  "8043",
  "8045",
  "8046",
  "8048",
  "8049",
  "8050",
  "8051",
  "8052",
  "8053",
  "8054",
  "8055",
  "8056",
  "8057",
  "8059",
  "8060",
  "8061",
  "8062",
  "8063",
  "8064",
  "8065",
  "8066",
  "8067",
  "8068",
  "8069",
  "8070",
  "8071",
  "8072",
  "8073",
  "8074",
  "8075",
  "8076",
  "8077",
  "8078",
  "8079",
  "8080",
  "8081",
  "8083",
  "8084",
  "8085",
  "8086",
  "8087",
  "8088",
  "8089",
  "8090",
  "8091",
  "8092",
  "8093",
  "8094",
  "8095",
  "8096",
  "8098",
  "8101",
  "8102",
  "8103",
  "8104",
  "8105",
  "8106",
  "8107",
  "8108",
  "8109",
  "8110",
  "8201",
  "8202",
  "8203",
  "8204",
  "8205",
  "8206",
  "8208",
  "8210",
  "8212",
  "8214",
  "8215",
  "8217",
  "8220",
  "8221",
  "8223",
  "8224",
  "8225",
  "8226",
  "8230",
  "8231",
  "8232",
  "8234",
  "8240",
  "8241",
  "8242",
  "8243",
  "8244",
  "8246",
  "8247",
  "8248",
  "8250",
  "8251",
  "8252",
  "8260",
  "8261",
  "8262",
  "8265",
  "8266",
  "8268",
  "8269",
  "8270",
  "8271",
  "8277",
  "8280",
  "8281",
  "8282",
  "8283",
  "8285",
  "8286",
  "8287",
  "8288",
  "8290",
  "8291",
  "8292",
  "8293",
  "8294",
  "8295",
  "8296",
  "8310",
  "8311",
  "8312",
  "8313",
  "8314",
  "8315",
  "8316",
  "8317",
  "8318",
  "8319",
  "8320",
  "8321",
  "8322",
  "8323",
  "8324",
  "8326",
  "8327",
  "8328",
  "8329",
  "8330",
  "8332",
  "8340",
  "8341",
  "8342",
  "8343",
  "8344",
  "8345",
  "8346",
  "8347",
  "8348",
  "8349",
  "8350",
  "8352",
  "8353",
  "8360",
  "8361",
  "8362",
  "8401",
  "8402",
  "8403",
  "8404",
  "8405",
  "8406",
  "8501",
  "8502",
  "8503",
  "8504",
  "8505",
  "8506",
  "8510",
  "8511",
  "8512",
  "8514",
  "8515",
  "8518",
  "8520",
  "8521",
  "8522",
  "8523",
  "8525",
  "8526",
  "8527",
  "8528",
  "8530",
  "8533",
  "8534",
  "8535",
  "8536",
  "8540",
  "8541",
  "8542",
  "8543",
  "8544",
  "8550",
  "8551",
  "8552",
  "8553",
  "8554",
  "8560",
  "8561",
  "8562",
  "8602",
  "8603",
  "8604",
  "8605",
  "8606",
  "8607",
  "8608",
  "8609",
  "8610",
  "8611",
  "8618",
  "8619",
  "8620",
  "8625",
  "8628",
  "8629",
  "8638",
  "8640",
  "8641",
  "8646",
  "8647",
  "8648",
  "8650",
  "8666",
  "8690",
  "8691",
  "8695",
  "8696",
  "8699",
  "8701",
  "8720",
  "8721",
  "8722",
  "8723",
  "8724",
  "8730",
  "8731",
  "8732",
  "8733",
  "8734",
  "8735",
  "8736",
  "8738",
  "8740",
  "8741",
  "8742",
  "8750",
  "8751",
  "8752",
  "8753",
  "8754",
  "8755",
  "8756",
  "8757",
  "8758",
  "8759",
  "8760",
  "8761",
  "8762",
  "8763",
  "8765",
  "8766",
  "8767",
  "8770",
  "8771",
  "8772",
  "8773",
  "8775",
  "8776",
  "8777",
  "8778",
  "8780",
  "8781",
  "8782",
  "8790",
  "8791",
  "8792",
  "8793",
  "8794",
  "8801",
  "8802",
  "8803",
  "8804",
  "8805",
  "8807",
  "8808",
  "8809",
  "8810",
  "8812",
  "8816",
  "8817",
  "8820",
  "8821",
  "8822",
  "8823",
  "8824",
  "8825",
  "8826",
  "8827",
  "8828",
  "8829",
  "8830",
  "8831",
  "8832",
  "8833",
  "8834",
  "8835",
  "8836",
  "8837",
  "8840",
  "8844",
  "8846",
  "8848",
  "8850",
  "8851",
  "8852",
  "8853",
  "8854",
  "8855",
  "8857",
  "8858",
  "8859",
  "8861",
  "8862",
  "8863",
  "8865",
  "8867",
  "8868",
  "8869",
  "8870",
  "8871",
  "8872",
  "8873",
  "8875",
  "8876",
  "8879",
  "8880",
  "8882",
  "8884",
  "8885",
  "8886",
  "8887",
  "8888",
  "8889",
  "8890",
  "8896",
  "8899",
  "8901",
  "8902",
  "8903",
  "8904",
  "8905",
  "8922",
  "8923",
  "8924",
  "8925",
  "8926",
  "8933",
  "8988",
  "8989",
  "8901",
  "8902",
  "8904",
  "8933",
];

const LocationCheck = ({
  risks,
  setRisks,
  setShowError,
  setSelected,
  show,
  close,
}) => {
  const [risksError, setRisksError] = useState(false);

  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );

  // const handleSubmit = () => {
  //   // console.log(formik.values);
  //   if (
  //     !formik.values.billingAddress ||
  //     !formik.values.billingState ||
  //     !formik.values.billingZip
  //   ) {
  //     alert("Please fill all the fields");
  //   } else {
  //     if (risks) {
  //       if (formik.values.billingZip ===) {
  //         close();
  //       } else {
  //         setShowError(true);
  //       }
  //     } else {
  //       // close();
  //       setRisksError(true);
  //     }
  //   }
  // };
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (
      !details?.address ||
      !details?.state ||
      !details?.zip ||
      !details?.city
    ) {
      alert("Please fill all the fields");
    } else {
      if (risks) {
        if (zipCodeArray.includes(details?.zip)) {
          close();
        } else {
          setShowError(true);
          close();
        }
      } else {
        setRisksError(true);
      }
    }
  };
  useEffect(() => {
    // console.log(zipCodeArray.toString());
    if (details?.address && details?.state && details?.zip && details?.city) {
      if (zipCodeArray.includes(details?.zip)) {
        close();
      } else {
        setShowError(true);
        close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "50%" : "90%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };

  // console.log(risks);
  return (
    <Modal
      disableEnforceFocus
      disablebackdropclick="true"
      disableAutoFocus
      disableEscapeKeyDown
      open={show}
      //  onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "1rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            Tell us where you're located:
          </Typography>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelected("pickup");
              dispatch(setProductDeliveryType("pickup"));
              close();
            }}
          >
            <Close />
          </div>
        </div>
        <div>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12} sm={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Enter your address here
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="billingAddress"
                value={details?.address}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      address: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your address here"
              />
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12} sm={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                City
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="city"
                value={details?.city}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      city: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your city here"
              />
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12} sm={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                State
              </label>
              <br />
              <select
                style={{
                  height: "48px",
                  border: "2px solid #AFE9E0",
                  color: details.state ? "#000000" : "#8F8F8F",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="billingState"
                value={details?.state}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      state: e.target.value,
                    })
                  );
                }}
              >
                <option key="" value="">
                  -- Select State
                </option>
                <option key="New York" value="New York">
                  New York
                </option>
                <option key="New Jersey" value="New Jersey">
                  New Jersey
                </option>
                <option key="Connecticut" value="Connecticut">
                  Connecticut
                </option>
              </select>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={12} sm={12}>
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Zip Code
              </label>
              <br />
              <input
                list="zipCodes"
                style={{
                  height: "48px",
                  border: "2px solid #AFE9E0",
                  // color: details.zip ? "#000000" : "#8F8F8F",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                type="number"
                placeholder="Enter Zip Code"
                name="billingZip"
                value={details?.zip}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      zip: e.target.value,
                    })
                  );
                }}
              >
                {/* <option value="">-- Select Zip Code</option>
              {zipCodes?.map((zipCode) => (
                <option key={zipCode} value={zipCode}>
                  {zipCode}
                </option>
              ))} */}
              </input>
              <datalist id="zipCodes">
                {zipCodeArray.map((zipCode) => (
                  <option value={zipCode}></option>
                ))}
              </datalist>
            </Col>
          </Row>
          {/* <Row>
          <Col xs={6}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              Country
            </label>
            <br />
            <select
              typeof="country"
              id="country"
              style={{
                height: "48px",
                border: "2px solid #AFE9E0",
                color: formik.values.billingCountry ? "#000000" : "#8F8F8F",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingCountry"
              value={formik.values.billingCountry}
              onChange={handleCountryChange}
            >
              <option value="">-- Select Country</option>
              <option value="US">United States</option>
              <option value="UK">United Kingdom</option>
              <option value="CAN">Canada</option>
            </select>
            {formik.touched.billingCountry && formik.errors.billingCountry && (
              <div
                style={{
                  color: "red",
                  margin: "5px",
                }}
              >
                {formik.errors.billingCountry}
              </div>
            )}
          </Col>
          <Col xs={6}>
            <label
              style={{
                marginBottom: "5px",
                color: "#8F8F8F",
              }}
            >
              State
            </label>
            <br />
            <select
              style={{
                height: "48px",
                border: "2px solid #AFE9E0",
                color: formik.values.billingState ? "#000000" : "#8F8F8F",
                outline: "none",
                width: "100%",
                backgroundColor: "#F9F9F9",
                borderRadius: "8px",
                padding: "10px",
              }}
              name="billingState"
              value={formik.values.billingState}
              onChange={formik.handleChange}
            >
              <option value="">-- Select State</option>
              {states?.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {formik.touched.billingState && formik.errors.billingState && (
              <div
                style={{
                  color: "red",
                  margin: "5px",
                }}
              >
                {formik.errors.billingState}
              </div>
            )}
          </Col>
        </Row> */}
          <Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                    value={risks}
                    onChange={() => {
                      setRisks(!risks);
                      if (!risks) {
                        setRisksError(false);
                      }
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      textAlign: "left",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    <a
                      style={{
                        color: "#BF6BE3",
                        fontFamily: "Beatrice",
                        fontWeight: "400",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://glo-studio.com/policies/shipping-policy"
                    >
                      I understand that site-specific factors{" "}
                    </a>
                    may affect final pricing and feasibility.
                  </Typography>
                </div>
                {risksError && (
                  <div
                    style={{
                      // display: "block",
                      color: "red",
                      // margin: "5px",
                    }}
                  >
                    Please check this box to proceed
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  width: window.innerWidth > 768 ? "40%" : "100%",
                  color: "white",
                  fontWeight: "600",
                  fontSize: window.innerWidth > 768 ? "22px" : "1rem",
                  cursor: "pointer",
                  backgroundColor: "#36CDB4",
                  textAlign: "center",
                  padding: "10px",
                  paddingRight: "40px",
                  paddingLeft: "40px",
                  paddingTop: "10px",
                  borderRadius: "35px",
                  border: "none",
                }}
                onClick={handleSubmit}
              >
                OK, I'M READY
              </Button>
            </div>
          </Row>
        </div>

        {/* <RisksModal showRisks={showRisks} setShowRisks={setShowRisks} /> */}
      </Box>
    </Modal>
  );
};

export default LocationCheck;
