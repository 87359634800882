import {
  Box,
  Button,
  Modal,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFont from "../../Assets/UploadFont.png";
import Close from "../../Assets/Close";
import Search from "../../Assets/Search";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addOtherFonts,
  setProductFont,
  setProductFormat,
  updateUserFont,
} from "../../Redux/Slice";
import Font from "./Font";
import FontUpload from "./FontUpload";
import Info from "../../Assets/SuperCustom/Info";
import {
  debounce,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../../utils/client/helpers";
import { getOSInfo } from "../../utils/client/calculations";

const OtherFonts = ({ show, close }) => {
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [errors, setErrors] = useState({});
  const [buttonName, setButtonName] = useState("Other Fonts");

  const fonts = useSelector((state) => state.activeStep.fonts);
  const product = useSelector((state) => state.activeStep.product);
  const otherFonts = useSelector((state) => state.activeStep.otherFonts);
  const userUploadedFonts = useSelector((state) => state.activeStep.userFonts);

  const [inputText, setInputText] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [openHeadingInfo, setOpenHeadingInfo] = useState(false);
  const [openGloInfo, setOpenGloInfo] = useState(false);
  const [openYourInfo, setOpenYourInfo] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const filteredData = fonts.filter((el) => {
    //if no input the return the original
    if (inputText === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(inputText);
    }
  });

  const [name, setName] = useState("");
  const [fontFile, setFontFile] = useState("");

  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    } else if (
      fonts.some((font) => font.name === name) ||
      userUploadedFonts.some((font) => font.name === name)
    ) {
      errors.name = "Font with this name already exists";
    }
    if (!fontFile) {
      errors.fontFile = "Font file is required";
    }

    return errors;
  };

  const handleFontClick = (font) => {
    // console.log(otherFonts.length);

    if (otherFonts.length < 3) {
      dispatch(addOtherFonts(font));
    }
  };

  const handleFontFileSelect = (e) => {
    // console.log(e);
    setFontFile(e.target.files[0]);
  };

  const dispatch = useDispatch();

  const checkFontRenderable = async () => {
    // Create a new FontFace with the uploaded font file
    const font = new FontFace(
      name,
      `url(${URL.createObjectURL(fontFile)}) format('woff')`
    );

    try {
      // Load the font asynchronously
      await font.load();

      // Add the loaded font to the document
      document.fonts.add(font);

      // Check if the font has been registered and is ready for use
      if (document.fonts.check(`12px ${name}`)) {
        const newFont = {
          _id: fonts.length + userUploadedFonts.length,
          name: name,
          type: product?.source,
          description: "<p>This is a new font added by the user</p>",
        };
        // console.log("Font is render able and ready for use.");
        dispatch(updateUserFont(newFont));
      } else {
        // console.log("Font is not renderable.");
      }
    } catch (error) {
      console.error("Error loading font:", error);
    }
  };

  const handleSubmit = () => {
    setButtonName("Other Fonts");
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      // console.log("validating");
      setErrors(errors);
      return;
    }
    setErrors({});
    if (name && fontFile) {
      checkFontRenderable()
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setFail(true);
          console.error("Error loading font:", error);
        });
    }
  };
  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  // Preload images
  const preloadImages = () => {
    fonts.forEach((font) => {
      const imgOn = new Image();
      const imgOff = new Image();
      imgOn.src = font.imageOn;
      imgOff.src = font.imageOff;
    });
  };

  // Call preloadImages function when your component mounts
  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "100vh",
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  // console.log(product.fonts);
  // console.log(otherFonts);
  return (
    <>
      {fonts.map((font, index) => (
        <Font
          key={index}
          name={font.name}
          woffFile={font.woffFile}
          woff2File={font.woff2File}
        />
      ))}
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Beatrice-Display",
                  color: "#25CCB0",
                  fontSize: window.innerWidth <= 500 ? "28px" : "40px",
                  fontWeight: "700",
                  fontStyle: "italic",
                  letterSpacing: "0.05em",
                  marginTop: window.innerWidth <= 500 ? "6px" : "",
                }}
              >
                Your Fonts
                <CustomWidthTooltip
                  open={openHeadingInfo}
                  onClose={() => handleTooltipClose(setOpenHeadingInfo)}
                  title={
                    "You can choose up to three of our in house fonts for your design. If you are looking to add an additional font please either use the note function to share with us more information."
                  }
                >
                  <MyComponent
                    onMouseEnter={() => handleMouseEnter(setOpenHeadingInfo)}
                    onMouseLeave={() => handleMouseLeave(setOpenHeadingInfo)}
                    onClick={() =>
                      handleClick(setOpenHeadingInfo, openHeadingInfo)
                    }
                    style={{
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </CustomWidthTooltip>
              </Typography>
            </div>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: window.innerWidth <= 500 ? "12px" : "22px",
                color: "#595959",
              }}
            >
              By choosing
              <span
                style={{
                  fontFamily: "Beatrice",
                  fontSize: window.innerWidth <= 500 ? "12px" : "16px",
                  fontWeight: "600",
                }}
              >
                {" "}
                "Your Fonts"{" "}
              </span>
              you'll be taken to a different page. There, you’ll create a piece
              that we need to review before we can provide you with a price.
            </Typography>
            <div
              style={{
                position: "relative",
                padding: "0px",
                marginTop: "20px",
              }}
            >
              <input
                className="input"
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "16px",
                  padding: window.innerWidth <= 500 ? "10px" : "20px",
                  background: "white",
                  paddingBottom: "15px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "Beatrice",
                  fontWeight: "600",
                  fontSize: window.innerWidth <= 500 ? "12px" : "21px",
                  lineHeight: "32px",
                  color: "#595959",
                }}
                value={inputText}
                onChange={inputHandler}
                name="inputText"
                placeholder="Search for a font"
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "20px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <Search />
              </div>
            </div>

            {filteredData.length === 0 && (
              <Typography
                className="warning"
                style={{
                  color: "#727272",
                  fontFamily: "Beatrice",
                  width: "100%",
                  textAlign: "center",
                  padding: "5px",
                  fontSize: "13px",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                The “{inputText}” font is not found in our directory, please
                find another font or upload new font.
              </Typography>
            )}
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: window.innerWidth <= 500 ? "column " : "",
                alignItems: window.innerWidth <= 500 ? "center" : "",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  color: buttonName === "Glo Fonts" ? "#ffffff" : "#36CDB4",
                  fontSize: window.innerWidth <= 500 ? "12px" : "16px",
                  width: "20%",
                  padding: "10px",
                  fontWeight: "600",
                  bgcolor: buttonName === "Glo Fonts" ? "#36CDB4" : "#ffffff",
                  borderRadius: "16px",
                  border:
                    buttonName === "Glo Fonts" ? "none" : "1px solid #36CDB4",
                  marginBottom: window.innerWidth <= 500 ? "10px" : "",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                  minWidth: "170px",
                  marginRight: window.innerWidth <= 500 ? "" : "20px",
                }}
                onClick={() => setButtonName("Glo Fonts")}
              >
                Glo Fonts
                <CustomWidthTooltip
                  open={openGloInfo}
                  onClose={() => handleTooltipClose(setOpenGloInfo)}
                  title={
                    "These are our in-house fonts that are pre-priced and ready to produce."
                  }
                >
                  <MyComponent
                    onMouseEnter={() => handleMouseEnter(setOpenGloInfo)}
                    onMouseLeave={() => handleMouseLeave(setOpenGloInfo)}
                    onClick={() => handleClick(setOpenGloInfo, openGloInfo)}
                    style={{
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </CustomWidthTooltip>
              </Button>
              <Button
                sx={{
                  color: buttonName === "Other Fonts" ? "#ffffff" : "#36CDB4",
                  fontSize: window.innerWidth <= 500 ? "12px" : "16px",

                  width: "20%",
                  padding: "10px",
                  fontWeight: "600",
                  bgcolor: buttonName === "Other Fonts" ? "#36CDB4" : "#ffffff",
                  borderRadius: "16px",
                  // marginLeft: "10px",
                  border:
                    buttonName === "Other Fonts" ? "none" : "1px solid #36CDB4",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                  minWidth: "170px",
                }}
                onClick={() => setButtonName("Other Fonts")}
              >
                Your Fonts
                <CustomWidthTooltip
                  open={openYourInfo}
                  onClose={() => handleTooltipClose(setOpenYourInfo)}
                  title={
                    "This is a temporary place where your uploaded fonts will show-up. From here you can select them and use them in your design."
                  }
                >
                  <MyComponent
                    onMouseEnter={() => handleMouseEnter(setOpenYourInfo)}
                    onMouseLeave={() => handleMouseLeave(setOpenYourInfo)}
                    onClick={() => handleClick(setOpenYourInfo, openYourInfo)}
                    style={{
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </CustomWidthTooltip>
              </Button>
            </div>
            <div
              style={{
                marginTop: "20px",
                height: "200px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Row>
                {buttonName === "Other Fonts" ? (
                  <>
                    {userUploadedFonts.map((font, index) => (
                      <>
                        <Col md={4} lg={3} xs={6} sm={6}>
                          <div
                            key={index}
                            draggable
                            className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer ${
                              otherFonts?.includes(font)
                                ? "selected"
                                : "hover-effect"
                            }`}
                            style={{
                              fontFamily: font.name,
                              // height: "10%",
                              // flex: "1 0 100px", // Set to 1 to allow flex items to grow and shrink, minimum width of 100px
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "18px",
                            }}
                            onClick={() => handleFontClick(font)}
                            onMouseEnter={() => {
                              let userPlatform = getOSInfo();
                              if (
                                !(
                                  userPlatform === "iPhone" ||
                                  userPlatform === "Android"
                                )
                              ) {
                                handleHoverChange(index, true);
                              }
                            }}
                            onMouseLeave={() => {
                              let userPlatform = getOSInfo();
                              if (
                                !(
                                  userPlatform === "iPhone" ||
                                  userPlatform === "Android"
                                )
                              ) {
                                handleHoverChange(index, false);
                              }
                            }}
                          >
                            {font.name}
                          </div>
                        </Col>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {filteredData.map((font, index) => (
                      <>
                        <Col md={4} lg={3} xs={6} sm={6}>
                          <div
                            key={index}
                            draggable
                            className={`border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-3xl ${
                              otherFonts?.includes(font)
                                ? "selected"
                                : "hover-effect"
                            }`}
                            style={{
                              fontFamily: font.name,
                              // height: "10%",
                              flex: "1 0 100px", // Set to 1 to allow flex items to grow and shrink, minimum width of 100px
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "18px",
                            }}
                            onClick={() => {
                              handleFontClick(font);
                              handleHoverChange(index, true);
                            }}
                            onMouseEnter={() => {
                              let userPlatform = getOSInfo();
                              if (
                                !(
                                  userPlatform === "iPhone" ||
                                  userPlatform === "Android"
                                )
                              ) {
                                handleHoverChange(index, true);
                              }
                            }}
                            onMouseLeave={() => {
                              let userPlatform = getOSInfo();
                              if (
                                !(
                                  userPlatform === "iPhone" ||
                                  userPlatform === "Android"
                                )
                              ) {
                                handleHoverChange(index, false);
                              }
                            }}
                          >
                            {hoveredStates[index] ? (
                              <img
                                src={font?.imageOff}
                                alt={font.name}
                                style={{
                                  borderRadius: "18px",
                                  width: "100%",
                                  height: "100%",
                                  fontFamily: font.name,
                                }}
                              ></img>
                            ) : (
                              <img
                                src={font.imageOn}
                                alt={font.name}
                                style={{
                                  borderRadius: "18px",
                                  width: "100%",
                                  height: "100%",
                                  fontFamily: font.name,
                                }}
                              ></img>
                            )}
                          </div>
                        </Col>
                      </>
                    ))}
                  </>
                )}
              </Row>
            </div>
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: window.innerWidth <= 500 ? "12px" : "16px",
                margin: "10px",
                color: "#595959",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Don’t see the font as you like? No worries.
              </Typography>
              Add the font name below and upload the font files you want us to
              use.
              <span style={{ color: "#BF6BE3" }}>
                {" "}
                (See “Your Fonts” above to use!)
              </span>
            </Typography>
            <Row
              style={{
                display: window.innerWidth <= 900 ? "flex" : "",
                flexDirection: window.innerWidth <= 900 ? "column" : "",
                alignItems: window.innerWidth <= 900 ? "center" : "",
              }}
            >
              <Col xl={4} md={12} xs={12}>
                <input
                  className="input"
                  style={{
                    border: "2px solid #AFE9E0",
                    outline: "none",
                    width: "100%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: "16px",
                    padding: window.innerWidth <= 900 ? "10px" : "20px",
                    background: "white",
                    paddingBottom: "15px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Beatrice",
                    fontWeight: "600",
                    fontSize: window.innerWidth <= 500 ? "12px" : "22px",
                    lineHeight: "32px",
                    color: "#595959",
                    height: window.innerWidth <= 900 ? "50px" : "80px",
                    marginBottom: window.innerWidth <= 900 ? "20px" : "",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  placeholder="Enter the Font Name"
                />
                {errors.name && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.name}
                  </span>
                )}
              </Col>
              <Col xl={4} md={12} xs={12}>
                <label
                  style={{
                    background: "white",
                    borderRadius: "16px",
                    paddingBottom: "15px",
                    border: "2px dashed #595959 ",
                    padding: window.innerWidth <= 900 ? "10px" : "20px",
                    marginBottom: window.innerWidth <= 900 ? "20px" : "",

                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    id="fileInput"
                    style={{ display: "none" }}
                    type="file"
                    accept=".ttf, .otf, .woff, .woff2"
                    onChange={(e) => handleFontFileSelect(e)}
                  />
                  <img src={UploadFont} alt="UploadFont" />
                  {fontFile ? (
                    <span
                      style={{
                        // display: "inline-flex",
                        color: "#D1A8EA",
                      }}
                    >
                      Font File Uploaded
                    </span>
                  ) : null}
                </label>
                {errors.fontFile && (
                  <span className="text-red-500 text-sm mt-1">
                    {errors.fontFile}
                  </span>
                )}
              </Col>
              <Col xl={4} md={12} xs={12}>
                <Button
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "#03705E",
                    fontSize: window.innerWidth <= 500 ? "12px" : "20px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontWeight: "600",
                    bgcolor: "#AFE9E0",
                    borderRadius: "16px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                    ":hover": {
                      color: "#03705E",
                      border: "1px solid #03705E",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Submit Font
                </Button>
              </Col>
            </Row>
            <div
              className="mobile-flow-switch"
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: window.innerWidth <= 900 ? "center" : "end",
              }}
            >
              <Button
                sx={{
                  width: window.innerWidth <= 900 ? "90%" : "30%",
                  height: "50px",
                  color: "#ffffff",
                  fontSize: window.innerWidth <= 500 ? "12px" : "16px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontWeight: "600",
                  bgcolor: "#36CDB4",
                  borderRadius: "16px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                }}
                onClick={() => {
                  close();
                  dispatch(setProductFont([]));
                  dispatch(setProductFormat("super-custom"));
                }}
              >
                OK, TAKE ME TO THE NEXT PAGE.
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <FontUpload
        show={success}
        close={() => setSuccess(false)}
        file={fontFile}
        name={name}
        setFontFile={setFontFile}
        setName={setName}
      />
    </>
  );
};

export default OtherFonts;
