import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Card, Carousel } from "react-bootstrap";
import EditModal from "./EditModal";
import AddOn from "../../Components/Accessories/AddOn";
import { useSelector } from "react-redux";

const AddOnCard = ({ heading }) => {
  const addOns = useSelector(
    (state) => state.activeStep.product.accessories.addOns
  );
  const [edit, setEdit] = useState("");
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const allImages = [];
  addOns.forEach((addOn) => {
    addOn.images.forEach((imageUrl) => {
      allImages.push(imageUrl);
    });
  });
  // console.log(addOns);
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => setModalShow(true)}
        >
          <>Edit</>
        </Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   border: "2px solid #AFE9E0",
          //   borderRadius: "16px",
          //   padding: "10px",
        }}
      >
        {addOns?.length > 0 ? (
          <div
            style={{
              border: "2px solid #AFE9E0",
              width: "100%",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Carousel>
              {allImages?.map((image, index) => (
                <Carousel.Item
                  style={{
                    height: "80px",
                  }}
                  key={index}
                >
                  <img
                    style={{
                      height: "100%",
                    }}
                    src={image}
                    alt={`${index} img`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        ) : (
          <Card
            style={{
              height: "100px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #AFE9E0",
              borderRadius: "16px",
            }}
          >
            <Typography
              style={{
                margin: "20px",
                fontSize: "32px",
                fontWeight: "400",
                fontFamily: "Beatrice",
                color: "#000000",
              }}
            >
              N/A
            </Typography>
          </Card>
        )}
      </div>
      <AddOn show={modalShow} onHide={() => setModalShow(false)} />
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default AddOnCard;
