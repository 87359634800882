import { Typography } from "@mui/material";
import React from "react";
import DescriptionCard from "./DescriptionCard";
import { useSelector } from "react-redux";

const Notes = () => {
  const fontsDescription = useSelector(
    (state) => state.activeStep.product.fontsDescription
  );
  const colorDescription = useSelector(
    (state) => state.activeStep.product.colorDescription
  );
  const backingStyleDescription = useSelector(
    (state) => state.activeStep.product.backing.styleDescription
  );
  const backingColorDescription = useSelector(
    (state) => state.activeStep.product.backing.colorDescription
  );
  const sizeDescription = useSelector(
    (state) => state.activeStep.product.size.description
  );
  const accessoriesDescription = useSelector(
    (state) => state.activeStep.product.accessories.description
  );
  return (
    <>
      <Typography
        style={{
          color: "#000000",
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
          fontWeight: "400",
          fontFamily: "Beatrice",
        }}
      >
        Customer Notes
      </Typography>
      <div
        style={{
          borderRadius: "16px",
          backgroundColor: `#F4F4F4`,
          maxHeight: "430px",
          overflowY: "auto",
        }}
      >
        {fontsDescription && (
          <DescriptionCard
            heading={"Custom Fonts"}
            description={fontsDescription}
          />
        )}
        {colorDescription && (
          <DescriptionCard
            heading={"Custom Color"}
            description={colorDescription}
          />
        )}
        {backingStyleDescription && (
          <DescriptionCard
            heading={"Custom Backing Style"}
            description={backingStyleDescription}
          />
        )}
        {backingColorDescription && (
          <DescriptionCard
            heading={"Custom Backing Color"}
            description={backingColorDescription}
          />
        )}
        {sizeDescription && (
          <DescriptionCard
            heading={"Custom Size"}
            description={sizeDescription}
          />
        )}
        {accessoriesDescription && (
          <DescriptionCard
            heading={"Custom Accessories"}
            description={accessoriesDescription}
          />
        )}
      </div>
    </>
  );
};

export default Notes;
