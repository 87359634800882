import React from "react";

const Instagram = () => {
  return (
    <svg
      width="54"
      height="52"
      viewBox="0 0 54 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.071 51.3363C41.6283 51.3363 53.4428 39.8369 53.4428 25.6681C53.4428 11.4993 41.6283 0 27.071 0C12.5138 0 0.699219 11.4993 0.699219 25.6681C0.699219 39.8369 12.5138 51.3363 27.071 51.3363Z"
        fill="#36CDB4"
      />
      <path
        d="M21.5345 12.834H32.6107C36.8302 12.834 40.2585 16.1708 40.2585 20.2777V31.0584C40.2585 33.0326 39.4528 34.9259 38.0185 36.3219C36.5843 37.7179 34.639 38.5021 32.6107 38.5021H21.5345C17.3151 38.5021 13.8867 35.1653 13.8867 31.0584V20.2777C13.8867 18.3035 14.6925 16.4102 16.1267 15.0142C17.561 13.6182 19.5062 12.834 21.5345 12.834ZM21.2708 15.4008C20.0119 15.4008 18.8045 15.8876 17.9142 16.754C17.024 17.6205 16.5239 18.7957 16.5239 20.0211V31.315C16.5239 33.869 18.6468 35.9353 21.2708 35.9353H32.8744C34.1334 35.9353 35.3408 35.4485 36.231 34.5821C37.1212 33.7156 37.6213 32.5404 37.6213 31.315V20.0211C37.6213 17.4671 35.4984 15.4008 32.8744 15.4008H21.2708ZM33.9952 17.3259C34.4324 17.3259 34.8516 17.4949 35.1607 17.7958C35.4698 18.0966 35.6435 18.5047 35.6435 18.9302C35.6435 19.3556 35.4698 19.7637 35.1607 20.0646C34.8516 20.3654 34.4324 20.5344 33.9952 20.5344C33.5581 20.5344 33.1388 20.3654 32.8297 20.0646C32.5206 19.7637 32.347 19.3556 32.347 18.9302C32.347 18.5047 32.5206 18.0966 32.8297 17.7958C33.1388 17.4949 33.5581 17.3259 33.9952 17.3259ZM27.0726 19.251C28.8212 19.251 30.4981 19.9271 31.7345 21.1305C32.971 22.334 33.6656 23.9662 33.6656 25.6681C33.6656 27.37 32.971 29.0022 31.7345 30.2056C30.4981 31.409 28.8212 32.0851 27.0726 32.0851C25.3241 32.0851 23.6471 31.409 22.4107 30.2056C21.1743 29.0022 20.4797 27.37 20.4797 25.6681C20.4797 23.9662 21.1743 22.334 22.4107 21.1305C23.6471 19.9271 25.3241 19.251 27.0726 19.251ZM27.0726 21.8178C26.0235 21.8178 25.0173 22.2235 24.2755 22.9455C23.5336 23.6676 23.1169 24.6469 23.1169 25.6681C23.1169 26.6892 23.5336 27.6685 24.2755 28.3906C25.0173 29.1126 26.0235 29.5183 27.0726 29.5183C28.1218 29.5183 29.1279 29.1126 29.8698 28.3906C30.6116 27.6685 31.0284 26.6892 31.0284 25.6681C31.0284 24.6469 30.6116 23.6676 29.8698 22.9455C29.1279 22.2235 28.1218 21.8178 27.0726 21.8178Z"
        fill="white"
      />
    </svg>
  );
};

export default Instagram;
