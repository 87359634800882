import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, setShowExamples, setTheme } from "../../Redux/Slice";
import { Button, ButtonGroup, Typography } from "@mui/material";
import Moon from "../../Assets/Moon";
import Sun from "../../Assets/Sun";
import "./SuperDelivery/styles/SuperBottomControls.css";
const SuperBottomControls = () => {
  const theme = useSelector((state) => state.activeStep.theme);
  const product = useSelector((state) => state.activeStep.product);
  const showExamples = useSelector((state) => state.activeStep.showExamples);

  const dispatch = useDispatch();

  const toggleLight = () => {
    dispatch(setTheme("white"));
  };
  const toggleDark = () => {
    dispatch(setTheme("black"));
  };
  const activeStep = useSelector((state) => state.activeStep);

  // const width = product.size.width ? product.size.width : 10;
  // const height = product.size.height ? product.size.height : 7;

  // console.log(activeStep.step);
  return (
    <div
      style={{
        fontFamily: "Beatrice",
        marginTop: "20px",
        padding: "0px",
      }}
      className="flex flex-col md:flex-row justify-between items-center"
    >
      <Button
        className="bottom-example-buttons hover-effect"
        sx={{
          color: "#25CCB0",
          fontSize: "16px",
          fontFamily: "Beatrice",
          fontWeight: "600",
          textTransform: "uppercase",
          letterSpacing: "2px",
          bgcolor: "#D8F4F1",
          borderRadius: "16px",
          paddingRight: "20px",
          paddingLeft: "20px",
          marginBottom: window.innerWidth > 768 ? "" : "15px",
        }}
        onClick={() => {
          dispatch(setShowExamples(!showExamples));
        }}
      >
        EXAMPLES
      </Button>
      {activeStep.step === 2 ? (
        <></>
      ) : (
        <>
          <ButtonGroup
            className="bg-[#D8F4F1] bottom-buttons rounded-full"
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            style={{ height: "50px" }}
          >
            <Button
              style={{
                border: "none",
                borderRadius: "22px",
                // color: theme === "white" ? "white" : "#25CCB0",
                backgroundColor: theme === "white" ? "#25CCB0" : "#D8F4F1",
              }}
              onClick={toggleLight}
            >
              <Sun color={theme === "white" ? "white" : "#25CCB0"} />
            </Button>
            <div
              style={{
                width: "10px",
              }}
            ></div>
            <Button
              style={{
                border: "none",
                borderRadius: "22px",
                // color: theme === "black" ? "white" : "#25CCB0",
                backgroundColor: theme === "black" ? "#25CCB0" : "#D8F4F1",
              }}
              onClick={toggleDark}
            >
              <Moon color={theme === "black" ? "white" : "#25CCB0"} />
            </Button>
          </ButtonGroup>
        </>
      )}
      {activeStep.step === 8 ? (
        <div
          // className="continue-button-width-adjust"
          style={{
            fontFamily: "Beatrice",
            marginTop: "15px", // Add margin for separation
            display: "flex",
            justifyContent: "flex-end",
          }}
        ></div>
      ) : (
        <div
          // className="continue-button-width-adjust"
          style={{
            fontFamily: "Beatrice",
            marginTop: "15px", // Add margin for separation
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className=" hover-effect"
            sx={{
              color: "#03705E",
              fontSize: "20px",
              fontFamily: "Beatrice",
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "2px",
              bgcolor: "#D8F4F1",
              borderRadius: "16px",
              paddingRight: "30px",
              paddingLeft: "30px",
            }}
            onClick={() => dispatch(nextStep())}
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export default SuperBottomControls;
