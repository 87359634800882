import React from "react";

const Redo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="10"
      viewBox="0 0 24 10"
      fill="none"
    >
      <path
        d="M1.71603 8.20338C2.65609 5.93995 4.25305 4.06724 6.26618 2.86732C8.2795 1.6674 10.5998 1.20551 12.8776 1.55106C16.6005 2.11585 19.1952 5.07303 22.0078 7.79439M22.0078 1.43945L22.0078 8.20338L16.4737 8.20338"
        stroke="#36CDB4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Redo;
