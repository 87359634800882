import { Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as DescriptionIcon } from "../Assets/DescriptionIcon.svg";
import { useDispatch } from "react-redux";
import Close from "../Assets/Close";
import Send from "../Assets/Send";

const DescriptionButton = ({ value, setValue }) => {
  const [desc, setDesc] = useState(value);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      {" "}
      <button onClick={handleDescriptionClick}>
        <DescriptionIcon />
      </button>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",

            backgroundColor: "#F4F4F4",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleDescriptionClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: window.innerWidth > 768 ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: window.innerWidth > 768 ? "right" : "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F4",

            margin: "10px",

            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#595959",

                fontFamily: "Beatrice",

                fontWeight: "600",

                fontSize: "15px",
              }}
            >
              Anything we should know?
            </Typography>

            <button style={{}} onClick={handleDescriptionClose}>
              <Close />
            </button>
          </div>

          <div
            style={{
              background: "white",

              borderRadius: "16px",

              paddingBottom: "15px",
            }}
          >
            <textarea
              style={{
                padding: "10px",

                borderRadius: "16px",

                height: "200px",

                width: "100%",

                color: "#8F8F8F",

                outline: "none",

                resize: "none",
              }}
              value={desc}
              placeholder="Drop us a note if you have any specific details to share on this particular design selection."
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>

            <div
              style={{
                margin: "0px",

                padding: "0px",

                position: "relative",

                float: "right",

                bottom: "20px",

                right: "15px",
              }}
            >
              <button
                onClick={() => {
                  dispatch(setValue(desc));

                  handleDescriptionClose();
                }}
              >
                <Send />
              </button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DescriptionButton;
