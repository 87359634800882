import axios from "axios";
const opentype = require("opentype.js");

export const calculateKerning = async (woffFile, text) => {
  try {
    // console.log(woffFile, text);
    const response = await axios.get(woffFile, { responseType: "arraybuffer" });
    const fontDataArrayBuffer = response.data;
    const fontData = new Uint8Array(fontDataArrayBuffer);
    const font = opentype.parse(fontData.buffer);
    // Convert the text into an array of glyphs
    const glyphs = [];
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      const glyph = font.charToGlyph(char);
      glyphs.push(glyph);
    }

    // Calculate kerning for each character
    let totalKerningValue = 0;
    for (let i = 0; i < glyphs.length - 1; i++) {
      const pair1 = glyphs[i];
      const pair2 = glyphs[i + 1];
      const kerningValue = font.getKerningValue(pair1, pair2);
      totalKerningValue += kerningValue;
    }

    return totalKerningValue ? totalKerningValue : 0;
  } catch (error) {
    // alert("Kerning Value issue");
    console.error("Error in calculateKerning:", error);
    throw error;
  }
  // console.log(woffFile);
};

export const calculateDescender = async (woffFile) => {
  try {
    // console.log(woffFile, text);
    const response = await axios.get(woffFile, { responseType: "arraybuffer" });
    const fontDataArrayBuffer = response.data;
    const fontData = new Uint8Array(fontDataArrayBuffer);
    const font = opentype.parse(fontData.buffer);

    let descenderValue = font.descender;
    // Convert the text into an array of glyphs

    return descenderValue ? descenderValue : 0;
  } catch (error) {
    // alert("Kerning Value issue");
    console.error("Error in calculateDescender:", error);
    throw error;
  }
  // console.log(woffFile);
};

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserInfo = "Unknown Browser";

  if (userAgent.indexOf("Firefox") > -1) {
    browserInfo = "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserInfo = "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserInfo = "Apple Safari";
  } else if (userAgent.indexOf("Edge") > -1 || userAgent.indexOf("Edg") > -1) {
    browserInfo = "Microsoft Edge";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident/") > -1
  ) {
    browserInfo = "Internet Explorer";
  }

  return browserInfo;
};

export const getOSInfo = () => {
  const userAgent = navigator.userAgent;

  if (/iPhone/i.test(userAgent)) {
    return "iPhone";
  } else if (/Android/i.test(userAgent)) {
    return "Android";
  } else if (navigator.platform.indexOf("Win") > -1) {
    return "Windows";
  } else if (navigator.platform.indexOf("Mac") > -1) {
    return "Mac";
  } else if (navigator.platform.indexOf("Linux") > -1) {
    return "Linux";
  } else {
    return "Unknown";
  }
};

export const calculateFontArea = async (value, font, jsonLink, woffFile) => {
  let totalWidth = 0;
  let totalHeight = 0;
  let mHeight = 0;
  let currentMaxHeight = 0;
  let materialLength = 0;
  let lineWidth = 0; // Track the current line width
  let maxLineWidth = 0; // Track the maximum line width
  let kerningValue = 0; // Track kerning value

  if (!font) {
    // Handle missing font data
  } else {
    try {
      const response = await axios.get(jsonLink);

      // console.log(response.data);
      const fontData = response.data;

      // console.log(fontData);
      const userAgent = getBrowserInfo();
      const userPlatform = getOSInfo();

      // if (
      //   userAgent === "Apple Safari" ||
      //   userPlatform === "iPhone" ||
      //   userPlatform === "Android"
      // ) {
      // } else {
      //   // kerningValue = await calculateKerning(woffFile, value);
      // }

      if (value) {
        // console.log("Kerning Value:", kerningValue / 96);
        for (let i = 0; i < value.length; i++) {
          const char = value[i];
          if (char === " ") {
            const spaceCharacter = fontData.find(
              (data) => data.char === "space"
            );
            if (spaceCharacter) {
              const width = spaceCharacter.width;
              totalWidth += width;
              materialLength += spaceCharacter.length;
              lineWidth += width;
            }
          } else if (char === "\n") {
            mHeight += currentMaxHeight;
            totalHeight += mHeight;
            currentMaxHeight = 0;
            // Check if the current line width is greater than the maximum line width
            if (lineWidth > maxLineWidth) {
              maxLineWidth = lineWidth;
            }
            // Reset the current line width
            lineWidth = 0;
          } else {
            const character = fontData.find((data) => data.char === char);
            if (character) {
              const width = character.width;
              totalWidth += width;
              materialLength += character.length / 96;
              lineWidth += width;

              const height = character.height;
              if (height > currentMaxHeight) {
                currentMaxHeight = height;
              }
            }
          }
        }
        if (value.split("").some((char) => "gjpqyzf".includes(char))) {
          // Add the descender value to the height
          let descenderHeight = 0;
          let descender = 0;
          if (
            userAgent === "Apple Safari" ||
            userPlatform === "iPhone" ||
            userPlatform === "Android"
          ) {
          } else {
            descender = await calculateDescender(woffFile);
          }

          descenderHeight = Math.abs(descender);
          // console.log(currentMaxHeight);
          // console.log(descenderHeight);
          currentMaxHeight += descenderHeight;
          // console.log(currentMaxHeight);
        }
      }

      mHeight += currentMaxHeight;
      totalHeight += mHeight;
      // Check the width of the last line
      if (lineWidth > maxLineWidth) {
        maxLineWidth = lineWidth;
      }

      let maxHeight = totalHeight / 96;
      // console.log("Width without Kerning:", maxLineWidth / 96);
      totalWidth = (maxLineWidth + kerningValue) / 96;
      // console.log("Width with Kerning:", (maxLineWidth + kerningValue) / 96);
      return { materialLength, totalWidth, maxHeight };
    } catch (error) {
      console.error("Error fetching font data:", error);
    }
  }
};

export const calculateLetterLength = async (letter, font, jsonLink) => {
  if (!font) {
    // Handle error when font is not selected
    return 0;
  }

  try {
    const response = await axios.get(jsonLink);
    const fontData = await response.data;

    // let width = 0;
    // let height = 0;
    let materialLength = 0;

    if (letter === " ") {
      const spaceCharacter = fontData.find((data) => data.char === "space");
      if (spaceCharacter) {
        // width = spaceCharacter.width / 96;
        // height = spaceCharacter.height / 96;
        materialLength = spaceCharacter.length / 96;
      }
    } else {
      const character = fontData.find((data) => data.char === letter);
      if (character) {
        // width = character.width / 96;
        // height = character.height / 96;
        materialLength = character.length / 96;
      }
    }

    return materialLength;
  } catch (error) {
    console.error("Error fetching font data:", error);
    return 0; // Return 0 or handle the error in an appropriate way
  }
};
