import { Button, Typography } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../Assets/Close";

const PickUpMap = ({ showMap, closeMap }) => {
  return (
    <Modal
      show={showMap}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        margin: "0px",
      }}
    >
      <Modal.Header
        style={{
          border: "none",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Beatrice-Display",
            color: "#25CCB0",
            fontSize: "40px",
            fontWeight: "700",
            fontStyle: "italic",
            letterSpacing: "0.05em",
          }}
        >
          Pick Up Location
        </Typography>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={closeMap}
        >
          <Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            textAlign: "center",
            display: "block",
            border: "none",
            margin: "0px",
            padding: "0px",
          }}
        >
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.911608670714!2d-73.9868481!3d40.7199623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259816d03e001%3A0x99d4398aecc2108e!2s133%20Norfolk%20St%2C%20New%20York%2C%20NY%2010002%2C%20USA!5e0!3m2!1sen!2s!4v1687291468401!5m2!1sen!2s"
            width="770"
            height="600"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PickUpMap;
