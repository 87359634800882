import React from "react";

const Unlock = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "35"}
      height={height ? height : "38"}
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="15" width="26" height="22.75" fill="#25CCB0" />
      <path
        d="M20 15C20 15 20 12.8333 20 8.5C20 4.16667 22.1667 2 26.5 2C30.8333 2 33 4.16667 33 8.5C33 12.8333 33 15 33 15"
        stroke="#25CCB0"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Unlock;
