export const handleTooltipClose = (setOpen) => {
  setOpen(false);
};

export const handleTooltipOpen = (setOpen) => {
  setOpen(true);
};

export const handleMouseEnter = (setOpen) => {
  setOpen(true);
};

export const handleMouseLeave = (setOpen) => {
  setOpen(false);
};

export const handleClick = (setOpen, open) => {
  setOpen(!open);
};

export function debounce(func, delay) {
  let timer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}
