// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .price-container {
    flex-direction: column; /* Change to column layout for mobile screens */
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/styles/Shared.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA,mCAAmC;AACnC;EACE;IACE,sBAAsB,EAAE,+CAA+C;EACzE;AACF","sourcesContent":[".price-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n/* Media query for mobile screens */\n@media (max-width: 768px) {\n  .price-container {\n    flex-direction: column; /* Change to column layout for mobile screens */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
