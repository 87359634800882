// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



@media (max-width: 1000px)  {
    .delivery-tabs {
 margin-bottom: 20px;
margin-left: 35px;
width: 95%;
  }
}
 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .delivery-items {
    margin-bottom: 20px;
    width: 87%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }  
  .delivery-item {
    width: 70%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/styles/Delivery.css"],"names":[],"mappings":"AAAA,eAAe;AACf,wCAAwC;AACxC,wCAAwC;;;;AAIxC;IACI;CACH,mBAAmB;AACpB,iBAAiB;AACjB,UAAU;EACR;AACF;CACC,oCAAoC;CACpC;EACC;IACE,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":["/* styles.css */\n/* Media query for larger screen sizes */\n/* Media query for larger screen sizes */\n\n\n\n@media (max-width: 1000px)  {\n    .delivery-tabs {\n margin-bottom: 20px;\nmargin-left: 35px;\nwidth: 95%;\n  }\n}\n /* Media query for smaller screens */\n @media (max-width: 769px) {\n  .delivery-items {\n    margin-bottom: 20px;\n    width: 87%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }  \n  .delivery-item {\n    width: 70%;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
