import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
// import Joyride, { STATUS } from "react-joyride";
import sanitize from "sanitize-html";

const Help = ({ show, handleClose }) => {
  const helpData = useSelector((state) => state.activeStep.helpData);
  // const hasVisitedhelpPage = localStorage.getItem("hasVisitedhelpPage");
  // const [showJoyride, setShowJoyride] = useState(!hasVisitedhelpPage);

  // const [{ run, steps }, setSteps] = useState({
  //   run: true,
  //   steps: [
  //     {
  //       content: <h2>Let's begin our journey!</h2>,
  //       locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
  //       placement: "center",
  //       target: "body",
  //       title: "About Formate",
  //     },
  //     {
  //       content: "A picture’s worth a thousand words.",
  //       placement: "left",
  //       styles: {
  //         options: {
  //           width: 300,
  //         },
  //       },
  //       target: ".sourceList",
  //       title: "Submit Your Own Design!",
  //     },
  //     {
  //       content: "Who said actions speak louder than words?",
  //       placement: "bottom",
  //       styles: {
  //         options: {
  //           width: 300,
  //           marginLeft: 50,
  //         },
  //       },
  //       target: ".helpPage",
  //       title: "Text Based Neon Designer!",
  //     },
  //   ],
  // });
  // const handleJoyrideCallback = (data) => {
  //   const { status, type } = data;
  //   const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

  //   if (finishedStatuses.includes(status)) {
  //     setSteps({ run: false });
  //   }
  // };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(helpData);
  return (
    <>
      {/* <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> */}

      <Offcanvas
        style={{
          width:
            screenWidth <= 480
              ? "85%"
              : screenWidth <= 768
              ? "60%"
              : screenWidth <= 1000
              ? "60%"
              : "35%",
          zIndex: "9",
        }}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header
          style={{
            padding: "0px",
            marginRight: "10px",
          }}
        >
          {/* <Close
            style={{
              color: "#36CDB4",
              width: "60px",
              cursor: "pointer",
            }}
            className="justify-end"
            onClick={() => {
              handleClose();
            }}
          /> */}
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            marginLeft:
              window.innerWidth <= 435
                ? "10px"
                : window.innerWidth <= 768
                ? "20px"
                : "40px",
            marginRight:
              window.innerWidth <= 435
                ? "10px"
                : window.innerWidth <= 768
                ? "20px"
                : "40px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            // paddingTop: "90px",
          }}
        >
          <img
            style={{
              borderRadius: "16px",
              width: "100%",
              // height: window.innerWidth <= 435 ? "" : "250px",
            }}
            src={helpData?.imageUrls?.[0]}
            alt={helpData?.title}
          />
          <div
            className="helpPage"
            style={
              {
                // marginTop: "10px",
              }
            }
          >
            <Typography
              style={{
                fontSize:
                  window.innerWidth <= 435
                    ? "20px"
                    : window.innerWidth <= 768
                    ? "18px"
                    : "1.5rem",
                fontWeight: "600",
                fontFamily: "Beatrice",
                color: "#595959",
                marginTop: "10px",
                // height: "70px",
              }}
            >
              {helpData?.title}
            </Typography>

            <div
              className="enable-listing-style"
              style={{
                marginTop: "13px",
                fontSize:
                  window.innerWidth <= 435
                    ? "12px"
                    : window.innerWidth <= 768
                    ? "12px"
                    : "0.75rem",
                fontFamily: "Beatrice",
                overflowY: "auto",
                color: "#8F8F8F",
                maxHeight: "300px",
                paddingRight: "10px",
              }}
              dangerouslySetInnerHTML={{
                __html: sanitize(helpData?.content),
              }}
            />
          </div>
          <div className=" text-center">
            <Button
              sx={{
                fontFamily: "Beatrice",
                bgcolor: "#36CDB4",
                borderRadius: "35px",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                ":hover": {
                  color: "#36CDB4",
                  bgcolor: "white",
                  border: "1px solid #36CDB4",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              <Typography
                style={{
                  fontSize: window.innerWidth <= 435 ? "14px" : "1.25rem",
                  fontWeight: "600",
                  fontFamily: "Beatrice",
                }}
              >
                KEEP DESIGNING
              </Typography>
            </Button>
          </div>
          {screenWidth === 1920 ? (
            <button
              onClick={handleClose}
              className="vertical-button-offcanvas flex justify-center items-center"
            >
              Need help deciding?
            </button>
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Help;
