import { Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const DeliveryInfo = () => {
  const deliveryDetails = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const deliveryType = useSelector(
    (state) => state.activeStep.product.delivery.deliveryType
  );
  // console.log(deliveryDetails);
  return (
    <>
      <Typography
        style={{
          color: "#000000",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
          fontWeight: "400",
          fontFamily: "Beatrice",
        }}
      >
        Delivery Info
      </Typography>
      <Row
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            Delivery Type
          </Typography>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            {deliveryType?.toUpperCase()}
          </Typography>
        </Col>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            Address
          </Typography>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            {deliveryDetails?.address}
          </Typography>
        </Col>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            State
          </Typography>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            {deliveryDetails?.state}
          </Typography>
        </Col>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            City
          </Typography>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            {deliveryDetails?.city}
          </Typography>
        </Col>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            Zip
          </Typography>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            {deliveryDetails?.zip}
          </Typography>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default DeliveryInfo;
