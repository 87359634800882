import React from "react";

const Wire = ({ color }) => {
  return (
    <svg
      draggable="true"
      width="14"
      height="100"
      viewBox="0 0 14 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_3067_120128)">
        <path
          d="M0.00034335 180.327C0.0887391 173.666 3.54166 167.143 5.94406 160.585C7.14183 157.315 8.08042 154.021 8.16456 150.711C8.24982 147.403 7.43657 144.101 6.31433 140.823C4.29944 134.94 1.26076 129.097 0.443303 123.159C-0.375278 117.22 1.82254 111.364 4.19277 105.527C6.5129 99.8126 8.86135 94.0665 8.32074 88.2488C7.76821 82.3085 4.80284 76.4585 2.68531 70.5855C1.63046 67.6572 0.774087 64.7128 0.540045 61.7561C0.305974 58.8066 0.795627 55.8627 1.66286 52.9375C3.4099 47.0418 6.5872 41.2287 7.97921 35.3138C9.37468 29.3799 7.97626 23.5409 5.71221 17.6616C3.45484 11.8017 0.746537 5.94261 0.714844 0C1.22213 0.00167781 1.73055 0.00335935 2.23784 0.00503716C2.65044 0.00640178 3.06417 0.00777013 3.47676 0.00913475C3.78226 0.0101452 4.08777 0.0111556 4.39327 0.012166C4.72808 0.0132733 5.06176 0.014377 5.39658 0.0154843C5.42827 5.95809 8.13657 11.8172 10.3939 17.6767C12.658 23.5564 14.0575 29.395 12.6609 35.3288C11.2689 41.2442 8.09164 47.0568 6.34459 52.9525C5.47736 55.8781 4.98771 58.8216 5.22178 61.7712C5.45582 64.7279 6.3122 67.6722 7.36705 70.6006C9.48345 76.4735 12.4499 82.324 13.0025 88.2638C13.5442 94.0811 11.1958 99.8276 8.8745 105.542C6.50428 111.379 4.30646 117.235 5.12504 123.174C5.94363 129.112 8.98117 134.955 10.9961 140.838C12.1183 144.116 12.9316 147.417 12.8463 150.726C12.761 154.036 11.8236 157.33 10.6258 160.6C8.2234 167.158 4.77047 173.681 4.68208 180.342C4.34727 180.341 4.01358 180.34 3.67877 180.339C3.37327 180.338 3.06777 180.337 2.76226 180.336C2.34967 180.335 1.93594 180.333 1.52335 180.332C1.01605 180.331 0.507634 180.329 0.00034335 180.327Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_3067_120128"
          x="0"
          y="0"
          width="13.2188"
          height="184.342"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3067_120128"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Wire;
