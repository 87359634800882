// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping-detail{
   margin-bottom: 20px;
}
@media (max-width: 769px) {
    .shipping-detail {
 margin-bottom: 250px;
    }
  }

  /* // pickup */
  `, "",{"version":3,"sources":["webpack://./src/Components/SuperCustom/SuperDelivery/SuperRush/styles/ShippingAddress.css"],"names":[],"mappings":"AAAA;GACG,mBAAmB;AACtB;AACA;IACI;CACH,oBAAoB;IACjB;EACF;;EAEA,cAAc","sourcesContent":[".shipping-detail{\n   margin-bottom: 20px;\n}\n@media (max-width: 769px) {\n    .shipping-detail {\n margin-bottom: 250px;\n    }\n  }\n\n  /* // pickup */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
