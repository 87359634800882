import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import Close from "../../Assets/Close";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setProductDeliveryDetails } from "../../Redux/Slice";
// import ThankYouModal from "./ThankYouModal";
import InquiryThankYou from "./InquiryThankYou";
import { getBrowserInfo } from "../../utils/client/calculations";

const SubmitInquiry = ({
  errors,
  setErrors,
  calculateTotalKerning,

  fontColors,
  format,
  source,
  fonts,
  backing,
  png,
  colors,
  backingColors,
  cables,
  calculateFontArea,
  calculateLetterLength,
  cost,
  shippingCost,
  deliveryDetails,
  power,
  powerQuantity,
  weightPerCubicInch,
  svgLink,
  link,
  showSave,
  closeSave,
}) => {
  const [openThankYou, setOpenThankYou] = useState(false);
  const product = useSelector((state) => state.activeStep.product);
  const size = useSelector((state) => state.activeStep.product.size);

  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const dispatch = useDispatch();
  const date = new Date();

  const validateForm = () => {
    const errors = {};
    if (!details?.name) {
      errors.name = "Name is required";
    }
    if (!details?.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(details?.email)) {
      errors.email = "Email is invalid";
    }
    // console.log(errors);
    return errors;
  };

  const handleInquiry = async () => {
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    if (format && source && fonts && backing && png) {
      let concatenatedValues = "";
      product.fonts.forEach((font) => {
        concatenatedValues += font.value + " ";
      });

      let colorNames = fontColors.map((code) => {
        let found = colors.find((color) => color.code === code);
        return found ? found.name : "custom";
      });
      let uniqueColorNamesSet = new Set(colorNames);
      let uniqueColorNamesArray = Array.from(uniqueColorNamesSet);

      let backingColor = backingColors.find(
        (color) => color.code === product.backing.color
      );
      let accessoryColor = cables.find(
        (cable) => cable.color === product.accessories.wireColor
      );

      let totalKerning = 0;

      const userAgent = getBrowserInfo();
      if (userAgent === "Apple Safari") {
      } else {
        totalKerning = await await calculateTotalKerning(product?.fonts);
      }
      // console.log(accessoryColor);

      let fontsDimensions = [];
      for (const font of product.fonts) {
        const dimensions = await calculateFontArea(
          font.value,
          font.fontFamily,
          font.jsonLink
        );
        fontsDimensions.push({
          fontFamily: font.fontFamily,
          value: font.value,
          ...dimensions,
        });
      }
      // console.log(fontsDimensions);

      let letterDimensions = {};
      for (const font of product.fonts) {
        if (!letterDimensions[font.value]) {
          // Initialize the nested object for the font.value
        }

        for (const char of font.value) {
          const key = `${font.value}_${font.fontFamily}`; // Create a unique key for each font
          if (!letterDimensions[key]) {
            letterDimensions[key] = {}; // Initialize the nested object for the font
          }

          const letterDimension = await calculateLetterLength(
            char,
            font.fontFamily,
            font.jsonLink
          );

          letterDimensions[key][char] = letterDimension;
        }
      }
      let weight = 0;
      if (product.source === "LED") {
        weight =
          (Number(size.width) + backing.width + backing.lockedWidth + 3) *
          (Number(size.height) + backing.height + backing.lockedHeight + 3) *
          (Number(size.depth) + 3) *
          weightPerCubicInch;
      } else {
        weight =
          (Number(size.width) + backing.width + backing.lockedWidth + 5) *
          (Number(size.height) + backing.height + backing.lockedHeight + 5) *
          (Number(size.depth) + 5) *
          weightPerCubicInch;
      }

      // console.log(size, size.height, size.depth);

      concatenatedValues = concatenatedValues.trim();
      let updatedProduct = {
        ...product,
        fontsColor: uniqueColorNamesArray,
        backingColor: backingColor ? backingColor?.name : "Custom Color",
        wireColor: accessoryColor?.name,
        widthInches:
          product.size.width + product.backing.width + backing.lockedWidth,
        HeightInches:
          product.size.height + product.backing.height + backing.lockedHeight,
        png: png,
        cost: cost + shippingCost,
        concatenatedValues: concatenatedValues,
        productionImage: svgLink ? svgLink : "N/A",
        userCreatedProductImage: png,
        customShapeImage: product.backing.image,
        deliveryDate: product.shippingTime ? product.shippingTime : "N/A",
        orderDate: date.toISOString(),
        customerName: details?.name,
        customerEmail: details?.email,
        customerContactNumber: details?.phoneNumber,
        customerAddress: deliveryDetails?.address,
        customerCountry: deliveryDetails?.country
          ? deliveryDetails?.country
          : "US",
        customerProvince: deliveryDetails?.state,
        customerCity: deliveryDetails?.city,
        customerZipCode: deliveryDetails?.zip,
        totalWeight: weight,
        fontsDimensions: fontsDimensions,
        WordDimensions: letterDimensions,
        power: power,
        powerQuantity: powerQuantity,
        kerning: totalKerning,
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}inquiry/${details.email}`,
          updatedProduct
        );
        // console.log(response.data);
        if (response.data) {
          setOpenThankYou(true);
          closeSave();
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(updatedProduct);
    }
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "40%" : "90%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <>
      <Modal
        open={showSave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={closeSave}
            >
              <Close />
            </div>
          </div>
          <div
            style={
              {
                // overflow: "scroll",
                // paddingLeft: "100px",
                // paddingRight: "100px",
              }
            }
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
                textAlign: "center",
              }}
            >
              Submit your design for a Quote
            </Typography>
            <Row style={{ margin: "0px", marginBottom: "20px" }}>
              <Typography
                style={{
                  color: "#03705E",
                  fontSize: window.innerWidth > 768 ? "" : "0.75rem",
                  textAlign: "justify",
                }}
              >
                Have questions about design options, pricing, sizing or just
                need a human touch? Fill out your information below and submit
                your design to our team. Someone will be in touch to answer your
                questions!
              </Typography>
            </Row>
            <Row
              style={{
                margin: "0px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <label>Your Name</label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={details?.name}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      name: e.target.value,
                    })
                  );
                }}
                name="address"
                placeholder="Enter your name here"
              />
              {errors.name && (
                <span className="text-red-500 text-sm mt-1">{errors.name}</span>
              )}
            </Row>
            <Row
              style={{
                margin: "0px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <label>Your Email</label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={details?.email}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      email: e.target.value,
                    })
                  );
                }}
                name="address"
                placeholder="Enter your email here"
              />
              {errors.email && (
                <span className="text-red-500 text-sm mt-1">
                  {errors.email}
                </span>
              )}
            </Row>

            <Row
              style={{
                margin: "0px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <label>URL to your design</label>
              <br />
              <input
                readOnly
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={link}
                name="address"
                placeholder="URL here"
              />
            </Row>
            <Row
              style={{
                margin: "0px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <Checkbox
                  style={{
                    color: "#9EA0DC",
                    outline: "#AFE9E0",
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  Yes, add me to your newsletter!
                </Typography>
              </div>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // marginBottom: "10px",
            }}
          >
            <Button
              sx={{
                color: "#03705E",
                fontSize: window.innerWidth > 768 ? "16px" : "0.75rem",
                padding: "15px",
                fontWeight: "600",
                bgcolor: "#AFE9E0",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleInquiry}
            >
              SUBMIT YOUR DESIGN TO GLO stUdio
            </Button>
          </div>
        </Box>
      </Modal>
      <InquiryThankYou
        open={openThankYou}
        close={() => setOpenThankYou(false)}
      />
    </>
  );
};

export default SubmitInquiry;
