// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styles for columns */
.col-item {
    margin-top: 10px; /* Adjust margin for smaller screens */
  }
  /* Media query for tablet screens */
  @media (max-width: 768px) {
    .col-item {
      margin-top: 25px; /* Adjust margin for smaller screens */
    }
  }
 `, "",{"version":3,"sources":["webpack://./src/Pages/styles/review.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,gBAAgB,EAAE,sCAAsC;EAC1D;EACA,mCAAmC;EACnC;IACE;MACE,gBAAgB,EAAE,sCAAsC;IAC1D;EACF","sourcesContent":["/* Default styles for columns */\n.col-item {\n    margin-top: 10px; /* Adjust margin for smaller screens */\n  }\n  /* Media query for tablet screens */\n  @media (max-width: 768px) {\n    .col-item {\n      margin-top: 25px; /* Adjust margin for smaller screens */\n    }\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
