import React from "react";

const Send = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1035 0.441117L5.0623 3.77814C-1.68743 6.03619 -1.68743 9.71808 5.0623 11.965L8.04241 12.955L9.03203 15.9361C11.2782 22.688 14.97 22.688 17.2162 15.9361L20.5633 5.90271C22.0533 1.39773 19.607 -1.06054 15.1035 0.441117ZM15.4593 6.42551L11.2338 10.6747C11.067 10.8415 10.8557 10.9194 10.6444 10.9194C10.4331 10.9194 10.2219 10.8415 10.0551 10.6747C9.73258 10.3521 9.73258 9.81819 10.0551 9.49561L14.2806 5.24643C14.6031 4.92385 15.1368 4.92385 15.4593 5.24643C15.7818 5.56901 15.7818 6.10294 15.4593 6.42551Z"
        fill="#D1A8EA"
      />
    </svg>
  );
};

export default Send;
