import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import InstallList from "./SuperInstall/InstallList";
import InstallCard from "./SuperInstall/InstallCard";
// import ExampleModal from "./SuperInstall/ExampleModal";
import Help from "../../Help";
import BottomControls from "../../BottomControls";
import LocationCheck from "./SuperInstall/LocationCheck";
import LocationError from "./SuperInstall/LocationError";
import InstallMap from "../../Delivery/Install/InstallMap";
import ExampleModal from "../../ExampleModal";

const SuperInstall = ({ setSelected }) => {
  const product = useSelector((state) => state.activeStep.product);
  const [showHelp, setShowHelp] = useState(false);
  const [showLocation, setShowLocation] = useState(true);
  const [showError, setShowError] = useState(false);
  const [risks, setRisks] = useState(false);

  const handleClose = () => setShowHelp(false);
  const [showMap, setShowMap] = useState(false);
  const [installationPrice, setInstallationPrice] = useState(0);

  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [select, setSelect] = useState(
    product.delivery.install ? product.delivery.install : ""
  );

  const handleSelect = (selection) => {
    setSelect(selection);
  };

  return (
    <>
      <Row>
        <Typography
          style={{
            fontFamily: "Beatrice-Display",
            color: "#25CCB0",
            fontSize: "40px",
            fontWeight: "700",
            fontStyle: "italic",
            letterSpacing: "0.05em",
          }}
        >
          Pick an Installation method
        </Typography>
        <div
          style={{
            display: "inline-block",
            padding: "20px",
            margin: "20px",
          }}
          className="warning"
        >
          <Typography
            style={{
              fontFamily: "Beatrice",
            }}
          >
            We provide professional installation services to the{" "}
            <span
              style={{
                color: "#BF6BE3",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => setShowMap(true)}
            >
              NYC Tri State Area.{" "}
            </span>
            For installations options for other states, please see
            <span
              style={{
                color: "#BF6BE3",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => setShowHelp(true)}
            >
              {" "}
              "Need help deciding"{" "}
            </span>
            tab for more info.
          </Typography>
          {/* <Button
              sx={{
                color: "#03705E",
                fontSize: "20px",
                fontWeight: "600",
                bgcolor: "#D8F4F1",
                borderRadius: "24px",
                paddingRight: "30px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                paddingLeft: "30px",
                marginTop: "20px",
                marginBottom: "20px",
                fontFamily: "Beatrice",
                textTransform: "none",
              }}
              onClick={() => setShowMap(true)}
            >
              View on Map
            </Button> */}
        </div>
      </Row>
      {/* {showExamples && (
        <>
          <ExampleModal select={select} show={show} setShow={setShow} />
        </>
      )} */}
      <Row>
        <Col xs={12} md={4} lg={3} sm={12}>
          <InstallList
            setInstallationPrice={setInstallationPrice}
            select={select}
            handleSelect={handleSelect}
          />
        </Col>
        <Col xs={12} md={8} lg={9} sm={12}>
          <InstallCard select={select} />
          <Row
            style={{
              marginTop: "20px",
            }}
          >
            <BottomControls />
          </Row>
        </Col>
      </Row>
      <Help show={showHelp} handleClose={handleClose} />
      <InstallMap showMap={showMap} closeMap={() => setShowMap(false)} />{" "}
      <LocationCheck
        setRisks={setRisks}
        risks={risks}
        setShowError={setShowError}
        setSelected={setSelected}
        show={showLocation}
        close={() => setShowLocation(false)}
      />
      <LocationError
        setRisks={setRisks}
        setShowLocation={setShowLocation}
        setSelected={setSelected}
        show={showError}
        close={() => setShowError(false)}
      />
      {showExamples && (
        <>
          <ExampleModal />
        </>
      )}
    </>
  );
};

export default SuperInstall;
