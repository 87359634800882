import React from "react";

const Moon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17089 3.26864C7.47598 3.46378 6.66563 3.79356 5.83538 4.34832C4.51979 5.22737 3.49441 6.4768 2.88891 7.93861C2.2834 9.40042 2.12498 11.0089 2.43366 12.5608C2.74234 14.1126 3.50427 15.5381 4.62309 16.6569C5.74191 17.7757 7.16737 18.5377 8.71922 18.8464C10.2711 19.155 11.8796 18.9966 13.3414 18.3911C14.8032 17.7856 16.0526 16.7602 16.9317 15.4446C17.4112 14.727 17.7688 13.9406 17.9947 13.1172C17.0022 13.5958 15.9055 13.8503 14.7828 13.8483C12.8342 13.8449 10.9664 13.0693 9.58856 11.6915C8.21069 10.3136 7.43511 8.4458 7.4317 6.49721C7.42973 5.36825 7.68705 4.26549 8.17089 3.26864ZM10.2799 2.00007L10.3645 1.00366C10.7516 1.03652 11.0846 1.29057 11.2186 1.6552C11.3526 2.01983 11.2632 2.42906 10.9895 2.7047C9.98939 3.71185 9.42922 5.07434 9.4317 6.49371C9.43418 7.91308 9.99912 9.2736 11.0028 10.2772C12.0064 11.2809 13.3669 11.8458 14.7863 11.8483C16.2057 11.8508 17.5682 11.2906 18.5753 10.2905C18.8619 10.0059 19.2914 9.92167 19.6642 10.0769C20.0371 10.2321 20.2799 10.5962 20.2799 11.0001C20.2799 12.9779 19.6935 14.9113 18.5946 16.5558C17.4958 18.2003 15.934 19.482 14.1068 20.2389C12.2795 20.9957 10.2689 21.1938 8.32904 20.8079C6.38923 20.4221 4.6074 19.4697 3.20887 18.0711C1.81035 16.6726 0.857942 14.8908 0.472089 12.951C0.0862373 11.0112 0.284271 9.0005 1.04115 7.17324C1.79802 5.34598 3.07975 3.78419 4.72424 2.68538C6.15038 1.73246 7.5371 1.31071 8.5726 1.12779C9.09048 1.03631 9.52291 1.0041 9.83264 0.99526C9.98766 0.990834 10.1125 0.992249 10.2025 0.995103C10.2475 0.996531 10.2839 0.99832 10.3111 0.999927L10.3449 1.00213L10.3565 1.00301L10.361 1.00336L10.3629 1.00352L10.3637 1.00359C10.3641 1.00362 10.3645 1.00366 10.2799 2.00007Z"
        fill={color}
      />
    </svg>
  );
};

export default Moon;
