// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list {
  list-style: none;
  padding-left: 1em;
}

.custom-list li::before {
  content: "•";
  color: #8F8F8F;
  font-size: 14px;
  margin-right: 8px;
}
@media (max-width: 769px) {
    .card-group {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Components/styles/sourceList.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;AACA;IACI;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;IACrB;EACF","sourcesContent":[".custom-list {\n  list-style: none;\n  padding-left: 1em;\n}\n\n.custom-list li::before {\n  content: \"•\";\n  color: #8F8F8F;\n  font-size: 14px;\n  margin-right: 8px;\n}\n@media (max-width: 769px) {\n    .card-group {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
