import React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { init as initFullStory } from "@fullstory/browser";

// initFullStory({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID });

// const config = {
//   apiKey: "1b22dfe568cc84a7baff7859d5cb17e9",
//   host: "YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbmFtZWdsby1wbA",
//   forceRedirect: true,
// };
const root = ReactDOM.createRoot(document.getElementById("root"));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
// console.log(process.env.REACT_APP_GOOGLE_TRACKING_ID);

root.render(
  // <React.StrictMode>
  <HashRouter>
    {/* <AppBridgeProvider config={config}> */}
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>
    {/* </AppBridgeProvider> */}
  </HashRouter>
  // </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
