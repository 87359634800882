import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Close from "../../Assets/Close";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import SaveSuccess from "./SaveSuccess";
import { setProductDeliveryDetails } from "../../Redux/Slice";

const Save = ({ weightPerCubicInch, svgLink, link, showSave, closeSave }) => {
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const product = useSelector((state) => state.activeStep.product);
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const size = useSelector((state) => state.activeStep.product.size);

  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const dispatch = useDispatch();
  const date = new Date();

  const handleSave = async () => {
    if (details.email && details.name) {
      try {
        let backingColor = backingColors.find(
          (color) => color.code === product.backing.color
        );
        const updatedProduct = {
          ...product,
          productionImage: svgLink,
          userCreatedProductImage: link,
          backingColor: backingColor ? backingColor?.name : "Custom Color",
          customShapeImage: product.backing.image,
          orderType: "draft",
          deliveryDate: product.shippingTime ? product.shippingTime : "N/A",
          orderDate: date.toISOString(),
          customerName: details.name,
          customerEmail: details.email,
          paymentStatus: "draft",
          fullfillmentStatus: "draft",
          totalWeight:
            Number(size.width) *
            Number(size.height) *
            Number(size.depth) *
            weightPerCubicInch,
        };
        // console.log(updatedProduct);
        if (product.format === "custom") {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}save/${details.email}`,
            updatedProduct
          );
          // console.log(response);
          if (response.data) {
            setShowSaveSuccess(true);
          }
        } else if (product.format === "super-custom") {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}super-save/${details.email}`,
            updatedProduct
          );
          if (response.data) {
            setShowSaveSuccess(true);
          }
          // console.log(response);
        }
        closeSave();
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please enter email");
    }
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 1500,
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <>
      <Modal
        open={showSave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Beatrice-Display",
                  color: "#25CCB0",
                  fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                  fontWeight: "700",
                  fontStyle: "italic",
                  letterSpacing: "0.05em",
                }}
              >
                Save your design!
              </Typography>
            </div>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={closeSave}
            >
              <Close />
            </div>
          </div>
          <div
            style={
              {
                // overflow: "scroll",
                // paddingLeft: "100px",
                // paddingRight: "100px",
              }
            }
          >
            <Row style={{ marginBottom: "20px" }}>
              <span>
                The specifics of your neon design will be sent to the email
                provided below
              </span>
            </Row>
            <Row
              style={{
                marginBottom: "20px",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  paddingRight: "0px",
                }}
              >
                Name
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={details?.name}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      name: e.target.value,
                    })
                  );
                }}
                name="name"
                placeholder="Enter your name here"
              />
            </Row>
            <Row
              style={{
                marginBottom: "20px",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  paddingRight: "0px",
                }}
              >
                Email
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={details?.email}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      email: e.target.value,
                    })
                  );
                }}
                name="email"
                placeholder="Enter your email here"
              />
            </Row>

            <Row
              style={{
                marginBottom: "20px",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                }}
              >
                Copy Link
              </label>
              <br />
              <input
                readOnly
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                value={link}
                name="address"
                placeholder="URL here"
              />
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <Checkbox
                  style={{
                    color: "#9EA0DC",
                    outline: "#AFE9E0",
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  Yes, add me to your newsletter!
                </Typography>
              </div>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              // marginBottom: "10px",
            }}
          >
            <Button
              sx={{
                color: "#03705E",
                fontSize: "16px",
                width: "40%",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#AFE9E0",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <SaveSuccess
        show={showSaveSuccess}
        close={() => setShowSaveSuccess(false)}
      />
    </>
  );
};

export default Save;
