import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import EditModal from "./EditModal";
import { useSelector } from "react-redux";
import Black from "../../Assets/Wires/Black.png";
import GreyWire from "../../Assets/Wires/Grey.png";

const AccessoriesCard = ({ heading, selection }) => {
  const [edit, setEdit] = useState("");
  const [show, setShow] = useState(false);
  const product = useSelector((state) => state.activeStep.product);
  const cables = useSelector((state) => state.activeStep.cables);
  const [wireImage, setWireImage] = useState(
    product?.source === "LED" ? GreyWire : Black
  );
  const getImage = () => {
    // Find the cable with the matching color
    const selectedCable = cables.find(
      (cable) => cable.color === product?.accessories?.wireColor
    );

    // Update the wireImage state with the selected cable's image
    if (selectedCable) {
      setWireImage(selectedCable.image);
    }
  };
  useEffect(() => {
    getImage();
  }, [product]);

  // console.log(wireImage);
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          {heading === "Format" ||
          heading === "Size" ||
          heading === "Controllers" ||
          heading === "Add-ons" ||
          heading === "Reference Image" ? (
            <></>
          ) : (
            <>Edit</>
          )}
        </Typography> */}
      </div>
      <div
        style={{
          border: "2px solid #AFE9E0",
          width: "100%",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          height: "100px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <img src={wireImage} alt="" />
      </div>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default AccessoriesCard;
