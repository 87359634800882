// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 769px) {
    .header-tabs {
      margin-bottom: 0px;
      height: 50px;
    }
  }

 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .acce-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
  .acce-container {
    width: 100%;
    display: flex; 
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
  .other-fonts {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  } 

  .acce-item {
    width: 60%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 440px) {
  .sketch-design-button {
    margin-bottom: 0px;
    height: 40px;
  }
.mobile-margin{
  margin-bottom: 270px;
}
  .preview-design-text{
  margin-top: "40px";
}
}`, "",{"version":3,"sources":["webpack://./src/Pages/SuperCustom/styles/SuperAccessories.css"],"names":[],"mappings":";AACA;IACI;MACE,kBAAkB;MAClB,YAAY;IACd;EACF;;CAED,oCAAoC;CACpC;EACC;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;EACE;IACE,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;EAErB;;EAEA;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;AACF;AACA;EACE;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;EACE,oBAAoB;AACtB;EACE;EACA,kBAAkB;AACpB;AACA","sourcesContent":["\n@media (max-width: 769px) {\n    .header-tabs {\n      margin-bottom: 0px;\n      height: 50px;\n    }\n  }\n\n /* Media query for smaller screens */\n @media (max-width: 769px) {\n  .acce-items {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }  \n  .acce-container {\n    width: 100%;\n    display: flex; \n    flex-direction: column;\n    /* justify-content: center; */\n    align-items: center;\n}\n  .other-fonts {\n    margin-bottom: 20px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  \n  } \n\n  .acce-item {\n    width: 60%;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n  }\n}\n@media (max-width: 440px) {\n  .sketch-design-button {\n    margin-bottom: 0px;\n    height: 40px;\n  }\n.mobile-margin{\n  margin-bottom: 270px;\n}\n  .preview-design-text{\n  margin-top: \"40px\";\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
