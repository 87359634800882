import React from "react";

const Undo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="9"
      viewBox="0 0 24 9"
      fill="none"
    >
      <path
        d="M22.1941 7.20216C21.2541 5.39141 19.6571 3.89324 17.644 2.93331C15.6307 1.97337 13.3104 1.60386 11.0325 1.8803C7.30968 2.33213 4.71493 4.69788 1.90234 6.87496M1.90234 1.79102V7.20216H7.43647"
        stroke="#36CDB4"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Undo;
