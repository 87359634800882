import React, { useEffect, useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../styles/SuperShipping.css";
import axios from "axios";
import {
  setProductDeliveryDetails,
  setShippingCost,
} from "../../../Redux/Slice";

const SuperShipping = ({ setSelected }) => {
  const product = useSelector((state) => state.activeStep.product);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );

  const [states, setStates] = useState(null);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const selectedCountryStates = getStatesForCountry(selectedCountry);
    setStates(selectedCountryStates);
  };

  const getStatesForCountry = (country) => {
    switch (country) {
      case "US":
        return [
          "Alabama",
          "Alaska",
          "Arizona",
          "Arkansas",
          "California",
          "Colorado",
          "Connecticut",
          "Delaware",
          "Florida",
          "Georgia",
          "Hawaii",
          "Idaho",
          "Illinois",
          "Indiana",
          "Iowa",
          "Kansas",
          "Kentucky",
          "Louisiana",
          "Maine",
          "Maryland",
          "Massachusetts",
          "Michigan",
          "Minnesota",
          "Mississippi",
          "Missouri",
          "Montana",
          "Nebraska",
          "Nevada",
          "New Hampshire",
          "New Jersey",
          "New Mexico",
          "New York",
          "North Carolina",
          "North Dakota",
          "Ohio",
          "Oklahoma",
          "Oregon",
          "Pennsylvania",
          "Rhode Island",
          "South Carolina",
          "South Dakota",
          "Tennessee",
          "Texas",
          "Utah",
          "Vermont",
          "Virginia",
          "Washington",
          "West Virginia",
          "Wisconsin",
          "Wyoming",
        ];
      case "UK":
        return ["England", "Scotland", "Wales"];
      case "CAN":
        return ["Ontario", "Quebec", "British Columbia"];
      default:
        return [];
    }
  };
  useEffect(() => {
    if (details?.country) {
      const selectedCountryStates = getStatesForCountry(details?.country);
      setStates(selectedCountryStates);
    }
  }, [details]);

  const [minimumProductionDays, setMinimumProductionDays] = useState(0);
  const [maximumProductionDays, setMaximumProductionDays] = useState(0);
  const errors = false;
  const [risks, setRisks] = useState(false);
  const [risksError, setRisksError] = useState(false);
  // const [weightPerCubicInch, setWeightPerCubicInch] = useState(0);
  // const [glassShippingMaterial, setGlassShippingMaterial] = useState(0);

  // const validateForm = () => {
  //   const errors = {};
  //   if (!details?.address) {
  //     errors.address = "Address is required";
  //   }
  //   if (!details?.city) {
  //     errors.city = "City is required";
  //   }
  //   if (!details?.zip) {
  //     errors.zip = "Zip Code is required";
  //   }
  //   if (!details?.country) {
  //     errors.country = "Country is required";
  //   }
  //   if (!details?.state) {
  //     errors.state = "State is required";
  //   }

  //   return errors;
  // };

  const dispatch = useDispatch();

  // const handleFEDx = async () => {
  //   let errors = validateForm();
  //   if (Object.keys(errors).length > 0) {
  //     // console.log("validating");
  //     setErrors(errors);
  //     return;
  //   }
  //   setErrors({});
  //   // console.log(product.size);
  //   // dispatch(setProductDeliveryDetails(details));
  //   let weight =
  //     weightPerCubicInch *
  //     (product.size.width + 5) *
  //     (product.size.height + 5) *
  //     (product.size.depth + 5);
  //   weight = Math.ceil(weight);
  //   // console.log(weightPerCubicInch);
  //   const data = {
  //     recipientPostalCode: Number(details?.zip),
  //     recipientCountryCode: details?.country,
  //     shipperPostalCode: 10002,
  //     shipperCountryCode: "US",
  //     weight,
  //   };
  //   // console.log(data);

  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_URL}shippings/fedEx/getRates`,
  //       data
  //     );
  //     // console.log(response.data);
  //     // console.log(typeof response.data);
  //     if (typeof response.data) {
  //       setLoading(false);
  //       // console.log(response.data);
  //       dispatch(setShippingCost(response.data.rate));
  //       // let responseDate = new Date(response.data.transiteDate)
  //       //   .toISOString()
  //       //   .split("T")[0];
  //       // console.log(responseDate);
  //       // const formattedDate = format(
  //       //   new Date(responseDate),
  //       //   "MMM dd, yyyy"
  //       // );
  //       // console.log(formattedDate);
  //       // console.log(
  //       //   "response.data.transiteDate>",
  //       //   response.data.transiteDate
  //       // );
  //       // Assuming you have the following data from your response and input
  //       const transiteDateStr = response.data.transiteDate; // "2023-08-24T23:59:00"
  //       // console.log(currentDate);
  //       // const dateRange = "Aug 21 - Aug 26, 2023";

  //       // Parse the transiteDate string into a Date object
  //       const transiteDateObj = new Date(transiteDateStr);

  //       // Extract start and end dates from the date range
  //       const [startRange, endRange] = finalTime.split(" - ");
  //       // const [, startDate] = startRange.split(" ");
  //       const [endMonth, endDate, endYear] = endRange.split(" ");

  //       // Calculate the new end date by adding the transit date and one more day
  //       const newEndDateObj = new Date(
  //         transiteDateObj.getTime() +
  //           (new Date(`${endMonth} ${endDate}, ${endYear}`).getTime() -
  //             new Date(`${startRange}, ${endYear}`).getTime()) +
  //           24 * 60 * 60 * 1000 // Add one more day in milliseconds
  //       );

  //       // Format the new end date for display
  //       const newEndMonth = newEndDateObj.toLocaleString("default", {
  //         month: "short",
  //       });
  //       const newEndDateFormatted = `${newEndMonth} ${newEndDateObj.getDate()}, ${newEndDateObj.getFullYear()}`;

  //       // Construct the new final time string
  //       const newFinalTime = `${startRange} - ${newEndDateFormatted}`;

  //       setFinalTime(newFinalTime);

  //       dispatch(setShippingTime(newFinalTime));
  //     } else {
  //       swal({
  //         title: "Note",
  //         text: `${response.data}`,
  //         icon: "info",
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const handleUPS = async () => {
  //   let errors = validateForm();
  //   if (Object.keys(errors).length > 0) {
  //     // console.log("validating");
  //     setErrors(errors);
  //     return;
  //   }
  //   setErrors({});

  //   // dispatch(setProductDeliveryDetails(details));
  //   let weight =
  //     weightPerCubicInch *
  //     (product.size.width + 5) *
  //     (product.size.height + 5) *
  //     (product.size.depth + 5);
  //   weight = Math.ceil(weight);
  //   // console.log("Weight", Math.ceil(weight));
  //   // console.log("product.size", product.size);

  //   const data = {
  //     FreightRateRequest: {
  //       ShipFrom: {
  //         Name: "Name Glo",
  //         Address: {
  //           AddressLine: "123 Lane",
  //           City: "TIMONIUM",
  //           StateProvinceCode: "NY",
  //           PostalCode: "10002",
  //           CountryCode: "US",
  //           ResidentialAddressIndicator: "",
  //         },
  //         AttentionName: "Test Shipper",
  //         Phone: {
  //           Number: "4444444444",
  //           Extension: "4444",
  //         },
  //         EMailAddress: "gcc0htq@ups.com",
  //       },
  //       ShipperNumber: "R7F146",
  //       ShipTo: {
  //         Name: "Dilbert's Derbies",
  //         Address: {
  //           AddressLine: details?.address,
  //           City: details?.city,
  //           StateProvinceCode: details?.state,
  //           PostalCode: details?.zip,
  //           CountryCode: details?.country,
  //         },
  //         AttentionName: "Dilbert",
  //         Phone: {
  //           Number: "8459865555",
  //         },
  //       },
  //       PaymentInformation: {
  //         Payer: {
  //           Name: "Test US Shipper",
  //           Address: {
  //             AddressLine: "123 Lane",
  //             City: "new York",
  //             StateProvinceCode: "NY",
  //             PostalCode: "10001",
  //             CountryCode: "US",
  //           },
  //           ShipperNumber: "AT0123",
  //           AccountType: "1",
  //           AttentionName: "Test Shipper",
  //           Phone: {
  //             Number: "4444444444",
  //             Extension: "4444",
  //           },
  //           EMailAddress: "gcc0htq@ups.com",
  //         },
  //         ShipmentBillingOption: {
  //           Code: "10",
  //         },
  //       },
  //       Service: {
  //         Code: "308",
  //       },
  //       Commodity: {
  //         Description: "FRS-Freight",
  //         Weight: {
  //           UnitOfMeasurement: {
  //             Code: "LBS",
  //           },
  //           Value: `${weight}`,
  //         },
  //         Dimensions: {
  //           UnitOfMeasurement: {
  //             Code: "IN",
  //             Description: " ",
  //           },
  //           Length: "0",
  //           Width: `${Number(
  //             product.size.width + product.backing.width
  //           ).toFixed(2)}`,
  //           Height: `${Number(
  //             product.size.height + product.backing.height
  //           ).toFixed(2)}`,
  //         },
  //         NumberOfPieces: "1",
  //         PackagingType: {
  //           Code: "PLT",
  //         },
  //         FreightClass: "60",
  //       },
  //       DensityEligibleIndicator: "",
  //       AlternateRateOptions: {
  //         Code: "3",
  //       },
  //       PickupRequest: {
  //         PickupDate: "20210731",
  //       },
  //       GFPOptions: {
  //         GPFAccesorialRateIndicator: "",
  //       },
  //       TimeInTransitIndicator: "",
  //     },
  //   };
  //   // console.log(data);
  //   if (!risks) {
  //     setRisksError(true);
  //     return;
  //   } else {
  //     setRisksError(false);

  //     try {
  //       setLoading(true);
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BACKEND_URL}shippings/ups/getRate`,
  //         data
  //       );
  //       // console.log(response.data);
  //       // console.log(typeof response.data);
  //       if (response.data) {
  //         setLoading(false);
  //         // console.log("Shipping Information :", response.data);
  //         let shippingHandlingRate = Number(response.data.rate);
  //         let shippingCost =
  //           (product.size.width + product.backing.width + 5) *
  //             (product.size.height + product.backing.height + 5) *
  //             glassShippingMaterial +
  //           shippingHandlingRate;
  //         // console.log(glassShippingMaterial);
  //         dispatch(setShippingCost(shippingCost));
  //         // const currentDate = new Date();
  //         // const futureDate = new Date(
  //         //   currentDate.getTime() + response.data.time * 24 * 60 * 60 * 1000
  //         // );

  //         // // Format the future date to the desired format: "yyyy-MM-ddTHH:mm:ss"
  //         // const formattedFutureDate = futureDate
  //         //   .toISOString()
  //         //   .slice(0, 19)
  //         //   .split("T")[0];

  //         // const formattedDate = format(
  //         //   new Date(formattedFutureDate),
  //         //   "MMM dd, yyyy"
  //         // );
  //         // console.log(formattedDate);
  //         // Parse transit date string into a Date object
  //         const transitDays = response.data.time; // Number of transit days

  //         // console.log(transitDays);
  //         // Parse the original final time range
  //         let [startRange, endRange] = finalTime.split(" - ");
  //         let [startMonth, startDate] = startRange.split(" ");
  //         let [, endDay, endYear] = endRange.split(" "); // We don't need endMonth here
  //         // console.log(
  //         //   startRange,
  //         //   endRange,
  //         //   startMonth,
  //         //   startDate,
  //         //   endDay,
  //         //   endYear
  //         // );

  //         // Calculate the end date of the original range

  //         // console.log(endDay, startMonth);
  //         // console.log(!isValidDate(endDay, startMonth, endYear));
  //         let startMonthIndex = monthNames.indexOf(startMonth) + 1;
  //         // console.log(startMonthIndex);

  //         if (!isValidDate(endDay, startMonthIndex, endYear)) {
  //           // If the date is not valid, add the remaining days to the current month
  //           const lastDayOfMonth = getLastDayOfMonth(endYear, startMonthIndex);
  //           // console.log(parseInt(endDay));
  //           // console.log(parseInt(lastDayOfMonth));
  //           endDay = parseInt(endDay) - lastDayOfMonth;

  //           // Move to the next month
  //           let nextMonthIndex = startMonthIndex + 1;
  //           let nextYear = endYear;
  //           // console.log(nextMonthIndex);

  //           if (nextMonthIndex >= 12) {
  //             nextMonthIndex = 0; // Wrap around to January if necessary
  //             nextYear++; // Increment the year if we move to the next year
  //           }

  //           startMonthIndex = nextMonthIndex;
  //           endYear = nextYear;
  //         }

  //         const endDateObj = new Date(endYear, startMonthIndex, endDay);
  //         const startDateObj = new Date(endYear, startMonthIndex, startDate);
  //         // console.log(startDateObj.getTime());

  //         // Calculate the new end date by adding transit days and additional days
  //         const newEndDateObj = new Date(
  //           endDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
  //         );
  //         const newStartDateObj = new Date(
  //           startDateObj.getTime() + transitDays * 24 * 60 * 60 * 1000
  //         );
  //         // console.log(newStartDateObj);

  //         // Format the dates for display
  //         const newEndMonth = newEndDateObj.toLocaleString("default", {
  //           month: "short",
  //         });
  //         const newStartDateFormatted = `${startMonth} ${newStartDateObj.getDate()}`;
  //         const newEndDateFormatted = `${newEndMonth} ${newEndDateObj.getDate()}, ${newEndDateObj.getFullYear()}`;
  //         // console.log(
  //         //   newStartDateFormatted,
  //         //   newStartDateFormatted,
  //         //   "response.data.rate,",
  //         //   response.data.rate
  //         // );
  //         // Construct the new final time string
  //         const newFinalTime = `${newStartDateFormatted} - ${newEndDateFormatted}`;

  //         setFinalTime(newFinalTime);
  //         dispatch(setShippingTime(newFinalTime));
  //       } else {
  //         swal({
  //           title: "Note",
  //           text: `${response.data}`,
  //           icon: "info",
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/shippings/${product.source}/${product.backing.name}`
      );
      if (response?.data[0]) {
        setMinimumProductionDays(response?.data[0]?.minimumProductionDays);
        setMaximumProductionDays(response?.data[0]?.maximumProductionDays);
        // setWeightPerCubicInch(response?.data[0]?.signWeightPerSqinch);
        // setGlassShippingMaterial(
        //   response?.data[0]?.glassNeonShippingMaterialPerSquareInch
        // );

        // const productionEndDate = new Date();
        // productionEndDate.setDate(
        //   date + Number(response.data[0].maximumProductionDays)
        //   );

        //   const productionEndMonth = monthNames[productionEndDate.getMonth()];
        //   const productionEndYear = productionEndDate.getFullYear();

        //   setFinalTime(
        //     `${month} ${date} - ${productionEndMonth} ${productionEndDate.getDate()}, ${productionEndYear}`
        //     );
        dispatch(setShippingCost(null));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductionDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (details?.country) {
      const selectedCountryStates = getStatesForCountry(details?.country);
      setStates(selectedCountryStates);
    }
  }, [details]);

  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          height: "280px",
          flexDirection: "column",
          textAlign: "center",
          color: "#07AB90",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <Typography
            style={{
              fontSize: window.innerWidth > 768 ? "21px" : "1rem",
              fontFamily: "Beatrice",
              width: window.innerWidth > 768 ? "50%" : "100%",
            }}
          >
            Submit your design today with the{" "}
            <Typography
              style={{
                display: "inline-block",
                fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                fontFamily: "Beatrice",
                color: "#BF6BE3",
              }}
            >
              “Shipping”
            </Typography>{" "}
            method, and your estimated delivery window will be:
          </Typography>
        </div>
        <Typography
          style={{
            fontSize: window.innerWidth > 768 ? "52px" : "2rem",
            fontFamily: "Beatrice",
          }}
        >
          {minimumProductionDays} – {maximumProductionDays} Business Days +
          Shipping Time
        </Typography>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Beatrice",
          }}
        >
          Tell us where your piece is shipping to:
        </Typography>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Row
            style={{
              marginBottom: "20px",
            }}
          >
            <Col
              lg={product.source === "Glass" ? 6 : 12}
              md={product.source === "Glass" ? 6 : 12}
              sm={12}
              xs={12}
              className="shipping-details-items"
            >
              <label
                style={{
                  marginBottom: "5px",
                  color: "#8F8F8F",
                }}
              >
                Enter your address here
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="billingAddress"
                value={details?.address}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      address: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your address here"
              />
              {errors.address && (
                <span className="text-red-500 text-sm mt-1">
                  {errors.address}
                </span>
              )}
              <Row
                style={{
                  marginTop: "20px",
                }}
              >
                <Col xs={6}>
                  <label
                    style={{
                      marginBottom: "5px",
                      color: "#8F8F8F",
                    }}
                  >
                    City
                  </label>
                  <br />
                  <input
                    style={{
                      border: "2px solid #AFE9E0",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                    name="billingCity"
                    value={details?.city}
                    onChange={(e) => {
                      dispatch(
                        setProductDeliveryDetails({
                          ...details,
                          city: e.target.value,
                        })
                      );
                    }}
                    placeholder="Please enter city"
                  />
                  {errors.city && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.city}
                    </span>
                  )}
                </Col>
                <Col xs={6}>
                  <label
                    style={{
                      marginBottom: "5px",
                      color: "#8F8F8F",
                    }}
                  >
                    Zip
                  </label>
                  <br />
                  <input
                    style={{
                      border: "2px solid #AFE9E0",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                    name="billingZip"
                    value={details?.zip}
                    onChange={(e) => {
                      dispatch(
                        setProductDeliveryDetails({
                          ...details,
                          zip: e.target.value,
                        })
                      );
                    }}
                    placeholder="Enter zip"
                  />
                  {errors.zip && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.zip}
                    </span>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "20px",
                }}
              >
                <Col xs={6}>
                  <label
                    style={{
                      marginBottom: "5px",
                      color: "#8F8F8F",
                    }}
                  >
                    Country
                  </label>
                  <br />
                  <select
                    typeof="country"
                    id="country"
                    style={{
                      height: "48px",
                      border: "2px solid #AFE9E0",
                      color: details?.country ? "#000000" : "#8F8F8F",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                    name="billingCountry"
                    value={details?.country}
                    onChange={(e) => {
                      handleCountryChange(e);
                      dispatch(
                        setProductDeliveryDetails({
                          ...details,
                          country: e.target.value,
                        })
                      );
                    }}
                  >
                    <option value="">-- Select Country</option>
                    <option value="US">United States</option>
                    {/* <option value="UK">United Kingdom</option>
                    <option value="CAN">Canada</option> */}
                  </select>
                  {errors.country && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.country}
                    </span>
                  )}
                </Col>
                <Col xs={6}>
                  <label
                    style={{
                      marginBottom: "5px",
                      color: "#8F8F8F",
                    }}
                  >
                    State
                  </label>
                  <br />
                  <select
                    style={{
                      height: "48px",
                      border: "2px solid #AFE9E0",
                      color: details?.state ? "#000000" : "#8F8F8F",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                    name="billingState"
                    value={details?.state}
                    onChange={(e) => {
                      dispatch(
                        setProductDeliveryDetails({
                          ...details,
                          state: e.target.value,
                        })
                      );
                    }}
                  >
                    <option value="">-- Select State</option>
                    {states?.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <span className="text-red-500 text-sm mt-1">
                      {errors.state}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            {product.source === "Glass" ? (
              <Col lg={6} md={6} sm={12} xs={12}>
                <div
                  style={{
                    padding: "20px",
                    margin: "20px",
                  }}
                  className="warning"
                >
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontFamily: "Beatrice",
                    }}
                  >
                    Here's how we ship our neons!
                  </Typography>
                  <Typography
                    className="text-[#BF6BE3]"
                    style={{
                      height: "150px",
                      overflowY: "auto",
                      fontSize: "16px",
                      fontFamily: "Beatrice",
                    }}
                  >
                    We use UPS air and insure all of our shipments. We also
                    package every piece for optimal safety for the neon. If a
                    piece is too large to put into a regular box safely, we need
                    to build a wood crate for it. This is an additional cost so
                    we’ll reach out to you if this is the case with your piece!
                  </Typography>
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            sx={{
                              color: "#AFE9E0",
                              "&.Mui-checked": {
                                color: "#9EA0DC",
                              },
                            }}
                            value={risks}
                            onChange={() => {
                              setRisks(!risks);
                              if (!risks) {
                                setRisksError(false);
                              }
                            }}
                          />
                          <Typography
                            style={{
                              color: "black",
                              textAlign: "center",
                              fontFamily: "Beatrice",
                              fontWeight: "400",
                            }}
                          >
                            Got it!{" "}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://glo-studio.com/policies/shipping-policy"
                              style={{
                                color: "#BF6BE3",
                                textAlign: "center",
                                fontFamily: "Beatrice",
                                fontWeight: "400",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              learn more
                            </a>
                          </Typography>
                        </div>
                        {risksError && (
                          <div
                            style={{
                              // display: "block",
                              color: "red",
                              // margin: "5px",
                            }}
                          >
                            Please check this box to proceed
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              color: "white",
              fontWeight: "600",
              fontSize: "22px",
              cursor: "pointer",
              backgroundColor: "#36CDB4",
              textAlign: "center",
              padding: "10px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingTop: "10px",
              borderRadius: "35px",
              border: "none",
            }}
          >
            calculate UPS AIR
          </Button>
        </div> */}
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {product.source === "LED" ? (
          <>
            <Button
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "22px",
                cursor: "pointer",
                backgroundColor: "#36CDB4",
                textAlign: "center",
                padding: "10px",
                paddingRight: "40px",
                paddingLeft: "40px",
                paddingTop: "10px",
                borderRadius: "35px",
                border: "none",
              }}
              onClick={handleFEDx}
            >
              {loading ? <>Calculating....</> : <>Calculate FEDEX</>}
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "22px",
                cursor: "pointer",
                backgroundColor: "#36CDB4",
                textAlign: "center",
                padding: "10px",
                paddingRight: "40px",
                paddingLeft: "40px",
                paddingTop: "10px",
                borderRadius: "35px",
                border: "none",
              }}
              onClick={handleUPS}
            >
              {loading ? <>Calculating....</> : <>Calculate UPS Air</>}
            </Button>
          </>
        )}
      </div> */}
      {/* {product.source === "Glass" ? (
        <NeonPopUp
          setSelected={setSelected}
          show={showModal}
          close={() => setShowModal(false)}
        />
      ) : (
        <></>
      )} */}
    </>
  );
};

export default SuperShipping;
