// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



/* Media query for mobile view */
@media (max-width: 768px) {
    .shipping-details-items {
        margin-bottom: 50px;  
    }
}
/* Media query for mobile view */
@media (max-width: 630px) {
    .shipping-details-container {
        margin-top: 100px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 600px) {
    .shipping-details-container {
        margin-top: 130px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 430px) {
    .shipping-details-container {
        margin-top: 190px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 410px) {
    .shipping-details-container {
        margin-top: 280px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 390px) {
    .shipping-details-container {
        margin-top: 330px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 370px) {
    .shipping-details-container {
        margin-top: 310px;  
    }
  }
  /* Media query for mobile view */
@media (max-width: 410px) {
    .shipping-details-container {
        margin-top: 280px;  
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/SuperCustom/styles/SuperShipping.css"],"names":[],"mappings":"AAAA,eAAe;AACf,wCAAwC;AACxC,wCAAwC;;;;AAIxC,gCAAgC;AAChC;IACI;QACI,mBAAmB;IACvB;AACJ;AACA,gCAAgC;AAChC;IACI;QACI,iBAAiB;IACrB;EACF;AACF,gCAAgC;AAChC;IACI;QACI,iBAAiB;IACrB;EACF;AACF,gCAAgC;AAChC;IACI;QACI,iBAAiB;IACrB;EACF;AACF,gCAAgC;AAChC;IACI;QACI,iBAAiB;IACrB;EACF;EACA,gCAAgC;AAClC;IACI;QACI,iBAAiB;IACrB;EACF;EACA,gCAAgC;AAClC;IACI;QACI,iBAAiB;IACrB;EACF;EACA,gCAAgC;AAClC;IACI;QACI,iBAAiB;IACrB;EACF","sourcesContent":["/* styles.css */\n/* Media query for larger screen sizes */\n/* Media query for larger screen sizes */\n\n\n\n/* Media query for mobile view */\n@media (max-width: 768px) {\n    .shipping-details-items {\n        margin-bottom: 50px;  \n    }\n}\n/* Media query for mobile view */\n@media (max-width: 630px) {\n    .shipping-details-container {\n        margin-top: 100px;  \n    }\n  }\n/* Media query for mobile view */\n@media (max-width: 600px) {\n    .shipping-details-container {\n        margin-top: 130px;  \n    }\n  }\n/* Media query for mobile view */\n@media (max-width: 430px) {\n    .shipping-details-container {\n        margin-top: 190px;  \n    }\n  }\n/* Media query for mobile view */\n@media (max-width: 410px) {\n    .shipping-details-container {\n        margin-top: 280px;  \n    }\n  }\n  /* Media query for mobile view */\n@media (max-width: 390px) {\n    .shipping-details-container {\n        margin-top: 330px;  \n    }\n  }\n  /* Media query for mobile view */\n@media (max-width: 370px) {\n    .shipping-details-container {\n        margin-top: 310px;  \n    }\n  }\n  /* Media query for mobile view */\n@media (max-width: 410px) {\n    .shipping-details-container {\n        margin-top: 280px;  \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
