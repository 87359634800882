// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  /* Media query for desktop screens */
  .continue-mobile {
    width: 20%;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px;
    font-weight: 600; /* Note: corrected the property name to font-weight */
    background-color: #AFE9E0;
    color: #03705E; /* Text color */
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 440px) {
    .continue-mobile {
        width: 40%; /* Show the desktop div */
        
        
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Review/styles/EditModal.css"],"names":[],"mappings":"EAAE,oCAAoC;EACpC;IACE,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,gBAAgB,EAAE,qDAAqD;IACvE,yBAAyB;IACzB,cAAc,EAAE,eAAe;IAC/B,mBAAmB;IACnB,0CAA0C;EAC5C;;EAEA;IACE;QACI,UAAU,EAAE,yBAAyB;;;IAGzC;AACJ","sourcesContent":["  /* Media query for desktop screens */\n  .continue-mobile {\n    width: 20%;\n    font-size: 16px;\n    margin-right: 10px;\n    padding: 10px;\n    font-weight: 600; /* Note: corrected the property name to font-weight */\n    background-color: #AFE9E0;\n    color: #03705E; /* Text color */\n    border-radius: 16px;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);\n  }\n\n  @media (max-width: 440px) {\n    .continue-mobile {\n        width: 40%; /* Show the desktop div */\n        \n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
