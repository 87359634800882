// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */


/* Media query for mobile view */
@media (max-width: 768px) {
    .flex-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Components/styles/BottomControls.css"],"names":[],"mappings":"AAAA,eAAe;AACf,wCAAwC;AACxC,wCAAwC;;;AAGxC,gCAAgC;AAChC;IACI;MACE,sBAAsB;MACtB,8BAA8B;MAC9B,uBAAuB;;IAEzB;EACF","sourcesContent":["/* styles.css */\n/* Media query for larger screen sizes */\n/* Media query for larger screen sizes */\n\n\n/* Media query for mobile view */\n@media (max-width: 768px) {\n    .flex-container {\n      flex-direction: column;\n      justify-content: space-between;\n      align-items: flex-start;\n      \n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
