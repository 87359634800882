import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./styles/ShippingAddress.css";
import { setProductDeliveryDetails } from "../../../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";

const ShippingAddress = () => {
  const [states, setStates] = useState(null);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const dispatch = useDispatch();
  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const selectedCountryStates = getStatesForCountry(selectedCountry);
    setStates(selectedCountryStates);
  };

  const getStatesForCountry = (country) => {
    switch (country) {
      case "US":
        return [
          "Alabama",
          "Alaska",
          "Arizona",
          "Arkansas",
          "California",
          "Colorado",
          "Connecticut",
          "Delaware",
          "Florida",
          "Georgia",
          "Hawaii",
          "Idaho",
          "Illinois",
          "Indiana",
          "Iowa",
          "Kansas",
          "Kentucky",
          "Louisiana",
          "Maine",
          "Maryland",
          "Massachusetts",
          "Michigan",
          "Minnesota",
          "Mississippi",
          "Missouri",
          "Montana",
          "Nebraska",
          "Nevada",
          "New Hampshire",
          "New Jersey",
          "New Mexico",
          "New York",
          "North Carolina",
          "North Dakota",
          "Ohio",
          "Oklahoma",
          "Oregon",
          "Pennsylvania",
          "Rhode Island",
          "South Carolina",
          "South Dakota",
          "Tennessee",
          "Texas",
          "Utah",
          "Vermont",
          "Virginia",
          "Washington",
          "West Virginia",
          "Wisconsin",
          "Wyoming",
        ];
      case "UK":
        return ["England", "Scotland", "Wales"];
      case "Canada":
        return ["Ontario", "Quebec", "British Columbia"];
      default:
        return [];
    }
  };
  useEffect(() => {
    if (details?.country) {
      const selectedCountryStates = getStatesForCountry(details?.country);
      setStates(selectedCountryStates);
    }
  }, [details]);
  // console.log(states);
  return (
    <>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#595959",
          fontFamily: "Beatrice",
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        Delivery Address
      </Typography>
      <Row>
        <Col xs={12} md={6}>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={12} style={{ marginBottom: "5px" }}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Name
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="name"
                value={details?.shippingName}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      shippingName: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your full name here"
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={12}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Address
              </label>
              <br />
              <input
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="shippingAddress"
                value={details?.address}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      address: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your address here"
              />
              {/* Error message */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Shipping Phone #
              </label>
              <br />
              <input
                type="number"
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="shippingNumber"
                placeholder="Please enter the recipient phone # number"
                value={details?.shippingNumber}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      shippingNumber: e.target.value,
                    })
                  );
                }}
              />
              {/* Error message */}
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={12} md={6} lg={6}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                City
              </label>
              <br />
              <input
                type="email"
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="city"
                value={details?.city}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      city: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter your city here"
              />
            </Col>
            <Col xs={12} md={6} lg={6}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Zip
              </label>
              <br />
              <input
                type="email"
                style={{
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                }}
                name="zip"
                value={details?.zip}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      zip: e.target.value,
                    })
                  );
                }}
                placeholder="Please enter zip here"
              />
            </Col>
          </Row>
          <Row className="shipping-detail">
            <Col xs={12} md={6}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                Country
              </label>
              <br />
              <select
                typeof="country"
                id="country"
                style={{
                  height: "48px",
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                  color: details.country ? "#000000" : "#8F8F8F",
                }}
                name="shippingCountry"
                value={details?.country}
                onChange={(e) => {
                  handleCountryChange(e);
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      country: e.target.value,
                    })
                  );
                }}
              >
                <option value="">-- Select Country</option>

                <option value="US">United States</option>

                {/* <option value="UK">United Kingdom</option> */}

                {/* <option value="CAN">Canada</option> */}
              </select>
              {/* Error message */}
            </Col>
            <Col xs={12} md={6} style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "5px", color: "#8F8F8F" }}>
                State
              </label>
              <br />
              <select
                style={{
                  height: "48px",
                  border: "2px solid #AFE9E0",
                  outline: "none",
                  width: "100%",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  padding: "10px",
                  color: details.state ? "#000000" : "#8F8F8F",
                }}
                name="shippingState"
                value={details?.state}
                onChange={(e) => {
                  dispatch(
                    setProductDeliveryDetails({
                      ...details,
                      state: e.target.value,
                    })
                  );
                }}
              >
                <option value="">-- Select State</option>

                {states?.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {/* Error message */}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ShippingAddress;
