import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateFontArea,
  calculateLetterLength,
} from "../../utils/client/calculations";
import {
  addPrice,
  setAccessoriesCost,
  setBackingCost,
  setInstallationPrice,
  setNeonCost,
  setTotalCost,
} from "../../Redux/Slice";

const PriceDetails = () => {
  const dispatch = useDispatch();
  const baseInstallValues = useSelector(
    (state) => state.activeStep.baseInstallValues
  );
  const showConsoles = useSelector((state) => state.activeStep.showConsoles);
  const product = useSelector((state) => state.activeStep.product);
  const colors = useSelector((state) => state.activeStep.colors);
  const [sourceType, setSourceType] = useState("Neon");
  const backingWidth = useSelector(
    (state) => state.activeStep.product.backing.width
  );

  const backingHeight = useSelector(
    (state) => state.activeStep.product.backing.height
  );

  const lockedWidth = useSelector(
    (state) => state.activeStep.product.backing.lockedWidth
  );
  const lockedHeight = useSelector(
    (state) => state.activeStep.product.backing.lockedHeight
  );

  const InstallationPrice = useSelector(
    (state) => state.activeStep.product.installationPrice
  );
  const textAreas = product?.fonts;
  const cables = useSelector((state) => state.activeStep.cables);
  const allFonts = useSelector((state) => state.activeStep.fonts);
  // let bendingCostPerFt = 0;
  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  // const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );
  // const backingFlatRate = useSelector(
  //   (state) => state.activeStep.backingFlatRate
  // );
  const factor = useSelector((state) => state.activeStep.materialLengthFactor);

  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );
  // const flatRateAdded = useSelector(
  //   (state) => state.activeStep.backingFlatRateAdded
  // );
  const cost = useSelector((state) => state.activeStep.product.cost);
  const shippingCost = useSelector(
    (state) => state.activeStep.product.shippingCost
  );
  const shippingAPIRate = useSelector(
    (state) => state.activeStep.shippingAPIRate
  );
  const colorCost = useSelector((state) => state.activeStep.colorCost);

  // const installations = useSelector((state) => state.activeStep.installations);
  const backings = useSelector((state) => state.activeStep.backingStyles);
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const customBackingCost = useSelector(
    (state) => state.activeStep.customBackingCost
  );
  const isCustomColor = useSelector(
    (state) => state.activeStep.product.customBacking
  );
  const addedAddOns = useSelector(
    (state) => state.activeStep.product.accessories.addOns
  );
  const controllers = useSelector((state) => state.activeStep.controllers);

  // const accessoriesCost = useSelector(
  //   (state) => state.activeStep.product.accessoriesCost
  // );
  // const neonCost = useSelector((state) => state.activeStep.product.neonCost);

  let shippingPrice = shippingCost ? shippingCost : 0;
  const installationCost = useSelector(
    (state) => state.activeStep.installationCost
  );
  const blackFranceCost = useSelector(
    (state) => state.activeStep.blackFranceRate
  );
  const whiteFranceCost = useSelector(
    (state) => state.activeStep.whiteFranceRate
  );
  const getColorPriceForLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return colorCost; // Use the default color cost if color is not found
    }
  };

  const calculateBackingPrice = async () => {
    // console.log("Calculating Price");
    // console.log(selectedFont);

    if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
      // console.log(selectedFont);

      let baseValue = 0;
      let backingPrice;
      let wireCost = blackAccessoryCost;
      let backingPerFt = clearPlexiCost;
      let colorPricePerFt = 0;
      let fontPricePerFt = 0;
      let materialLength = 0;
      let neonWidth = 0;
      let neonHeight = 0;
      let signWidth = 0;
      let signHeight = 0;
      let glassCost = 0;
      let glassCost1 = 0;
      let glassCost2 = 0;
      let glassCost3 = 0;
      let unitNumber = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Bending Cost :", bendingCost);
        console.log("EGL White Cost :", colorCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }

      if (textAreas.length === 1) {
        // console.log("Single Font");
        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth + lockedWidth;
          signHeight = result?.maxHeight + backingHeight + lockedHeight;

          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );

            glassCost +=
              Number(fontPricePerFt + bendingCost + colorPricePerFt) *
              (letterLength / 12);
          }
          // console.log(glassCost, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + backingWidth + lockedWidth;
          signHeight = result?.maxHeight + backingHeight + lockedHeight;

          materialLength *= factor;

          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt = color.price;
            }
          });

          colorPricePerFt = colorPricePerFt ? colorPricePerFt : colorCost;

          // console.log(colorPricePerFt);
          glassCost =
            Number(fontPricePerFt + bendingCost + colorPricePerFt) *
            (materialLength / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (showConsoles) {
          console.log("Material Length : ", materialLength);

          console.log("Neon Width : ", neonWidth);
          console.log("Neon Height : ", neonHeight);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        unitNumber = Number(
          Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
        );
        if (showConsoles) {
          console.log("Unit Numbers : ", unitNumber);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments : ", segments);
        }
      } else if (textAreas.length === 2) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2);
        // console.log(glassCost);
        signHeight =
          Number(signHeight1 + signHeight2) + backingHeight + lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2) + backingWidth + lockedWidth;
        materialLength = materialLength1 + materialLength2;
        // console.log("Sign Height :", signHeight);
        // console.log("Sign Width :", signWidth);
        // console.log("Total Material Length :", materialLength);

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
            Math.ceil((neonHeight1 + neonHeight2) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      } else if (textAreas.length === 3) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let fontPricePerFt3 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;
        let colorPricePerFt3 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let neonWidth3 = 0;
        let neonHeight3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          allFonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          allFonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          for (const letterObj of textAreas[2].letters) {
            colorPricePerFt3 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            glassCost3 +=
              Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          allFonts.forEach((font) => {
            if (font.name === textAreas[2].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPricePerFt3 = color.price;
            }
          });

          colorPricePerFt3 = colorPricePerFt3 ? colorPricePerFt3 : colorCost;

          // console.log(fontPricePerFt);
          glassCost3 =
            Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
            (materialLength3 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2 + glassCost3);
        // console.log(glassCost);
        signHeight =
          signHeight1 +
          signHeight2 +
          signHeight3 +
          backingHeight +
          lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2, signWidth3) +
          backingWidth +
          lockedWidth;
        materialLength = materialLength1 + materialLength2 + materialLength3;

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
            Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      }
      if (showConsoles) {
        console.log("Glass Cost : ", glassCost);
      }
      dispatch(setNeonCost(glassCost));

      let techs = Number(
        Math.ceil((materialLength / 12 + unitNumber) / materialLengthPerTech) +
          segments -
          1
      );
      if (showConsoles) {
        console.log("Techs:", techs);
      }

      let transformerCost = Number(techs * tech22Cost);
      if (showConsoles) {
        console.log("Transformer Cost:", transformerCost);
      }

      backings.forEach((backing) => {
        if (backing.type === product.backing.name) {
          // console.log(color.code);
          backingPerFt = backing.BackingPricePerSqInch;
        }
      });

      if (isCustomColor) {
        backingPerFt = customBackingCost;
      } else {
        backingColors.forEach((backing) => {
          if (backing.code === product.backing.color) {
            // console.log(color.code);
            backingPerFt += backing.price;
          }
        });
      }
      backingPrice = Number(signWidth * signHeight * backingPerFt);
      if (product.backing.name === "") {
        backingPrice = 0;
      } else {
        if (product.backing.name === "wood" || product.backing.name === "box") {
          backingPrice = Number(
            (2 * signWidth * signHeight + signHeight * signHeight) *
              backingPerFt
          );
        } else if (product.backing.name === "skeleton") {
          backingPrice = Number(materialLength * backingPerFt);
        }
      }
      // console.log(backingPrice);

      if (showConsoles) {
        console.log("BackingCost:", backingPrice);
      }
      dispatch(setBackingCost(backingPrice));
      if (product.delivery.install.name) {
        if (product.delivery.install.name === "Concealed Install") {
          transformerCost = Number(techs * whiteFranceCost);
        }
        if (product.delivery.install.name === "Surface Install") {
          transformerCost = Number(techs * blackFranceCost);
        }

        baseInstallValues.forEach((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq +
          Number(baseValue + installationCost * unitNumber);

        let decimalMarkUp = markUp / 100;
        let decimalDiscount = discount / 100;

        let markupAmount = decimalMarkUp * installationPrice;

        let markUpPrice = installationPrice + markupAmount;
        let discountFactor = Number(1 - decimalDiscount);

        let installationMarkedUpPrice = Number(markUpPrice * discountFactor);
        dispatch(setInstallationPrice(Math.ceil(installationMarkedUpPrice)));

        if (showConsoles) {
          console.log("Installation Cost:", installationPrice);
        }
      } else {
        dispatch(setInstallationPrice(0));
      }
      cables.forEach((cable) => {
        if (cable.color === product?.accessories?.wireColor) {
          // console.log(cable);
          wireCost = cable.price;
        }
      });
      let accessoriesCost = Number(unitNumber * wireCost);
      if (showConsoles) {
        console.log("Accessories Cost:", accessoriesCost);
      }

      controllers.forEach((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          // console.log(cable);
          accessoriesCost += controller.price;
        }
      });
      if (product?.accessories?.addOns.length > 0) {
        addedAddOns.forEach((addOn) => {
          accessoriesCost += addOn.price;
        });
      }
      if (showConsoles) {
        console.log("Total Accessories Cost:", accessoriesCost);
      }
      dispatch(setAccessoriesCost(accessoriesCost));

      let totalCost = Number(
        glassCost + transformerCost + backingPrice + accessoriesCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);
      }

      dispatch(setTotalCost(totalCost));
      if (showConsoles) {
        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price:", price);
      }
      dispatch(addPrice(Math.ceil(price)));
    }
  };

  const RGBColor = useSelector((state) => state.activeStep.product.RGBColor);
  const SpecialColorFee = useSelector(
    (state) => state.activeStep.SpecialColorFee
  );
  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );
  const AcrylicInterShippingCost = useSelector(
    (state) => state.activeStep.woodInterShippingCost
  );
  const WoodInterShippingCost = useSelector(
    (state) => state.activeStep.acrylicInterShippingCost
  );
  const standardRGBCost = useSelector(
    (state) => state.activeStep.standardRGBCost
  );
  const magicRGBCost = useSelector((state) => state.activeStep.magicRGBCost);

  const getColorPriceForLEDLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return LEDCostPerMaterial; // Use the default color cost if color is not found
    }
  };
  const calculateLEDFontPrice = async () => {
    // console.log("Calculating Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let colorPrice = colorCost;
      let backingPerFt = customBackingCost;
      let baseValue = 0;
      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;
      // let minPowerSupply = 0;
      let LEDCost = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Custom Color Backing Cost :", customBackingCost);
        console.log("Special Color Cost :", SpecialColorFee);
        console.log("Magic RGB Cost :", magicRGBCost);
        console.log("Standard RGB Cost :", standardRGBCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1 && textAreas[0].value) {
        if (showConsoles) {
          console.log("------------------Single Font");
        }
        if (textAreas[0]?.isSplit) {
          if (showConsoles) {
            console.log("------------------Is Split");
          }
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + backingWidth + lockedWidth;
          signHeight = result?.maxHeight + backingHeight + lockedHeight;

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }
          for (const letterObj of textAreas[0].letters) {
            colorPrice = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost += Number((SpecialColorFee + colorPrice) * letterLength);
            } else {
              LEDCost += Number(colorPrice * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          materialLength *= factor;
          signWidth = result?.totalWidth + backingWidth + lockedWidth;
          signHeight = result?.maxHeight + backingHeight + lockedHeight;

          if (showConsoles) {
            console.log(`Material length :`, materialLength);
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost :`, colorPrice);
          }

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }
          if (textAreas[0]?.customColor) {
            LEDCost = Number((colorPrice + SpecialColorFee) * materialLength);
          } else {
            LEDCost = Number(colorPrice) * materialLength;
          }
          // console.log("LED Cost :", LEDCost);
        }
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 2) {
        if (showConsoles) {
          console.log("------------------Double Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[1].value}:`, colorPrice2);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[1].value} :`, LEDCost2);
          }
        }

        LEDCost = LEDCost1 + LEDCost2;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2;
        materialLength *= factor;
        signHeight =
          Number(signHeight1 + signHeight2) + backingHeight + lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2) + backingWidth + lockedWidth;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 3) {
        if (showConsoles) {
          console.log("------------------Triple Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let colorPrice3 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;
        let LEDCost3 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          for (const letterObj of textAreas[2].letters) {
            colorPrice3 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost3 += Number(
                (SpecialColorFee + colorPrice3) * letterLength
              );
            } else {
              LEDCost3 += Number(colorPrice3 * letterLength);
            }
          }
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[2].value}:`,
              materialLength3
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPrice3 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[2].value}:`, colorPrice3);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[2]?.customColor) {
            LEDCost3 = Number(
              (colorPrice3 + SpecialColorFee) * materialLength3
            );
          } else {
            LEDCost3 = Number(colorPrice3) * materialLength3;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[2].value} :`, LEDCost3);
          }
        }

        LEDCost = LEDCost1 + LEDCost2 + LEDCost3;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2 + materialLength3;
        materialLength *= factor;
        signHeight =
          Number(signHeight1 + signHeight2 + signHeight3) +
          backingHeight +
          lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2, signWidth3) +
          backingWidth +
          lockedWidth;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }
        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      }
      if (showConsoles) {
        console.log("Total LED Cost :", LEDCost);
      }
      dispatch(setNeonCost(LEDCost));

      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      if (showConsoles) {
        console.log("Number of Power Supply :", powerSupplies);
      }

      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      if (showConsoles) {
        console.log("Power Supply Cost :", powerSupplyCost);
      }

      let backingCost;

      backings.forEach((backing) => {
        if (backing.type === product.backing.name) {
          // console.log(color.code);
          backingPerFt = backing.BackingPricePerSqInch;
        }
      });

      if (isCustomColor) {
        backingPerFt = customBackingCost;
      } else {
        backingColors.forEach((backing) => {
          if (backing.code === product.backing.color) {
            // console.log(color.code);
            backingPerFt += backing.price;
          }
        });
      }
      if (showConsoles) {
        console.log("Backing Color Cost :", backingPerFt);
      }
      backingCost = Number(backingArea * backingPerFt);
      if (product.backing.name === "") {
        backingCost = 0;
      }
      if (showConsoles) {
        console.log("Backing Cost :", backingCost);
      }
      dispatch(setBackingCost(backingCost));

      if (product.delivery.install.name) {
        baseInstallValues.forEach((installValue) => {
          if (installValue.name === product?.delivery?.install?.name) {
            baseValue = installValue?.value;
          }
        });
        // console.log(installationCost, baseValue, unitNumber);
        let installationPrice =
          signWidth * signHeight * product.delivery.install.installPerSq +
          Number(baseValue + installationCost);
        let decimalMarkUp = markUp / 100;
        let decimalDiscount = discount / 100;

        let markupAmount = decimalMarkUp * installationPrice;

        let markUpPrice = installationPrice + markupAmount;
        let discountFactor = Number(1 - decimalDiscount);

        let installationMarkedUpPrice = Number(markUpPrice * discountFactor);
        dispatch(setInstallationPrice(Math.ceil(installationMarkedUpPrice)));

        if (showConsoles) {
          console.log("Installation Cost:", installationPrice);
        }
      } else {
        dispatch(setInstallationPrice(0));
      }
      let accessoriesCost = Number(segments * blackAccessoryCost);
      // console.log(accessoriesCost);

      // console.log(accessoriesCost);
      controllers.forEach((controller) => {
        if (controller.name === product?.accessories?.dimmer?.name) {
          if (showConsoles) {
            console.log("Controller Price :", controller.price);
          }
          accessoriesCost =
            Number(segments * blackAccessoryCost) +
            powerSupplies * controller.price;
        }
      });
      // console.log(accessoriesCost);
      if (product.accessories.addOns.length > 0) {
        addedAddOns.forEach((addOn) => {
          // console.log(accessoriesCost, addOn.price);
          accessoriesCost += addOn.price;
        });
      }

      // console.log("Accessories Cost :", accessoriesCost);
      if (showConsoles) {
        console.log("RGB Style Selected :", RGBColor);
      }
      if (RGBColor) {
        if (RGBColor === "standardRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + standardRGBCost)
          );
          if (showConsoles) {
            console.log(
              "Accessories Cost with Standard RGB :",
              accessoriesCost
            );
          }
        }
        if (RGBColor === "magicRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + magicRGBCost)
          );
          if (showConsoles) {
            console.log("Accessories Cost with Magic RGB :", accessoriesCost);
          }
        }
      }
      // console.log(accessoriesCost);
      dispatch(setAccessoriesCost(accessoriesCost));

      let shippingCost = Number(
        Number(backingArea * InterShippingCost) / segments
      );
      if (product.backing.name === "wood") {
        shippingCost = Number(
          Number(backingArea * WoodInterShippingCost) / segments
        );
      }
      if (product.backing.name === "box") {
        shippingCost = Number(
          Number(backingArea * AcrylicInterShippingCost) / segments
        );
      }
      if (showConsoles) {
        console.log("Shipping Cost :", shippingCost);
      }

      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);
      }
      dispatch(setTotalCost(totalCost));

      if (showConsoles) {
        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price:", price);
      }
      dispatch(addPrice(Math.ceil(price)));
    }
  };
  useEffect(() => {
    if (product.source === "Glass") {
      setSourceType("Neon");

      calculateBackingPrice();
    } else if (product.source === "LED") {
      setSourceType("LED");

      calculateLEDFontPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.fonts, product.backing, product.accessories]);
  // console.log(neonCost);
  return (
    <div>
      <Typography
        style={{
          color: "#000000",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
          fontWeight: "400",
          fontFamily: "Beatrice",
        }}
      >
        Price Detail
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#000000",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
          }}
        >
          {`${sourceType} Price`}
        </Typography>
        <Typography
          style={{
            color: "#03705E",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
          }}
        >
          {product.delivery.deliveryType === "pickup" ? (
            <>${cost}</>
          ) : (
            <>${cost + shippingPrice}</>
          )}
        </Typography>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#000000",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
          }}
        >
          Backing
        </Typography>
        <Typography
          style={{
            color: "#03705E",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
          }}
        >
          ${backingCost?.toFixed(2)}
        </Typography>
      </div>
        */}
      {InstallationPrice ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000000",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Beatrice",
            }}
          >
            Installation Price
          </Typography>
          <Typography
            style={{
              color: "#03705E",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Beatrice",
            }}
          >
            ${InstallationPrice}
          </Typography>
        </div>
      ) : (
        <></>
      )}
      {shippingAPIRate || shippingCost ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000000",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Beatrice",
            }}
          >
            {product.delivery.deliveryType === "pickup" ? (
              <>Packaging Cost</>
            ) : (
              <>Delivery Price</>
            )}
          </Typography>
          <Typography
            style={{
              color: "#03705E",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Beatrice",
            }}
          >
            {product.delivery.deliveryType === "pickup" ? (
              <> ${shippingCost}</>
            ) : (
              <> ${shippingAPIRate}</>
            )}
          </Typography>
        </div>
      ) : (
        <></>
      )}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#BF6BE3",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
          }}
        >
          Controller
        </Typography>
        <Typography
          style={{
            color: "#BF6BE3",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
          }}
        >
          N/A
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#BF6BE3",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
          }}
        >
          Other Fonts
        </Typography>
        <Typography
          style={{
            color: "#BF6BE3",
            marginTop: "20px",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
          }}
        >
          N/A
        </Typography>
      </div> */}
      {/* <hr /> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#000000",
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "20px",
            fontWeight: "600",
            fontFamily: "Beatrice",
          }}
        >
          Estimated Price
        </Typography>
        <Typography
          style={{
            color: "#03705E",
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "20px",
            fontWeight: "400",
            fontFamily: "Beatrice",
          }}
        >
          ${cost?.toFixed(2)}
        </Typography>
      </div> */}
    </div>
  );
};

export default PriceDetails;
