import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Close from "../../Assets/Close.jsx";
import AddOnCarousel from "../Accessories/AddOnCarousel";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  removeProductAccessoriesAddOns,
  setProductAccessoriesAddOns,
} from "../../Redux/Slice";

const AddOn = ({ show, onHide }) => {
  const markUp = useSelector((state) => state.activeStep.markup);

  const dispatch = useDispatch();

  const addedAddOns = useSelector(
    (state) => state.activeStep.product.accessories.addOns
  );
  const addOns = useSelector((state) => state.activeStep.accessories);

  const handleAdd = (addOn) => {
    // console.log(addOn);
    const isAlreadyAdded = addedAddOns?.some(
      (addedAddOn) => addedAddOn.title === addOn.title
    );

    if (!isAlreadyAdded) {
      dispatch(setProductAccessoriesAddOns(addOn));
    }
  };

  const handleRemove = (addOn) => {
    // console.log(addOn);
    const isAlreadyAdded = addedAddOns?.some(
      (addedAddOn) => addedAddOn.title === addOn.title
    );
    // console.log(isAlreadyAdded);

    if (isAlreadyAdded) {
      dispatch(removeProductAccessoriesAddOns(addOn));
    }
  };
  // console.log(addOns);

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "100%",
  };

  return (
    <Modal
      open={show}
      onClose={onHide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            // borderRadius: "25px",
            // padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            Other Add-Ons
          </Typography>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onHide();
            }}
          >
            <Close />
          </div>
        </div>
        <div
          style={{
            padding: "15px",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          <Row>
            {addOns.map((addOn) => (
              <Col key={addOn.title} xs={12} sm={6} md={6} lg={4}>
                <Card
                  className={`${
                    addedAddOns?.some(
                      (addedAddOn) => addedAddOn.title === addOn.title
                    )
                      ? "selected"
                      : ""
                  }`}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid #AFE9E0",
                    margin: "10px",
                    marginTop: "0px",
                    padding: "20px",
                  }}
                >
                  <AddOnCarousel images={addOn.images} />
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: window.innerWidth > 768 ? "16px" : "1rem",
                        color: "#595959",
                      }}
                    >
                      {addOn.title}
                    </Card.Title>
                    <Card.Text
                      className="enable-listing-style"
                      style={{
                        fontSize: window.innerWidth > 768 ? "12px" : "0.5rem",
                        height: "150px",
                        overflowY: "auto",
                        color: "#8F8F8F",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: addOn?.desc,
                      }}
                    ></Card.Text>
                    <Typography
                      style={{
                        color: "#595959",
                        fontWeight: "600",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                    >
                      Price: $
                      {Math.ceil(addOn?.price * (markUp / 100) + addOn?.price)}
                    </Typography>
                  </Card.Body>
                  <Button
                    style={{
                      border: "none",
                      color: addedAddOns?.some(
                        (addedAddOn) => addedAddOn.title === addOn.title
                      )
                        ? "#ffffff"
                        : "#03705E",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      backgroundColor: addedAddOns?.some(
                        (addedAddOn) => addedAddOn.title === addOn.title
                      )
                        ? "#F25C12"
                        : "#AFE9E0",
                      textAlign: "center",
                      padding: "10px",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      addedAddOns?.some(
                        (addedAddOn) => addedAddOn.title === addOn.title
                      )
                        ? handleRemove(addOn)
                        : handleAdd(addOn);
                    }}
                  >
                    {addedAddOns?.some(
                      (addedAddOn) => addedAddOn.title === addOn.title
                    ) ? (
                      <>REMOVE</>
                    ) : (
                      <>ADD</>
                    )}
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              color: "white",
              fontWeight: "600",
              cursor: "pointer",
              backgroundColor: "#36CDB4",
              textAlign: "center",
              padding: "10px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingTop: "10px",
              borderRadius: "25px",
              border: "none",
            }}
            onClick={() => onHide()}
          >
            OK, I'M READY
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddOn;
