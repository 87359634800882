import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../Assets/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductAccessoriesDescription,
  setProductBackingColorDescription,
  setProductBackingStyleDescription,
  setProductColorDescription,
  setProductFontDescription,
  setProductSizeDescription,
} from "../../Redux/Slice";
import { Button } from "react-bootstrap";

const EditModal = ({ edit, show, close }) => {
  const dispatch = useDispatch();
  const fontDesc = useSelector(
    (state) => state.activeStep.product.fontsDescription
  );
  const colorDesc = useSelector(
    (state) => state.activeStep.product.colorDescription
  );
  const backingStyleDesc = useSelector(
    (state) => state.activeStep.product.backing.styleDescription
  );
  const backingColorDesc = useSelector(
    (state) => state.activeStep.product.backing.colorDescription
  );
  const sizeDesc = useSelector(
    (state) => state.activeStep.product.size.description
  );
  const accessoriesDesc = useSelector(
    (state) => state.activeStep.product.accessories.description
  );

  const [initialDesc, setInitialDesc] = useState("");
  const [descriptionAction, setDescriptionAction] = useState(null);

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  useEffect(() => {
    switch (edit) {
      case "Custom Fonts":
        setInitialDesc(fontDesc);
        break;
      case "Custom Color":
        setInitialDesc(colorDesc);
        break;
      case "Custom Backing Style":
        setInitialDesc(backingStyleDesc);
        break;
      case "Custom Backing Color":
        setInitialDesc(backingColorDesc);
        break;
      case "Custom Size":
        setInitialDesc(sizeDesc);
        break;
      case "Custom Accessories":
        setInitialDesc(accessoriesDesc);
        break;
      default:
        setInitialDesc("");
        break;
    }
  }, []);

  const handleUpdate = () => {
    if (edit === "Custom Fonts") {
      dispatch(setProductFontDescription(initialDesc));
    } else if (edit === "Custom Color") {
      dispatch(setProductColorDescription(initialDesc));
    } else if (edit === "Custom Backing Style") {
      dispatch(setProductBackingStyleDescription(initialDesc));
    } else if (edit === "Custom Backing Color") {
      dispatch(setProductBackingColorDescription(initialDesc));
    } else if (edit === "Custom Size") {
      dispatch(setProductSizeDescription(initialDesc));
    } else if (edit === "Custom Accessories") {
      dispatch(setProductAccessoriesDescription(initialDesc));
    }
    close();
  };

  return (
    <Modal
      open={show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            border: "none",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Update {edit} Description
            </Typography>
          </div>
          <div
            style={{
              color: "#36CDB4",
              width: "60px",
              cursor: "pointer",
              margin: "0px",
              padding: "0px",
            }}
            onClick={close}
          >
            <Close />
          </div>
        </div>
        <div>
          <textarea
            style={{
              padding: "10px",
              borderRadius: "16px",
              height: "150px",
              width: "100%",
              color: "#8F8F8F",
              outline: "none",
              resize: "none",
            }}
            value={initialDesc}
            onChange={(e) => setInitialDesc(e.target.value)}
            placeholder="Anything we should know?"
            className="bg-[#F4F4F4]"
          ></textarea>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="hover-effect"
              style={{
                color: "#03705E",
                textTransform: "uppercase",
                fontSize: "20px",
                width: "20%",
                paddingTop: "10px",
                fontWeight: "600",
                backgroundColor: "#AFE9E0",
                border: "none",
                borderRadius: "16px",
              }}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EditModal;
