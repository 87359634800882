import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import PickUpMap from "./PickUp/PickUpMap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./styles/Pickup.css";
import { setShippingCost } from "../../Redux/Slice";
import Info from "../../Assets/SuperCustom/Info";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../../utils/client/helpers";
const PickUp = () => {
  const cost = useSelector((state) => state.activeStep.product.cost);
  const showConsoles = useSelector((state) => state.activeStep.showConsoles);
  const markUp = useSelector((state) => state.activeStep.markup);
  const discount = useSelector((state) => state.activeStep.discount);
  const shippingCost = useSelector(
    (state) => state.activeStep.product.shippingCost
  );
  const product = useSelector((state) => state.activeStep.product);
  const [openPriceInfo, setOpenPriceInfo] = useState(false);
  const [openPickupPriceInfo, setOpenPickupPriceInfo] = useState(false);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [showMap, setShowMap] = useState(false);

  const dispatch = useDispatch();
  const [finalTime, setFinalTime] = useState("");

  const currentDate = new Date();
  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/nyc/${product.source}/${product.backing.name}`
      );
      if (response?.data[0]) {
        const maximumProductionDays = response?.data[0]?.maximumProductionDays;
        const minimumProductionDays = response?.data[0]?.minimumProductionDays;
        const pickUpPackingMaterial =
          response?.data[0]?.glassNeonPickUpPackingMaterialsPerSquareInch;

        const maxDate = new Date(currentDate);
        const minDate = new Date(currentDate);
        maxDate.setDate(maxDate.getDate() + maximumProductionDays);
        minDate.setDate(minDate.getDate() + minimumProductionDays);

        const newYear = maxDate.getFullYear();
        const maxMonth = monthNames[maxDate.getMonth()];
        const minMonth = monthNames[minDate.getMonth()];
        const maxDay = maxDate.getDate();
        const minDay = minDate.getDate();
        setFinalTime(
          `${minMonth} ${minDay} - ${maxMonth} ${maxDay}, ${newYear}`
        );
        let shippingCost =
          (product.size.width +
            product.backing.width +
            product.backing.lockedWidth +
            5) *
          (product.size.height +
            product.backing.height +
            product.backing.lockedHeight +
            5) *
          pickUpPackingMaterial;
        if (showConsoles) {
          console.log("Pick Up Cost per sq in  : ", pickUpPackingMaterial);
          console.log(
            "W*:",
            product.size.width +
              product.backing.width +
              product.backing.lockedWidth +
              5
          );
          console.log(
            "H*:",
            product.size.height +
              product.backing.height +
              product.backing.lockedHeight +
              5
          );
          console.log("Packaging Cost:", shippingCost);
        }
        let decimalMarkUp = markUp / 100;
        let decimalDiscount = discount / 100;

        let markupAmount = decimalMarkUp * shippingCost;

        let markUpPrice = shippingCost + markupAmount;
        let discountFactor = Number(1 - decimalDiscount);

        let shippingPrice = Number(markUpPrice * discountFactor);
        if (showConsoles) {
          console.log("Packaging Price : ", shippingPrice);
        }
        dispatch(setShippingCost(Math.ceil(shippingPrice)));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-end"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 300,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  useEffect(() => {
    getProductionDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(finalTime);
  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          // height: window.innerWidth > 768 ? "280px" : "",
          flexDirection: "column",
          textAlign: "center",
          color: "#07AB90",
        }}
      >
        <Row>
          <Col xl={6} md={6} xs={12}>
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                fontWeight: "700",
                fontStyle: "italic",
                textAlign: "left",
                letterSpacing: "0.05em",
              }}
            >
              Delivery Options
            </Typography>
          </Col>
          <Col xl={6} md={6} xs={12}>
            <div
              style={{
                display: product.backing.name === "custom" ? "none" : "",
              }}
              className=" justify-end"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{ fontFamily: "Beatrice", paddingTop: "5px" }}
                  className="text-[#BF6BE3]"
                >
                  Price Estimate
                </Typography>
                <CustomWidthTooltip
                  open={openPriceInfo}
                  onClose={() => handleTooltipClose(setOpenPriceInfo)}
                  title={
                    "Have questions about pricing or just need a human touch? Go through the process and submit an inquiry at the end. Someone from our team will be in touch!"
                  }
                >
                  <MyComponent
                    onMouseEnter={() => handleMouseEnter(setOpenPriceInfo)}
                    onMouseLeave={() => handleMouseLeave(setOpenPriceInfo)}
                    onClick={() => handleClick(setOpenPriceInfo, openPriceInfo)}
                    style={{
                      marginLeft: "10px",
                      display: "inline-block",
                    }}
                  />
                </CustomWidthTooltip>
              </div>
              <div
                style={{
                  fontFamily: "Beatrice",
                  fontSize: window.innerWidth > 768 ? "1.875rem" : "1rem",
                }}
                className=" text-[#07AB90] text-right"
              >
                {shippingCost ? (
                  <>
                    ${Number(cost + shippingCost)}
                    <CustomWidthTooltip
                      open={openPickupPriceInfo}
                      onClose={() => handleTooltipClose(setOpenPickupPriceInfo)}
                      title={`Neon : $${cost} + \n Custom Box: $${shippingCost}`}
                    >
                      <MyComponent
                        onMouseEnter={() =>
                          handleMouseEnter(setOpenPickupPriceInfo)
                        }
                        onMouseLeave={() =>
                          handleMouseLeave(setOpenPickupPriceInfo)
                        }
                        onClick={() =>
                          handleClick(
                            setOpenPickupPriceInfo,
                            openPickupPriceInfo
                          )
                        }
                        style={{
                          marginLeft: "10px",
                          display: "inline-block",
                        }}
                      />
                    </CustomWidthTooltip>
                  </>
                ) : (
                  <>${cost}</>
                )}{" "}
                + Free Pick Up
              </div>
            </div>
          </Col>
        </Row>
        <div>
          {product.backing.name === "custom" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: window.innerWidth > 768 ? "50px" : "20px",
                marginBottom: window.innerWidth > 768 ? "50px" : "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                  fontFamily: "Beatrice",
                  width: window.innerWidth > 768 ? "57%" : "100%",
                }}
              >
                We will be able to give you an estimated time frame after we
                reach out with an updated quote.
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                  fontFamily: "Beatrice",
                  width: window.innerWidth > 768 ? "57%" : "100%",
                }}
              >
                Order today with the{" "}
                <Typography
                  style={{
                    display: "inline-block",
                    fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                    fontFamily: "Beatrice",
                    color: "#BF6BE3",
                  }}
                >
                  “NYC Pick Up”{" "}
                </Typography>
                method, and Your estimated completion date will be:
              </Typography>
              <Typography
                style={{
                  fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                  fontFamily: "Beatrice",
                }}
              >
                {finalTime}
              </Typography>
            </div>
          )}
        </div>

        {/* <div className="flex justify-between">
          <Button
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "2px",
              borderRadius: "16px",
              color: "#25CCB0",
              bgcolor: "#D8F4F1",
              paddingRight: "20px",
              paddingLeft: "20px",
              fontFamily: "Beatrice",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            onClick={() => {
              setShow(true);
            }}
          >
            EXAMPLES
          </Button>
          <Button
            sx={{
              color: "#03705E",
              fontSize: "20px",
              fontWeight: "600",
              bgcolor: "#D8F4F1",
              borderRadius: "24px",
              paddingRight: "30px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
              paddingLeft: "30px",
              marginTop: "20px",
              marginBottom: "20px",
              fontFamily: "Beatrice",
              textTransform: "none",
            }}
            onClick={() => setShowMap(true)}
          >
            View Pick Up Location
          </Button>
        </div> */}
      </div>

      {/* <PersonalDetails formik={formik} /> */}
      {/* <ShippingInfo formik={formik} /> */}
      {/* <BillingInfo formik={formik} /> */}
      <div>
        {/* <Typography
          style={{
            fontSize: "20px",
            fontFamily: "Beatrice",
          }}
        >
          Pickup is the easiest!
        </Typography> */}
        <Typography
          style={{
            // marginTop: "50px",
            fontSize: "12px",
            fontFamily: "Beatrice",
            color: "#595959",
          }}
        >
          NYC Pick Up is the most cost effective if you're located nearby NYC.
          You can swing on by our studio to grab your piece but please note you
          will be installing yourself.
        </Typography>
      </div>
      <div
        style={{
          marginTop: "20px",
          borderRadius: "20px",
          height: "600px",
          width: "100%",
        }}
      >
        <iframe
          style={{
            borderRadius: "24px",
          }}
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.911608670714!2d-73.9868481!3d40.7199623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259816d03e001%3A0x99d4398aecc2108e!2s133%20Norfolk%20St%2C%20New%20York%2C%20NY%2010002%2C%20USA!5e0!3m2!1sen!2s!4v1687291468401!5m2!1sen!2s"
          width="100%"
          height="600"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <PickUpMap showMap={showMap} closeMap={() => setShowMap(false)} />
    </>
  );
};

export default PickUp;
