import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Close from "../Assets/Close";
import { useDispatch, useSelector } from "react-redux";
import { setShowExamples } from "../Redux/Slice";
import sanitize from "sanitize-html";

const ExampleModal = () => {
  const dispatch = useDispatch();

  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const examples = useSelector((state) => state.activeStep.examples);

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <Modal
      open={showExamples}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            border: "none",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth <= 768 ? "30px" : "40px",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
              marginBottom: "10px",
            }}
          >
            Examples
          </Typography>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(setShowExamples(!showExamples));
            }}
          >
            <Close />
          </div>
        </div>
        <div
          style={{
            height: "90%",
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: "5px",
          }}
        >
          {examples.map((example) => (
            <Row>
              <Col
                xl={6}
                lg={6}
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: window.innerWidth <= 768 ? "" : "300px",
                }}
              >
                <img
                  style={{
                    maxHeight: "250px",
                  }}
                  src={example?.imageUrls[0]}
                  alt="Example"
                />
              </Col>
              <Col xl={6} lg={6} md={12} xs={12}>
                <div
                  style={{
                    margin: "20px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    paddingRight: "1.5rem",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: window.innerWidth <= 768 ? "1rem" : "18px",
                      fontWeight: "600",
                      color: "#595959",
                    }}
                  >
                    {example?.title}
                  </Typography>
                  <div
                    className="enable-listing-style"
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: window.innerWidth <= 768 ? "0.75rem" : "12px",
                      fontWeight: "600",
                      color: "#8F8F8F",
                      paddingTop: "1rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(example?.content),
                    }}
                  ></div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default ExampleModal;
