import React from "react";

const Facebook = () => {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5085 51.3363C41.0658 51.3363 52.8803 39.8369 52.8803 25.6681C52.8803 11.4993 41.0658 0 26.5085 0C11.9513 0 0.136719 11.4993 0.136719 25.6681C0.136719 39.8369 11.9513 51.3363 26.5085 51.3363Z"
        fill="#36CDB4"
      />
      <path
        d="M39.6882 25.6676C39.6882 18.5832 33.7809 12.8335 26.5023 12.8335C19.2237 12.8335 13.3164 18.5832 13.3164 25.6676C13.3164 31.8793 17.8524 37.0514 23.8651 38.245V29.5178H21.2279V25.6676H23.8651V22.459C23.8651 19.9821 25.9353 17.9671 28.4802 17.9671H31.7767V21.8173H29.1395C28.4143 21.8173 27.8209 22.3949 27.8209 23.1008V25.6676H31.7767V29.5178H27.8209V38.4375C34.4798 37.7958 39.6882 32.3284 39.6882 25.6676Z"
        fill="white"
      />
    </svg>
  );
};

export default Facebook;
