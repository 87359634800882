import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import EditModal from "./EditModal";

const SizeCard = ({ heading, selection }) => {
  const [edit, setEdit] = useState("");
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          <>Edit</>
        </Typography> */}
      </div>
      <Card
        style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #AFE9E0",
          borderRadius: "16px",
        }}
      >
        <Typography
          style={{
            margin: "10px",
            fontSize: "32px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#000000",
          }}
        >
          {Array.isArray(selection) ? (
            <>
              <div class="select-toggle">
                <select
                  className="toggle-input"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                  }}
                >
                  {selection.map((select) => (
                    <option
                      style={{
                        fontFamily: selection.fontFamily,
                      }}
                      value=""
                    >
                      {select}
                    </option>
                  ))}
                </select>
                <span class="toggle-icon"></span>
              </div>
              {/* <select
                style={{
                  backgroundColor: "white",
                  outline: "none",
                }}
              >
                {selection.map((select) => (
                  <option
                    style={{
                      fontFamily: selection.fontFamily,
                    }}
                    value=""
                  >
                    {select}
                  </option>
                ))}
              </select> */}
            </>
          ) : selection ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {selection}
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "Beatrice",
                  color: "#03705E",
                  textAlign: "center",
                }}
              >
                Inches
              </Typography>
            </div>
          ) : (
            "None"
          )}
        </Typography>
      </Card>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default SizeCard;
