import { Box, Modal, Typography } from "@mui/material";

import React from "react";
import { Button } from "react-bootstrap";
import Close from "../../../../Assets/Close";
import { useDispatch } from "react-redux";
import {
  setProductDeliveryDetails,
  setProductDeliveryType,
  setProductInstall,
} from "../../../../Redux/Slice";

const LocationError = ({
  setRisks,
  setShowLocation,
  setSelected,
  show,
  close,
}) => {
  const dispatch = useDispatch();

  const handleContinue = () => {
    close();
    setShowLocation(true);
    setRisks(false);
    dispatch(setProductInstall({}));
    dispatch(setProductDeliveryDetails({}));
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "40%" : "80%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    overflowX: "hidden",
  };

  return (
    <Modal
      open={show}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "1rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            We are Sorry
          </Typography>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelected("pickup");
              dispatch(setProductDeliveryType("pickup"));
              close();
              dispatch(setProductDeliveryDetails({}));
            }}
          >
            <Close />
          </div>
        </div>
        <div>
          <Typography>
            The installation method is not available in your area. Please
            provide us with another address within the NYC Tri-State Area.
          </Typography>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                width: window.innerWidth > 768 ? "40%" : "100%",
                color: "white",
                fontWeight: "600",
                fontSize: window.innerWidth > 768 ? "22px" : "1rem",
                cursor: "pointer",
                backgroundColor: "#36CDB4",
                textAlign: "center",
                padding: "10px",
                paddingRight: "40px",
                paddingLeft: "40px",
                paddingTop: "10px",
                borderRadius: "35px",
                border: "none",
              }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LocationError;
