import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../Assets/Close";
import PropTypes from "prop-types";
import image from "../../Assets/DescriptionIcon.png";
import LinearProgress from "@mui/material/LinearProgress";
import { Card } from "react-bootstrap";

const FontUpload = ({ show, close, file, setName, setFontFile, name }) => {
  const handleClose = () => {
    setName("");
    setFontFile("");
    close();
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "90%", mr: 2, ml: 1, color: "#25CCB0" }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 30 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // height: "85vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Upload Font
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              height: "250px",
              overflow: "hidden",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: "22px",
              }}
            >
              Your font "{name}" is currently uploading.
            </Typography>

            <Card
              style={{
                height: "120px",
                width: "100%",
                borderRadius: "16px",
                border: "2px solid #AFE9E0",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    margin: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <img width="80px" src={image} alt="" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        display: "inline-flex",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {file?.name}
                    </Typography>
                    <Typography style={{ display: "inline-flex" }}>
                      {(file?.size / 1024).toFixed(2)} KB.
                    </Typography>
                  </div>
                </div>
                <LinearProgressWithLabel value={100} />
              </div>
            </Card>

            <div
              className="continue-button-width-adjust"
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontWeight: "600",
                  bgcolor: "#36CDB4",
                  borderRadius: "16px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  ":hover": {
                    color: "#36CDB4",
                    border: "1px solid #36CDB4",
                  },
                }}
                onClick={handleClose}
              >
                Continue
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FontUpload;
