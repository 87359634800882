import React from "react";

const Plus = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4807 20.96C9.69405 20.96 9.05664 20.3226 9.05664 19.536V1.42407C9.05664 0.637413 9.69405 0 10.4807 0C11.2674 0 11.9048 0.637413 11.9048 1.42407V19.536C11.9048 20.3226 11.2674 20.96 10.4807 20.96Z"
        fill="#36CDB4"
      />
      <path
        d="M19.536 11.9067H1.42407C0.637413 11.9067 0 11.2693 0 10.4827C0 9.69601 0.637413 9.05859 1.42407 9.05859H19.536C20.3226 9.05859 20.96 9.69601 20.96 10.4827C20.96 11.2693 20.3226 11.9067 19.536 11.9067Z"
        fill="#36CDB4"
      />
    </svg>
  );
};

export default Plus;
