import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../../Assets/Close";
import { Carousel } from "react-bootstrap";
import Image1 from "../../../Assets/SuperCustom/ColorNavigation/1.png";
import Image2 from "../../../Assets/SuperCustom/ColorNavigation/2.png";
import Image3 from "../../../Assets/SuperCustom/ColorNavigation/3.png";

const ColorNavigation = ({ show, close }) => {
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 762 ? "90%" : "70%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };
  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                width: "100%",
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth < 762 ? "1.25rem" : "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
                textAlign: "center",
              }}
            >
              Colors page navigation
            </Typography>
          </div>

          <Carousel
            style={{
              width: "100%",
              borderRadius: "16px",
              // border: "2px solid #AFE9E0",
            }}
          >
            <Carousel.Item>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: window.innerWidth < 450 ? "200px" : "500px",
                    // width: "100%",
                    borderRadius: "16px",
                    // border: "2px solid #AFE9E0",
                  }}
                  src={Image1}
                  alt={`img1`}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: window.innerWidth < 450 ? "200px" : "500px",
                    // width: "100%",
                    borderRadius: "16px",
                    // border: "2px solid #AFE9E0",
                  }}
                  src={Image2}
                  alt={`img2`}
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: window.innerWidth < 450 ? "200px" : "500px",
                    // width: "100%",
                    borderRadius: "16px",
                    // border: "2px solid #AFE9E0",
                  }}
                  src={Image3}
                  alt={`img3`}
                />
              </div>
            </Carousel.Item>
          </Carousel>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="continue-button-width-adjust"
              sx={{
                cursor: "pointer",
                color: "#ffffff",
                textAlign: "center",
                fontSize: window.innerWidth < 762 ? "0.8rem" : "16px",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#36CDB4",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  color: "#36CDB4",
                  border: "1px solid #36CDB4",
                },
              }}
              onClick={() => close()}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ColorNavigation;
