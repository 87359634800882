import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditModal from "./EditModal";
import { useSelector } from "react-redux";

const BackingColorCard = ({ heading, selection }) => {
  const edit = "";
  const [show, setShow] = useState(false);
  const [custom, setCustom] = useState(false);
  const backing = useSelector((state) => state.activeStep.product.backing);
  const backingColors = useSelector((state) => state.activeStep.backingColors);

  let [backingColorImage, setBackingColorImage] = useState();

  useEffect(() => {
    if (backingColors && backing.color) {
      setCustom(!backingColors.some((color) => color.code === selection));

      backingColors.map((backingColor) => {
        if (backingColor.code === backing.color) {
          setBackingColorImage(backingColor.image);
        }
        return 0;
      });
    }
  }, [backing.color, backingColors, selection]);

  // console.log(selection);
  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: "600",
            fontFamily: "Beatrice",
            color: "#000000",
            marginBottom: "5px",
          }}
        >
          {heading}
        </Typography>
        {/* <Typography
          style={{
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Beatrice",
            color: "#03705E",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(heading);
            setShow(true);
          }}
        >
          {heading === "Format" ||
          heading === "Size" ||
          heading === "Controllers" ||
          heading === "Add-ons" ||
          heading === "Reference Image" ? (
            <></>
          ) : (
            <>Edit</>
          )}
        </Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {backingColorImage ? (
          <div
            style={{
              border: "2px solid #AFE9E0",
              width: "100%",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              height: "100px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img src={backingColorImage} alt="" />
          </div>
        ) : (
          <div
            style={{
              height: "100px",
              width: "100%",
              border: "2px solid #AFE9E0",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!custom ? (
              <Typography
                style={{
                  margin: "20px",
                  fontSize: "32px",
                  fontWeight: "400",
                  fontFamily: "Beatrice",
                  color: "#000000",
                }}
              >
                N/A
              </Typography>
            ) : (
              <Typography
                style={{
                  margin: "20px",
                  fontSize: "32px",
                  fontWeight: "400",
                  fontFamily: "Beatrice",
                  color: "#000000",
                }}
              >
                Custom
              </Typography>
            )}
          </div>
        )}
      </div>
      <EditModal edit={edit} show={show} close={() => setShow(false)} />
    </div>
  );
};

export default BackingColorCard;
