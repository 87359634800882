import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  addProductAccessoriesAddOns,
  emptyOtherColors,
  nextStep,
  setProductAccessoriesDimmer,
  setProductBacking,
  setProductBackingImage,
  setProductBackingPaddings,
  setProductFont,
  setProductSource,
  updatePositions,
} from "../../Redux/Slice";
import { useDispatch, useSelector } from "react-redux";
import "../../Components/styles/sourceList.css";
import { ThreeDots } from "react-loader-spinner";

const SourceList = ({ selectedSource, setSelectedSource }) => {
  // const product = useSelector((state) => state.activeStep.product);
  const sources = useSelector((state) => state.activeStep.sources);
  const dispatch = useDispatch();
  return (
    <div
      style={{
        paddingLeft: "10%", // Adjust as needed
        paddingRight: "10%", // Adjust as needed
      }}
      className="card-group"
    >
      {sources.length > 0 ? (
        <>
          <Row>
            {sources.map((source, index) => (
              <Col lg={6} md={12} sm={12} xs={12} key={index}>
                <Card
                  className={`sourceDiv border-2 border-[#AFE9E0] p-3 m-3 cursor-pointer mt-0 rounded-3xl ${
                    selectedSource === source.type ? "selected" : "hover-effect"
                  }`}
                  style={{
                    display: source?.active ? "" : "none",
                    height: "auto", // Adjusted to auto for responsive height
                  }}
                  onClick={() => {
                    setSelectedSource(source.type);
                    dispatch(setProductSource(source.type));
                    dispatch(setProductFont([]));
                    dispatch(setProductBackingImage(null));
                    dispatch(setProductBacking("square"));
                    dispatch(setProductAccessoriesDimmer({}));
                    dispatch(addProductAccessoriesAddOns([]));
                    dispatch(updatePositions({ x: 0, y: 0 }));
                    dispatch(emptyOtherColors());
                    dispatch(setProductBackingPaddings({}));
                    dispatch(nextStep());
                  }}
                  // onDoubleClick={() => {
                  //   setSelectedSource(source.type);
                  //   dispatch(setProductSource(source.type));
                  //   dispatch(setProductFont([]));
                  //   dispatch(setProductBackingImage(null));
                  //   dispatch(setProductBacking("square"));
                  //   dispatch(setProductAccessoriesDimmer({}));
                  //   dispatch(addProductAccessoriesAddOns([]));
                  //   dispatch(updatePositions({ x: 0, y: 0 }));
                  //   dispatch(nextStep());
                  //   dispatch(emptyOtherColors());
                  //   dispatch(setProductBackingPaddings({}));
                  // }}
                >
                  <Card.Img
                    className="sourceImage"
                    style={{
                      width: "100%", // Set the width to 100% to ensure the entire image is visible
                      // height: "auto", // Allow the height to adjust based on the image's aspect ratio
                      borderRadius: "24px",
                      objectFit: "contain", // Use "contain" to fit the entire image within the container
                    }}
                    variant="top"
                    src={source?.image}
                    alt={source.type} // Provide alt text for accessibility
                  />
                  <Card.Body>
                    <Card.Title className="font-bold text-[#595959] ">
                      {source?.type}
                    </Card.Title>
                    <Card.Text
                      style={{
                        color: "#8F8F8F",
                        height: window.innerWidth > 768 ? "20vh" : "100%",
                        overflowY: "auto",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.75rem",
                        }}
                        className="custom-bullets"
                      >
                        {/* Use dangerouslySetInnerHTML to insert the HTML content */}
                        <span
                          dangerouslySetInnerHTML={{ __html: source?.desc }}
                        />
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <>
          <ThreeDots
            // height="100%"
            width="100%"
            radius="9"
            color="#36CDB4"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </>
      )}
    </div>
  );
};

export default SourceList;
