import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../../Assets/Close";

const SplitNavigation = ({ show, close }) => {
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "60%" : "90%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };
  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                width: "100%",
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
                textAlign: "center",
              }}
            >
              Split into Letters
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              fontFamily: "Beatrice",
              //   fontSize: "40?px",
              //   fontWeight: "700",
              width: "100%",
            }}
          >
            <Typography
              style={{
                color: "#BF6BE3",
              }}
            >
              NOTE: Sometimes this feature can impact the look of your piece in
              the preview window.
            </Typography>
            <Typography>
              This typically happens with long or vertically stacked text
              designs. If this happens to you, you can remove the split letter
              colors and tell us the colors you want to use in the notepad.
            </Typography>
          </div>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="continue-button-width-adjust"
              sx={{
                cursor: "pointer",
                color: "#ffffff",
                textAlign: "center",
                fontSize: "16px",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#36CDB4",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  color: "#36CDB4",
                  border: "1px solid #36CDB4",
                },
              }}
              onClick={() => close()}
            >
              OK, Got it!
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SplitNavigation;
