import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../../Assets/Close";

const InstallMap = ({ showMap, closeMap }) => {
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "50%" : "90%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  return (
    <Modal
      open={showMap}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "1rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            Installation Coverage Map
          </Typography>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={closeMap}
          >
            <Close />
          </div>
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              display: "block",
              border: "none",
              margin: "0px",
              padding: "0px",
            }}
          >
            <iframe
              title="map"
              src="https://www.google.com/maps/d/embed?mid=1H-PFX3IDmhKdkIG-_GpH7kdDyYSqPe8&ehbc=2E312F"
              width="100%"
              height="500"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InstallMap;
