// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media query for desktop screens */
/* .source-items {
    width: 100%;
    padding: "120px";
    
  }
  .source-item {
    width: 100%;
    padding: "20px";
  } */
/* Media query for smaller screens */
@media (min-width: 500px) and(max-width: 769px) {
  .source-items-backing {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .source-items {
    margin-bottom: 20px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .source-items-backing {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .source-items {
    margin-bottom: 20px;
    /* width: 87%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 769px) {


  .source-item {
    width: 70%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/styles/source.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;;;;;;;;KAQK;AACL,oCAAoC;AACpC;EACE;IACE,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;AACF;AACA;EACE;IACE,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;AACF;AACA;;;EAGE;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":["/* Media query for desktop screens */\n/* .source-items {\n    width: 100%;\n    padding: \"120px\";\n    \n  }\n  .source-item {\n    width: 100%;\n    padding: \"20px\";\n  } */\n/* Media query for smaller screens */\n@media (min-width: 500px) and(max-width: 769px) {\n  .source-items-backing {\n    margin-bottom: 20px;\n    /* width: 87%; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: auto;\n  }\n  .source-items {\n    margin-bottom: 20px;\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n}\n@media (max-width: 500px) {\n  .source-items-backing {\n    margin-bottom: 20px;\n    /* width: 87%; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: auto;\n  }\n  .source-items {\n    margin-bottom: 20px;\n    /* width: 87%; */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n}\n@media (max-width: 769px) {\n\n\n  .source-item {\n    width: 70%;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
