import React from "react";

const Eraser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0557 0.328125L25.6403 9.27558L20.0523 14.9416L14.0377 21.0263H26.0232V23.3255H6.26613L0.320312 17.2967L17.0557 0.328125ZM10.827 21.0263L17.6462 14.1276L12.1495 8.55431L3.52721 17.2967L7.20541 21.0263H10.827Z"
        fill="white"
      />
    </svg>
  );
};

export default Eraser;
