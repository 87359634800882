import React from "react";

const Sun = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C10.5523 0 11 0.447715 11 1V2C11 2.55228 10.5523 3 10 3C9.44772 3 9 2.55228 9 2V1C9 0.447715 9.44772 0 10 0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C10.5523 17 11 17.4477 11 18V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V18C9 17.4477 9.44772 17 10 17Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 9.44772 0.447715 9 1 9H2C2.55228 9 3 9.44772 3 10C3 10.5523 2.55228 11 2 11H1C0.447715 11 0 10.5523 0 10Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10C17 9.44772 17.4477 9 18 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H18C17.4477 11 17 10.5523 17 10Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0711 2.92888C17.4616 3.3194 17.4616 3.95257 17.0711 4.34309L16.3641 5.05009C15.9736 5.44062 15.3404 5.44062 14.9499 5.05009C14.5594 4.65957 14.5594 4.0264 14.9499 3.63588L15.6569 2.92888C16.0474 2.53836 16.6806 2.53836 17.0711 2.92888Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05009 14.9499C5.44062 15.3404 5.44062 15.9736 5.05009 16.3641L4.34309 17.0711C3.95257 17.4616 3.3194 17.4616 2.92888 17.0711C2.53836 16.6806 2.53836 16.0474 2.92888 15.6569L3.63588 14.9499C4.0264 14.5594 4.65957 14.5594 5.05009 14.9499Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92888 2.92888C3.3194 2.53836 3.95257 2.53836 4.34309 2.92888L5.05009 3.63588C5.44062 4.0264 5.44062 4.65957 5.05009 5.05009C4.65957 5.44062 4.0264 5.44062 3.63588 5.05009L2.92888 4.34309C2.53836 3.95257 2.53836 3.3194 2.92888 2.92888Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9499 14.9499C15.3404 14.5594 15.9736 14.5594 16.3641 14.9499L17.0711 15.6569C17.4616 16.0474 17.4616 16.6806 17.0711 17.0711C16.6806 17.4616 16.0474 17.4616 15.6569 17.0711L14.9499 16.3641C14.5594 15.9736 14.5594 15.3404 14.9499 14.9499Z"
        fill={color}
      />
    </svg>
  );
};

export default Sun;
