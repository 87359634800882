// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 440px) {
    .bottom-buttons {
      margin-bottom: 10px;
      background-color: "#afe9e0" ;
      height: 50px;
      width: 180px;
font-family:"Beatrice" ;
justify-content: space-between;
margin-top: 15px;
    }
  .bottom-example-buttons {
width: 180px;
color: #25CCB0;
font-size: 16px;
font-family: "Beatrice";
font-weight: 600;
text-transform: uppercase;
letter-spacing: 2px;
background-color: "#D8F4F1";
border-radius: 16px;
padding-right: 20px;
padding-left: 20px;
}
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperCustom/SuperDelivery/styles/SuperBottomControls.css"],"names":[],"mappings":"AAAA;IACI;MACE,mBAAmB;MAEnB,4BAA4B;MAC5B,YAAY;MACZ,YAAY;AAClB,uBAAuB;AACvB,8BAA8B;AAC9B,gBAAgB;IACZ;EACF;AACF,YAAY;AACZ,cAAc;AACd,eAAe;AACf,uBAAuB;AACvB,gBAAgB;AAChB,yBAAyB;AACzB,mBAAmB;AACnB,2BAA2B;AAC3B,mBAAmB;AACnB,mBAAmB;AACnB,kBAAkB;AAClB;AACA","sourcesContent":["@media (max-width: 440px) {\n    .bottom-buttons {\n      margin-bottom: 10px;\n      height: 80px;\n      background-color: \"#afe9e0\" ;\n      height: 50px;\n      width: 180px;\nfont-family:\"Beatrice\" ;\njustify-content: space-between;\nmargin-top: 15px;\n    }\n  .bottom-example-buttons {\nwidth: 180px;\ncolor: #25CCB0;\nfont-size: 16px;\nfont-family: \"Beatrice\";\nfont-weight: 600;\ntext-transform: uppercase;\nletter-spacing: 2px;\nbackground-color: \"#D8F4F1\";\nborder-radius: 16px;\npadding-right: 20px;\npadding-left: 20px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
