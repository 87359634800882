import React from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrice,
  setProductFont,
  setProductSizeHeight,
  setProductSizeWidth,
  updatePositions,
} from "../Redux/Slice";
import ContentEditable from "react-contenteditable";

const TextBoxes = ({
  setTextAreas,
  handleTextAreaChange,
  handleDrop,
  handleTextAreaDragStart,
  textAreas,
  updateTextareaHeight,
  setSelectedTextAreaId,
  selectedTextAreaId,
}) => {
  const dispatch = useDispatch();
  // const [positions, setPositions] = useState({});
  const width = window.innerWidth;

  const theme = useSelector((state) => state.activeStep.theme);
  const positions = useSelector((state) => state.activeStep.positions);
  const product = useSelector((state) => state.activeStep.product);

  const handleDragDrop = (e, data, id) => {
    const initialPosition = positions[id] || { x: 0, y: 0 };
    const newX = initialPosition.x + data.deltaX;
    const newY = initialPosition.y + data.deltaY;

    const updatedPositions = {
      ...positions,
      [id]: { x: newX, y: newY },
    };
    dispatch(updatePositions(updatedPositions));
  };

  const setTextAreaEmpty = (id, event) => {
    textAreas.forEach((textArea) => {
      if (textArea.id === id && textArea.value === "Type Something") {
        dispatch(addPrice(0));
        dispatch(setProductSizeWidth(0));
        dispatch(setProductSizeHeight(0));
        const updatedTextAreas = textAreas.map((textarea) => {
          if (textarea.id === id) {
            // console.log(textarea.value);
            const inputHtml = "";

            return {
              ...textarea,
              value: "",
              html: inputHtml,
            };
          }
          return textarea;
        });
        setTextAreas(updatedTextAreas);
        dispatch(setProductFont(updatedTextAreas));
      }
    });
  };

  return (
    <div
      style={{
        backgroundColor: `${theme}`,
        display: "flex",
        flexDirection: `${product.fontsAlignment}`,
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        width: "100%",
        position: "relative",
        overflowY: "auto",
      }}
    >
      {textAreas.map((textArea, index) => (
        <Draggable
          bounds="parent"
          key={index}
          disabled={width > 990 ? false : true}
          defaultPosition={positions[textArea?.id] || { x: 0, y: 0 }}
          onDrag={(e, data) => handleDragDrop(e, data, textArea?.id)}
          onStop={(e, data) => handleDragDrop(e, data, textArea?.id)}
        >
          <div
            onClick={(event) => {
              setSelectedTextAreaId(textArea.id);
              setTextAreaEmpty(textArea.id, event);
            }}
            onDragStart={(e) => handleTextAreaDragStart(e, textArea)}
            onDrop={(e) => handleDrop(e, textArea?.id)}
            style={{
              display: "flex",
              flexDirection: "row",
              color: `${textArea.color}`,
              textShadow:
                textArea.fontFamily === "Dylan" ||
                textArea.fontFamily === "Spencer"
                  ? ""
                  : `1px 2px 10px ${textArea.color}, 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`,
            }}
          >
            <ContentEditable
              id={textArea.id}
              className={
                textArea.fontFamily === "Dylan" ||
                textArea.fontFamily === "Spencer"
                  ? "Dylan-Spencer"
                  : ""
              }
              style={{
                backgroundColor: "transparent",

                fontFamily: `${textArea.fontFamily}`,
                outline: "none",
                fontSize: `${textArea.fontSize}px`,

                cursor: "pointer",
                textAlign: `${textArea.horizontalAlign}`,
                zIndex: 99999,
                maxWidth: "1000px",
                border: `1px solid ${
                  selectedTextAreaId === textArea.id ? "#4EBFFF" : "#AFE9E0"
                }`,
              }}
              html={textArea.html}
              onChange={(event) => {
                handleTextAreaChange(textArea.id, event);
                updateTextareaHeight(index);
              }}
              onTouchStart={(event) => {
                document.getElementById(textArea.id).focus();
                setTextAreaEmpty(textArea.id, event);
                setSelectedTextAreaId(textArea.id);
              }}
              onClick={() => {
                setSelectedTextAreaId(textArea.id);
              }}
            />
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default TextBoxes;
