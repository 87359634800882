import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import RushDetails from "./SuperRush/RushDetails";
import ShippingAddress from "./SuperRush/ShippingAddress";
import RushPopUp from "./SuperRush/RushPopup";
import axios from "axios";

const SuperRush = ({ setSelected }) => {
  const product = useSelector((state) => state.activeStep.product);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const [dateAdded, setDateAdded] = useState(details?.mustDate ? true : false);
  const initialMustDate = details?.mustDate
    ? new Date(details.mustDate)
    : new Date();

  function isValidDate(date) {
    // Check if the date is a valid Date object
    return date instanceof Date && !isNaN(date);
  }
  const [mustDate, setMustDate] = useState(
    isValidDate(initialMustDate) ? initialMustDate : new Date()
  );
  const [minimumProductionDays, setMinimumProductionDays] = useState(0);
  const [maximumProductionDays, setMaximumProductionDays] = useState(0);
  // const [mustProductionDays, setMustProductionDays] = useState(0);

  const [showRush, setShowRush] = useState(true);

  // const [productionDays, setProductionDays] = useState(0);

  const dateString = new Date(mustDate).toDateString();
  // const [finalTime, setFinalTime] = useState("");
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  // const currentDate = new Date();
  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/rush/${product.source}/${product.backing.name}`
      );

      if (response?.data[0]) {
        // const maximumEstimatedTime = response?.data[0]?.maximumEstimatedTime;
        // const minimumEstimatedTime = response?.data[0]?.minimumEstimatedTime;
        setMinimumProductionDays(response?.data[0]?.minimumEstimatedTime);
        setMaximumProductionDays(response?.data[0]?.maximumEstimatedTime);
        // setProductionDays(maximumEstimatedTime);

        // const maxDate = new Date(currentDate);
        // const minDate = new Date(currentDate);
        // maxDate.setDate(maxDate.getDate() + maximumEstimatedTime);
        // minDate.setDate(minDate.getDate() + minimumEstimatedTime);

        // const newYear = maxDate.getFullYear();
        // const maxMonth = months[maxDate.getMonth()];
        // const minMonth = months[minDate.getMonth()];
        // const maxDay = maxDate.getDate();
        // const minDay = minDate.getDate();
        // setFinalTime(
        //   `${minMonth} ${minDay} - ${maxMonth} ${maxDay}, ${newYear}`
        // );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductionDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [finalTime, setFinalTime] = useState(
  //   `${month} ${day + Number(productionDays)}, ${year}`
  // );
  // const getProductionDays = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}delivery/rush/${product.source}/${product.backing.name}`
  //     );
  //     console.log(response.data[0].maximumEstimatedTime);
  //     setProductionDays(response.data[0].maximumEstimatedTime);
  //     setFinalTime(
  //       `${month} ${
  //         day + Number(response.data[0].maximumEstimatedTime)
  //       }, ${year}`
  //     );
  //     console.log();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   setMustProductionDays(addDays(new Date(mustDate), minimumProductionDays));
  // }, [mustDate]);
  // console.log(dateString);

  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#07AB90",
          padding: "20px", // Add padding for better spacing
        }}
      >
        <Typography
          style={{
            color: "#D42678",
            fontSize: "21px",
            fontFamily: "Beatrice",
            maxWidth: "90%", // Use percentage for responsiveness
            fontWeight: "600",
          }}
        >
          We get it, sometimes due dates sneak up on you fast!
        </Typography>
        <Typography
          style={{
            color: "#D42678",
            fontSize: "21px",
            fontFamily: "Beatrice",
            maxWidth: "70%", // Use percentage for responsiveness
            fontWeight: "400",
            marginTop: "10px", // Add margin for spacing
          }}
        >
          Tell us your due date below and more about your needs. We’ll circle
          back ASAP with news of feasibility and a quote.
        </Typography>
        <Typography
          hidden={dateAdded ? false : true}
          style={{
            fontSize: "52px",
            color: "#D42678",
            fontFamily: "Beatrice",
            marginTop: "20px", // Add margin for spacing
          }}
        >
          Order by: {dateString} + ({minimumProductionDays} -{" "}
          {maximumProductionDays} Business Days)
        </Typography>
      </div>

      <RushDetails
        mustDate={mustDate}
        dateAdded={dateAdded}
        setDateAdded={setDateAdded}
        setMustDate={setMustDate}
      />
      <ShippingAddress />
      <RushPopUp
        setSelected={setSelected}
        show={showRush}
        close={() => setShowRush(false)}
      />
    </>
  );
};

export default SuperRush;
