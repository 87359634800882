import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import image from "../../Assets/ChooseFont.png";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import UploadImage from "../../Components/SuperCustom/SuperContent/UploadImage";
import Hand from "../../Assets/SuperCustom/Hand";
import ColorPallette from "../../Assets/SuperCustom/ColorPallette";
import Eraser from "../../Assets/SuperCustom/Eraser";
import Undo from "../../Assets/SuperCustom/Undo";
import Redo from "../../Assets/SuperCustom/Redo";
import ChooseFonts from "../../Components/SuperCustom/SuperContent/ChooseFonts";
import Info from "../../Assets/SuperCustom/Info";
import Pencil from "../../Assets/SuperCustom/Pencil";
import Frame from "../../Assets/SuperCustom/Frame.png";
import Clear from "../../Assets/Clear";
import {
  addOtherFonts,
  removeOtherFonts,
  setProductFont,
  setProductFontDescription,
  setProductRefImageLink,
  setProductRefImageName,
  setProductRefImageSize,
  updatePositions,
} from "../../Redux/Slice";
import Draggable from "react-draggable";
// import Save from "../../Assets/SuperCustom/Save";
import { ReactSketchCanvas } from "react-sketch-canvas";
import Close from "../../Assets/Close";
import SuperBottomControls from "../../Components/SuperCustom/SuperBottomControls";
import axios from "axios";
import "./styles/SuperContent.css";
import ContentEditable from "react-contenteditable";
import ChooseStrokeColors from "../../Components/SuperCustom/SuperColor/ChooseStrokeColor";
import Upload from "../../Assets/Upload";
import ExampleModal from "../../Components/ExampleModal";
import DescriptionButton from "../../Components/DescriptionButton";
import HeadingInfo from "../../Components/SuperCustom/HeadingInfo";

const SuperContent = () => {
  const fontSize = [
    { name: "Avery", desktopSize: "37", mobileSize: "21.1", type: "Glass" },
    { name: "Carter", desktopSize: "36", mobileSize: "20.6", type: "Glass" },
    { name: "Goldie", desktopSize: "95", mobileSize: "54.3", type: "Glass" },
    { name: "Wylie", desktopSize: "38", mobileSize: "21.7", type: "Glass" },
    { name: "Cleo", desktopSize: "50", mobileSize: "28.6", type: "Glass" },
    { name: "Charlie", desktopSize: "30", mobileSize: "17.1", type: "Glass" },
    { name: "Izzy", desktopSize: "55", mobileSize: "31.4", type: "Glass" },
    { name: "Dylan", desktopSize: "55", mobileSize: "31.4", type: "Glass" },
    { name: "Kyle", desktopSize: "40", mobileSize: "22.9", type: "Glass" },
    { name: "Logan", desktopSize: "28", mobileSize: "16", type: "Glass" },
    { name: "James", desktopSize: "33", mobileSize: "18.9", type: "Glass" },
    { name: "Hunter", desktopSize: "48", mobileSize: "27.4", type: "Glass" },
    { name: "Quinn", desktopSize: "42", mobileSize: "24", type: "Glass" },
    { name: "Micah", desktopSize: "90", mobileSize: "51.4", type: "Glass" },
    { name: "Parker", desktopSize: "40", mobileSize: "22.9", type: "Glass" },
    { name: "Lola", desktopSize: "75", mobileSize: "42.9", type: "Glass" },
    { name: "Spencer", desktopSize: "70", mobileSize: "40", type: "Glass" },
    { name: "Avery", desktopSize: "35", mobileSize: "20.0", type: "LED" },
    { name: "Carter", desktopSize: "38", mobileSize: "21.7", type: "LED" },
    { name: "Goldie", desktopSize: "95", mobileSize: "54.3", type: "LED" },
    { name: "Wylie", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Cleo", desktopSize: "77", mobileSize: "44", type: "LED" },
    { name: "Charlie", desktopSize: "34", mobileSize: "19.4", type: "LED" },
    { name: "Izzy", desktopSize: "58", mobileSize: "33.1", type: "LED" },
    { name: "Dylan", desktopSize: "55", mobileSize: "31.4", type: "LED" },
    { name: "Kyle", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Logan", desktopSize: "28", mobileSize: "16", type: "LED" },
    { name: "James", desktopSize: "37", mobileSize: "21.1", type: "LED" },
    { name: "Hunter", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Quinn", desktopSize: "47", mobileSize: "26.9", type: "LED" },
    { name: "Micah", desktopSize: "105", mobileSize: "60", type: "LED" },
    { name: "Parker", desktopSize: "45", mobileSize: "25.7", type: "LED" },
    { name: "Lola", desktopSize: "78", mobileSize: "44.6", type: "LED" },
    { name: "Spencer", desktopSize: "90", mobileSize: "51.4", type: "LED" },
  ];
  const fontDesc = useSelector(
    (state) => state.activeStep.product.fontsDescription
  );
  const otherColors = useSelector((state) => state.activeStep.otherColors);

  function getFontSize(fontFamily, type) {
    // console.log(fontFamily, type);
    const fontSizeEntry = fontSize.find(
      (font) => font.name === fontFamily && font.type === type
    );
    // console.log(fontSizeEntry);
    if (window.innerWidth > 762) {
      return fontSizeEntry.desktopSize;
    } else {
      return fontSizeEntry.mobileSize;
    }
  }

  const [openUploadInfo, setOpenUploadInfo] = useState(false);
  const [openFontInfo, setOpenFontInfo] = useState(false);
  const [openSketchInfo, setOpenSketchInfo] = useState(false);

  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const desc = fontDesc ? fontDesc : "";

  const otherFonts = useSelector((state) => state.activeStep.otherFonts);
  const product = useSelector((state) => state.activeStep.product);
  const theme = useSelector((state) => state.activeStep.theme);
  const positions = useSelector((state) => state.activeStep.positions);

  const [textAreas, setTextAreas] = useState(
    product.fonts.length > 0
      ? product.fonts
      : otherFonts.map((font, index) => ({
          id: index,
          fontFamily: "",
          jsonLink: "",
          woffFile: "",
          fontSize,
          verticalAlign: "middle",
          horizontalAlign: "center",
          tracking: "",
          value: "Type Something",
          isSplit: false,
          html: "Type Something",
          color: "#36CDB4",
        }))
  );

  const handleTextAreaChange = (id, event) => {
    // console.log(event.target.value);
    const updatedTextAreas = textAreas.map((textarea) => {
      if (textarea.id === id) {
        // console.log(textarea.id);
        const inputHtml = event.target.value;

        let plainText = inputHtml
          .replace(/<div style="font-family: inherit;">/g, "\n")
          .replace(/<[^>]*>/g, "")
          // .replace(/<\/div>/g, "") // Remove HTML tags
          .replace(/&nbsp;/g, " "); // Replace &nbsp; with a space

        plainText = plainText
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&");

        // Convert HTML to plain text
        // const parser = new DOMParser();
        // const doc = parser.parseFromString(inputHtml, "text/html");
        // const plainText = doc.body.textContent;
        // console.log(plainText);

        // Replace <div> elements with newlines
        // const replacedText = inputHtml
        //   .replace(/<div style="font-family: inherit;">/g, "\n")
        //   .replace(/<\/div>/g, "")
        //   .replace(/&nbsp;/g, " ");
        const replacedHtml = inputHtml
          .replace(/<span[^>]*>(.*?)<\/span>/g, "$1")
          .replace(/<div>/g, '<div style="font-family: inherit;">');

        // console.log(replacedText);
        // console.log(replacedHtml);
        const value = plainText;
        const lines = value?.split("\n");
        const longestLine = lines?.reduce(
          (acc, line) => (line?.length > acc ? line?.length : acc),
          0
        );
        const newWidth = (longestLine + 1) * 30;
        if (textarea?.isSplit) {
          // console.log("split handle");
          // Create the new letters array based on the new text
          const newLetters = value?.split("").map((letter, index) => {
            const existingLetter = textarea?.letters?.find(
              (l) => l.id === index
            );
            const color = existingLetter
              ? existingLetter?.color
              : textarea.color;
            return { id: index, letter, color };
          });
          // console.log(newLetters);

          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
            letters: newLetters,
          };
        } else {
          return {
            ...textarea,
            value,
            html: replacedHtml,
            width: newWidth,
          };
        }
      }
      return textarea;
    });
    // console.log(updatedTextAreas);
    setTextAreas(updatedTextAreas);
    dispatch(setProductFont(updatedTextAreas));
  };

  const setTextAreaEmpty = (id) => {
    textAreas.forEach((textArea) => {
      if (textArea.id === id && textArea.value === "Type Something") {
        const updatedTextAreas = textAreas.map((textarea) => {
          if (textarea.id === id) {
            // console.log(textarea.value);
            const inputHtml = "";

            return {
              ...textarea,
              value: "",
              html: inputHtml,
            };
          }
          return textarea;
        });
        // console.log(updatedTextAreas);
        setTextAreas(updatedTextAreas);
        dispatch(setProductFont(updatedTextAreas));
      }
    });
  };

  const handleDragDrop = (e, data, id) => {
    const initialPosition = positions[id] || { x: 0, y: 0 };
    const newX = initialPosition.x + data.deltaX;
    const newY = initialPosition.y + data.deltaY;

    const updatedPositions = {
      ...positions,
      [id]: { x: newX, y: newY },
    };
    dispatch(updatePositions(updatedPositions));
  };

  const [showImage, setShowImage] = useState(false);
  const [showFonts, setShowFonts] = useState(false);
  const [sketch, setSketch] = useState(false);
  const [draggable, setDraggable] = useState(false);
  const [showStrokeColors, setStrokeShowColors] = useState(false);
  const [warningOne, setWarningOne] = useState({});
  const refImage = useSelector((state) => state.activeStep.product.refImage);

  const dispatch = useDispatch();

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Super Content/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFontClick = (font, index) => {
    // console.log(index);
    if (otherFonts?.includes(font)) {
      dispatch(removeOtherFonts(font));
      const updatedTextAreas = textAreas.filter(
        (textArea) => textArea.id !== index
      );
      // console.log(updatedTextAreas);
      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas));
    } else {
      let updatedFont = {
        ...font,
        id: otherFonts.length,
      };
      dispatch(addOtherFonts(updatedFont));
    }
  };

  const handleFileSelect = async (e) => {
    let image = e.target.files[0];
    // console.log(image);
    const form = new FormData();
    form.append("image", image);
    // console.log(form.get("image"));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}led-backing`,
        form
      );
      // console.log(response.data);
      dispatch(setProductRefImageLink(response.data));
      dispatch(setProductRefImageSize(image?.size));
      dispatch(setProductRefImageName(image?.name));
    } catch (error) {
      console.log(error);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  const [savedDrawingData, setSavedDrawingData] = useState(
    localStorage.getItem("savedDrawing")
      ? JSON.parse(localStorage.getItem("savedDrawing"))
      : ""
  );

  const activeCanvasRef = useRef(null); // Ref for the active drawing canvas
  const disableCanvasRef = useRef(null); // Ref for the active drawing canvas
  const [brushColor, setBrushColor] = useState("#36CDB4");
  const [isErasing, setIsErasing] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);

  const handleUndo = () => {
    activeCanvasRef.current.eraseMode(false);
    setIsErasing(false);
    activeCanvasRef.current.undo();
  };
  const handleRedo = () => {
    activeCanvasRef.current.eraseMode(false);
    setIsErasing(false);
    activeCanvasRef.current.redo();
  };

  const handleClear = () => {
    activeCanvasRef.current.eraseMode(false);
    setIsErasing(false);
    activeCanvasRef.current.resetCanvas();
    // localStorage.setItem("savedDrawing", []);
  };

  const autoSaveDrawing = () => {
    activeCanvasRef.current
      .exportPaths()
      .then((data) => {
        // console.log(data);
        setSavedDrawingData(data);
        localStorage.setItem("savedDrawing", JSON.stringify(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (sketch) {
      const interval = setInterval(() => {
        autoSaveDrawing();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [sketch]);

  useEffect(() => {
    if (savedDrawingData.length && disableCanvasRef.current) {
      // console.log("fetching drawing");
      // console.log(savedDrawingData);
      disableCanvasRef.current.loadPaths(savedDrawingData);
    }
  }, [savedDrawingData, sketch]);

  useEffect(() => {
    if (textAreas.every((textArea) => textArea.value === "Type Something")) {
      const updatedTextAreas = otherFonts.map((font, index) => ({
        id: index,
        fontFamily: font.name,
        jsonLink: font.jsonFile,
        woffFile: font.woffFile,
        fontSize: getFontSize(font.name, product.source),
        verticalAlign: "middle",
        horizontalAlign: "center",
        tracking: font.tracking,
        value: "Type Something",
        isSplit: false,
        html: "Type Something",
        color: "#36CDB4",
      }));

      // console.log("updated one", updatedTextAreas);
      setTextAreas(updatedTextAreas);
      dispatch(setProductFont(updatedTextAreas)); // You can dispatch the updated textAreas here
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherFonts]);

  useEffect(() => {
    fetchWarnings(product.source, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row
        style={{
          border: "2px solid #AFE9E0",
          borderRadius: "24px",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <Col lg={8} md={8} sm={12} xs={12}>
          <Typography
            style={{
              fontFamily: "Beatrice-Display",
              color: "#25CCB0",
              fontSize: window.innerWidth > 768 ? "40px" : "2rem",
              fontWeight: "700",
              fontStyle: "italic",
              letterSpacing: "0.05em",
            }}
          >
            Submit your Inspo
          </Typography>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <div
            style={{
              height: "50px",
              display: warningOne?.warningStatus ? "" : "none",
              zIndex: 9,
              marginTop: window.innerWidth > 761 ? "" : "50px",
              marginBottom: window.innerWidth > 761 ? "" : "50px",
            }}
            className="warning"
          >
            <Typography className="warning-title">
              {warningOne?.title}
            </Typography>
            <div
              className="enable-listing-style warning-desc"
              dangerouslySetInnerHTML={{
                __html: warningOne?.warningContent,
              }}
            ></div>
          </div>
        </Col>

        <Row>
          <Typography
            style={{
              fontFamily: "Beatrice",
              color: "#25CCB0",
              textAlign: "justify",
              //   fontSize: "40?px",
              //   fontWeight: "700",
              width: window.innerWidth > 768 ? "60%" : "100%",
            }}
          >
            We are excited to create some neon magic. As artists this is our
            favorite part of the process. We will use all your files and design
            assets as inspiration to bring your neon dreams to reality.
          </Typography>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            display: "flex",
          }}
        >
          <Col xs={12} md={4}>
            <div style={{ margin: "10px" }}>
              <HeadingInfo
                open={openUploadInfo}
                setOpen={setOpenUploadInfo}
                heading={"Upload an image of your neon idea (preferred)"}
                text={"Only 1 image can be uploaded per design."}
              />

              <div
                style={{
                  height: "100px",
                  width: "100%",
                  background: "white",
                  borderRadius: "16px",
                  paddingBottom: "15px",
                  border: "2px dashed #595959 ",
                  padding: "15px",
                  cursor: "pointer",
                }}
                className={refImage?.link ? "selected" : " hover-effect"}
                onClick={() => setShowImage(true)}
              >
                <input
                  id="fileInput"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileSelect(e)}
                />
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    paddingBottom: "15px",
                  }}
                >
                  <Upload />
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontWeight: "600",
                      color: "#595959",
                      fontSize: "1.2rem",
                      marginLeft: "5px",
                    }}
                  >
                    Upload image{" "}
                    <span
                      style={{
                        fontSize: "0.7rem",
                        fontFamily: "Beatrice",
                        fontWeight: "400",
                        color: "#595959",
                      }}
                    >
                      (png,jpg)
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div
              style={{
                margin: "10px",
              }}
            >
              <HeadingInfo
                open={openFontInfo}
                setOpen={setOpenFontInfo}
                optional={true}
                heading={"Write Something"}
                text={
                  "Choose upto 3 fonts. For more information, kindly check examples."
                }
              />
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  backgroundColor: "#F9F9F9",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className={otherFonts.length > 0 ? "selected" : " hover-effect"}
                onClick={() => setShowFonts(true)}
              >
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <img src={image} alt="" />
                </div>
                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    fontSize: "16px",
                    margin: "5px",
                    marginTop: "10px",
                  }}
                >
                  Add or Select a Font
                </Typography>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} style={{}}>
            <div
              style={{
                margin: "10px",
              }}
            >
              <HeadingInfo
                open={openSketchInfo}
                setOpen={setOpenSketchInfo}
                optional={true}
                heading={"Draw and Collage your design"}
                text={
                  "You can draw your own design using the pen tool and move the text around with the hand tool. Colors for text can be assigned in the following page."
                }
              />
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  backgroundColor: "#F9F9F9",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className={
                  savedDrawingData.length > 0 ? "selected" : " hover-effect"
                }
                onClick={() => setSketch(!sketch)}
              >
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    backgroundColor: "#36CDB4",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <Pencil />
                </div>

                <Typography
                  style={{
                    fontFamily: "Beatrice",
                    fontSize: "16px",
                    margin: "5px",
                    marginTop: "10px",
                  }}
                >
                  Sketch your neon lines
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        <Col
          className="main-drawing-container"
          style={{
            backgroundColor: `${theme}`,
            border: "2px solid #AFE9E0",
            borderRadius: "24px",
            padding: "20px",
            // height: "80vh",
          }}
          lg={9}
          md={9}
          xm={12}
          xs={12}
        >
          <Row>
            <Col lg={6} md={12} xm={12} xs={12}>
              <Typography
                style={{
                  fontFamily: "Beatrice-Display",
                  color: "#25CCB0",
                  fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                  fontWeight: "700",
                  fontStyle: "italic",
                  letterSpacing: "0.05em",
                  width: "100%",
                }}
              >
                Draw your Design
              </Typography>
            </Col>
            <Col lg={6} md={12} xm={12} xs={12}>
              {sketch ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      className={isDrawing ? "selected" : " hover-effect"}
                      style={{
                        height: "45px",
                        width: "45px",
                        backgroundColor: "#36CDB4",
                        borderRadius: "12px",
                        padding: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        activeCanvasRef.current.eraseMode(false);
                        setIsErasing(false);
                        setIsDrawing(!isDrawing);
                      }}
                    >
                      <Pencil />
                    </button>

                    <button
                      style={{
                        height: "45px",
                        width: "45px",
                        backgroundColor: "#36CDB4",
                        borderRadius: "12px",
                        padding: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className={
                        showStrokeColors ? "selected" : " hover-effect"
                      }
                      onClick={() => setStrokeShowColors(true)}
                    >
                      <ColorPallette />
                    </button>
                    <button
                      className={isErasing ? "selected" : " hover-effect"}
                      style={{
                        height: "45px",
                        width: "45px",
                        backgroundColor: "#36CDB4",
                        borderRadius: "12px",
                        padding: "8px",
                      }}
                      onClick={() => {
                        activeCanvasRef.current.eraseMode(!isErasing);
                        setIsErasing(!isErasing);
                        setIsDrawing(false);
                      }}
                    >
                      <Eraser />
                    </button>
                    <button
                      style={{
                        height: "45px",
                        width: "45px",
                        border: "2px solid #36CDB4",
                        borderRadius: "12px",
                        padding: "8px",
                      }}
                      className={" hover-effect"}
                      onClick={handleUndo}
                    >
                      <Undo />
                    </button>

                    <button
                      style={{
                        height: "45px",
                        width: "45px",
                        border: "2px solid #36CDB4",
                        borderRadius: "12px",
                        padding: "8px",
                      }}
                      className={" hover-effect"}
                      onClick={handleRedo}
                    >
                      <Redo />
                    </button>
                    <button
                      style={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "20px",
                      }}
                      className={" hover-effect"}
                      onClick={handleClear}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} lg={11} md={12} className="tablet-padding">
              <div
                style={{
                  //   height: "100%",
                  margin: "30px",
                  position: "relative",
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {otherFonts.length > 0 ||
                refImage?.link ||
                sketch ||
                savedDrawingData.length > 0 ? (
                  <>
                    {refImage?.link || sketch || savedDrawingData.length > 0 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          zIndex: 1,
                        }}
                      >
                        <ReactSketchCanvas
                          ref={sketch ? activeCanvasRef : disableCanvasRef}
                          width="100%"
                          height="450px"
                          backgroundImage={refImage.link}
                          preserveBackgroundImageAspectRatio="1"
                          strokeWidth={sketch && isDrawing ? 10 : 0}
                          eraserWidth={30}
                          strokeColor={brushColor}
                          style={{
                            zIndex: 1,
                            border: "none",
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {otherFonts.length > 0 ? (
                      <>
                        <>
                          {textAreas.map((textArea, index) => (
                            <Draggable
                              disabled={!draggable}
                              bounds="parent"
                              key={index}
                              defaultPosition={
                                positions[textArea?.id] || { x: 0, y: 0 }
                              }
                              onDrag={(e, data) =>
                                handleDragDrop(e, data, textArea?.id)
                              }
                              onStop={(e, data) =>
                                handleDragDrop(e, data, textArea?.id)
                              }
                            >
                              <div
                                onClick={(event) => {
                                  setTextAreaEmpty(textArea.id, event);
                                }}
                                style={{
                                  display: "flex",
                                  zIndex: 3,
                                  flexDirection: "row",
                                  color: `${textArea.color}`,
                                  textShadow:
                                    textArea.fontFamily === "Dylan" ||
                                    textArea.fontFamily === "Spencer"
                                      ? ""
                                      : !textArea.isSplit
                                      ? `1px 2px 10px ${textArea.color}, 0 0 50px ${textArea.color}, 0 0 50px ${textArea.color}`
                                      : "",
                                }}
                              >
                                <ContentEditable
                                  className={
                                    textArea.fontFamily === "Dylan" ||
                                    textArea.fontFamily === "Spencer"
                                      ? "Dylan-Spencer"
                                      : ""
                                  }
                                  style={{
                                    backgroundColor: "transparent",
                                    fontFamily: `${textArea.fontFamily}`,
                                    outline: "none",
                                    fontSize: `${textArea.fontSize}px`,
                                    cursor: "pointer",
                                    textAlign: `${textArea.horizontalAlign}`,
                                    maxWidth: "1000px",
                                    border: `1px solid #AFE9E0`,
                                  }}
                                  // content={textArea.value}
                                  html={textArea.html}
                                  onChange={(event) => {
                                    handleTextAreaChange(textArea.id, event);
                                  }}
                                />
                              </div>
                            </Draggable>
                          ))}
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <img
                    style={{
                      width: "400px",
                      padding: "10px",
                    }}
                    src={Frame}
                    onError={(event) => (event.target.src = "")}
                    alt="noImage"
                  />
                )}
                {/* </>
                )} */}
              </div>
            </Col>
            <Col lg={1} xs={0} sm={0} className="desktop-div">
              <button
                style={{
                  height: "65px",
                  width: "65px",
                  backgroundColor: "#36CDB4",
                  borderRadius: "10000px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={draggable ? "selected" : " hover-effect"}
                onClick={() => setDraggable(!draggable)}
              >
                <Hand />
              </button>
              <DescriptionButton
                value={desc}
                setValue={setProductFontDescription}
              />
              <button
                onClick={(event) => {
                  // Clear local storage
                  localStorage.clear();

                  // You can also add additional code to clear other browser data like cookies, cache, etc.
                  // Example for clearing cookies:
                  // document.cookie = '';

                  // Change the button text to "Clearing..."
                  event.target.innerText = "Clearing...";

                  // Add a class to the button to enable scrolling animation
                  event.target.classList.add("scrolling");

                  // Remove the class and change the button text back to "Clear Selection" after the delay
                  setTimeout(() => {
                    // Remove the scrolling class
                    event.target.classList.remove("scrolling");

                    // Change the button text back to "Clear Selection"
                    event.target.innerText = "Clear Selection";

                    // Refresh the page after clearing
                    window.location.reload();
                  }, 2000); // Adjust the delay time as needed
                }}
              >
                <Clear />
              </button>
            </Col>
            <div xs={1} className="mobile-tablet-div">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    height: "65px",
                    width: "65px",
                    backgroundColor: "#36CDB4",
                    borderRadius: "10000px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={draggable ? "selected" : " hover-effect"}
                  onClick={() => setDraggable(!draggable)}
                >
                  <Hand />
                </button>
                <DescriptionButton
                  value={desc}
                  setValue={setProductFontDescription}
                />
                <button
                  onClick={(event) => {
                    // Clear local storage
                    localStorage.clear();

                    // You can also add additional code to clear other browser data like cookies, cache, etc.
                    // Example for clearing cookies:
                    // document.cookie = '';

                    // Change the button text to "Clearing..."
                    event.target.innerText = "Clearing...";

                    // Add a class to the button to enable scrolling animation
                    event.target.classList.add("scrolling");

                    // Remove the class and change the button text back to "Clear Selection" after the delay
                    setTimeout(() => {
                      // Remove the scrolling class
                      event.target.classList.remove("scrolling");

                      // Change the button text back to "Clear Selection"
                      event.target.innerText = "Clear Selection";

                      // Refresh the page after clearing
                      window.location.reload();
                    }, 2000); // Adjust the delay time as needed
                  }}
                >
                  <Clear />
                </button>
              </div>
            </div>
          </Row>
          <SuperBottomControls />
        </Col>
        <Col lg={3} md={3} xm={12} xs={12} className="mobile-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Uploaded Image
            </Typography>
          </div>
          {refImage?.link ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  height: "100px",
                  border: "2px solid #AFE9E0",
                  borderRadius: "24px",
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "10px",
                  }}
                  src={refImage.link}
                  onError={(event) => (event.target.src = "")}
                  alt="noImage"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: "18px",
                      fontWeight: "600",
                      height: "50px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      wordBreak: "break-all",
                    }}
                  >
                    {refImage?.name}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: "12px",
                      color: "#03705E",
                    }}
                  >
                    {(refImage?.size / 1024).toFixed(2)} KB
                  </Typography>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setProductRefImageLink(null));
                    dispatch(setProductRefImageName(null));
                    dispatch(setProductRefImageSize(null));
                  }}
                >
                  <Close />
                </div>
              </div>
            </>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No image Uploaded
              </Typography>
            </>
          )}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Selected font(s)
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: "Beatrice",
                color: "#03705E",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShowFonts(true)}
            >
              Add More
            </Typography>
          </div>
          {otherFonts.length > 0 ? (
            <>
              {otherFonts.map((font, index) => (
                <>
                  <Card
                    style={{
                      margin: "5px",
                      // height: "100px",
                      width: "98%",
                      borderRadius: "16px",
                      border: "2px solid #AFE9E0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        margin: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img draggable="false" src={image} alt="" />
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "10px",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography style={{ display: "inline-flex" }}>
                          {font.name}
                          <CustomWidthTooltip
                            title={
                              "Remove the fonts by pressing the minus button."
                            }
                          >
                            <MyComponent
                              style={{
                                marginLeft: "10px",
                                display: "inline-block",
                              }}
                            />
                          </CustomWidthTooltip>
                        </Typography>
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleFontClick(font, index)}
                        >
                          <Close />
                        </div>
                      </div>
                    </div>
                  </Card>
                </>
              ))}
            </>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No Font Selected
              </Typography>
            </>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",

              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontFamily: "Beatrice",
                color: "#595959",
              }}
            >
              Selected color(s)
            </Typography>
          </div>
          {otherColors.length > 0 ? (
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "58vh",
              }}
            >
              {otherColors.map((color) => (
                <>
                  <Card
                    style={{
                      margin: "5px",
                      height: "100px",
                      width: "98%",
                      borderRadius: "16px",
                      border: "2px solid #AFE9E0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        margin: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        draggable="false"
                        style={{
                          width: "150px",
                          height: "80px",
                        }}
                        src={color.imageOn}
                        onClick={() => {
                          setBrushColor(color.code);
                        }}
                        alt=""
                      />
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "10px",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography>
                            {color.name}
                            <CustomWidthTooltip
                              title={
                                "Remove the colors by pressing the minus button."
                              }
                            >
                              <MyComponent
                                style={{
                                  marginLeft: "10px",
                                  display: "inline-block",
                                }}
                              />
                            </CustomWidthTooltip>
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Beatrice",
                              fontSize: "10px",
                              color: "#03705E",
                            }}
                          >
                            {color.code}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Card>
                </>
              ))}
            </div>
          ) : (
            <>
              <Typography
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #AFE9E0",
                  borderRadius: "16px",
                  padding: "10px",
                }}
              >
                No Color Selected
              </Typography>
            </>
          )}
        </Col>
      </Row>
      <UploadImage show={showImage} close={() => setShowImage(false)} />
      <ChooseStrokeColors
        setStrokeColor={setBrushColor}
        strokeColor={brushColor}
        setSavedDrawingData={setSavedDrawingData}
        show={showStrokeColors}
        close={() => setStrokeShowColors(false)}
      />
      <ChooseFonts
        textAreas={textAreas}
        setTextAreas={setTextAreas}
        show={showFonts}
        close={() => setShowFonts(false)}
      />
      {showExamples && <ExampleModal />}
    </>
  );
};

export default SuperContent;
