import { Typography } from "@mui/material";
import React, { useState } from "react";
import FontsDescIcon from "../../Assets/DescriptionIcon.png";
import Line from "../../Assets/Line.png";
import ColorDescIcon from "../../Assets/ColorDescIcon.png";
import BackingDescIcon from "../../Assets/BackingDescIcon.png";
import BackingColorDescIcon from "../../Assets/BackingColorDescIcon.png";
import SizeDescIcon from "../../Assets/SizeDescIcon.png";
import AccessoriesDescIcon from "../../Assets/AccessoriesDescIcon.png";
import DescriptionPopUp from "../Review/DescriptionPopUp";

const DescriptionCard = ({ heading, description }) => {
  const [show, setShow] = useState(false);
  let Icon;
  switch (heading) {
    case "Custom Fonts":
      Icon = FontsDescIcon;
      break;
    case "Custom Color":
      Icon = ColorDescIcon;
      break;
    case "Custom Backing Style":
      Icon = BackingDescIcon;
      break;
    case "Custom Backing Color":
      Icon = BackingColorDescIcon;
      break;
    case "Custom Size":
      Icon = SizeDescIcon;
      break;
    case "Custom Accessories":
      Icon = AccessoriesDescIcon;
      break;
    default:
      Icon = FontsDescIcon;
      break;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            margin: "10px",
          }}
          src={Icon}
          alt=""
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              padding: "20px",
              fontFamily: "Beatrice",
              fontWeight: "600",
            }}
          >
            {heading}
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Beatrice",
              color: "#03705E",
              marginRight: "40px",
              marginTop: "30px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShow(true);
            }}
          >
            {heading === "Format" ? <></> : <>Edit</>}
          </Typography>
        </div>
      </div>
      <div
        style={{
          marginLeft: "25px",
          marginRight: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img src={Line} alt="" />
        <Typography
          style={{
            color: "#03705E",
            backgroundColor: "white",
            padding: "20px",
            marginLeft: "45px",
            width: "100%",
            fontFamily: "Beatrice",
            borderRadius: "16px",
            overflowY: "auto",
            overflowWrap: "break-word",
          }}
        >
          {description ? description : "None"}
        </Typography>
      </div>

      <DescriptionPopUp
        edit={heading}
        show={show}
        close={() => setShow(false)}
      />
    </>
  );
};

export default DescriptionCard;
