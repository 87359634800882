import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addPrice,
  fetchExamples,
  fetchHelp,
  setLockedHeight,
  setLockedWidth,
  setMaterialLengthFactor,
  setProductBackingHeight,
  setProductBackingWidth,
  // setProductFont,
  setProductSizeDescription,
  // updatePositions,
} from "../Redux/Slice";
import { Typography } from "@mui/material";

import BottomControls from "../Components/BottomControls";
// import Close from "../Assets/Close";
// import Lock from "../Assets/Lock";
// import Unlock from "../Assets/Unlock";
import Minus from "../Assets/Minus";
import Plus from "../Assets/Plus";
import Reset from "../Assets/Reset";
// import Draggable from "react-draggable";
import SuperSize from "./SuperCustom/SuperSize";
import ZoomIn from "../Assets/ZoomIn";
import ZoomOut from "../Assets/ZoomOut";
import axios from "axios";
import ExampleModal from "../Components/ExampleModal";
import "./styles/Size.css";
import "./styles/Backing.css";
import {
  calculateFontArea,
  calculateLetterLength,
} from "../utils/client/calculations";
// import Clear from "../Assets/Clear";
// import Info from "../Assets/SuperCustom/Info";
import DescriptionButton from "../Components/DescriptionButton";
import ClearButton from "../Components/ClearButton";
import PriceEstimate from "../Components/PriceEstimate";
import PreviewDiv from "../Components/Review/PreviewDiv";

const Size = () => {
  // const screenWidth = window.innerWidth;
  const [scalingFactor, setScalingFactor] = useState(0);
  const [fullHeight, setFullHeight] = useState(0);
  const [fullWidth, setFullWidth] = useState(0);

  const showConsoles = useSelector((state) => state.activeStep.showConsoles);

  const customBackingCost = useSelector(
    (state) => state.activeStep.customBackingCost
  );
  const product = useSelector((state) => state.activeStep.product);
  const backings = useSelector((state) => state.activeStep.backingStyles);
  const backingColors = useSelector((state) => state.activeStep.backingColors);

  const minimumSizes = useSelector((state) => state.activeStep.product.size);
  // const backingFlatRate = useSelector(
  //   (state) => state.activeStep.backingFlatRate
  // );
  const size = useSelector((state) => state.activeStep.product.size);

  // const flatRateAdded = useSelector(
  //   (state) => state.activeStep.backingFlatRateAdded
  // );
  // const backingImage = useSelector(
  //   (state) => state.activeStep.product.backing.image
  // );
  const width = product.backing.width;

  const height = product.backing.height;
  const [sizeWidth, setSizeWidth] = useState(width);
  const [sizeHeight, setSizeHeight] = useState(height);
  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});
  const [warningThree, setWarningThree] = useState({});
  const [warningFour, setWarningFour] = useState({});
  const [locked, setLocked] = useState(false);
  const desc = product.size.description ? product.size.description : "";

  const textAreas = product?.fonts;
  // const positions = useSelector((state) => state.activeStep.positions);
  const dispatch = useDispatch();
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const theme = useSelector((state) => state.activeStep.theme);
  // const paddings = useSelector(
  //   (state) => state.activeStep.product.backing.paddings
  // );
  const lockedWidth = useSelector(
    (state) => state.activeStep.product.backing.lockedWidth
  );
  const lockedHeight = useSelector(
    (state) => state.activeStep.product.backing.lockedHeight
  );

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Size/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;
        case 3:
          setWarningThree(response.data);
          break;
        case 4:
          setWarningFour(response.data);
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const handleDragDrop = (e, data, id) => {
  //   const { x, y } = positions[id] || { x: 0, y: 0 };
  //   const newX = x + data.deltaX;
  //   const newY = y + data.deltaY;

  //   const updatedPositions = {
  //     ...positions,
  //     [id]: { x: newX, y: newY },
  //   };
  //   // console.log(updatedPositions);

  //   dispatch(updatePositions(updatedPositions));
  // };

  const decrementWidth = () => {
    // console.log(sizeWidth, minimumSizes?.minWidth);
    if (!locked && sizeWidth > 1) {
      if (lockedHeight <= 0 || lockedHeight <= 0) {
        return;
      } else {
        dispatch(setLockedWidth(lockedWidth - 1));
        let fullWidth = Math.ceil(
          Number(sizeWidth) + Number(size.width) + lockedWidth - 1
        );

        // console.log("Full Width:", fullWidth);
        // console.log("Full Height:", fullHeight);

        // console.log("Scaling Factor:", scalingFactor);

        let scaledHeight = fullWidth / scalingFactor;
        // console.log("Scaled Height:", fullWidth / scalingFactor);
        let updatedLockedHeight = scaledHeight - fullHeight;
        // console.log("New Height:", updatedLockedHeight);

        dispatch(setLockedHeight(Math.ceil(updatedLockedHeight)));
      }
    } else {
      if (sizeWidth <= minimumSizes?.minWidth) {
        return;
      } else {
        setSizeWidth(sizeWidth - 1);
        // setWidth(width - 1);
        // dispatch(setProductSizeWidth(width - 1));
      }
    }
  };

  const incrementWidth = () => {
    if (!locked) {
      dispatch(setLockedWidth(lockedWidth + 1));
      let fullWidth = Math.ceil(
        Number(sizeWidth) + Number(size.width) + lockedWidth + 1
      );

      // console.log("Full Width:", fullWidth);
      // console.log("Full Height:", fullHeight);

      // console.log("Scaling Factor:", scalingFactor);

      let scaledHeight = fullWidth / scalingFactor;
      // console.log("Scaled Height:", fullWidth / scalingFactor);
      let updatedLockedHeight = scaledHeight - fullHeight;
      // console.log("New Height:", updatedLockedHeight);

      dispatch(setLockedHeight(Math.ceil(updatedLockedHeight)));
    } else {
      setSizeWidth(sizeWidth + 1);
      // setWidth(width + 1);
      // dispatch(setProductSizeWidth(width + 1));
    }
  };

  const decrementHeight = () => {
    if (!locked && sizeHeight > 1) {
      if (lockedHeight <= 0 || lockedHeight <= 0) {
        return;
      } else {
        dispatch(setLockedHeight(lockedHeight - 1));
        let fullHeight = Math.ceil(
          Number(sizeHeight) + Number(size.height) + lockedHeight - 1
        );

        // console.log("Full Width:", fullWidth);
        // console.log("Full Height:", fullHeight);

        // console.log("Scaling Factor:", scalingFactor);

        let scaledWidth = fullHeight * scalingFactor;
        // console.log("Scaled Width:", scaledWidth);
        let updatedLockedWidth = scaledWidth - fullWidth;
        // console.log("New Height:", updatedLockedWidth);

        dispatch(setLockedWidth(Math.ceil(updatedLockedWidth)));
      }
    } else {
      if (sizeHeight <= minimumSizes?.minHeight) {
        return;
      } else {
        setSizeHeight(sizeHeight - 1);
      }
    }
  };

  const incrementHeight = () => {
    if (!locked) {
      dispatch(setLockedHeight(lockedHeight + 1));
      let fullHeight = Math.ceil(
        Number(sizeHeight) + Number(size.height) + lockedHeight + 1
      );

      // console.log("Full Width:", fullWidth);
      // console.log("Full Height:", fullHeight);

      // console.log("Scaling Factor:", scalingFactor);

      let scaledWidth = fullHeight * scalingFactor;
      // console.log("Scaled Width:", scaledWidth);
      let updatedLockedWidth = scaledWidth - fullWidth;
      // console.log("New Width:", updatedLockedWidth);

      dispatch(setLockedWidth(Math.ceil(updatedLockedWidth)));
    } else {
      setSizeHeight(sizeHeight + 1);
    }
  };

  const resetSize = () => {
    backings.forEach((backing) => {
      if (backing.type === product.backing.name) {
        setSizeHeight(
          Number(backing?.BackingTopPadding + backing?.BackingBottomPadding)
        );
        setSizeWidth(
          Number(backing?.BackingLeftPadding + backing?.BackingRightPadding)
        );
        dispatch(setLockedHeight(0));
        dispatch(setLockedWidth(0));
        setLocked(false);
      }
    });
  };

  // const toggleLock = () => {
  //   setLocked(!locked);
  // };

  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 0.1)); // Limit minimum zoom to 0.1
  };

  useEffect(() => {
    fetchWarnings(product.source, 1);
    fetchWarnings(product.source, 2);
    fetchWarnings(product.source, 3);
    fetchWarnings(product.source, 4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product.format === "super-custom") {
      dispatch(fetchHelp(`Super Size/${product.source}`));
      dispatch(fetchExamples("Super Size", product.source));
    } else {
      dispatch(fetchHelp(`Size/${product.source}`));
      dispatch(fetchExamples("Size", product.source));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fonts = useSelector((state) => state.activeStep.fonts);
  const colors = useSelector((state) => state.activeStep.colors);
  const clearPlexiCost = useSelector(
    (state) => state.activeStep.clearPlexiCost
  );

  const bendingCost = useSelector((state) => state.activeStep.bendingCostPerFt);
  const colorCost = useSelector((state) => state.activeStep.colorCost);
  const tech22Cost = useSelector((state) => state.activeStep.tech22Cost);
  const blackAccessoryCost = useSelector(
    (state) => state.activeStep.blackAccessoryCost
  );

  const Hmax = useSelector((state) => state.activeStep.plexiHeightMax);
  const Wmax = useSelector((state) => state.activeStep.plexiWidthMax);
  const markUp = useSelector((state) => state.activeStep.markup);

  const discount = useSelector((state) => state.activeStep.discount);
  const materialLengthPerTech = useSelector(
    (state) => state.activeStep.materialLengthPerTech
  );
  const isCustomColor = useSelector(
    (state) => state.activeStep.product.customBacking
  );
  const getColorPriceForLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return colorCost; // Use the default color cost if color is not found
    }
  };

  const calculateBackingPrice = async () => {
    // console.log("Calculating Price");
    // console.log(selectedFont);

    // console.log(selectedFont);

    if (textAreas[0]?.value || textAreas[1]?.value || textAreas[2]?.value) {
      // console.log(selectedFont);

      let backingPerFt = clearPlexiCost;
      let colorPricePerFt = 0;
      let fontPricePerFt = 0;
      let materialLength = 0;
      let neonWidth = 0;
      let neonHeight = 0;
      let signWidth = 0;
      let signHeight = 0;
      let glassCost = 0;
      let glassCost1 = 0;
      let glassCost2 = 0;
      let glassCost3 = 0;
      let unitNumber = 0;
      let segments = 0;

      if (showConsoles) {
        console.log("------------- Latest Calculations Starting from here");
        console.log("Bending Cost :", bendingCost);
        console.log("EGL White Cost :", colorCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1) {
        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + sizeWidth + lockedWidth;
          signHeight = result?.maxHeight + sizeHeight + lockedHeight;

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );

            glassCost +=
              Number(fontPricePerFt + bendingCost + colorPricePerFt) *
              (letterLength / 12);
          }
          // console.log(glassCost, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          neonWidth = result?.totalWidth;
          neonHeight = result?.maxHeight;
          let originalWidth =
            neonWidth +
            parseInt(product.backing.paddings.leftPadding) +
            parseInt(product.backing.paddings.rightPadding);
          let originalHeight =
            neonHeight +
            parseInt(product.backing.paddings.bottomPadding) +
            parseInt(product.backing.paddings.topPadding);
          signWidth = result?.totalWidth + sizeWidth + lockedWidth;
          signHeight = result?.maxHeight + sizeHeight + lockedHeight;
          if (showConsoles) {
            console.log("Material Length before Factor :", materialLength);

            console.log("New Sign Area : ", signWidth * signHeight);
            console.log(
              "Original Sign Area : ",
              originalWidth * originalHeight
            );
          }

          let factor = Math.sqrt(
            (signWidth * signHeight) / (originalWidth * originalHeight)
          );

          dispatch(setMaterialLengthFactor(factor));
          materialLength = materialLength * factor;
          if (showConsoles) {
            console.log("Factor :", factor);
            console.log("Material Length after Factor :", materialLength);
          }

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt = color.price;
            }
          });

          colorPricePerFt = colorPricePerFt ? colorPricePerFt : colorCost;
          if (showConsoles) {
            console.log("Color Price : ", colorPricePerFt);
            console.log("Font Price : ", fontPricePerFt);
          }
          glassCost =
            Number(fontPricePerFt + bendingCost + colorPricePerFt) *
            (materialLength / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (showConsoles) {
          console.log("Material Length : ", materialLength);

          console.log("Neon Width : ", neonWidth);
          console.log("Neon Height : ", neonHeight);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        unitNumber = Number(
          Math.ceil(neonWidth / 24) + Math.ceil(neonHeight / 10)
        );
        if (showConsoles) {
          console.log("Unit Numbers : ", unitNumber);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments : ", segments);
        }
      } else if (textAreas.length === 2) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);

            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2);
        // console.log(glassCost);
        signHeight =
          Number(signHeight1 + signHeight2) + sizeHeight + lockedHeight;
        signWidth = Math.max(signWidth1, signWidth2) + sizeWidth + lockedWidth;
        materialLength = materialLength1 + materialLength2;
        // console.log("Sign Height :", signHeight);
        // console.log("Sign Width :", signWidth);
        // console.log("Total Material Length :", materialLength);

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2) / 24) +
            Math.ceil((neonHeight1 + neonHeight2) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      } else if (textAreas.length === 3) {
        // console.log("Multiple Fonts");
        let fontPricePerFt1 = 0;
        let fontPricePerFt2 = 0;
        let fontPricePerFt3 = 0;
        let colorPricePerFt1 = 0;
        let colorPricePerFt2 = 0;
        let colorPricePerFt3 = 0;

        let materialLength1 = 0;
        let neonWidth1 = 0;
        let neonHeight1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let neonWidth2 = 0;
        let neonHeight2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let neonWidth3 = 0;
        let neonHeight3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          for (const letterObj of textAreas[0].letters) {
            colorPricePerFt1 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            glassCost1 +=
              Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
              (letterLength / 12);
          }
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result1 = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result1);
          materialLength1 = result1?.materialLength;
          neonWidth1 = result1?.totalWidth;
          neonHeight1 = result1?.maxHeight;
          signWidth1 = result1?.totalWidth;
          signHeight1 = result1?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[0].fontFamily) {
              // console.log(font.name);
              fontPricePerFt1 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPricePerFt1 = color.price;
            }
          });

          colorPricePerFt1 = colorPricePerFt1 ? colorPricePerFt1 : colorCost;

          // console.log(fontPricePerFt);
          glassCost1 =
            Number(fontPricePerFt1 + bendingCost + colorPricePerFt1) *
            (materialLength1 / 12);
          // console.log(glassCost);
          // console.log(glassCost1, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          for (const letterObj of textAreas[1].letters) {
            colorPricePerFt2 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            glassCost2 +=
              Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          // console.log(result2);
          materialLength2 = result2?.materialLength;
          neonWidth2 = result2?.totalWidth;
          neonHeight2 = result2?.maxHeight;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt2 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPricePerFt2 = color.price;
            }
          });

          colorPricePerFt2 = colorPricePerFt2 ? colorPricePerFt2 : colorCost;

          // console.log(fontPricePerFt);
          glassCost2 =
            Number(fontPricePerFt2 + bendingCost + colorPricePerFt2) *
            (materialLength2 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          fonts.forEach((font) => {
            if (font.name === textAreas[1].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          for (const letterObj of textAreas[2].letters) {
            colorPricePerFt3 = getColorPriceForLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            glassCost3 +=
              Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
              (letterLength / 12);
          }
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          // console.log(result2);
          materialLength3 = result3?.materialLength;
          neonWidth3 = result3?.totalWidth;
          neonHeight3 = result3?.maxHeight;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          // console.log(signWidth);
          // console.log(materialLength / 12);

          fonts.forEach((font) => {
            if (font.name === textAreas[2].fontFamily) {
              // console.log(font.name);
              fontPricePerFt3 = font.price;
            }
          });
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPricePerFt3 = color.price;
            }
          });

          colorPricePerFt3 = colorPricePerFt3 ? colorPricePerFt3 : colorCost;

          // console.log(fontPricePerFt);
          glassCost3 =
            Number(fontPricePerFt3 + bendingCost + colorPricePerFt3) *
            (materialLength3 / 12);
          // console.log(glassCost);
          // console.log(glassCost2, signHeight, signWidth, neonWidth, neonHeight);
        }

        // console.log(fontPricePerFt);
        glassCost = Number(glassCost1 + glassCost2 + glassCost3);
        // console.log(glassCost);
        signHeight =
          signHeight1 + signHeight2 + signHeight3 + sizeHeight + lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2, signWidth3) +
          sizeWidth +
          lockedWidth;
        materialLength = materialLength1 + materialLength2 + materialLength3;

        unitNumber = Number(
          Math.ceil(Math.max(neonWidth1, neonWidth2, neonWidth3) / 24) +
            Math.ceil((neonHeight1 + neonHeight2 + neonHeight3) / 10)
        );
        // console.log(unitNumber);

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        // console.log(segments);
      }
      if (showConsoles) {
        console.log("Glass Cost : ", glassCost);
      }

      let area = signWidth * signHeight;
      if (showConsoles) {
        console.log("Area : ", area);
      }

      let techs = Number(
        Math.ceil((materialLength / 12 + unitNumber) / materialLengthPerTech) +
          segments -
          1
      );
      if (showConsoles) {
        console.log("Techs:", techs);
      }
      backings.forEach((backing) => {
        if (backing.type === product.backing.name) {
          // console.log(color.code);
          backingPerFt = backing.BackingPricePerSqInch;
        }
      });

      if (isCustomColor) {
        backingPerFt = customBackingCost;
      } else {
        backingColors.forEach((backing) => {
          if (backing.code === product.backing.color) {
            // console.log(color.code);
            backingPerFt += backing.price;
          }
        });
      }
      if (showConsoles) {
        console.log("Backing per sq in : ", backingPerFt);
      }

      let transformerCost = Number(techs * tech22Cost);
      if (showConsoles) {
        console.log("Transformer Cost:", transformerCost);
      }

      let backingCost = Number(signWidth * signHeight * backingPerFt);
      if (product.backing.name === "") {
        backingCost = 0;
      } else {
        if (product.backing.name === "wood" || product.backing.name === "box") {
          backingCost = Number(
            (2 * signWidth * signHeight + signHeight * signHeight) *
              backingPerFt
          );
        } else if (product.backing.name === "skeleton") {
          backingCost = Number(materialLength * backingPerFt);
        }
      }
      if (showConsoles) {
        console.log("Backing Cost:", backingCost);
      }

      let accessoriesCost = Number(unitNumber * blackAccessoryCost);
      if (showConsoles) {
        console.log("Accessories Cost:", accessoriesCost);
      }

      let totalCost = Number(
        glassCost + transformerCost + backingCost + accessoriesCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);

        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price:", price);
      }

      dispatch(addPrice(Math.ceil(price)));
    }
  };

  const RGBColor = useSelector((state) => state.activeStep.product.RGBColor);
  const SpecialColorFee = useSelector(
    (state) => state.activeStep.SpecialColorFee
  );
  const LEDCostPerMaterial = useSelector((state) => state.activeStep.colorCost);
  const InterShippingCost = useSelector(
    (state) => state.activeStep.InterShippingCost
  );
  const AcrylicInterShippingCost = useSelector(
    (state) => state.activeStep.woodInterShippingCost
  );
  const WoodInterShippingCost = useSelector(
    (state) => state.activeStep.acrylicInterShippingCost
  );
  const standardRGBCost = useSelector(
    (state) => state.activeStep.standardRGBCost
  );
  const magicRGBCost = useSelector((state) => state.activeStep.magicRGBCost);

  const getColorPriceForLEDLetter = (colorCode) => {
    const color = colors.find((color) => color.code === colorCode);
    if (color) {
      return color.price;
    } else {
      return LEDCostPerMaterial; // Use the default color cost if color is not found
    }
  };
  const calculateLEDFontPrice = async () => {
    // console.log("Calculating Price");
    if (textAreas[0]?.value || textAreas[1]?.value) {
      // console.log(selectedFont);

      let colorPrice = colorCost;
      let backingPerFt = customBackingCost;

      let materialLength = 0;
      let signWidth = 0;
      let signHeight = 0;
      let backingArea = 0;
      // let minPowerSupply = 0;
      let LEDCost = 0;
      let segments = 0;
      if (showConsoles) {
        console.log("Custom Color Backing Cost :", customBackingCost);
        console.log("Special Color Cost :", SpecialColorFee);
        console.log("Magic RGB Cost :", magicRGBCost);
        console.log("Standard RGB Cost :", standardRGBCost);
        console.log("Max Plexi Height :", Hmax);
        console.log("Max Plexi Width :", Wmax);
      }
      if (textAreas.length === 1 && textAreas[0].value) {
        if (showConsoles) {
          console.log("------------------Single Font");
        }
        if (textAreas[0]?.isSplit) {
          if (showConsoles) {
            console.log("------------------Is Split");
          }
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          signWidth = result?.totalWidth + sizeWidth + lockedWidth;
          signHeight = result?.maxHeight + sizeHeight + lockedHeight;

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }

          for (const letterObj of textAreas[0].letters) {
            colorPrice = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost += Number((SpecialColorFee + colorPrice) * letterLength);
            } else {
              LEDCost += Number(colorPrice * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          // console.log(result);
          materialLength = result?.materialLength;
          let neonWidth = result?.totalWidth;
          let neonHeight = result?.maxHeight;
          signWidth = result?.totalWidth + sizeWidth + lockedWidth;
          signHeight = result?.maxHeight + sizeHeight + lockedHeight;

          let originalWidth =
            neonWidth +
            parseInt(product.backing.paddings.leftPadding) +
            parseInt(product.backing.paddings.rightPadding);
          let originalHeight =
            neonHeight +
            parseInt(product.backing.paddings.bottomPadding) +
            parseInt(product.backing.paddings.topPadding);
          // console.log(signWidth, signHeight, originalHeight, originalWidth);

          if (showConsoles) {
            console.log("Material Length before Factor :", materialLength);

            console.log("New Sign Area : ", signWidth * signHeight);
            console.log(
              "Original Sign Area : ",
              originalWidth * originalHeight
            );
          }

          let factor = Math.sqrt(
            (signWidth * signHeight) / (originalWidth * originalHeight)
          );

          dispatch(setMaterialLengthFactor(factor));
          materialLength = materialLength * factor;
          if (showConsoles) {
            console.log("Factor :", factor);
            console.log("Material Length after Factor :", materialLength);
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost :`, colorPrice);
          }

          if (showConsoles) {
            console.log("Custom Color Present :", textAreas[0]?.customColor);
          }
          if (textAreas[0]?.customColor) {
            LEDCost = Number((colorPrice + SpecialColorFee) * materialLength);
          } else {
            LEDCost = Number(colorPrice) * materialLength;
          }
          // console.log("LED Cost :", LEDCost);
        }

        // console.log("Material Length : ", materialLength);
        if (showConsoles) {
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 2) {
        if (showConsoles) {
          console.log("------------------Double Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[1].value}:`, colorPrice2);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[1].value} :`, LEDCost2);
          }
        }

        LEDCost = LEDCost1 + LEDCost2;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2;
        signHeight =
          Number(signHeight1 + signHeight2) + sizeHeight + lockedHeight;
        signWidth = Math.max(signWidth1, signWidth2) + sizeWidth + lockedWidth;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }

        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      } else if (textAreas.length === 3) {
        if (showConsoles) {
          console.log("------------------Triple Font");
        }
        let colorPrice1 = 0;
        let colorPrice2 = 0;
        let colorPrice3 = 0;
        let LEDCost1 = 0;
        let LEDCost2 = 0;
        let LEDCost3 = 0;

        let materialLength1 = 0;
        let signWidth1 = 0;
        let signHeight1 = 0;

        let materialLength2 = 0;
        let signWidth2 = 0;
        let signHeight2 = 0;

        let materialLength3 = 0;
        let signWidth3 = 0;
        let signHeight3 = 0;

        if (textAreas[0]?.isSplit) {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;

          for (const letterObj of textAreas[0].letters) {
            colorPrice1 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[0]?.fontFamily,
              textAreas[0]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost1 += Number(
                (SpecialColorFee + colorPrice1) * letterLength
              );
            } else {
              LEDCost1 += Number(colorPrice1 * letterLength);
            }
          }
        } else {
          let result = await calculateFontArea(
            textAreas[0]?.value,
            textAreas[0]?.fontFamily,
            textAreas[0]?.jsonLink,
            textAreas[0]?.woffFile
          );
          materialLength1 = result?.materialLength;
          signWidth1 = result?.totalWidth;
          signHeight1 = result?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[0].value}:`,
              materialLength1
            );
          }
          colors.forEach((color) => {
            if (color.code === textAreas[0].color) {
              // console.log(color.code);
              colorPrice1 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[0].value}:`, colorPrice1);
          }

          // console.log(customColorExists);
          if (textAreas[0]?.customColor) {
            LEDCost1 = Number(
              (colorPrice1 + SpecialColorFee) * materialLength1
            );
          } else {
            LEDCost1 = Number(colorPrice1) * materialLength1;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[0].value} :`, LEDCost1);
          }
        }
        if (textAreas[1]?.isSplit) {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;

          for (const letterObj of textAreas[1].letters) {
            colorPrice2 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[1]?.fontFamily,
              textAreas[1]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost2 += Number(
                (SpecialColorFee + colorPrice2) * letterLength
              );
            } else {
              LEDCost2 += Number(colorPrice2 * letterLength);
            }
          }
        } else {
          let result2 = await calculateFontArea(
            textAreas[1]?.value,
            textAreas[1]?.fontFamily,
            textAreas[1]?.jsonLink,
            textAreas[1]?.woffFile
          );
          materialLength2 = result2?.materialLength;
          signWidth2 = result2?.totalWidth;
          signHeight2 = result2?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[1].value}:`,
              materialLength2
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[1].color) {
              // console.log(color.code);
              colorPrice2 = color.price;
            }
          });

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[1]?.customColor) {
            LEDCost2 = Number(
              (colorPrice2 + SpecialColorFee) * materialLength2
            );
          } else {
            LEDCost2 = Number(colorPrice2) * materialLength2;
          }
        }
        if (textAreas[2]?.isSplit) {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;

          for (const letterObj of textAreas[2].letters) {
            colorPrice3 = getColorPriceForLEDLetter(letterObj.color);
            const letterLength = await calculateLetterLength(
              letterObj.letter,
              textAreas[2]?.fontFamily,
              textAreas[2]?.jsonLink
            );
            let customColor = !colors.some((color) => {
              if (color.code === letterObj.color) {
                return true;
              }
              return false;
            });
            if (customColor) {
              LEDCost3 += Number(
                (SpecialColorFee + colorPrice3) * letterLength
              );
            } else {
              LEDCost3 += Number(colorPrice3 * letterLength);
            }
          }
        } else {
          let result3 = await calculateFontArea(
            textAreas[2]?.value,
            textAreas[2]?.fontFamily,
            textAreas[2]?.jsonLink,
            textAreas[2]?.woffFile
          );
          materialLength3 = result3?.materialLength;
          signWidth3 = result3?.totalWidth;
          signHeight3 = result3?.maxHeight;
          if (showConsoles) {
            console.log(
              `Material length of ${textAreas[2].value}:`,
              materialLength3
            );
          }
          // console.log(materialLength / 12);
          colors.forEach((color) => {
            if (color.code === textAreas[2].color) {
              // console.log(color.code);
              colorPrice3 = color.price;
            }
          });
          if (showConsoles) {
            console.log(`Color Cost of ${textAreas[2].value}:`, colorPrice3);
          }

          // console.log(LEDCost);

          // console.log(customColorExists);
          if (textAreas[2]?.customColor) {
            LEDCost3 = Number(
              (colorPrice3 + SpecialColorFee) * materialLength3
            );
          } else {
            LEDCost3 = Number(colorPrice3) * materialLength3;
          }
          if (showConsoles) {
            console.log(`LED Cost of ${textAreas[2].value} :`, LEDCost3);
          }
        }

        LEDCost = LEDCost1 + LEDCost2 + LEDCost3;

        // console.log(signWidth);
        // console.log(materialLength / 12);

        // console.log(fontPricePerFt);

        // console.log(LEDCost);
        materialLength = materialLength1 + materialLength2 + materialLength3;
        signHeight =
          Number(signHeight1 + signHeight2 + signHeight3) +
          sizeHeight +
          lockedHeight;
        signWidth =
          Math.max(signWidth1, signWidth2, signWidth3) +
          sizeWidth +
          lockedWidth;
        if (showConsoles) {
          console.log("Material Length : ", materialLength);
          console.log("Sign Width : ", signWidth);
          console.log("Sign Height : ", signHeight);
        }

        segments = Number(
          Math.ceil(signHeight / Hmax) + Math.ceil(signWidth / Wmax) - 1
        );
        if (showConsoles) {
          console.log("Segments :", segments);
        }
        backingArea = Number(signHeight * signWidth);
        if (showConsoles) {
          console.log("Backing Area :", backingArea);
        }
      }
      if (showConsoles) {
        console.log("Total LED Cost :", LEDCost);
      }

      let powerSupplies = Number(
        Math.ceil(materialLength / 12 / materialLengthPerTech)
      );
      if (showConsoles) {
        console.log("Number of Power Supply :", powerSupplies);
      }

      let powerSupplyCost = Number(powerSupplies * tech22Cost);
      if (showConsoles) {
        console.log("Power Supply Cost :", powerSupplyCost);
      }
      backings.forEach((backing) => {
        if (backing.type === product.backing.name) {
          // console.log(color.code);
          backingPerFt = backing.BackingPricePerSqInch;
        }
      });

      if (isCustomColor) {
        backingPerFt = customBackingCost;
      } else {
        backingColors.forEach((backing) => {
          if (backing.code === product.backing.color) {
            // console.log(color.code);
            backingPerFt += backing.price;
          }
        });
      }
      if (showConsoles) {
        console.log("Backing Per Ft :", backingPerFt);
      }

      let backingCost = Number(backingArea * backingPerFt);
      if (product.backing.name === "") {
        backingCost = 0;
      }
      if (showConsoles) {
        console.log("Backing Cost :", backingCost);
      }

      let accessoriesCost = Number(segments * blackAccessoryCost);
      // console.log(accessoriesCost);
      if (showConsoles) {
        console.log("RGB Style Selected :", RGBColor);
      }
      if (RGBColor) {
        if (RGBColor === "standardRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + standardRGBCost)
          );
          if (showConsoles) {
            console.log(
              "Accessories Cost with Standard RGB :",
              accessoriesCost
            );
          }
        }
        if (RGBColor === "magicRGB") {
          accessoriesCost = Number(
            segments * (blackAccessoryCost + magicRGBCost)
          );
          if (showConsoles) {
            console.log("Accessories Cost with Magic RGB :", accessoriesCost);
          }
        }
      }
      if (showConsoles) {
        console.log("Accessories Cost : ", accessoriesCost);
      }
      let shippingCost = Number(
        Number(backingArea * InterShippingCost) / segments
      );
      if (product.backing.name === "wood") {
        shippingCost = Number(
          Number(backingArea * WoodInterShippingCost) / segments
        );
      }
      if (product.backing.name === "box") {
        shippingCost = Number(
          Number(backingArea * AcrylicInterShippingCost) / segments
        );
      }
      if (showConsoles) {
        console.log("Shipping Cost :", shippingCost);
      }

      let totalCost = Number(
        LEDCost + powerSupplyCost + backingCost + accessoriesCost + shippingCost
      );
      if (showConsoles) {
        console.log("Total Cost:", totalCost);

        console.log("Mark Up Percentage :", markUp);
        console.log("Discount Percentage :", discount);
      }
      let decimalMarkUp = markUp / 100;
      let decimalDiscount = discount / 100;

      let markupAmount = decimalMarkUp * totalCost;
      if (showConsoles) {
        console.log("MarkUp Amount:", markupAmount);
        console.log("Decimal Discount:", decimalDiscount);
      }
      let markUpPrice = totalCost + markupAmount;
      let discountFactor = Number(1 - decimalDiscount);
      if (showConsoles) {
        console.log("MarkUp Price :", markUpPrice);
        console.log("Discounted Factor :", discountFactor);
      }

      let price = Number(markUpPrice * discountFactor);
      if (showConsoles) {
        console.log("Price :", price);
      }

      dispatch(addPrice(Math.ceil(price)));
    }
  };
  useEffect(() => {
    if (product.source === "Glass") {
      calculateBackingPrice();
    } else if (product.source === "LED") {
      calculateLEDFontPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    dispatch(setProductBackingHeight(sizeHeight));
    dispatch(setProductBackingWidth(sizeWidth));
  }, [sizeWidth, sizeHeight, dispatch]);

  useEffect(() => {
    localStorage.setItem("fromBackingPage", false);
    localStorage.setItem("fromSizePage", true);
  }, []);

  useEffect(() => {
    let fullWidth = Number(
      Number(sizeWidth) + Number(size.width) + lockedWidth
    );
    setFullWidth(Math.ceil(fullWidth));

    let fullHeight = Number(
      Number(sizeHeight) + Number(size.height) + lockedHeight
    );
    setFullHeight(Math.ceil(fullHeight));

    let scalingFactor = Math.ceil(fullWidth) / Math.ceil(fullHeight);
    // console.log(scalingFactor);

    setScalingFactor(scalingFactor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className=" margin-adjust parent-container">
        {product.format === "super-custom" ? (
          <>
            <SuperSize />
          </>
        ) : (
          <>
            <Row className="size-container">
              <Col
                className="size-items"
                md={12}
                lg={2}
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginBottom: "40px",
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                    }}
                    className="text-[#595959] ml-2"
                  >
                    Width (In)
                  </Typography>
                  <Card
                    style={{
                      border: "unset",
                    }}
                    className="flex flex-row m-2  justify-between items-center"
                  >
                    <button
                      onClick={decrementWidth}
                      className=" text-[#36CDB4]  border-[#36CDB4]"
                      style={{
                        width: "100%",
                        backgroundColor: "#D8F4F1",
                        height: "65px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "2px solid",
                        borderTopLeftRadius: "1.5rem",
                        borderBottomLeftRadius: "1.5rem",
                      }}
                    >
                      <Minus />
                    </button>
                    <input
                      readOnly
                      value={Math.ceil(
                        Number(
                          Number(sizeWidth) + Number(size.width) + lockedWidth
                        )
                      )}
                      type="number"
                      onChange={(e) => {
                        if (!locked) {
                          setSizeWidth(Number(e.target.value));
                          dispatch(
                            setProductBackingWidth(Number(e.target.value))
                          );
                          setSizeHeight(Number(e.target.value));
                          dispatch(
                            setProductBackingHeight(Number(e.target.value))
                          );
                        } else {
                          setSizeWidth(Number(e.target.value));
                          dispatch(
                            setProductBackingWidth(Number(e.target.value))
                          );
                        }
                      }}
                      style={{
                        fontFamily: "Beatrice",
                        outline: "none",
                        borderTop: "2px solid #36CDB4",
                        borderBottom: "2px solid #36CDB4",
                      }}
                      className=" w-full h-full text-center py-2 text-3xl bg-white"
                    ></input>
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#D8F4F1",
                        height: "65px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "2px solid",
                        borderTopRightRadius: "1.5rem",
                        borderBottomRightRadius: "1.5rem",
                      }}
                      className=" text-[#36CDB4]  border-[#36CDB4]"
                      onClick={incrementWidth}
                    >
                      <Plus />
                    </button>
                  </Card>
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                    }}
                    className=" text-[#595959] mt-5 ml-3"
                  >
                    Height (In)
                  </Typography>
                  <Card
                    style={{
                      border: "unset",
                    }}
                    className="flex flex-row m-2  justify-between items-center"
                  >
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#D8F4F1",
                        height: "65px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "2px solid",
                        borderTopLeftRadius: "1.5rem",
                        borderBottomLeftRadius: "1.5rem",
                      }}
                      className=" text-[#36CDB4]  border-[#36CDB4]"
                      onClick={decrementHeight}
                    >
                      <Minus />
                    </button>
                    <input
                      readOnly
                      type="number"
                      value={Math.ceil(
                        Number(
                          Number(sizeHeight) +
                            Number(size.height) +
                            lockedHeight
                        )
                      )}
                      onChange={(e) => {
                        if (!locked) {
                          setSizeWidth(Number(e.target.value));
                          dispatch(
                            setProductBackingWidth(Number(e.target.value))
                          );
                          setSizeHeight(Number(e.target.value));
                          dispatch(
                            setProductBackingHeight(Number(e.target.value))
                          );
                        } else {
                          setSizeHeight(Number(e.target.value));
                          dispatch(
                            setProductBackingHeight(Number(e.target.value))
                          );
                        }
                      }}
                      style={{
                        fontFamily: "Beatrice",
                        outline: "none",
                        borderTop: "2px solid #36CDB4",
                        borderBottom: "2px solid #36CDB4",
                      }}
                      className=" w-full h-full text-center py-2 text-3xl bg-white"
                    ></input>
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#D8F4F1",
                        height: "65px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "2px solid",
                        borderTopRightRadius: "1.5rem",
                        borderBottomRightRadius: "1.5rem",
                      }}
                      className=" text-[#36CDB4]  border-[#36CDB4]"
                      onClick={incrementHeight}
                    >
                      <Plus />
                    </button>
                  </Card>
                  <Card className="size-input flex flex-row heading border-none p-3 mb-3 mt-3 font-black text-center items-center">
                    {/* {!locked ? (
                      <button disabled onClick={toggleLock}>
                        <Lock
                          className="cursor-pointer"
                          size="50px"
                          color="#25CCB0"
                        />
                      </button>
                    ) : (
                      <button disabled onClick={toggleLock}>
                        <Unlock
                          className="cursor-pointer"
                          size="50px"
                          color="#25CCB0"
                        />
                      </button>
                    )}
                    <Typography
                      style={{
                        fontFamily: "Beatrice",
                        fontSize: "12px",
                      }}
                      className="text-[#727272] text-left p-2"
                    >
                      {locked
                        ? "Lock to keep the current proportions of the backing"
                        : "Unlock to change the proportions of the backing"}
                    </Typography> */}
                  </Card>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    className="size-input w-full rounded-3xl text-white bg-[#25CCB0] p-3  hover-effect"
                    onClick={resetSize}
                  >
                    <Reset />
                    <Typography
                      style={{
                        fontFamily: "Beatrice",
                        fontSize: "20px",
                        fontWeight: "600",
                        marginLeft: "5px",
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      Reset
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    height: "30%",
                    display: warningOne?.warningStatus ? "" : "none",
                  }}
                  className="warning"
                >
                  <Typography className="warning-title">
                    {warningOne?.title}
                  </Typography>
                  <div
                    className="enable-listing-style warning-desc"
                    dangerouslySetInnerHTML={{
                      __html: warningOne?.warningContent,
                    }}
                  ></div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={10} sm={12}>
                <div
                  className="border-2 border-[#AFE9E0] rounded-3xl  p-4"
                  style={{
                    backgroundColor: `${theme}`,
                    boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
                  }}
                >
                  <div className="price-container">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Beatrice-Display",
                          color: "#25CCB0",
                          fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                          fontWeight: "700",
                          fontStyle: "italic",
                          letterSpacing: "0.05em",
                        }}
                      >
                        Set Size
                      </Typography>
                      <div
                        style={{
                          width: "40%",
                          height: "10vh",
                          display: warningTwo?.warningStatus ? "" : "none",
                          zIndex: 9,
                        }}
                        className="warning"
                      >
                        <Typography className="warning-title">
                          {warningTwo?.title}
                        </Typography>
                        <div
                          className="enable-listing-style warning-desc"
                          dangerouslySetInnerHTML={{
                            __html: warningTwo?.warningContent,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          warningThree?.warningStatus ||
                          product?.backing?.name === "custom"
                            ? "none"
                            : "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                      className="text-end"
                    >
                      <PriceEstimate />
                    </div>
                    <div
                      style={{
                        width: "30%",
                        height: "100px",
                        display: warningThree?.warningStatus ? "" : "none",
                        zIndex: 9,
                      }}
                      className="warning"
                    >
                      <Typography className="warning-title">
                        {warningThree?.title}
                      </Typography>
                      <div
                        className="enable-listing-style warning-desc"
                        dangerouslySetInnerHTML={{
                          __html: warningThree?.warningContent,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      display: warningFour?.warningStatus ? "" : "none",
                      zIndex: 9,
                      position: "relative",
                    }}
                    className="warning"
                  >
                    <Typography className="warning-title">
                      {warningFour?.title}
                    </Typography>
                    <div
                      className="enable-listing-style warning-desc"
                      dangerouslySetInnerHTML={{
                        __html: warningFour?.warningContent,
                      }}
                    ></div>
                  </div>
                  <div>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          lg={11}
                          md={12}
                          style={{
                            backgroundColor: `${theme}`,
                            color: !theme,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50vh",
                            overflowX: "hidden",
                          }}
                        >
                          <PreviewDiv zoomLevel={zoomLevel} />
                        </Col>

                        <Col
                          lg={1}
                          style={{
                            display: "flex",
                          }}
                          xs={0}
                          sm={0}
                          className="desktop-div"
                        >
                          <span className="mobile-display">
                            <button
                              style={{
                                padding: "5px",
                              }}
                              onClick={handleZoomIn}
                            >
                              <ZoomIn />
                            </button>
                            <button
                              style={{
                                padding: "5px",
                              }}
                              onClick={handleZoomOut}
                            >
                              <ZoomOut />
                            </button>

                            <DescriptionButton
                              value={desc}
                              setValue={setProductSizeDescription}
                            />
                            <ClearButton />
                          </span>
                        </Col>
                        <div xs={1} className="mobile-tablet-div">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <button
                                style={{
                                  padding: "5px",
                                }}
                                onClick={handleZoomIn}
                              >
                                <ZoomIn />
                              </button>
                              <button
                                style={{
                                  padding: "5px",
                                }}
                                onClick={handleZoomOut}
                              >
                                <ZoomOut />
                              </button>
                            </div>
                            <div>
                              <DescriptionButton
                                value={desc}
                                setValue={setProductSizeDescription}
                              />
                              <ClearButton />
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <BottomControls />
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
            {showExamples && <ExampleModal />}
          </>
        )}
      </div>
    </>
  );
};

export default Size;
