import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import React from "react";
import Info from "../../Assets/SuperCustom/Info";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../../utils/client/helpers";

const HeadingInfo = ({ open, setOpen, text, heading, optional }) => {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });
  return (
    <Typography
      className="main-buttons"
      style={{
        fontFamily: "Beatrice",
        fontWeight: "600",
        height: "50px",
        display: "inline-flex",
      }}
    >
      {heading}
      {optional ? (
        <>
          <Typography
            style={{
              fontFamily: "Beatrice",
              fontWeight: "600",
              height: "50px",
              display: "inline-flex",
              color: "#BF6BE3",
            }}
          >
            (optional)
          </Typography>
        </>
      ) : (
        <></>
      )}
      <CustomWidthTooltip
        open={open}
        onClose={() => handleTooltipClose(setOpen)}
        title={text}
      >
        <MyComponent
          onMouseEnter={() => handleMouseEnter(setOpen)}
          onMouseLeave={() => handleMouseLeave(setOpen)}
          onClick={() => handleClick(setOpen, open)}
          style={{
            marginLeft: "10px",
            display: "inline-block",
          }}
        />
      </CustomWidthTooltip>
    </Typography>
  );
};

export default HeadingInfo;
