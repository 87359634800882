import React from "react";

const Minus = () => {
  return (
    <svg
      width="27"
      height="4"
      viewBox="0 0 27 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60252 3.24275H25.3787C25.5917 3.24275 25.8025 3.20081 25.9992 3.11932C26.1959 3.03783 26.3747 2.91839 26.5252 2.76782C26.6758 2.61725 26.7952 2.43849 26.8767 2.24177C26.9581 2.04504 27 1.83419 27 1.62127C27 1.40835 26.9581 1.19752 26.8766 1.0008C26.7952 0.804087 26.6758 0.625348 26.5252 0.474793C26.3747 0.324239 26.1959 0.204819 25.9992 0.123353C25.8025 0.0418867 25.5917 -2.91215e-05 25.3787 1.51797e-08H1.60252C1.17579 0.00499137 0.768233 0.178012 0.468237 0.481537C0.168242 0.785062 0 1.19462 0 1.62138C0 2.04814 0.168242 2.45769 0.468237 2.76122C0.768233 3.06474 1.17579 3.23776 1.60252 3.24275Z"
        fill="#36CDB4"
      />
    </svg>
  );
};

export default Minus;
