import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import { setProductDeliveryType } from "../../Redux/Slice";

import SuperPickUp from "../../Components/SuperCustom/SuperDelivery/SuperPickUp";
import SuperMessenger from "../../Components/SuperCustom/SuperDelivery/SuperMessenger";
import SuperInstall from "../../Components/SuperCustom/SuperDelivery/SuperInstall";
import SuperShipping from "../../Components/SuperCustom/SuperDelivery/SuperShipping";
import SuperRush from "../../Components/SuperCustom/SuperDelivery/SuperRush";
import axios from "axios";

const SuperDelivery = () => {
  const product = useSelector((state) => state.activeStep.product);
  const [show, setShow] = useState(
    product.delivery.deliveryType ? product.delivery.deliveryType : "pickup"
  );
  const [warningOne, setWarningOne] = useState({});

  const deliveryOptions = [
    { name: "NYC Pick Up", method: "pickup" },
    // { name: "Messenger", method: "messenger" },
    { name: "Tri-State Area Install", method: "install" },
    { name: "Shipping", method: "shipping" },
    { name: "I'm in a RUSH!", method: "rush" },
  ];
  const dispatch = useDispatch();

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Super Delivery/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (heading) => {
    setShow(heading);
  };

  useEffect(() => {
    fetchWarnings(product.source, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="mt-3 super-margin-adjust"
        style={{
          border: "2px solid #AFE9E0",
          borderRadius: "24px",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <Row>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: "40px",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Delivery Options
            </Typography>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div
              style={{
                height: "50px",
                display: warningOne?.warningStatus ? "" : "none",
                zIndex: 9,
                marginTop: window.innerWidth < 762 ? "50px" : "",
                marginBottom: window.innerWidth < 762 ? "50px" : "",
              }}
              className="warning"
            >
              <Typography className="warning-title">
                {warningOne?.title}
              </Typography>
              <div
                className="enable-listing-style warning-desc"
                dangerouslySetInnerHTML={{
                  __html: warningOne?.warningContent,
                }}
              ></div>
            </div>
          </Col>
          <Row>
            <Typography
              style={{
                fontFamily: "Beatrice",
                textAlign: "justify",
                width: window.innerWidth > 768 ? "60%" : "100%",
              }}
            >
              Choose a delivery option below. Please note, some options are only
              available for certain locations
            </Typography>
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "row", // Default to row for desktop and tablet
              justifyContent: "space-between",
              padding: "20px",
              flexWrap: "wrap", // Allow wrapping for smaller screens
            }}
          >
            {deliveryOptions.map((option) => (
              <Col>
                <div
                  key={option.method} // Adding a unique key
                  style={{
                    minWidth: "250px", // Set a minimum width
                    height: "90px", // Set the height
                    border: "2px solid #AFE9E0",
                    borderRadius: "16px",
                    cursor: "pointer",
                    display: "flex",
                    marginRight: "8px",
                    marginBottom: "15px",
                    justifyContent: "center",
                    alignItems: "center", // Vertically center the text
                    textAlign: "center",
                  }}
                  className={`${
                    show === option?.method ? "selected" : "hover-effect"
                  }`}
                  onClick={() => {
                    handleSelect(option.method);
                    dispatch(setProductDeliveryType(option.method));
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                      fontSize: "18px",
                      fontWeight: "600",
                      cursor: "pointer",
                      borderRadius: "16px",
                    }}
                  >
                    {option.name}
                  </Typography>
                </div>
              </Col>
            ))}
          </Row>

          <>
            {show === "install" && <SuperInstall setSelected={setShow} />}
            {show === "shipping" && <SuperShipping setSelected={setShow} />}
            {show === "pickup" && <SuperPickUp />}
            {show === "messenger" && <SuperMessenger />}
            {show === "rush" && <SuperRush setSelected={setShow} />}
          </>
        </Row>
      </div>
    </>
  );
};

export default SuperDelivery;
