import React from "react";

const Pencil = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2888 4.22941L3.71094 16.0605L8.46734 20.7151L20.0452 8.88397L15.2888 4.22941Z"
        fill="white"
      />
      <path
        d="M2.838 17.5891L0.846128 23.7874L6.99997 21.6619L2.838 17.5891Z"
        fill="white"
      />
      <path
        d="M18.8761 0.551342L16.2754 3.20898L21.0318 7.86355L23.6325 5.20591L18.8761 0.551342Z"
        fill="white"
      />
    </svg>
  );
};

export default Pencil;
