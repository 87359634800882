import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLockedHeight,
  setLockedWidth,
  setProductBacking,
  setProductBackingColor,
  setProductBackingHeight,
  setProductBackingPaddings,
  setProductBackingWidth,
  setProductMinHeight,
  setProductMinWidth,
  setProductSizeDepth,
  setProductSizeFontSize,
} from "../../Redux/Slice";
import { Col, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { getOSInfo } from "../../utils/client/calculations";
import { debounce } from "../../utils/client/helpers";

const BackingStyleList = ({ setShowNoBacking, setBG }) => {
  const backings = useSelector((state) => state.activeStep.backingStyles);
  const product = useSelector((state) => state.activeStep.product);
  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  const handleBackingSelect = (backing) => {
    // console.log(backing);
    setBG(backing.type);
    if (backing.type === "no") {
      setShowNoBacking(true);
      dispatch(setProductBackingColor(""));
    } else {
      setShowNoBacking(false);
    }
    dispatch(setProductSizeFontSize(window.innerWidth > 768 ? 50 : 25));
    dispatch(setProductSizeDepth(backing?.depth));
    dispatch(setProductBacking(backing?.type));
    dispatch(setProductMinWidth(Number(backing?.minWidth)));
    dispatch(setProductMinHeight(Number(backing?.minHeight)));
    dispatch(
      setProductBackingWidth(
        Number(backing?.BackingLeftPadding + backing?.BackingRightPadding)
      )
    );
    dispatch(setLockedWidth(0));
    dispatch(setLockedHeight(0));
    dispatch(
      setProductBackingHeight(
        Number(backing?.BackingTopPadding + backing?.BackingBottomPadding)
      )
    );
    let paddings = {
      leftPadding: backing?.BackingLeftPadding,
      rightPadding: backing?.BackingRightPadding,
      topPadding: backing?.BackingTopPadding,
      bottomPadding: backing?.BackingBottomPadding,
    };
    // console.log(paddings);
    dispatch(setProductBackingPaddings(paddings));
  };

  const dispatch = useDispatch();

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  return (
    <>
      {backings.length > 0 ? (
        <>
          <Row
            style={{
              justifyContent: "center",
            }}
            draggable="false"
          >
            {backings.map((backing, index) => {
              const isSelectedBacking = product.backing.name === backing.type;
              return (
                <Col key={index} md={4} lg={12} xs={8} sm={6}>
                  <div
                    className={` border-2 border-[#AFE9E0] m-3 font-black text-center items-center text-3xl cursor-pointer rounded-2xl ${
                      isSelectedBacking ? "selected" : "hover-effect"
                    }`}
                  >
                    <div
                      style={{
                        borderRadius: "16px",
                        width: "100%",
                        position: "relative",
                        overflow: "hidden",
                      }}
                      onClick={() => handleBackingSelect(backing)}
                      onTouchStart={() => {
                        handleBackingSelect(backing);
                        handleHoverChange(index, true);
                      }}
                      onTouchEnd={() => {
                        handleHoverChange(index, false);
                      }}
                      onMouseEnter={() => {
                        let userPlatform = getOSInfo();
                        if (
                          userPlatform === "iPhone" ||
                          userPlatform === "Android"
                        ) {
                          // Handle iPhone-specific logic here
                        } else {
                          handleHoverChange(index, true);
                        }
                      }}
                      onMouseLeave={() => {
                        let userPlatform = getOSInfo();
                        if (
                          userPlatform === "iPhone" ||
                          userPlatform === "Android"
                        ) {
                          // Handle iPhone-specific logic here
                        } else {
                          handleHoverChange(index, false);
                        }
                      }}
                    >
                      <img
                        loading="lazy"
                        style={{
                          opacity: hoveredStates[index] ? 0 : 1,
                          width: "100%" /* Adjust as needed */,
                          height: "100%" /* Adjust as needed */,
                          transition: "opacity 0.3s ease",
                          borderRadius: "16px",
                        }}
                        draggable="false"
                        src={backing?.image}
                        alt={backing?.name}
                      />
                      {hoveredStates[index] && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {backing.name}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      ) : (
        <>
          <ThreeDots
            width="100%"
            radius="9"
            color="#36CDB4"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </>
      )}
    </>
  );
};

export default BackingStyleList;
