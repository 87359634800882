// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 768px) {
    .pickup-map{
        margin-top: 80px;  
    }
  }

  @media (max-width: 672px) {
    .pickup-map{
        margin-top: 100px;  
    }
  }
  @media (max-width: 530px) {
    .pickup-map{
        margin-top: 130px;  
    }
  }
  @media (max-width: 490px) {
    .pickup-map{
        margin-top: 180px;  
    }
  }
  @media (max-width: 482px) {
    .pickup-map{
        margin-top: 210px;  
    }
  }
  @media (max-width: 466px) {
    .pickup-map{
        margin-top: 250px;  
    }
  }
  @media (max-width: 392px) {
    .pickup-map{
        margin-top: 260px;  
    }
  }
 
  @media (max-width: 359px) {
    .pickup-map{
        margin-top: 300px;  
    }
  }
  @media (max-width: 354px) {
    .pickup-map{
        margin-top: 320px;  
    }
  }
  @media (max-width: 353px) {
    .pickup-map{
        margin-top: 350px;  
    }
  }
  @media (max-width: 347px) {
    .pickup-map{
        margin-top: 420px;  
    }
  }
  @media (max-width: 326px) {
    .pickup-map{
        margin-top: 450px;  
    }
  }
  @media (max-width: 292px) {
    .pickup-map{
        margin-top: 500px;  
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Components/Delivery/styles/Pickup.css"],"names":[],"mappings":";AACA;IACI;QACI,gBAAgB;IACpB;EACF;;EAEA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;;EAEA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF","sourcesContent":["\n@media (max-width: 768px) {\n    .pickup-map{\n        margin-top: 80px;  \n    }\n  }\n\n  @media (max-width: 672px) {\n    .pickup-map{\n        margin-top: 100px;  \n    }\n  }\n  @media (max-width: 530px) {\n    .pickup-map{\n        margin-top: 130px;  \n    }\n  }\n  @media (max-width: 490px) {\n    .pickup-map{\n        margin-top: 180px;  \n    }\n  }\n  @media (max-width: 482px) {\n    .pickup-map{\n        margin-top: 210px;  \n    }\n  }\n  @media (max-width: 466px) {\n    .pickup-map{\n        margin-top: 250px;  \n    }\n  }\n  @media (max-width: 392px) {\n    .pickup-map{\n        margin-top: 260px;  \n    }\n  }\n \n  @media (max-width: 359px) {\n    .pickup-map{\n        margin-top: 300px;  \n    }\n  }\n  @media (max-width: 354px) {\n    .pickup-map{\n        margin-top: 320px;  \n    }\n  }\n  @media (max-width: 353px) {\n    .pickup-map{\n        margin-top: 350px;  \n    }\n  }\n  @media (max-width: 347px) {\n    .pickup-map{\n        margin-top: 420px;  \n    }\n  }\n  @media (max-width: 326px) {\n    .pickup-map{\n        margin-top: 450px;  \n    }\n  }\n  @media (max-width: 292px) {\n    .pickup-map{\n        margin-top: 500px;  \n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
