import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import Slice from "./Slice";
import TotalSteps from "./TotalSteps";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const reducers = combineReducers({
  steps: TotalSteps,
  activeStep: Slice,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
