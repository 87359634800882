import React from "react";
import { useSelector } from "react-redux";

const HangingIframe = () => {
  const product = useSelector((state) => state.activeStep.product);

  return (
    <>
      {product.source === "LED" ? (
        <>
          <video
            loading="lazy"
            style={{
              borderRadius: "22px",
              height: "50vh",
            }}
            width="100%"
            controls
            controlsList="nodownload"
            loop
          >
            <source
              src={
                "https://glo-studio-resources.s3.amazonaws.com/f641f165-451e-40ea-8371-a0352efcb18e-mounting+plexi+backing+LED+installation+video+%281%29.mp4"
              }
              type="video/mp4"
            />
          </video>
        </>
      ) : (
        <>
          <iframe
            title="hanging"
            style={{
              borderRadius: "22px",
              height: window.innerWidth > 991 ? "50vh" : "30vh",
            }}
            width="100%"
            src="https://i.simmer.io/@GLO/hanging-install"
          ></iframe>
        </>
      )}
    </>
  );
};

export default HangingIframe;
