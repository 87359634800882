import React from "react";
import { Card } from "react-bootstrap";
import PassthroughIframe from "./PassthroughIframe";
import BackingIframe from "./BackingIframe";
import ConcealedIframe from "./ConcealedIframe";
import HangingIframe from "./HangingIframe";
import SurfaceIframe from "./SurfaceIframe";

const InstallCard = ({ select }) => {
  return (
    <>
      <Card
        style={{
          backgroundColor: "white",
          border: "none",
          marginTop: "20px",
        }}
      >
        <Card.Body
          style={{
            display: "flex",
            border: "1px solid #25CCB0",
            borderRadius: "24px",
            padding: "0px",
            justifyContent: "center",
          }}
        >
          {select?.name ? (
            <>
              {select?.name === "Surface Install" ? <SurfaceIframe /> : <></>}
              {select?.name === "Concealed Install" ? (
                <ConcealedIframe />
              ) : (
                <></>
              )}
              {select?.name === "Hanging Install" ? <HangingIframe /> : <></>}
              {select?.name === "Backing Install" ? <BackingIframe /> : <></>}
              {select?.name === "Passthrough Install" ? (
                <PassthroughIframe />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Card
                style={{
                  // width: "960px",
                  height: "200px",
                  display: "flex",
                  border: "none",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Installation method Selected
              </Card>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default InstallCard;
