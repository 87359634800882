import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowExamples, setTheme } from "../Redux/Slice";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import Moon from "../Assets/Moon";
import Sun from "../Assets/Sun";
import "../Components/styles/BottomControls.css";
import Info from "../Assets/SuperCustom/Info";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../utils/client/helpers";

const BottomControls = () => {
  const theme = useSelector((state) => state.activeStep.theme);
  const product = useSelector((state) => state.activeStep.product);
  const backings = useSelector((state) => state.activeStep.backingStyles);
  const backingSize = useSelector((state) => state.activeStep.product.backing);
  const showExamples = useSelector((state) => state.activeStep.showExamples);
  const [sizeExceed, setSizeExceed] = useState(false);
  const activeStep = useSelector((state) => state.activeStep);
  const lockedWidth = useSelector(
    (state) => state.activeStep.product.backing.lockedWidth
  );
  const lockedHeight = useSelector(
    (state) => state.activeStep.product.backing.lockedHeight
  );

  const dispatch = useDispatch();

  const toggleLight = () => {
    dispatch(setTheme("white"));
  };
  const toggleDark = () => {
    dispatch(setTheme("black"));
  };
  const width = product.size.width ? product.size.width : 0;
  const height = product.size.height ? product.size.height : 0;

  const [openInfo, setOpenInfo] = useState(false);
  const [openInfoMobile, setOpenInfoMobile] = useState(false);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="top-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 280,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });

  // console.log(lockedHeight, lockedWidth);

  return (
    <div
    // style={{
    //   fontFamily: "Beatrice",
    //   marginTop: "20px",
    //   padding: "0px",
    // }}
    // className="flex justify-between items-center flex-container"
    >
      <div
        style={{
          fontFamily: "Beatrice",
          marginTop: "10px",
          padding: "0px",
          display: window.innerWidth >= 768 ? "flex" : "none",
        }}
        className="justify-between items-center flex-container"
      >
        <Button
          className="hover-effect buttons-container"
          sx={{
            color: "#25CCB0",
            fontSize: "16px",
            fontFamily: "Beatrice",
            fontWeight: "600",
            textTransform: "uppercase",
            letterSpacing: "2px",
            bgcolor: "#D8F4F1",
            borderRadius: "16px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
          onClick={() => {
            dispatch(setShowExamples(!showExamples));
          }}
        >
          EXAMPLES
        </Button>
        {activeStep.step === 2 ? (
          <></>
        ) : (
          <>
            <ButtonGroup
              sx={{
                bgcolor: "#afe9e0",
                height: "50px",
                fontFamily: "Beatrice",
              }}
              className="bg-[#D8F4F1] rounded-full buttons-container"
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button
                style={{
                  border: "none",
                  borderRadius: "22px",
                  // color: theme === "white" ? "white" : "#25CCB0",
                  backgroundColor: theme === "white" ? "#25CCB0" : "#D8F4F1",
                }}
                onClick={toggleLight}
              >
                <Sun color={theme === "white" ? "white" : "#25CCB0"} />
              </Button>
              <div
                style={{
                  width: "10px",
                }}
              ></div>
              <Button
                className="buttons-container"
                style={{
                  border: "none",
                  borderRadius: "22px",
                  // color: theme === "black" ? "white" : "#25CCB0",
                  backgroundColor: theme === "black" ? "#25CCB0" : "#D8F4F1",
                }}
                onClick={toggleDark}
              >
                <Moon color={theme === "black" ? "white" : "#25CCB0"} />
              </Button>
            </ButtonGroup>
          </>
        )}
        <div
          style={{
            fontFamily: "Beatrice",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: "1.2rem",
              }}
              className="text-[#25CCB0] text-right"
            >
              {Math.ceil(Number(width) + backingSize.width + lockedWidth)}’’
              Wide x{" "}
              {Math.ceil(Number(height) + backingSize.height + lockedHeight)}
              ’’ Tall
            </Typography>
            <CustomWidthTooltip
              open={openInfo}
              onClose={() => handleTooltipClose(setOpenInfo)}
              title={
                "If you have questions about size, or if you can’t design exactly what you want here, no worries! Go through the process, use the notepad to share your thoughts. At the end, submit an inquiry. Someone from our team will guide you :)"
              }
            >
              <MyComponent
                onMouseEnter={() => handleMouseEnter(setOpenInfo)}
                onMouseLeave={() => handleMouseLeave(setOpenInfo)}
                onClick={() => handleClick(setOpenInfo, openInfo)}
                style={{
                  marginLeft: "10px",
                  display: "inline-block",
                }}
              />
            </CustomWidthTooltip>
          </div>
          {sizeExceed ? (
            <Typography
              style={{
                fontFamily: "Beatrice",
              }}
              className="text-[#25CCB0] text-right"
            >
              (Maximum Backing Size Exceed)
            </Typography>
          ) : (
            <></>
          )}
          {backings?.map((backing) => {
            if (backing.type === product.backing.name) {
              if (
                product.size.width === Number(backing.minWidth) &&
                product.size.height === Number(backing.minHeight)
              ) {
                return (
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                    }}
                    className="text-[#25CCB0] text-right"
                  >
                    (Default: smallest backing size possible)
                  </Typography>
                );
              } else {
                return null;
              }
            }
          })}
          {product?.size?.width === 4 && product?.size?.height === 4 ? (
            <Typography
              style={{
                fontFamily: "Beatrice",
              }}
              className="text-[#727272] text-right"
            >
              (Default: smallest possible)
            </Typography>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          display: window.innerWidth < 768 ? "flex" : "none",
        }}
        className="justify-between items-center flex-container"
      >
        <Button
          className="hover-effect buttons-container"
          sx={{
            color: "#25CCB0",
            fontSize: "16px",
            fontFamily: "Beatrice",
            fontWeight: "600",
            textTransform: "uppercase",
            letterSpacing: "2px",
            bgcolor: "#D8F4F1",
            borderRadius: "16px",
            paddingRight: "20px",
            paddingLeft: "20px",
            margin: "10px",
          }}
          onClick={() => {
            dispatch(setShowExamples(!showExamples));
          }}
        >
          EXAMPLES
        </Button>
        {activeStep.step === 2 ? (
          <></>
        ) : (
          <>
            <ButtonGroup
              sx={{
                bgcolor: "#afe9e0",
                height: "50px",
                fontFamily: "Beatrice",
                margin: "10px",
              }}
              className="bg-[#D8F4F1] rounded-full buttons-container"
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button
                style={{
                  border: "none",
                  borderRadius: "22px",
                  // color: theme === "white" ? "white" : "#25CCB0",
                  backgroundColor: theme === "white" ? "#25CCB0" : "#D8F4F1",
                }}
                onClick={toggleLight}
              >
                <Sun color={theme === "white" ? "white" : "#25CCB0"} />
              </Button>
              <div
                style={{
                  width: "10px",
                }}
              ></div>
              <Button
                className="buttons-container"
                style={{
                  border: "none",
                  borderRadius: "22px",
                  // color: theme === "black" ? "white" : "#25CCB0",
                  backgroundColor: theme === "black" ? "#25CCB0" : "#D8F4F1",
                }}
                onClick={toggleDark}
              >
                <Moon color={theme === "black" ? "white" : "#25CCB0"} />
              </Button>
            </ButtonGroup>
          </>
        )}
        <div
          style={{
            fontFamily: "Beatrice",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                fontSize: "1.2rem",
              }}
              className="text-[#25CCB0] text-right"
            >
              {Math.ceil(Number(width) + backingSize.width + lockedWidth)}’’
              Wide x{" "}
              {Math.ceil(Number(height) + backingSize.height + lockedHeight)}
              ’’ Tall
            </Typography>

            <CustomWidthTooltip
              open={openInfoMobile}
              onClose={() => handleTooltipClose(setOpenInfoMobile)}
              title={
                "If you have questions about size, or if you can’t design exactly what you want here, no worries! Go through the process, use the notepad to share your thoughts. At the end, submit an inquiry. Someone from our team will guide you :)"
              }
            >
              <MyComponent
                onMouseEnter={() => handleMouseEnter(setOpenInfoMobile)}
                onMouseLeave={() => handleMouseLeave(setOpenInfoMobile)}
                onClick={() => handleClick(setOpenInfoMobile, openInfoMobile)}
                style={{
                  marginLeft: "10px",
                  display: "inline-block",
                }}
              />
            </CustomWidthTooltip>
          </div>
          {sizeExceed ? (
            <Typography
              style={{
                fontFamily: "Beatrice",
              }}
              className="text-[#25CCB0] text-right"
            >
              (Maximum Backing Size Exceed)
            </Typography>
          ) : (
            <></>
          )}
          {backings?.map((backing) => {
            if (backing.type === product.backing.name) {
              if (
                product.size.width === Number(backing.minWidth) &&
                product.size.height === Number(backing.minHeight)
              ) {
                return (
                  <Typography
                    style={{
                      fontFamily: "Beatrice",
                    }}
                    className="text-[#25CCB0] text-right"
                  >
                    (Default: smallest backing size possible)
                  </Typography>
                );
              } else {
                return null;
              }
            }
          })}
          {product?.size?.width === 4 && product?.size?.height === 4 ? (
            <Typography
              style={{
                fontFamily: "Beatrice",
              }}
              className="text-[#727272] text-right"
            >
              (Default: smallest possible)
            </Typography>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomControls;
