import React from "react";

const Browse = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1251 33.3363C26.5781 33.3363 34.2502 25.8689 34.2502 16.6681C34.2502 7.46732 26.5781 0 17.1251 0C7.67203 0 0 7.46732 0 16.6681C0 25.8689 7.67203 33.3363 17.1251 33.3363Z"
        fill="#36CDB4"
      />
      <path
        d="M27.1726 14.2684C27.1726 14.3129 27.1726 14.3796 27.1726 14.424C27.1726 14.4462 27.1498 14.4462 27.1498 14.4684C26.3278 17.1576 25.5058 19.8245 24.6838 22.5136C24.6382 22.6692 24.5468 22.7803 24.4555 22.8692C24.2956 23.0025 24.1129 23.0692 23.9074 23.1137C23.8846 23.1137 23.8618 23.1137 23.8161 23.1137C18.8613 23.1137 13.9064 23.1137 8.95155 23.1137C8.90588 23.1137 8.83739 23.1137 8.79172 23.1137C8.76889 23.0914 8.74604 23.0914 8.72321 23.0914C8.42638 23.0025 8.3122 22.7803 8.42637 22.4692C8.56337 22.0024 8.72323 21.5135 8.86023 21.0468C9.5224 18.8688 10.1846 16.6909 10.8696 14.5129C10.9153 14.3573 11.0066 14.224 11.1207 14.1351C11.3262 13.9573 11.5774 13.8906 11.8286 13.8906C16.7378 13.8906 21.647 13.8906 26.5561 13.8906C26.6018 13.8906 26.6475 13.8906 26.6703 13.8906C26.8986 13.9128 27.1041 14.0017 27.1726 14.2684Z"
        fill="white"
      />
      <path
        d="M13.5197 8.86817C13.9307 9.00152 14.1591 9.31265 14.3417 9.69046C14.5929 10.2016 14.7527 10.7572 14.8897 11.3128C14.9125 11.4017 14.9354 11.424 15.0267 11.424C17.9494 11.424 20.8949 11.424 23.8176 11.424C24.1373 11.424 24.3656 11.5573 24.5254 11.8462C24.5711 11.9573 24.6168 12.0685 24.6168 12.1796C24.6168 12.4685 24.6168 12.7352 24.6168 13.0241C24.6168 13.0908 24.5939 13.113 24.5483 13.0908C24.5026 13.0908 24.4569 13.0908 24.4341 13.0908C20.2556 13.0908 16.0771 13.0908 11.8986 13.0908C11.4419 13.0908 11.0537 13.2241 10.6884 13.513C10.4372 13.713 10.2774 13.9797 10.1861 14.2909C9.75221 15.691 9.31836 17.0911 8.90736 18.4912C8.74752 19.0468 8.56486 19.6025 8.40503 20.1581C8.40503 20.2025 8.40504 20.2247 8.35938 20.247C8.35938 16.98 8.35938 13.713 8.35938 10.4461C8.38221 10.4239 8.38217 10.4016 8.38217 10.3794C8.42784 10.0683 8.49636 9.77936 8.63337 9.49045C8.74753 9.24598 8.88452 9.04596 9.13569 8.93484C9.20419 8.89039 9.29552 8.86817 9.36402 8.84595C10.7112 8.86817 12.104 8.86817 13.5197 8.86817Z"
        fill="white"
      />
    </svg>
  );
};

export default Browse;
