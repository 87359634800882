import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import Close from "../../../../Assets/Close";
import { setProductDeliveryType } from "../../../../Redux/Slice";
import { useDispatch } from "react-redux";

const RushPopUp = ({ setSelected, show, close }) => {
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "30%" : "80%",
    // height: "95vh",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice",
                color: "#000",
                fontSize: "20px",
                fontWeight: "600",
                letterSpacing: "0.05em",
              }}
            >
              Considerations with Rush
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                close();
                dispatch(setProductDeliveryType("pickup"));
                setSelected("pickup");
              }}
            >
              <Close />
            </div>
          </div>
          <div>
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                marginBottom: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#595959",
                  fontFamily: "Beatrice",
                  fontWeight: "600",
                }}
              >
                There are a few different options depending on how soon you need
                your piece, and what else is on our schedule. But the good news
                is we can often turn Glass neon pieces around in under two
                weeks.
                <br />
                <br />
                If theres anything we should know about your flexibility and
                timing, please be sure to let us know below.
                <br />
                <br />
                Don’t fret — we’re here to come up with solutions that work for
                you!
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Beatrice",
                  width: window.innerWidth > 768 ? "50%" : "100%",
                  textAlign: "center",
                  paddingTop: "10px",
                  border: "none",
                  backgroundColor: "#36CDB4",
                  borderRadius: "35px",
                  textTransform: "uppercase",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                }}
                onClick={close}
              >
                Ok, I’m ready
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RushPopUp;
