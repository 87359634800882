import { createSlice } from "@reduxjs/toolkit";

export const TotalSteps = createSlice({
  name: "steps",
  initialState: [
    "Format",
    "Light Source",
    "Content",
    "Colors",
    "Backing Style",
    "Backing Color",
    "Size",
    "Accessories",
    "Delivery",
    "Review",
  ],
  reducers: {},
});

export default TotalSteps.reducer;
