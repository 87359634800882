import { ReactComponent as Rainbow } from "../../Assets/Rainbow.svg";
import React from "react";
import { Popover, Typography } from "@mui/material";
import Close from "../../Assets/Close";
import { SketchPicker } from "react-color";
import { useDispatch } from "react-redux";
import { setProductBackingColor } from "../../Redux/Slice";

const RGBButton = ({ selectedColor, setSelectedColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDescriptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDescriptionClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleChangeComplete = (color) => {
    document.documentElement.style.setProperty(
      "--custom-background-color",
      color.hex
    );
    setSelectedColor(color.hex);
    // console.log(color);
    dispatch(setProductBackingColor(color.hex));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <button
        style={{
          padding: "10px",
        }}
        onClick={handleDescriptionClick}
      >
        <Rainbow />
      </button>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            backgroundColor: "#F4F4F4",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleDescriptionClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F4",
            margin: "10px",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#595959",
                fontFamily: "Beatrice",
                fontWeight: "600",
                fontSize: "15px",
              }}
            >
              Select a custom Color
            </Typography>
            <button onClick={handleDescriptionClose}>
              <Close />
            </button>
          </div>
          <div
            style={{
              margin: "5px",
              background: "white",
              borderRadius: "16px",
              padding: "5px",
            }}
          >
            <SketchPicker
              color={selectedColor}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default RGBButton;
