import { Button, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import domtoimage from "dom-to-image";
import SelectedCard from "../Components/Review/SelectedCard";
import Notes from "../Components/Review/Notes";
import BasicInfo from "../Components/Review/BasicInfo";
import DeliveryInfo from "../Components/Review/DeliveryInfo";
import Preview from "../Components/Review/Preview";
import PriceDetails from "../Components/Review/PriceDetails";
import Save from "../Components/Review/Save";
import Share from "../Components/Review/Share";
import ImageCard from "../Components/Review/ImageCard";
import BackingStyleCard from "../Components/Review/BackingStyleCard";
import BackingColorCard from "../Components/Review/BackingColorCard";
import AccessoriesCard from "../Components/Review/AccessoriesCard";
import SizeCard from "../Components/Review/SizeCard";
import AddOnCard from "../Components/Review/AddOnCard";
import ControllerCard from "../Components/Review/ControllerCard";
import { fetchHelp } from "../Redux/Slice";
import axios from "axios";
import RefImageCard from "../Components/Review/RefImageCard";
import "./styles/review.css";
import ThankYouModal from "../Components/Review/ThankYouModal";
import SuperPreview from "../Components/Review/SuperPreview";
import SubmitInquiry from "../Components/Review/SubmitInquiry";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { getBrowserInfo } from "../utils/client/calculations";

const opentype = require("opentype.js");

const Review = () => {
  const InstallationPrice = useSelector(
    (state) => state.activeStep.product.installationPrice
  );
  const [openThankYou, setOpenThankYou] = useState(false);
  const [errors, setErrors] = useState({});
  const [link, setLink] = useState("");
  const [toc, setTOC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [svgLink, setSvgLink] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showInquiry, setShowInquiry] = useState(false);
  const [png, setPng] = useState(null);
  const [warningOne, setWarningOne] = useState({});
  const [warningTwo, setWarningTwo] = useState({});

  const fetchWarnings = async (type, number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}warning-pages/Review/${type}/${number}`
      );
      switch (number) {
        case 1:
          setWarningOne(response.data);
          break;
        case 2:
          setWarningTwo(response.data);
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const colors = useSelector((state) => state.activeStep.colors);
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const cables = useSelector((state) => state.activeStep.cables);
  const product = useSelector((state) => state.activeStep.product);
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const [weightPerCubicInch, setWeightPerCubicInch] = useState(0);
  const factor = useSelector((state) => state.activeStep.materialLengthFactor);

  const format = useSelector((state) => state.activeStep.product.format);
  const source = useSelector((state) => state.activeStep.product.source);
  const power = useSelector((state) => state.activeStep.power);
  const powerQuantity = useSelector((state) => state.activeStep.powerQuantity);
  const lockedWidth = useSelector(
    (state) => state.activeStep.product.backing.lockedWidth
  );
  const lockedHeight = useSelector(
    (state) => state.activeStep.product.backing.lockedHeight
  );
  const shippingCost = useSelector(
    (state) => state.activeStep.product.shippingCost
  );
  const shippingAPIRate = useSelector(
    (state) => state.activeStep.shippingAPIRate
  );
  const cost = useSelector((state) => state.activeStep.product.cost);

  const fonts = useSelector((state) => state.activeStep.product.fonts);
  const backing = useSelector((state) => state.activeStep.product.backing);
  const refImage = useSelector((state) => state.activeStep.product.refImage);

  const size = useSelector((state) => state.activeStep.product.size);

  const dimmer = useSelector(
    (state) => state.activeStep.product.accessories.dimmer
  );
  const deliveryDetails = useSelector(
    (state) => state.activeStep.product.delivery.details
  );
  const fontFamilyArray = fonts.map((font) => font.fontFamily);
  const fontColors = fonts.flatMap((font) =>
    font.isSplit ? font.letters.map((letter) => letter.color) : font.color
  );

  const savedDrawingData =
    JSON.parse(localStorage.getItem("savedDrawing")) || "";

  const matchingColors = colors.filter((color) =>
    fontColors.includes(color.code)
  );

  const colorImages = matchingColors.map((color) => color.imageOn);

  const WH = `W${
    Number(Math.ceil(size?.width)?.toFixed(0)) +
    Number(product.backing.width) +
    product.backing.lockedWidth
  }xH${
    Number(Math.ceil(size?.height)?.toFixed(0)) +
    Number(product.backing.height) +
    product.backing.lockedHeight
  }`;

  const dispatch = useDispatch();
  const date = new Date();

  let backingStyle = "";
  let wireColor = "black";

  const validateForm = () => {
    const errors = {};
    if (!details?.name) {
      errors.name = "Name is required";
    }
    if (toc === false) {
      errors.toc = "Please check this box";
    }
    if (!details?.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(details?.email)) {
      errors.email = "Email is invalid";
    }
    if (!details?.phoneNumber) {
      errors.phoneNumber = "Contact is required";
    } else if (
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
        details?.phoneNumber
      )
    ) {
      errors.phoneNumber = "Contact is invalid";
    }

    // console.log(errors);
    return errors;
  };

  const calculateFontArea = async (value, font, jsonLink) => {
    let totalWidth = 0;
    let materialLength = 0;
    let maxHeight = 0;
    let currentMaxHeight = 0;

    if (!font) {
    } else {
      try {
        const response = await fetch(jsonLink);
        const fontData = await response.json();

        if (value) {
          for (const char of value) {
            if (char === " ") {
              const spaceCharacter = fontData.find(
                (data) => data.char === "space"
              );
              if (spaceCharacter) {
                const width = spaceCharacter.width / 96;
                totalWidth += width;
                materialLength += spaceCharacter.length / 96;
              }
            } else if (char === "\n") {
              maxHeight += currentMaxHeight;
              currentMaxHeight = 0;
            } else {
              const character = fontData.find((data) => data.char === char);
              if (character) {
                const width = character.width / 96;
                totalWidth += width;

                const height = character.height / 96;
                materialLength += character.length / 96;
                if (height > currentMaxHeight) {
                  currentMaxHeight = height;
                }
              }
            }
          }
        }

        maxHeight += currentMaxHeight;
        materialLength *= factor;
        return { materialLength, totalWidth, maxHeight };
      } catch (error) {
        console.error("Error fetching font data:", error);
      }
    }
  };

  const calculateKerning = async (woffFile, text) => {
    try {
      const response = await axios.get(woffFile, {
        responseType: "arraybuffer",
      });
      const fontDataArrayBuffer = response.data;
      const fontData = new Uint8Array(fontDataArrayBuffer);
      const font = opentype.parse(fontData.buffer);
      // Convert the text into an array of glyphs
      const glyphs = [];
      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const glyph = font.charToGlyph(char);
        glyphs.push(glyph);
      }

      // Calculate kerning for each character
      let totalKerningValue = 0;
      for (let i = 0; i < glyphs.length - 1; i++) {
        const pair1 = glyphs[i];
        const pair2 = glyphs[i + 1];
        const kerningValue = font.getKerningValue(pair1, pair2);
        totalKerningValue += kerningValue;
      }

      if (totalKerningValue !== 0) {
        return totalKerningValue / 96;
      } else {
        return totalKerningValue;
      }
    } catch (error) {
      console.error("Error in calculateKerning:", error);
      throw error;
    }
  };

  const calculateLetterLength = async (letter, font, jsonLink) => {
    if (!font) {
      // Handle error when font is not selected
      return 0;
    }

    try {
      const response = await fetch(jsonLink);
      const fontData = await response.json();

      let width = 0;
      let height = 0;
      let materialLength = 0;

      if (letter === " ") {
        const spaceCharacter = fontData.find((data) => data.char === "space");
        if (spaceCharacter) {
          width = spaceCharacter.width / 96;
          height = spaceCharacter.height / 96;
          materialLength = spaceCharacter.length / 96;
        }
      } else {
        const character = fontData.find((data) => data.char === letter);
        if (character) {
          width = character.width / 96;
          height = character.height / 96;
          materialLength = character.length / 96;
        }
      }
      materialLength *= factor;

      return { width, height, materialLength };
    } catch (error) {
      console.error("Error fetching font data:", error);
      return 0; // Return 0 or handle the error in an appropriate way
    }
  };

  const convertToPNG = async () => {
    try {
      const divToConvert = document.getElementById("divToConvert");

      if (product.format === "super-custom" && refImage.link) {
        const backgroundImage = new Image();
        backgroundImage.src = refImage.link || ""; // Use the background image URL
        await new Promise((resolve) => {
          backgroundImage.onload = resolve;
        });
      }

      // Wait for a short period (e.g., 500 milliseconds) to allow drawings to render
      await new Promise((resolve) => setTimeout(resolve, 500));

      domtoimage
        .toPng(divToConvert)
        .then(async (dataUrl) => {
          const blob = await fetch(dataUrl).then((response) => response.blob());
          const form = new FormData();

          form.append("image", blob, "image.png");

          // Upload the image to the S3 bucket or perform any other necessary action
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}led-backing`,
            form
          );

          // Log the response data from the server
          console.log("Response Data:", response.data);

          // Set the image data in your state (assuming you have a state variable named 'setPng')
          setPng(response.data);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    } catch (error) {
      console.error("Error converting to PNG:", error);
    }
  };

  const handleSubmit = async () => {
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    if (format && source && fonts && backing && png) {
      try {
        let concatenatedValues = "";
        product.fonts.forEach((font) => {
          concatenatedValues += font.value + " ";
        });
        let fontsDimensions = [];
        for (const font of product.fonts) {
          const dimensions = await calculateFontArea(
            font.value,
            font.fontFamily,
            font.jsonLink
          );
          fontsDimensions.push({
            fontFamily: font.fontFamily,
            value: font.value,
            ...dimensions,
          });
        }
        // console.log(fontsDimensions);

        let letterDimensions = {};
        for (const font of product.fonts) {
          if (!letterDimensions[font.value]) {
            // Initialize the nested object for the font.value
          }

          for (const char of font.value) {
            const key = `${font.value}_${font.fontFamily}`; // Create a unique key for each font
            if (!letterDimensions[key]) {
              letterDimensions[key] = {}; // Initialize the nested object for the font
            }

            const letterDimension = await calculateLetterLength(
              char,
              font.fontFamily,
              font.jsonLink
            );

            letterDimensions[key][char] = letterDimension;
          }
        }

        let colorNames = fontColors.map((code) => {
          let found = colors.find((color) => color.code === code);
          return found ? found.name : "custom";
        });

        if (savedDrawingData) {
          const colorCodes = savedDrawingData.map((item) => item.strokeColor);
          const matchingColors = colors.filter((color) =>
            colorCodes.includes(color.code)
          );

          matchingColors.forEach((color) => {
            if (!colorNames.includes(color.name)) {
              colorNames.push(color.name);
            }
          });
        }
        // console.log(colorNames);

        let uniqueColorNamesSet = new Set(colorNames);
        let uniqueColorNamesArray = Array.from(uniqueColorNamesSet);

        let backingColor = backingColors.find(
          (color) => color.code === product.backing.color
        );
        let accessoryColor = cables.find(
          (cable) => cable.color === product.accessories.wireColor
        );
        let weight;
        if (product.source === "LED") {
          weight =
            (Number(size.width) + backing.width + backing.lockedWidth + 3) *
            (Number(size.height) + backing.height + backing.lockedHeight + 3) *
            (Number(size.depth) + 3) *
            weightPerCubicInch;
        } else {
          weight =
            (Number(size.width) + backing.width + backing.lockedWidth + 5) *
            (Number(size.height) + backing.height + backing.lockedHeight + 5) *
            (Number(size.depth) + 5) *
            weightPerCubicInch;
        }

        concatenatedValues = concatenatedValues.trim();
        let updatedProduct = {
          ...product,
          fontsColor: uniqueColorNamesArray,
          backingColor: backingColor ? backingColor?.name : "Custom Color",
          wireColor: accessoryColor?.name,
          widthInches: product.size.width + product.backing.width,
          HeightInches: product.size.height + product.backing.height,
          png: png,
          cost: cost + shippingCost + InstallationPrice + shippingAPIRate,
          concatenatedValues: concatenatedValues,
          productionImage: svgLink ? svgLink : "N/A",
          userCreatedProductImage: png,
          drawLines: localStorage.getItem("savedDrawing"),
          orderType: "submission",
          customShapeImage: product.backing.image,
          deliveryDate: product.shippingTime ? product.shippingTime : "N/A",
          orderDate: date.toISOString(),
          customerName: details?.name,
          customerEmail: details?.email,
          totalWeight: weight,
          fullfillmentStatus: "submission",
          fontsDimensions: fontsDimensions,
          WordDimensions: letterDimensions,
          power: power,
          powerQuantity: powerQuantity,
          kerning: 0,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}submission/${details?.email}`,
          updatedProduct
        );
        // console.log(response.data);
        if (response.data) {
          setOpenThankYou(true);
        }
      } catch (err) {
        console.log(err);
      }
      // console.log(updatedProduct);
    } else {
      toast.error("Please Complete your order first");
    }
  };

  async function calculateTotalKerning(fonts) {
    const kerningPromises = fonts.map(async (font) => {
      const kerning = await calculateKerning(font?.woffFile, font?.value);
      return kerning;
    });

    const kerningValues = await Promise.all(kerningPromises);

    const totalKerning = kerningValues.reduce((sum, value) => sum + value, 0);

    return totalKerning;
  }

  const handleDraft = async () => {
    let errors = validateForm();
    if (Object.keys(errors).length > 0) {
      // console.log("validating");
      setErrors(errors);
      return;
    }
    setErrors({});
    if (format && source && fonts && backing && png) {
      try {
        setLoading(true);
        let concatenatedValues = "";
        product.fonts.forEach((font) => {
          concatenatedValues += font.value + " ";
        });

        let colorNames = fontColors.map((code) => {
          let found = colors.find((color) => color.code === code);
          return found ? found.name : "custom";
        });
        let uniqueColorNamesSet = new Set(colorNames);
        let uniqueColorNamesArray = Array.from(uniqueColorNamesSet);

        let backingColor = backingColors.find(
          (color) => color.code === product.backing.color
        );
        let accessoryColor = cables.find(
          (cable) => cable.color === product.accessories.wireColor
        );
        // console.log(accessoryColor);

        let fontsDimensions = [];
        for (const font of product.fonts) {
          const dimensions = await calculateFontArea(
            font.value,
            font.fontFamily,
            font.jsonLink
          );
          fontsDimensions.push({
            fontFamily: font.fontFamily,
            value: font.value,
            ...dimensions,
          });
        }
        // console.log(fontsDimensions);

        let letterDimensions = {};
        for (const font of product.fonts) {
          if (!letterDimensions[font.value]) {
            // Initialize the nested object for the font.value
          }

          for (const char of font.value) {
            const key = `${font.value}_${font.fontFamily}`; // Create a unique key for each font
            if (!letterDimensions[key]) {
              letterDimensions[key] = {}; // Initialize the nested object for the font
            }

            const letterDimension = await calculateLetterLength(
              char,
              font.fontFamily,
              font.jsonLink
            );

            letterDimensions[key][char] = letterDimension;
          }
        }
        let totalKerning = 0;

        const userAgent = getBrowserInfo();
        if (userAgent === "Apple Safari") {
        } else {
          totalKerning = await await calculateTotalKerning(product?.fonts);
        }

        let weight = 0;
        if (product.source === "LED") {
          weight =
            (Number(size.width) + backing.width + backing.lockedWidth + 3) *
            (Number(size.height) + backing.height + backing.lockedHeight + 3) *
            (Number(size.depth) + 3) *
            weightPerCubicInch;
        } else {
          weight =
            (Number(size.width) + backing.width + backing.lockedWidth + 5) *
            (Number(size.height) + backing.height + backing.lockedHeight + 5) *
            (Number(size.depth) + 5) *
            weightPerCubicInch;
        }

        concatenatedValues = concatenatedValues.trim();
        let updatedProduct = {
          ...product,
          fontsColor: uniqueColorNamesArray,
          backingColor: backingColor ? backingColor?.name : "Custom Color",
          wireColor: accessoryColor?.name,
          widthInches: product.size.width + product.backing.width + lockedWidth,
          HeightInches:
            product.size.height + product.backing.height + lockedHeight,
          png: png,
          cost: cost + shippingCost + InstallationPrice,
          concatenatedValues: concatenatedValues,
          productionImage: svgLink ? svgLink : "N/A",
          userCreatedProductImage: png,
          customShapeImage: product.backing.image,
          deliveryDate: product.shippingTime ? product.shippingTime : "N/A",
          orderDate: date.toISOString(),
          customerName: details?.name,
          customerEmail: details?.email,
          customerContactNumber: details?.phoneNumber,
          customerAddress: deliveryDetails?.address,
          customerCountry: deliveryDetails?.country
            ? deliveryDetails?.country
            : "US",
          customerProvince: deliveryDetails?.state,
          customerCity: deliveryDetails?.city,
          customerZipCode: deliveryDetails?.zip,
          totalWeight: weight,
          fontsDimensions: fontsDimensions,
          WordDimensions: letterDimensions,
          power: power,
          powerQuantity: powerQuantity,
          kerning: totalKerning,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}shopify-checkout/draft-order`,
          updatedProduct
        );
        // console.log(response.data);
        if (response.data) {
          setLink(response.data);
          setLoading(false);
          // setOpenThankYou(true);
          window.open(response.data, "_self", "noreferrer");
        }
      } catch (err) {
        // alert(JSON.stringify(err));
        console.log(err);
      }
      // console.log(updatedProduct);
    } else {
      toast.error("Please Complete your order first");
    }
  };

  const mergeSVGs = async (svgPaths) => {
    const svgPromises = svgPaths.map(async (path) => {
      const response = await axios.get(path?.url);
      const svgContent = response.data;
      const newSvg = convertSVG(svgContent);
      return newSvg;
    });

    const svgContents = await Promise.all(svgPromises);
    return svgContents;
  };

  function convertSVG(svgString) {
    // Parse the input SVG string
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

    // Get the viewBox attribute value if it exists
    const viewBoxAttr = svgDoc.documentElement.getAttribute("viewBox");
    let width = 100;
    let height = 100;

    if (viewBoxAttr) {
      const viewBoxValues = viewBoxAttr.split(" ").map(parseFloat);
      width = viewBoxValues[2];
      height = viewBoxValues[3];
    }

    // Get the paths and lines within the original SVG
    const pathsAndLines = Array.from(svgDoc.querySelectorAll("svg > *"));

    // Create the new SVG string
    let newSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
       `;

    // Add the paths and lines to the new SVG
    pathsAndLines.forEach((element) => {
      newSVG += `            ${element.outerHTML}\n`;
    });

    newSVG += `        
    </svg>`;

    return newSVG;
  }

  function getHighestSvgHeight(svgArray) {
    let maxHeight = 0;

    svgArray.forEach((svgString) => {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
      const svgElement = svgDoc.documentElement;
      const height = parseFloat(svgElement.getAttribute("height"));

      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    return maxHeight;
  }

  function combineAndAdjustSVGs(svgArray, tracking) {
    const bottomHeight = getHighestSvgHeight(svgArray);
    const strokeWidth = 20;
    const gap = tracking ? tracking : 10;

    let xOffset = 0;
    // let yOffset = 0;
    let totalHeight = 0;

    let combinedSVG = `
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style>.e{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:${strokeWidth}px;}</style>
        </defs>
        <g>
    `;

    for (const [index, svgContent] of svgArray.entries()) {
      const svgHeight = svgContent.match(/height="([^"]+)"/);
      let floatSVGHeight = parseFloat(svgHeight[1]);
      const adjustedSVG = svgContent.replace(/<svg[^>]+>|<\/svg>/g, "");
      combinedSVG += `
          <!-- Content from the SVG -->
          <g id="${index}" transform="translate(${xOffset}, ${
        bottomHeight - floatSVGHeight
      })">
            ${adjustedSVG}
          </g>
      `;

      const widthMatch = svgContent.match(/width="([^"]+)"/);
      const heightMatch = svgContent.match(/height="([^"]+)"/);
      if (widthMatch && heightMatch) {
        const svgWidth = parseFloat(widthMatch[1]);
        const svgHeight = parseFloat(heightMatch[1]);
        totalHeight = Math.max(totalHeight, svgHeight + strokeWidth);
        xOffset += svgWidth + gap;
      }
    }

    combinedSVG += `
        </g>
      </svg>
    `;

    combinedSVG = combinedSVG.replace(
      "<svg",
      `<svg viewBox="0 0 ${xOffset} ${totalHeight}" width="${
        (product.size.width +
          product.backing.width +
          product.backing.lockedWidth) *
        96
      }" height="${
        (product.size.height +
          product.backing.height +
          product.backing.lockedHeight) *
        96
      }" x="192" y="0"`
    );

    return combinedSVG;
  }

  const fetchAndMergeFilteredSVGs = async (textValue, tracking, svgFiles) => {
    try {
      const normalizedTextValue = textValue.replace(/\n/g, " ");
      const orderedCharacters = normalizedTextValue.split("");
      const filteredSVGPaths = orderedCharacters.map((character) => {
        return svgFiles.find((svgFile) => {
          const svgCharacter = getCharacterFromPath(svgFile.name);
          if (character === " ") {
            character = "space";
          }

          return character === svgCharacter;
        });
      });

      const mergedContent = await mergeSVGs(filteredSVGPaths);

      const combinedSVGS = combineAndAdjustSVGs(mergedContent, tracking);

      const blob = new Blob([combinedSVGS], { type: "image/svg+xml" });
      const svgFile = new File([blob], "image.svg", { type: "image/svg+xml" });
      const form = new FormData();
      form.append("image", svgFile);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}led-backing`,
        form
      );

      let newObj = { text: textValue, link: response.data };
      setSvgLink((prevSvgLink) => [...prevSvgLink, newObj]);
    } catch (error) {
      console.error("Error fetching or merging SVGs:", error);
    }
  };

  const fetchSVGs = async (textArea) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fonts-svgs/get-svgs/${product.source}/${textArea.fontFamily}`
      );
      if (response.data.svgFiles) {
        fetchAndMergeFilteredSVGs(
          textArea.value,
          textArea.tracking,
          response.data.svgFiles
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    fonts.forEach((textArea) => {
      fetchSVGs(textArea);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    convertToPNG();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCharacterFromPath = (path) => {
    const fileName = path.split(".");
    if (fileName[0] === "space") {
      return fileName[0];
    }
    return fileName[0].charAt(0);
  };

  useEffect(() => {
    dispatch(fetchHelp(`Review/${product.source}`));
    fetchWarnings(product.source, 1);
    fetchWarnings(product.source, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWeight = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/shippings/${product.source}/${product.backing.name}`
      );
      if (response?.data[0]) {
        setWeightPerCubicInch(response?.data[0]?.signWeightPerSqinch);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-3 margin-adjust ">
        <div
          style={{
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.20)",
          }}
          className="border-2 border-[#AFE9E0] rounded-3xl  p-4"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "2rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Review Your Inquiry
            </Typography>
            <div
              style={{
                width: "40%",
                height: "10vh",
                display: warningOne?.warningStatus ? "" : "none",
                zIndex: 9,
              }}
              className="warning"
            >
              <Typography className="warning-title">
                {warningOne?.title}
              </Typography>
              <div
                className="enable-listing-style warning-desc"
                dangerouslySetInnerHTML={{
                  __html: warningOne?.warningContent,
                }}
              ></div>
            </div>
          </div>
          {/* <Typography
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
              fontWeight: "400",
              fontFamily: "Beatrice",
              color: "#000000",
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {format} {product.source} Formatting
            </span>
          </Typography> */}
          <Row style={{ marginTop: "20px" }}>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SelectedCard heading={"Format"} selection={source} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SelectedCard heading={"Content"} selection={fontFamilyArray} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <ImageCard
                heading={"Colors"}
                selection={colorImages}
                fontColors={fontColors}
              />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <BackingStyleCard
                heading={"Backing Style"}
                selection={backingStyle}
              />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <BackingColorCard
                heading={"Backing Color"}
                selection={backing.color}
              />
            </Col>

            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <SizeCard heading={"Size"} selection={WH} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <AccessoriesCard heading={"Accessories"} selection={wireColor} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <ControllerCard heading={"Controllers"} selection={dimmer} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <AddOnCard heading={"Add-ons"} />
            </Col>
            <Col
              style={{
                marginBottom: "10px",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
            >
              <RefImageCard heading={"Reference Image"} selection={refImage} />
            </Col>
          </Row>
          <Notes />
          <Row>
            <Col
              xs={12}
              md={product.format === "custom" ? 8 : 9}
              lg={product.format === "custom" ? 8 : 9}
              sm={12}
              style={{ paddingRight: window.innerWidth > 762 ? "30px" : "" }}
            >
              <BasicInfo errors={errors} />

              {product?.delivery?.deliveryType === "pickup" ? (
                <></>
              ) : (
                <>
                  <DeliveryInfo />
                </>
              )}
              {product.format === "super-custom" ? (
                <SuperPreview
                  id={product?.format === "super-custom" ? "divToConvert" : ""}
                />
              ) : (
                <>
                  <Preview
                    id={product?.format === "custom" ? "divToConvert" : ""}
                  />
                </>
              )}
            </Col>
            <Col
              md={product.format === "custom" ? 4 : 3}
              lg={product.format === "custom" ? 4 : 3}
              xs={12}
              sm={12}
              style={{ paddingLeft: "30px" }}
            >
              {product.format === "super-custom" ||
              product.backing.name === "custom" ||
              product.delivery.deliveryType === "rush" ? (
                <></>
              ) : (
                <>
                  <PriceDetails />
                </>
              )}
              <div
                style={{
                  marginTop: "20px",
                  height: "auto",
                  display: warningTwo?.warningStatus ? "" : "none",
                }}
                className="warning"
              >
                <Typography className="warning-title">
                  {warningTwo?.title}
                </Typography>
                <div
                  className="enable-listing-style warning-desc"
                  dangerouslySetInnerHTML={{
                    __html: warningTwo?.warningContent,
                  }}
                ></div>
              </div>
              <Row
                style={{
                  margin: "0px",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                    value={toc}
                    onChange={() => {
                      setTOC(!toc);
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      fontFamily: "Beatrice",
                      textAlign: "left",
                      fontWeight: "400",
                    }}
                  >
                    *I have read all the{" "}
                    <a
                      style={{
                        color: "#BF6BE3",
                        fontFamily: "Beatrice",
                        fontWeight: "400",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://glo-studio.com/policies/terms-of-service"
                    >
                      Terms of Service
                    </a>
                    {/* <span
                      style={{
                        color: "#BF6BE3",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      terms and conditions
                    </span> */}
                  </Typography>
                </div>
                {errors.toc && (
                  <div
                    style={{
                      // display: "block",
                      color: "red",
                      // margin: "5px",
                    }}
                  >
                    Please check this box to proceed
                  </div>
                )}
              </Row>
              <Row style={{ margin: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    sx={{
                      color: "#AFE9E0",
                      "&.Mui-checked": {
                        color: "#9EA0DC",
                      },
                    }}
                  />
                  <Typography
                    style={{
                      color: "black",
                      textAlign: "left",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    Yes, add me to your newsletter!
                  </Typography>
                </div>
              </Row>
              {/* <Row style={{ margin: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      height: "auto",
                    }}
                    className="warning"
                  >
                    <div className="enable-listing-style warning-desc">
                      Before you proceed to checkout. Kindly allow Pop-up on
                      your browser.
                    </div>
                  </div>
                   <Typography
                    style={{
                      color: "black",
                      textAlign: "left",
                      fontFamily: "Beatrice",
                      fontWeight: "400",
                    }}
                  >
                    Before you proceed to checkout. Kindly allow pop-ups to
                    proceed.
                  </Typography> 
                </div>
              </Row> */}

              {product.format === "super-custom" ||
              product.backing.name === "custom" ||
              product.delivery.deliveryType === "rush" ? (
                <>
                  {png ? (
                    <>
                      <Button
                        className=" hover-effect"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #36CDB4",
                          borderRadius: "24px",
                          cursor: "pointer",
                          marginTop: "25px",
                          // fontSize: "32px",
                          // fontWeight: "400",
                          // fontFamily: "Beatrice",
                          // color: "#07AB90",
                          // textTransform: "none",
                          color: "#FFFFFF",
                          fontSize: window.innerWidth >= 768 ? "30px" : "1rem",
                          // width: "100%",
                          fontFamily: "Beatrice",
                          fontWeight: "600",
                          bgcolor: "#36CDB4",
                          // borderRadius: "24px",
                          padding: "25px",
                          // marginTop: "25px",
                          ":hover": {
                            color: "#FFFFFF",
                            bgcolor: "#36CDB4",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Proceed to Submission
                      </Button>
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        // height="100%"
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {png && svgLink.length > 0 ? (
                    <>
                      {loading ? (
                        <>
                          <ThreeDots
                            // height="100%"
                            width="100%"
                            radius="9"
                            color="#36CDB4"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            className=" hover-effect"
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "2px solid #36CDB4",
                              borderRadius: "24px",
                              cursor: "pointer",
                              marginTop: "25px",
                              // fontSize: "32px",
                              // fontWeight: "400",
                              // fontFamily: "Beatrice",
                              // color: "#07AB90",
                              // textTransform: "none",
                              color: "#FFFFFF",
                              fontSize:
                                window.innerWidth >= 768 ? "30px" : "1rem",
                              // width: "100%",
                              fontFamily: "Beatrice",
                              fontWeight: "600",
                              bgcolor: "#36CDB4",
                              // borderRadius: "24px",
                              padding: "25px",
                              // marginTop: "25px",
                              ":hover": {
                                color: "#FFFFFF",
                                bgcolor: "#36CDB4",
                              },
                            }}
                            onClick={handleDraft}
                          >
                            Proceed to Checkout
                          </Button>
                          <Button
                            className=" hover-effect"
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "2px solid #AFE9E0",
                              borderRadius: "24px",
                              cursor: "pointer",
                              marginTop: "25px",
                              // fontSize: "32px",
                              // fontWeight: "400",
                              // fontFamily: "Beatrice",
                              // color: "#07AB90",
                              // textTransform: "none",
                              fontSize: "30px",
                              // width: "100%",
                              fontFamily: "Beatrice",
                              fontWeight: "600",
                              bgcolor: "#D8F4F1",

                              // borderRadius: "24px",
                              padding: "10px",

                              // marginTop: "25px",
                            }}
                            onClick={() => setShowInquiry(true)}
                          >
                            <Typography
                              style={{
                                color: "#07AB90",
                                fontSize:
                                  window.innerWidth >= 768 ? "30px" : "1rem",
                                // width: "100%",
                                fontFamily: "Beatrice",
                                fontWeight: "600",

                                // borderRadius: "24px",
                              }}
                            >
                              Have Questions?
                            </Typography>
                            <Typography
                              style={{
                                color: "#07AB90",
                                fontSize:
                                  window.innerWidth >= 768 ? "30px" : "1rem",
                                // width: "100%",
                                fontFamily: "Beatrice",
                                fontWeight: "400",
                                // borderRadius: "24px",
                              }}
                            >
                              Submit for a Quote
                            </Typography>
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        // height="100%"
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  className=" hover-effect"
                  sx={{
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #AFE9E0",
                    borderRadius: "24px",
                    cursor: "pointer",
                    marginTop: "25px",
                    fontSize: window.innerWidth >= 768 ? "24px" : "0.75rem",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#07AB90",
                    textTransform: "none",
                  }}
                  onClick={() => setShowSave(true)}
                >
                  Save Your Design
                </Button>
                <Button
                  className=" hover-effect"
                  sx={{
                    width: "48%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #AFE9E0",
                    borderRadius: "24px",
                    cursor: "pointer",
                    marginTop: "25px",
                    fontSize: window.innerWidth >= 768 ? "24px" : "0.75rem",
                    fontWeight: "400",
                    fontFamily: "Beatrice",
                    color: "#07AB90",
                    textTransform: "none",
                  }}
                  onClick={() => setShowShare(true)}
                >
                  Share Your Design
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <SubmitInquiry
          calculateTotalKerning={calculateTotalKerning}
          errors={errors}
          setErrors={setErrors}
          backing={backing}
          backingColors={backingColors}
          cables={cables}
          calculateFontArea={calculateFontArea}
          calculateLetterLength={calculateLetterLength}
          colors={colors}
          cost={cost}
          deliveryDetails={deliveryDetails}
          fontColors={fontColors}
          fonts={fonts}
          format={format}
          png={png}
          power={power}
          powerQuantity={powerQuantity}
          shippingCost={shippingCost}
          source={source}
          weightPerCubicInch={weightPerCubicInch}
          svgLink={svgLink}
          link={png}
          showSave={showInquiry}
          closeSave={() => setShowInquiry(false)}
        />
        {/* <Terms showTerms={showTerms} closeTerms={() => setShowTerms(false)} /> */}
        <Save
          weightPerCubicInch={weightPerCubicInch}
          svgLink={svgLink}
          link={png}
          showSave={showSave}
          closeSave={() => setShowSave(false)}
        />
        <Share
          link={png}
          showShare={showShare}
          closeShare={() => setShowShare(false)}
        />
        <ThankYouModal
          open={openThankYou}
          close={() => setOpenThankYou(false)}
          link={link}
        />
      </div>
    </>
  );
};

export default Review;
