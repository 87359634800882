import {
  Box,
  Button,
  Modal,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../../Assets/Close";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Info from "../../../Assets/SuperCustom/Info";
import CustomColors from "../../../Assets/CustomColors.png";
import {
  addOtherColors,
  removeOtherColors,
  setCustomBacking,
  setProductBackingColor,
} from "../../../Redux/Slice";
import { SketchPicker } from "react-color";
import { getOSInfo } from "../../../utils/client/calculations";
import { debounce } from "../../../utils/client/helpers";

const ChooseBackingColor = ({
  bgColor,
  setBgColor,
  setCustom,
  setShowNoBacking,
  show,
  close,
}) => {
  const backingColors = useSelector((state) => state.activeStep.backingColors);
  const product = useSelector((state) => state.activeStep.product);

  const dispatch = useDispatch();
  // const [success, setSuccess] = useState(false);
  const [customColor, setCustomColor] = useState(false);

  // const colors = useSelector((state) => state.activeStep.colors);
  // const otherColors = useSelector((state) => state.activeStep.otherColors);
  // const [name, setName] = useState("");
  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  // const handleColorClick = (color) => {
  //   // console.log(color);
  //   if (otherColors?.includes(color)) {
  //     dispatch(removeOtherColors(color));
  //   } else {
  //     dispatch(addOtherColors(color));
  //   }
  //   // console.log(color);
  // };

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  // const checkFontRenderable = async () => {
  //   // Create a new FontFace with the uploaded font file
  //   const font = new FontFace(
  //     name,
  //     `url(${URL.createObjectURL(fontFile)}) format('woff')`
  //   );

  //   try {
  //     // Load the font asynchronously
  //     await font.load();

  //     // Add the loaded font to the document
  //     document.fonts.add(font);

  //     // Check if the font has been registered and is ready for use
  //     if (document.fonts.check(`12px ${name}`)) {
  //       const newFont = {
  //         _id: "new-font-id",
  //         name: name,
  //         type: product?.source,
  //         description: "<p>This is a new font added by the user</p>",
  //       };
  //       console.log("Font is renderable and ready for use.");
  //       dispatch(addFont(newFont));
  //     } else {
  //       console.log("Font is not renderable.");
  //     }
  //   } catch (error) {
  //     console.error("Error loading font:", error);
  //   }
  // };

  const handleChangeComplete = (color) => {
    document.documentElement.style.setProperty(
      "--custom-background-color",
      color.hex
    );
    // console.log(color);
    dispatch(setCustomBacking(true));
    dispatch(setProductBackingColor(color.hex));
    setBgColor(color.hex);
  };

  const handleSubmit = () => {
    close();
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "60%" : "100%",
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    overflowX: "hidden",
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);

  // console.log(product.backing.color);
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Pick a Backing Color
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <div
            style={{
              height: window.innerWidth > 768 ? "300px" : "200px",
              overflowY: "auto",
              // paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <Row
              style={{
                margin: "10px",
              }}
            >
              {backingColors.map((backingColor, index) => (
                <Col xs={6} sm={6} md={4} lg={4} xl={3}>
                  <div
                    className={` border-2 border-[#AFE9E0] m-1 cursor-pointer rounded-3xl font-black text-center items-center text-3xl ${
                      bgColor === backingColor?.code
                        ? "selected"
                        : "hover-effect"
                    }`}
                  >
                    <div
                      style={{
                        width: "100%",
                        // height: window.innerWidth > 768 ? "95px" : "60px",
                        position: "relative",
                        display: "flex",
                      }}
                      onClick={() => {
                        document.documentElement.style.setProperty(
                          "--custom-background-color",
                          backingColor?.code
                        );
                        setCustomColor(false);
                        setBgColor(backingColor?.code);
                        dispatch(setProductBackingColor(backingColor?.code));
                      }}
                      onTouchStart={() => {
                        document.documentElement.style.setProperty(
                          "--custom-background-color",
                          backingColor?.code
                        );
                        setCustomColor(false);
                        setBgColor(backingColor?.code);
                        dispatch(setProductBackingColor(backingColor?.code));
                        handleHoverChange(index, true);
                      }}
                      onTouchEnd={() => {
                        handleHoverChange(index, false);
                      }}
                      onMouseEnter={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, true);
                        }
                      }}
                      onMouseLeave={() => {
                        let userPlatform = getOSInfo();
                        if (
                          !(
                            userPlatform === "iPhone" ||
                            userPlatform === "Android"
                          )
                        ) {
                          handleHoverChange(index, false);
                        }
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          opacity: hoveredStates[index] ? 0 : 1,
                          borderRadius: "24px",
                        }}
                        src={backingColor?.image}
                        alt=""
                      />
                      {hoveredStates[index] && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            // backgroundImage: `url('${Spencer}')`, // Replace with the actual path
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {backingColor.name} <br />
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
              <Col xs={6} sm={6} md={4} lg={3}>
                <div
                  className={`border-2 border-[#AFE9E0] m-1 cursor-pointer rounded-3xl font-black  ${
                    customColor ? "selected" : ""
                  }`}
                  style={{
                    height: "100%",
                  }}
                  onClick={() => {
                    // setBG("white");
                    setCustomColor(true);
                    setBgColor("#ffff");
                    dispatch(setProductBackingColor("white"));
                    dispatch(setCustomBacking(true));
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                    }}
                    src={CustomColors}
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </div>
          {customColor && (
            <div
              className={`border-2 border-[#AFE9E0] m-1 cursor-pointer rounded-3xl font-black text-center items-center`}
              style={{
                width: "100%",
                // height: "150px",
              }}
            >
              <Row>
                <Typography
                  style={{
                    fontFamily: "Beatrice-Display",
                    color: "#25CCB0",
                    fontSize: "30px",
                    fontWeight: "700",
                    fontStyle: "italic",
                    textAlign: "left",
                    letterSpacing: "0.05em",
                    marginLeft: "10px",
                  }}
                >
                  Custom Color
                </Typography>
              </Row>
              <Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SketchPicker
                    disableAlpha
                    presetColors={[]}
                    width="40%"
                    color={product.backing.color}
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
              </Row>
            </div>
          )}
          <div
            className="mobile-flow-switch"
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                width: "30%",
                color: "#ffffff",
                fontSize: "16px",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#36CDB4",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  color: "#36CDB4",
                  border: "1px solid #36CDB4",
                },
              }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ChooseBackingColor;
