import React from "react";

const WhatsApp = () => {
  return (
    <svg
      width="54"
      height="52"
      viewBox="0 0 54 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9382 51.3363C41.4954 51.3363 53.31 39.8369 53.31 25.6681C53.31 11.4993 41.4954 0 26.9382 0C12.381 0 0.566406 11.4993 0.566406 25.6681C0.566406 39.8369 12.381 51.3363 26.9382 51.3363Z"
        fill="#36CDB4"
      />
      <path
        d="M35.6248 17.1753C34.4965 16.066 33.1525 15.1864 31.6714 14.588C30.1902 13.9895 28.6014 13.6841 26.9977 13.6895C20.2781 13.6895 14.8016 19.0199 14.8016 25.5602C14.8016 27.6564 15.3677 29.6928 16.4261 31.4895L14.7031 37.6465L21.1642 35.9934C22.9487 36.9397 24.9547 37.4428 26.9977 37.4428C33.7172 37.4428 39.1937 32.1124 39.1937 25.5722C39.1937 22.3979 37.9261 19.4152 35.6248 17.1753ZM26.9977 35.4304C25.1762 35.4304 23.3918 34.9513 21.8288 34.0529L21.4596 33.8373L17.6198 34.8195L18.6413 31.1781L18.3952 30.8068C17.3832 29.2339 16.8459 27.4159 16.8445 25.5602C16.8445 20.122 21.398 15.6899 26.9854 15.6899C29.6929 15.6899 32.2404 16.7201 34.1479 18.5887C35.0925 19.5038 35.841 20.5923 36.3501 21.7911C36.8592 22.9898 37.1189 24.275 37.1139 25.5722C37.1385 31.0104 32.585 35.4304 26.9977 35.4304ZM32.5604 28.0517C32.2527 27.908 30.7512 27.1893 30.4805 27.0814C30.1974 26.9856 30.0005 26.9377 29.7913 27.2252C29.5821 27.5247 29.0037 28.1954 28.8314 28.3871C28.6591 28.5907 28.4745 28.6147 28.1668 28.459C27.8591 28.3152 26.8746 27.9918 25.7178 26.9856C24.807 26.195 24.204 25.2248 24.0194 24.9253C23.8471 24.6259 23.9948 24.4701 24.1548 24.3144C24.2902 24.1827 24.4625 23.967 24.6101 23.7993C24.7578 23.6316 24.8194 23.4999 24.9178 23.3082C25.0163 23.1046 24.967 22.9369 24.8932 22.7932C24.8194 22.6494 24.204 21.188 23.9579 20.5891C23.7117 20.0142 23.4533 20.086 23.2687 20.074H22.678C22.4687 20.074 22.1488 20.1459 21.8657 20.4454C21.595 20.7448 20.8073 21.4635 20.8073 22.9249C20.8073 24.3863 21.9026 25.7998 22.0503 25.9914C22.198 26.195 24.204 29.1897 27.2561 30.4714C27.9822 30.7828 28.5483 30.9625 28.9914 31.0942C29.7175 31.3218 30.382 31.2859 30.9112 31.214C31.502 31.1302 32.7203 30.4953 32.9665 29.8006C33.2249 29.1058 33.2249 28.5189 33.1388 28.3871C33.0526 28.2553 32.868 28.1954 32.5604 28.0517Z"
        fill="white"
      />
    </svg>
  );
};

export default WhatsApp;
