import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setShippingCost } from "../../../Redux/Slice";

const SuperPickUp = () => {
  const product = useSelector((state) => state.activeStep.product);
  const [minimumProductionDays, setMinimumProductionDays] = useState(0);
  const [maximumProductionDays, setMaximumProductionDays] = useState(0);

  const dispatch = useDispatch();

  const getProductionDays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delivery/nyc/${product.source}/${product.backing.name}`
      );
      if (response?.data[0]) {
        setMinimumProductionDays(response?.data[0]?.minimumProductionDays);
        setMaximumProductionDays(response?.data[0]?.maximumProductionDays);
        const pickUpPackingMaterial =
          response?.data[0]?.glassNeonPickUpPackingMaterialsPerSquareInch;

        // console.log(pickUpPackingMaterial);
        let shippingCost =
          (product.size.width + product.backing.width + 5) *
          (product.size.height + product.backing.height + 5) *
          pickUpPackingMaterial;
        dispatch(setShippingCost(shippingCost));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductionDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className="shipping-bg"
        style={{
          display: "flex",
          height: "280px",
          flexDirection: "column",
          textAlign: "center",
          color: "#07AB90",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: window.innerWidth > 768 ? "50px" : "",
          }}
        >
          <Typography
            style={{
              fontSize: window.innerWidth > 768 ? "21px" : "1rem",
              fontFamily: "Beatrice",
              width: "50%",
            }}
          >
            Submit your design today with the{" "}
            <Typography
              style={{
                display: "inline-block",
                fontSize: window.innerWidth > 768 ? "21px" : "1rem",
                fontFamily: "Beatrice",
                color: "#BF6BE3",
              }}
            >
              “NYC Pick Up”
            </Typography>{" "}
            method, and your estimated delivery window will be:
          </Typography>
        </div>
        <Typography
          style={{
            fontSize: window.innerWidth > 768 ? "3rem" : "2rem",
            fontFamily: "Beatrice",
          }}
        >
          {minimumProductionDays} – {maximumProductionDays} Business Days +
          Pickup time
        </Typography>
      </div>
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Beatrice",
          }}
        >
          Pickup is the easiest!
        </Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontFamily: "Beatrice",
            color: "#595959",
          }}
        >
          NYC Pick Up is the most cost effective option if you're located
          nearby. Swing on by our studio to grab your piece.
        </Typography>
      </div>
      <div
        style={{
          marginTop: "20px",
          borderRadius: "20px",
          height: "600px",
          width: "100%",
        }}
      >
        <iframe
          style={{
            borderRadius: "24px",
          }}
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.911608670714!2d-73.9868481!3d40.7199623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259816d03e001%3A0x99d4398aecc2108e!2s133%20Norfolk%20St%2C%20New%20York%2C%20NY%2010002%2C%20USA!5e0!3m2!1sen!2s!4v1687291468401!5m2!1sen!2s"
          width="100%"
          height="600"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default SuperPickUp;
