// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media query for larger screen sizes */
/* Media query for larger screen sizes */

/* Media query for mobile view */
@media (max-width: 1280px), (max-width: 1480px) {
  .size-input {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .size-input {
    width: 115%;
  }
}
@media (max-width: 1100px) {
  .size-input {
    width: 115%;
  }
}
@media (max-width: 992px) {
  .size-input {
    width: 100%;
  }
}

/* Media query for smaller screenss */
@media (max-width: 991px) {
  
  .size-items {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .size-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .other-fonts {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .size-item {
    width: 55%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .size-items {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/styles/Size.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC,wCAAwC;;AAExC,gCAAgC;AAChC;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;;AAEA,qCAAqC;AACrC;;EAEE;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":["/* Media query for larger screen sizes */\n/* Media query for larger screen sizes */\n\n/* Media query for mobile view */\n@media (max-width: 1280px), (max-width: 1480px) {\n  .size-input {\n    width: 100%;\n  }\n}\n@media (max-width: 1200px) {\n  .size-input {\n    width: 115%;\n  }\n}\n@media (max-width: 1100px) {\n  .size-input {\n    width: 115%;\n  }\n}\n@media (max-width: 992px) {\n  .size-input {\n    width: 100%;\n  }\n}\n\n/* Media query for smaller screenss */\n@media (max-width: 991px) {\n  \n  .size-items {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .size-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .other-fonts {\n    margin-bottom: 20px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .size-item {\n    width: 55%;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n@media (max-width: 767px) {\n  .size-items {\n    width: 70%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
