import React from "react";
import { Carousel } from "react-bootstrap";

const AddOnCarousel = ({ images }) => {
  // console.log(images);
  return (
    <Carousel>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          {/* Don't forget to add a key */}
          <img
            style={{
              width: "100%",
              // height: "150px",
            }}
            className=" rounded-3xl"
            src={image}
            alt={`${index} img`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default AddOnCarousel;
