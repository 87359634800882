// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width: 769px) {
    .header-tabs {
      margin-bottom: 0px;
      height: 50px;
    }
  }
  @media (max-width: 440px) {
    .sketch-design-button {
      margin-bottom: 0px;
      height: 40px;
    }
  .mobile-margin{
    margin-bottom: 270px;
  }
    .preview-design-text{
    margin-top: "40px";
  }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/SuperCustom/styles/SuperBacking.css"],"names":[],"mappings":";AACA;IACI;MACE,kBAAkB;MAClB,YAAY;IACd;EACF;EACA;IACE;MACE,kBAAkB;MAClB,YAAY;IACd;EACF;IACE,oBAAoB;EACtB;IACE;IACA,kBAAkB;EACpB;EACA","sourcesContent":["\n@media (max-width: 769px) {\n    .header-tabs {\n      margin-bottom: 0px;\n      height: 50px;\n    }\n  }\n  @media (max-width: 440px) {\n    .sketch-design-button {\n      margin-bottom: 0px;\n      height: 40px;\n    }\n  .mobile-margin{\n    margin-bottom: 270px;\n  }\n    .preview-design-text{\n    margin-top: \"40px\";\n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
