// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media (max-width: 840px) {
  .messenger-main{
      margin-top: 60px;  
  }
}


@media (max-width: 816px) {
  .messenger-main{
      margin-top: 90px;  
  }
}
@media (max-width: 768px) {
    .messenger-main{
        margin-top: 100px;  
    }
  }

  @media (max-width: 668px) {
    .messenger-main{
        margin-top: 90px;  
    }
  }
  @media (max-width: 568px) {
    .messenger-main{
        margin-top: 90px;  
    }
  }
  @media (max-width: 474px) {
    .messenger-main{
        margin-top: 150px;  
    }
  }
  @media (max-width: 468px) {
    .messenger-main{
        margin-top: 160px;  
    }
  }
  @media (max-width: 450px) {
    .messenger-main{
        margin-top: 170px;  
    }
  }
  @media (max-width: 438px) {
    .messenger-main{
        margin-top: 180px;  
    }
  }
  @media (max-width: 410px) {
    .messenger-main{
        margin-top: 195px;  
    }
  }
  @media (max-width: 392px) {
    .messenger-main{
        margin-top: 255px;  
    }
  }
  @media (max-width: 374px) {
    .messenger-main{
        margin-top: 290px;  
    }
  }
  @media (max-width: 342px) {
    .messenger-main{
        margin-top: 310px;  
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Components/Delivery/styles/Meseenger.css"],"names":[],"mappings":";;AAEA;EACE;MACI,gBAAgB;EACpB;AACF;;;AAGA;EACE;MACI,gBAAgB;EACpB;AACF;AACA;IACI;QACI,iBAAiB;IACrB;EACF;;EAEA;IACE;QACI,gBAAgB;IACpB;EACF;EACA;IACE;QACI,gBAAgB;IACpB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF;EACA;IACE;QACI,iBAAiB;IACrB;EACF","sourcesContent":["\n\n@media (max-width: 840px) {\n  .messenger-main{\n      margin-top: 60px;  \n  }\n}\n\n\n@media (max-width: 816px) {\n  .messenger-main{\n      margin-top: 90px;  \n  }\n}\n@media (max-width: 768px) {\n    .messenger-main{\n        margin-top: 100px;  \n    }\n  }\n\n  @media (max-width: 668px) {\n    .messenger-main{\n        margin-top: 90px;  \n    }\n  }\n  @media (max-width: 568px) {\n    .messenger-main{\n        margin-top: 90px;  \n    }\n  }\n  @media (max-width: 474px) {\n    .messenger-main{\n        margin-top: 150px;  \n    }\n  }\n  @media (max-width: 468px) {\n    .messenger-main{\n        margin-top: 160px;  \n    }\n  }\n  @media (max-width: 450px) {\n    .messenger-main{\n        margin-top: 170px;  \n    }\n  }\n  @media (max-width: 438px) {\n    .messenger-main{\n        margin-top: 180px;  \n    }\n  }\n  @media (max-width: 410px) {\n    .messenger-main{\n        margin-top: 195px;  \n    }\n  }\n  @media (max-width: 392px) {\n    .messenger-main{\n        margin-top: 255px;  \n    }\n  }\n  @media (max-width: 374px) {\n    .messenger-main{\n        margin-top: 290px;  \n    }\n  }\n  @media (max-width: 342px) {\n    .messenger-main{\n        margin-top: 310px;  \n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
