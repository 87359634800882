import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import {
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleTooltipClose,
} from "../utils/client/helpers";
import Info from "../Assets/SuperCustom/Info";
import { useSelector } from "react-redux";

const PriceEstimate = () => {
  const cost = useSelector((state) => state.activeStep.product.cost);

  const [openPriceInfo, setOpenPriceInfo] = useState(false);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      arrow
      placement="bottom-start"
      {...props}
      style={{
        backgroundColor: "",
      }}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "14px",
      maxWidth: 200,
      backgroundColor: "#D9FF9E",
      color: "#03705E",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#D9FF9E",
    },
  });

  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref}>
        <Info />
      </div>
    );
  });
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          style={{ fontFamily: "Beatrice", paddingTop: "5px" }}
          className="text-[#BF6BE3]"
        >
          Price Estimate
        </Typography>
        <CustomWidthTooltip
          open={openPriceInfo}
          onClose={() => handleTooltipClose(setOpenPriceInfo)}
          title={
            "Have questions about pricing or just need a human touch? Go through the process and submit an inquiry at the end. Someone from our team will be in touch!"
          }
        >
          <MyComponent
            onMouseEnter={() => handleMouseEnter(setOpenPriceInfo)}
            onMouseLeave={() => handleMouseLeave(setOpenPriceInfo)}
            onClick={() => handleClick(setOpenPriceInfo, openPriceInfo)}
            style={{
              marginLeft: "10px",
              display: "inline-block",
            }}
          />
        </CustomWidthTooltip>
      </div>
      {cost ? (
        <>
          <Typography
            style={{ fontFamily: "Beatrice" }}
            className="text-[#07AB90] w-full text-md-end text-3xl"
          >
            ${cost}
          </Typography>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <ThreeDots
              // height="100%"
              width="100%"
              radius="9"
              color="#36CDB4"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PriceEstimate;
