import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Wire from "../../Assets/Wire";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import Dimmer from "../../Assets/Dimmer";
import TransformerBox from "../../Assets/TransformerBox";
import PreviewDiv from "./PreviewDiv";

const Preview = ({ id }) => {
  const product = useSelector((state) => state.activeStep.product);
  const accessoryPosition = useSelector(
    (state) => state.activeStep.product.accessories.position
  );
  const wire = useSelector(
    (state) => state.activeStep.product.accessories.wireColor
  );

  const theme = useSelector((state) => state.activeStep.theme);

  return (
    <>
      <Typography
        style={{
          color: "#000000",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
          fontWeight: "400",
          fontFamily: "Beatrice",
        }}
      >
        Preview
      </Typography>

      <div
        id={id}
        style={{
          backgroundColor: `${theme}`,
          color: !theme,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          flexDirection: "column",
          overflowY: "auto",
        }}
        className=" border-2 border-[#AFE9E0] cursor-pointer rounded-3xl text-center items-center"
      >
        <PreviewDiv />
        <Draggable
          disabled
          defaultPosition={accessoryPosition || { x: 0, y: 0 }}
        >
          {product?.backing?.name && product.backing.name !== "skeleton" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {product?.accessories?.dimmer?.name ? (
                <Dimmer color={wire} />
              ) : (
                <Wire color={wire} />
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {product?.accessories?.dimmer?.name ? (
                <Dimmer color={wire} />
              ) : (
                <>
                  <TransformerBox color={wire} />
                </>
              )}
            </div>
          )}
        </Draggable>
      </div>
    </>
  );
};

export default Preview;
