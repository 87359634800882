// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
/* Media query for larger screen sizes */
/* Media query for larger screen sizes */



@media (max-width: 1000px)  {
    .drop-down {
 margin:auto;
 margin-bottom: 30px;
 width: 100%;
  }
}
@media (max-width: 700px)  {
    .drop-down {
 width: 100%;
  }
}
 /* Media query for smaller screens */
 @media (max-width: 769px) {
  .accessories-items {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
 
  .accessories-main {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/styles/Accessories.css"],"names":[],"mappings":"AAAA,eAAe;AACf,wCAAwC;AACxC,wCAAwC;;;;AAIxC;IACI;CACH,WAAW;CACX,mBAAmB;CACnB,WAAW;EACV;AACF;AACA;IACI;CACH,WAAW;EACV;AACF;CACC,oCAAoC;CACpC;EACC;IACE,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;EACrB;AACF","sourcesContent":["/* styles.css */\n/* Media query for larger screen sizes */\n/* Media query for larger screen sizes */\n\n\n\n@media (max-width: 1000px)  {\n    .drop-down {\n margin:auto;\n margin-bottom: 30px;\n width: 100%;\n  }\n}\n@media (max-width: 700px)  {\n    .drop-down {\n width: 100%;\n  }\n}\n /* Media query for smaller screens */\n @media (max-width: 769px) {\n  .accessories-items {\n    margin-bottom: 20px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }  \n \n  .accessories-main {\n    width: 100%;\n    margin-bottom: 20px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
