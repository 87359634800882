import { useEffect } from "react";

const Font = ({ name, woffFile, woff2File }) => {
  useEffect(() => {
    const fontFace = new FontFace(
      name,
      `url(${woff2File}) format('woff2'), url(${woffFile}) format('woff')`
    );

    document.fonts.add(fontFace);
  }, [name, woffFile, woff2File]);

  return null;
};

export default Font;
