import { Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
// import Input from "react-phone-number-input/input";
import { useDispatch, useSelector } from "react-redux";
import { setProductDeliveryDetails } from "../../Redux/Slice";

const BasicInfo = ({ errors }) => {
  const details = useSelector(
    (state) => state.activeStep.product.delivery.details
  );

  const dispatch = useDispatch();
  return (
    <>
      <Typography
        style={{
          color: "#000000",
          marginTop: "20px",
          marginBottom: "10px",
          fontSize: window.innerWidth > 768 ? "30px" : "1.5rem",
          fontWeight: "400",
          fontFamily: "Beatrice",
        }}
      >
        Basic Info
      </Typography>
      <Row
        style={{
          marginBottom: "20px",
        }}
      >
        <Col xs={12} sm={12} lg={6} md={6}>
          <label
            style={{
              marginBottom: "5px",
            }}
          >
            Name
          </label>
          <input
            style={{
              border: "2px solid #AFE9E0",
              outline: "none",
              width: "100%",
              borderRadius: "8px",
              padding: "10px",
            }}
            name="address"
            placeholder="Please enter your name here"
            value={details?.name}
            onChange={(e) => {
              dispatch(
                setProductDeliveryDetails({
                  ...details,
                  name: e.target.value,
                })
              );
            }}
          />
          {errors.name && (
            <span className="text-red-500 text-sm mt-1">{errors.name}</span>
          )}
        </Col>
        <Col xs={12} sm={12} lg={6} md={6}>
          <label
            style={{
              marginBottom: "5px",
            }}
          >
            Email
          </label>
          <input
            style={{
              border: "2px solid #AFE9E0",
              outline: "none",
              width: "100%",
              borderRadius: "8px",
              padding: "10px",
            }}
            name="email"
            value={details?.email}
            onChange={(e) => {
              dispatch(
                setProductDeliveryDetails({
                  ...details,
                  email: e.target.value,
                })
              );
            }}
            placeholder="Please enter your email here"
          />
          {errors.email && (
            <span className="text-red-500 text-sm mt-1">{errors.email}</span>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} lg={6} md={6}>
          <label
            style={{
              marginBottom: "5px",
            }}
          >
            Contact Number
          </label>
          <input
            style={{
              border: "2px solid #AFE9E0",
              outline: "none",
              width: "100%",
              borderRadius: "8px",
              padding: "10px",
            }}
            type="number"
            name="number"
            placeholder="Enter Contact Number"
            value={details?.phoneNumber}
            onChange={(e) => {
              // console.log(e);
              dispatch(
                setProductDeliveryDetails({
                  ...details,
                  phoneNumber: e.target.value,
                })
              );
            }}
          />
          {/* <Input
            style={{
              border: "2px solid #AFE9E0",
              outline: "none",
              width: "100%",
              borderRadius: "8px",
              padding: "10px",
            }}
            placeholder="Enter Contact Number"
            value={details?.phoneNumber}
            onChange={(e) => {
              // console.log(e);
              dispatch(
                setProductDeliveryDetails({
                  ...details,
                  phoneNumber: e,
                })
              );
            }}
          /> */}
          {errors.phoneNumber && (
            <span className="text-red-500 text-sm mt-1">
              {errors.phoneNumber}
            </span>
          )}
        </Col>
        {/* <Col xs={12} sm={12} lg={6} md={6}>
          <label
            style={{
              marginBottom: "5px",
            }}
          >
            Province
          </label>
          <input
            style={{
              border: "2px solid #AFE9E0",
              outline: "none",
              width: "100%",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              padding: "10px",
            }}
            name="province"
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            placeholder="Please enter your Province here"
          />
        </Col> */}
      </Row>
    </>
  );
};

export default BasicInfo;
