// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Media query for mobile view */
@media (max-width: 768px) {
    .shipping-form{
        margin-Top: 60px;  
    }
  }
/* Media query for mobile view */
@media (max-width: 480px) {
    .shipping-form{
        margin-Top: 110px;  
    }
  }
@media (max-width: 380px) {
    .shipping-form{
        margin-Top: 190px;  
    }
  } 
@media (max-width: 340px) {
    .shipping-form{
        margin-Top: 250px;  
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/styles/ShippingInfo.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI;QACI,gBAAgB;IACpB;EACF;AACF,gCAAgC;AAChC;IACI;QACI,iBAAiB;IACrB;EACF;AACF;IACI;QACI,iBAAiB;IACrB;EACF;AACF;IACI;QACI,iBAAiB;IACrB;EACF","sourcesContent":["/* Media query for mobile view */\n@media (max-width: 768px) {\n    .shipping-form{\n        margin-Top: 60px;  \n    }\n  }\n/* Media query for mobile view */\n@media (max-width: 480px) {\n    .shipping-form{\n        margin-Top: 110px;  \n    }\n  }\n@media (max-width: 380px) {\n    .shipping-form{\n        margin-Top: 190px;  \n    }\n  } \n@media (max-width: 340px) {\n    .shipping-form{\n        margin-Top: 250px;  \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
