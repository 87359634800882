import { Checkbox, Radio, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { styled } from "@mui/material";
import { tooltipClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductAccessoriesDimmer,
  setProductAccessoriesWireColor,
} from "../../Redux/Slice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ThreeDots } from "react-loader-spinner";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.37)",
    fontSize: 11,
    borderRadius: "16px",
    arrow: "white",
  },
}));

const AccessoriesList = () => {
  const selectedWire = useSelector(
    (state) => state.activeStep.product.accessories.wireColor
  );
  const dimmer = useSelector(
    (state) => state.activeStep.product.accessories.dimmer
  );
  const product = useSelector((state) => state.activeStep.product);
  const controllers = useSelector((state) => state.activeStep.controllers);
  const cables = useSelector((state) => state.activeStep.cables);
  const dispatch = useDispatch();

  // Set the initial state of expansion based on the current view
  const [isWireExpanded, setIsWireExpanded] = useState(true);
  const [isControllerExpanded, setIsControllerExpanded] = useState(true);
  const handleChange = (event) => {
    dispatch(setProductAccessoriesWireColor(event.target.value));
  };
  const handleCheck = (e, controller) => {
    if (dimmer?.name === controller?.name) {
      dispatch(setProductAccessoriesDimmer({}));
    } else {
      dispatch(setProductAccessoriesDimmer(controller));
    }
  };

  useEffect(() => {
    if (product.source === "LED") {
      dispatch(setProductAccessoriesWireColor("#D3D3D3"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "50%",
        overflowY: "auto",
        paddingRight: "5px",
        fontFamily: "Beatrice",
      }}
    >
      {product.source === "LED" ? (
        <></>
      ) : (
        <>
          <div>
            <div
              onClick={() => setIsWireExpanded(!isWireExpanded)}
              style={{
                fontFamily: "Beatrice",
                color: "#595959",
                backgroundColor: "#D8F4F1",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Accessories Color <ArrowDropDownIcon />
              </div>
            </div>
            {isWireExpanded && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <>
                  {cables.length > 0 ? (
                    <>
                      {cables.map((cable, index) => (
                        <Card
                          key={index}
                          style={{
                            // display: "flex",
                            // flexDirection: "row",
                            height: "50px",
                            border: "none",
                            marginTop: "20px",
                            fontSize: "14px",
                          }}
                        >
                          <Card
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              height: "50px",
                              // justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                              marginTop: "20px",
                              fontSize: "14px",
                            }}
                          >
                            <LightTooltip
                              title={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    border: "2px solid white",
                                    margin: "10px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "#595959",
                                      fontWeight: "600",
                                      fontFamily: "Beatrice",
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {cable?.name}
                                  </Typography>
                                  <img
                                    style={{
                                      height: "150px",
                                    }}
                                    draggable="false"
                                    src={cable?.image}
                                    alt="accessories"
                                  />
                                  <div
                                    className="enable-listing-style"
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "12px",
                                      color: "#8F8F8F",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: cable?.description,
                                    }}
                                  ></div>
                                </div>
                              }
                              placement="right"
                              // arrow
                            >
                              <Card.Img
                                style={{
                                  border: "1px solid #71E4D1",
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                                draggable="false"
                                src={cable?.image}
                              ></Card.Img>
                            </LightTooltip>
                            <Typography
                              style={{
                                fontSize: "14px",
                                padding: "10px",
                                fontWeight: "400",
                                color: "#595959",
                                // textAlign: "center",
                                flexGrow: "2",
                              }}
                            >
                              {cable?.name}
                            </Typography>
                            <Card.Footer
                              style={{
                                border: "none",
                                padding: "0px",
                                margin: "0px",
                                backgroundColor: "white",
                              }}
                            >
                              <Radio
                                sx={{
                                  color: "#AFE9E0",
                                  padding: "0px",
                                  margin: "0px",
                                  "&.Mui-checked": {
                                    color: "#9EA0DC",
                                  },
                                }}
                                // checked={wire === wire.color}
                                checked={selectedWire === cable.color}
                                onChange={handleChange}
                                value={cable.color}
                                name="radio-buttons"
                              />
                            </Card.Footer>
                          </Card>
                        </Card>
                      ))}
                    </>
                  ) : (
                    <>
                      <ThreeDots
                        // height="100%"
                        width="100%"
                        radius="9"
                        color="#36CDB4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </>
                  )}
                </>
              </div>
            )}
          </div>
        </>
      )}
      <div>
        <div
          style={{
            fontFamily: "Beatrice",
            color: "#595959",
            backgroundColor: "#D8F4F1",
            padding: "10px",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "10px",
            marginTop: "20px",
          }}
          onClick={() => setIsControllerExpanded(!isControllerExpanded)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Controllers <ArrowDropDownIcon />
          </div>
        </div>
        {isControllerExpanded && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // textAlign: "center",
              }}
            >
              <>
                {controllers.length > 0 ? (
                  <>
                    {controllers.map((controller, index) => (
                      <Card
                        key={index}
                        style={{
                          border: "none",
                          display: "flex",
                          flexDirection: "row",
                          height: "50px",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "20px",
                          fontSize: "14px",
                        }}
                      >
                        <LightTooltip
                          title={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                border: "2px solid white",
                                margin: "10px",
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#595959",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  textAlign: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                {controller?.name}
                              </Typography>
                              <img
                                style={{
                                  height: "150px",
                                }}
                                src={controller?.image}
                                alt="accessories"
                              />
                              <div
                                className="enable-listing-style"
                                style={{
                                  fontSize: "12px",
                                  color: "#8F8F8F",
                                  marginTop: "10px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: controller?.desc,
                                }}
                              ></div>
                              {/* <Typography
                          style={{
                            color: "#595959",
                            fontWeight: "600",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          Price: {controller?.price}$
                        </Typography> */}
                            </div>
                          }
                          placement="right"
                          // arrow
                        >
                          <Card.Img
                            style={{
                              border: "1px solid #71E4D1",
                              width: "40px",
                              height: "40px",
                              borderRadius: "10px",
                            }}
                            draggable="false"
                            src={controller?.image}
                          ></Card.Img>
                        </LightTooltip>
                        <Typography
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            fontWeight: "400",
                            color: "#595959",
                            // textAlign: "center",
                            flexGrow: "2",
                          }}
                        >
                          {controller?.name}
                        </Typography>
                        <Card.Footer
                          style={{
                            padding: "0px",
                            margin: "0px",
                            border: "none",
                            backgroundColor: "white",
                          }}
                        >
                          <Checkbox
                            sx={{
                              color: "#AFE9E0",
                              padding: "0px",
                              margin: "0px",
                              "&.Mui-checked": {
                                color: "#9EA0DC",
                              },
                            }}
                            checked={controller.name === dimmer.name}
                            onChange={(e) => handleCheck(e, controller)}
                          />
                        </Card.Footer>
                      </Card>
                    ))}
                  </>
                ) : (
                  <>
                    <ThreeDots
                      // height="100%"
                      width="100%"
                      radius="9"
                      color="#36CDB4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </>
                )}
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccessoriesList;
