// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 440px) {
    .mobile-warning-disply {
     display: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/SuperCustom/styles/Shared.css"],"names":[],"mappings":"AAAA;IACI;KACC,aAAa;IACd;EACF","sourcesContent":["@media (max-width: 440px) {\n    .mobile-warning-disply {\n     display: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
