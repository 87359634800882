import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Close from "../../../Assets/Close";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductBacking,
  setProductBackingColor,
} from "../../../Redux/Slice";
import { getOSInfo } from "../../../utils/client/calculations";
import { debounce } from "../../../utils/client/helpers";

const ChooseBacking = ({
  setBgColor,
  bg,
  setBG,
  setShowNoBacking,
  show,
  close,
}) => {
  const backings = useSelector((state) => state.activeStep.backingStyles);

  const dispatch = useDispatch();

  const [hoveredStates, setHoveredStates] = useState([]); // Initialize an array of hover states

  const handleHoverChange = (index, isHovered) => {
    const newHoveredStates = Array(hoveredStates.length).fill(false);

    // Set the specified index to the provided isHovered value
    newHoveredStates[index] = isHovered;

    // Update the state
    setHoveredStates(newHoveredStates);
  };

  const handleSubmit = () => {
    close();
  };

  const style = {
    position: "absolute",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth > 768 ? "60%" : "100%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };

  const debouncedHandleHoverChange = debounce(handleHoverChange, 300);

  useEffect(() => {
    // Attach debounced function to scroll event
    window.addEventListener("scroll", debouncedHandleHoverChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", debouncedHandleHoverChange);
    };
  }, [debouncedHandleHoverChange]);
  return (
    <>
      <Modal
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Beatrice-Display",
                color: "#25CCB0",
                fontSize: window.innerWidth > 768 ? "40px" : "1.5rem",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "0.05em",
              }}
            >
              Pick a Backing Style
            </Typography>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={close}
            >
              <Close />
            </div>
          </div>
          <Typography
            style={{
              color: "#03705E",
              fontFamily: "Beatrice",
              fontWeight: "400",
              fontSize: window.innerWidth > 768 ? "1rem" : "0.75rem",
            }}
          >
            <span
              style={{
                fontWeight: "bolder",
              }}
            >
              PLEASE NOTE:{" "}
            </span>
            Since you are designing a piece with our “All Custom Everything”
            option – the backing you choose here won’t be accurately reflected
            until our team has a moment to finalize this mockup. Once we receive
            your submission, we’ll reach out with a final mockup and pricing for
            your approval.
          </Typography>
          <div
            style={{
              height: window.innerWidth > 768 ? "300px" : "200px",
              overflowY: "auto",
              paddingRight: "30px",
            }}
          >
            <div
              style={{
                paddingRight: "10px",
              }}
            >
              <Row>
                {backings?.map((backing, index) => (
                  <Col xs={6} sm={6} md={4} lg={4}>
                    <div
                      className={` border-2 border-[#AFE9E0] m-3 cursor-pointer rounded-2xl font-black text-center items-center text-3xl ${
                        bg === backing?.type ? "selected" : "hover-effect"
                      }`}
                    >
                      <div
                        style={{
                          width: "100%",
                          // height: "100px",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          if (backing.type === "no") {
                            setShowNoBacking(true);
                          } else {
                            setShowNoBacking(false);
                          }
                          document.documentElement.style.setProperty(
                            "--custom-background-color",
                            "hsla(0,0%,39.2%,.25)"
                          );
                          setBG(backing.type);
                          if (
                            backing.type === "square" ||
                            backing.type === "outline"
                          ) {
                            setBgColor("#F0F0F0");
                          } else {
                            setBgColor("");
                            dispatch(setProductBackingColor(""));
                          }
                          dispatch(setProductBacking(backing.type));
                        }}
                        onTouchStart={() => {
                          if (backing.type === "no") {
                            setShowNoBacking(true);
                          } else {
                            setShowNoBacking(false);
                          }
                          document.documentElement.style.setProperty(
                            "--custom-background-color",
                            "hsla(0,0%,39.2%,.25)"
                          );
                          setBG(backing.type);
                          if (
                            backing.type === "square" ||
                            backing.type === "outline"
                          ) {
                            setBgColor("#F0F0F0");
                          } else {
                            setBgColor("");
                            dispatch(setProductBackingColor(""));
                          }
                          dispatch(setProductBacking(backing.type));
                          handleHoverChange(index, true);
                        }}
                        onTouchEnd={() => {
                          handleHoverChange(index, false);
                        }}
                        onMouseEnter={() => {
                          let userPlatform = getOSInfo();
                          if (
                            !(
                              userPlatform === "iPhone" ||
                              userPlatform === "Android"
                            )
                          ) {
                            handleHoverChange(index, true);
                          }
                        }}
                        onMouseLeave={() => {
                          let userPlatform = getOSInfo();
                          if (
                            !(
                              userPlatform === "iPhone" ||
                              userPlatform === "Android"
                            )
                          ) {
                            handleHoverChange(index, false);
                          }
                        }}
                      >
                        <img
                          style={{
                            opacity: hoveredStates[index] ? 0 : 1,
                          }}
                          src={backing?.image}
                          alt=""
                        />
                        {hoveredStates[index] && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              transition: "opacity 0.3s ease",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                            >
                              {backing.name}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div
            className="mobile-flow-switch"
            style={{
              marginTop: window.innerWidth > 768 ? "30px" : "",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                width: window.innerWidth > 768 ? "30%" : "100%",
                color: "#ffffff",
                fontSize: "16px",
                padding: "10px",
                fontWeight: "600",
                bgcolor: "#36CDB4",
                borderRadius: "16px",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  color: "#36CDB4",
                  border: "1px solid #36CDB4",
                },
              }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ChooseBacking;
